import React from "react";
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Button, Spinner } from "reactstrap";
import ModuleLocked from "./ModuleLocked";

const IntroModule3 = (props) => {

  if (props.moduleBlocked === null) {
    return <Col md="8" className="wizard-pills mr-auto ml-auto">
              <div className="text-center"><Spinner color="primary" size="md"/> </div> 
            </Col>
    }

  if (props.moduleBlocked === true && props.moduleBlocked !== undefined) {
    return  <Col md="8" className="wizard-pills mr-auto ml-auto">
              <ModuleLocked message={'Complete previous module to unlock.'}/>
            </Col>
  }

  return (
    <>
      <Col md="8" className="wizard-pills mr-auto ml-auto">
        <Card>
          <CardHeader className="text-center">
            <h2 className="mb-2"> Module 3 - Super Powers</h2> 
          </CardHeader>
          <CardBody>
            <h1 className="mb-1">Super Powers and Gifts</h1>   
            <p className="mb-3"> How gifts & superpowers make you, YOU</p>
            <iframe className="youtube" title="sp video" src="https://www.youtube.com/embed/u0Katmx4nU0?autoplay=0&showinfo=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </CardBody>
          <CardFooter>
            <Row className="justify-content-end">
              <Button color="default" outline type="button" onClick={() => props.history.push('/user/module3/ex1')}>
                Continue
              </Button>
            </Row> 
          </CardFooter> 
        </Card>  
      </Col> 
    </>
  );
}

export default IntroModule3;