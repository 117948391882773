import React, {useState, useEffect} from "react";
import { Row, Col, ListGroup, ListGroupItem, } from "reactstrap";
import ModuleLocked from "components/Sections/ModuleLocked"

import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';


const M3Ex2Summary = (props) => {
    const [ answers, setAnswers ] = useState({})

    useEffect(() => { setAnswers(props.exercise2m3) }, [props.exercise2m3])
    const { progress, moduleBlocked } = props;

    const renderObject = (answerObj, name) => {
      return Object.entries(answerObj).map(([key, value], i) => {
        if (key === name) {
          return (value.value)
        }
        return null
      })
    }

    return (
      <>
      { progress < 33 || moduleBlocked === true
      ? <ModuleLocked message={'Exercise Locked'}/> 
      : <>
          <Row className="justify-content-center">
            <Col md='10' xs='12' className='mt-2'>
              <h4 className="title-weight">Your Gifts</h4>
              <ListGroup className="thead-light">
                <ListGroupItem className="list-head">
                  <Row>
                    <Col md="4" xs="6">Question</Col>
                    <Col md="8" xs="6">Response</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="list-results">
                  <Row>
                    <Col md="4" xs="6"><p>1. What do you do in your spare time (when and if you have spare time)?</p></Col>
                    <Col md="8" xs="6">{answers.Q1}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="4" xs="6"><p>2. Is what you do in your spare time reflected in your values and strengths? Yes or No? Explain.</p></Col>
                    <Col md="8" xs="6">-{renderObject(answers, 'Q2A')}<br/>{answers.Q2B}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="4" xs="6"><p>3. Do you have hobbies and interests outside your current job or business?</p></Col>
                    <Col md="8" xs="6">{answers.Q3}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="4" xs="6"><p>4. What positive qualities do you possess that you couldn’t deny if you try?</p></Col>
                    <Col md="8" xs="6">{answers.Q4}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="4" xs="6"><p>5. What life experiences set you apart from everyone else?</p></Col>
                    <Col md="8" xs="6">{answers.Q5}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="4" xs="6"><p>6. How is your personal story unique?</p></Col>
                    <Col md="8" xs="6">{answers.Q6}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="4" xs="6"><p>7. What have you always been good at?</p></Col>
                    <Col md="8" xs="6">{answers.Q7}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="4" xs="6"><p>8. What made you stand out as a child?</p></Col>
                    <Col md="8" xs="6">{answers.Q8}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="4" xs="6"><p>9. What makes you feel strong?</p></Col>
                    <Col md="8" xs="6">{answers.Q9}</Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>        
            </Col>
          </Row>
        </>
      }
      </>
    )
}


const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    exercise2m3 : state.firestore.data.exercise2m3 || {}
  }
};

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module3",
          subcollections: [{ collection: "exercises", doc: "exercise2"}],
        }],
        storeAs: 'exercise2m3'
      }]
    }),
)(M3Ex2Summary);