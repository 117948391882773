import React from "react";
import { Route, Switch } from "react-router-dom";
import { Row, Col, Container } from "reactstrap";

import ModuleHeader from "../../../components/Headers/ModuleHeader";
import { ModuleProgressBar, IntroModule2, Module2Map } from "components/Sections";
import UpgradeMembershipModal from 'components/Modals/UpgradeMembershipModal';
import Exercise1 from "./Exercise1";
import Exercise2 from "./Exercise2";
import Exercise3 from "./Exercise3";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

class Module2 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      module2: {},
      progress: 0,
      blocked: null
    }
  }

  componentDidMount() {
    this.setState({
      module2: this.props.module2,
      blocked: this.props.module2.blocked,
      progress: this.props.module2.progress,
    })
  }

  componentDidUpdate(prevProps) { 
    if (this.props.module2 !== prevProps.module2) {
      this.setState({
        module2: this.props.module2,
        blocked: this.props.module2.blocked,
        progress: this.props.module2.progress,
      })
    }
  }

  render() {
    const { progress, blocked } = this.state;
    const { profile, history, module2, module3 } = this.props;
    const isIndividualUser = profile.institution_userID === null || profile.institution_userID === 'na' ? true : false;
    const isStarter = profile.token && profile.token.claims.stripeRole !== 'premium' ? true : false
    
    return (
      <>
       <ModuleHeader color={'module2'}/>
       <Container className="bg-mobile-2 mt-7" fluid>
          <Row>
            <Col md="4" className="text-center d-none d-lg-block">
              <ModuleProgressBar value={module2.progress || 0}/>
              <Module2Map history={history} progress={module2.progress} nextModuleBlocked={module3.blocked}/>   
            </Col>
          
            <Col md="8" className="wizard-pills mr-auto ml-auto">
              <Switch>
                <Route exact path="/user/module2" render={props => <IntroModule2 {...props} history={history} progress={progress} moduleBlocked={blocked}/>}/>
                <Route exact path="/user/module2/ex1" render={props => <Exercise1 {...props} history={history} progress={progress} moduleBlocked={blocked}/>}/>
                <Route exact path="/user/module2/ex2" render={props => <Exercise2 {...props} history={history} progress={progress} moduleBlocked={blocked}/>}/>
                <Route exact path="/user/module2/ex3" render={props => <Exercise3 {...props} history={history} progress={progress} moduleBlocked={blocked}/>}/>
              </Switch>        
            </Col>
          </Row>     
        </Container>

        <UpgradeMembershipModal
          isOpen={isIndividualUser && isStarter}
          history={history}
          message={'Seems like you must upgrade your account before continuing. Please go to settings and subscribe.'}
          redirectTo={'/user/settings'}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    profile: state.firebase.profile,
    module2 : state.firestore.data.module2 || {},
    module3 : state.firestore.data.module3 || {}
  }
};

export default compose( 
  connect(mapStateToProps),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module2"}],
        storeAs: 'module2'
      },
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module3"}],
        storeAs: 'module3'
      }]
    }),
)(Module2);
