const initState = {}
    
  const module1Reducer = (state = initState, action) => {
      switch(action.type){
        case 'SUBMIT_EXERCISE1_SUCCESS':
          console.log('Exercise 1 success');
          return state
        case 'SUBMIT_EXERCISE1_ERROR':
          console.log('Exercise 1 Error Submission');
          return state 
        case 'SUBMIT_EXERCISE2A_SUCCESS':
          console.log('Exercise 2A success');
          return state
        case 'SUBMIT_EXERCISE2A_ERROR':
          console.log('Exercise 2A Error Submission', action.err);
          return state
        case 'SUBMIT_EXERCISE2B_SUCCESS':
          console.log('Exercise 2B success');
          return state
        case 'SUBMIT_EXERCISE2B_ERROR':
          console.log('Exercise 2B Error Submission', action.err);
          return state
        case 'SUBMIT_EXERCISE2C_SUCCESS':
          console.log('Exercise 2C success');
          return state
        case 'SUBMIT_EXERCISE2C_ERROR':
          console.log('Exercise 2C Error Submission', action.err);
          return state 
        case 'SUBMIT_EXERCISE3_SUCCESS':
          console.log('Exercise 3 success');
          return state
        case 'SUBMIT_EXERCISE3_ERROR':
          console.log('Exercise 3 Error Submission', action.err);
          return state                  
        default:
          return state
      }
  };
    
  export default module1Reducer;