import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import ModuleLocked from "components/Sections/ModuleLocked";

import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';

const M5Ex1Summary = (props) => {

    const { progress, moduleBlocked } = props;
    const [ data, setData ] = useState({})

    useEffect(() => { 
      setData(props.exercise1m5);
    }, [props.exercise1m5])

    const userPaths = data.userPaths && 
      data.userPaths.filter(o => o.checked === true).map((i, index) => {
        return (
          <Fragment key={index}>
            <ListGroupItem className="list-results">
              <Row>
                <Col md="10" xs="12"><p>{i.approach}</p></Col>
              </Row>
            </ListGroupItem>
          </Fragment>
          )
        }
    );

    return (
      <>
      { progress < 0 || moduleBlocked === true
      ? <ModuleLocked message={'Exercise Locked'}/> 
      : <div className="p-2">
          <Row className="justify-content-center">
            <Col md='10' xs='12' className='mt-2'> 
              
              <h4 className="title-weight">Career Paths</h4>
              <ListGroup className="thead-light">
                <ListGroupItem className="list-head">
                  <Row>
                    <Col md="10" xs="12">Career Paths - Approach Selected </Col>
                  </Row>
                </ListGroupItem>
                {userPaths}
              </ListGroup>
            </Col>
          </Row>
        </div>
      }
      </>
    )
}


const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    exercise1m5 : state.firestore.data.exercise1m5 || {}
  }
};

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module5",
          subcollections: [{ collection: "exercises", doc: "exercise1"}],
        }],
        storeAs: 'exercise1m5'
      }]
    }),
)(M5Ex1Summary);