import React from 'react';
import { Container, Col, Row, UncontrolledCollapse, CardBody, Card } from "reactstrap";
import MainNavbar from "components/Navbars/MainNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import Sahir from 'assets/img/theme/SahirPhoto.jpg';
import Ahlia from 'assets/img/theme/AhliaPhoto.png';
import Calvin from 'assets/img/theme/CalvinPhoto.jpg';
import { LeavesImg } from "components/Sections";

const About = () => {
  
    return (
      <>
        <MainNavbar lightMode/>
        <LeavesImg/>
          <div className="main-content">
            <section className="py-4 pb-6"/>

            <section className="py-6 pb-8">
              <Container fluid>
                <Row className="justify-content-center text-center">
                  <Col md="8">
                    <h1 className="display-2 title-weight text-default mb-4">
                      Hope. Heart. Hustle.
                    </h1>
                    <p className="lead text-default">
                       Pathways by LBB is here to guide individuals and middle-skilled workers who are in embarking on a new career journey. 
                 
                    </p>
                    <p className="lead text-default">
                    What are middle skills jobs?  Middle skills require more education or training than a high school diploma but less than a four-year college degree. 
                    </p>
                    <p className="lead text-default">
                      In 2018, there were 3.5 million middle skills job openings 
                      that businesses were unable 
                      to fill in a reasonable amount of time.
                      Pathways by LBB aims to vet untapped <span className="title-light-weight-2"> talent 
                      and connect </span>them with businesses 
                      with middle skills job openings.
                    </p>
                    <h3 className="display-4 title-weight text-primary mb-4">
                      Uncover your Pathway
                    </h3>
                    
                  </Col>
                </Row>
              </Container>
            </section>
            <section className="py-4 mb-6">
              <Container>
                <Row className="align-items-center">
                  <Col className="text-center">
                    <h1 className="display-3 title-weight text-blue mb-4">
                      Our Team
                    </h1>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col className="order-md-2" md="4">
                    <div className="team-picture" id="toggler1">
                      <img
                        alt="Team Member"
                        src={Ahlia}
                      />
                    </div> 
                    <div className="pt-4 text-center card-lift--hover">
                      <h5 className="h3 title">
                        <span className="d-block title-weight mb-1">Ahlia Kitwana</span>
                        <small className="h4 font-weight-light text-muted">
                          CEO & Founder
                        </small>
                      </h5>  
                    </div>
                    <UncontrolledCollapse toggler="#toggler1">
                      <Card>
                        <CardBody>
                          <ul className="team-list-card text-default">
                            <li>Career coach | transition & career change</li>
                            <li>Experienced educator in High school & Community college settings</li>
                            <li>Corporate training program design</li>
                            <li>Experienced in engineering & Project Management</li>
                          </ul>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>  
           
                  </Col>
                  <Col className="order-md-2" md="4">
                    <div className="team-picture" id="toggler2">
                      <img
                        alt="Team Member"
                        src={Calvin}
                      />
                    </div> 
                    <div className="pt-4 text-center card-lift--hover">
                      <h5 className="h3 title">
                        <span className="d-block title-weight mb-1">Calvin Wilson</span>
                        <small className="h4 font-weight-light text-muted">
                          Marketing Lead
                        </small>
                      </h5>  
                    </div>   
                    <UncontrolledCollapse toggler="#toggler2">
                      <Card>
                        <CardBody>
                          <ul className="team-list-card text-default">
                            <li>Social Media experimenting & testing</li>
                            <li>Creative lead, content</li>
                            <li>Marketing implementation & execution</li>
                            <li>Webpage development</li>
                            <li>Game design</li>
                          </ul>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>         
                  </Col>
                  <Col className="order-md-2" md="4">
                    <div className="team-picture" id="toggler3">
                      <img
                        alt="Team Member"
                        src={Sahir}
                      />
                    </div> 
                    <div className="pt-4 text-center card-lift--hover">
                      <h5 className="h3 title">
                        <span className="d-block title-weight mb-1">Sahir Gomez</span>
                        <small className="h4 font-weight-light text-muted">
                          Developer
                        </small>
                      </h5>  
                    </div> 
                    <UncontrolledCollapse toggler="#toggler3">
                      <Card>
                        <CardBody>
                          <ul className="team-list-card text-default">
                            <li>Engineering and Web Development</li>
                            <li>UI Development</li>
                            <li>Data Modeling</li>
                            <li>Lean Startup</li>
                          </ul>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>        
                  </Col>
                </Row>
              </Container>
            </section>
          </div>  
        <AuthFooter/>
      </>
    )
}

export default About;
