import React from 'react';
import { Link } from "react-router-dom";
import Logo from '../../assets/img/brand/logo2.png';
import { Container, Card, CardBody, Row, Col, Button } from 'reactstrap';

const Slider3 = () => {
    return (
        <Container className="mt-9" fluid>
          <Row className="justify-content-center">
            <Col className="card-wrapper" lg="8" md="8">
              <Card className="bg-slider mt-5 pt-5">    
                <CardBody>
                  <Row className="justify-content-center">
                    <Col md='10' className="text-center">
                      <img src={Logo} alt='Logo' style={{width:'70%'}}/>
                    </Col> 
                  </Row>
                  <Row className="justify-content-center mt-6">
                    <Col md='6' className="text-center">
                      <h2 className="text-dark">Begin your quest, level up, earn badges, and uncover your <span className="font-weight-900">pathway!</span></h2>
                    </Col> 
                  </Row>
                  <Row className="justify-content-center mt-5">
                    <Col md='8' className="text-dark text-center">
                      <Link to="/register">
                        <Button color="default" outline type="button">
                            SIGN UP
                        </Button>
                      </Link>
                    </Col> 
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row> 
        </Container>
    )
}

export default Slider3;
