import React, {useState, useEffect} from "react";
import { Row, Col, ListGroup, ListGroupItem, UncontrolledTooltip } from "reactstrap";
import ModuleLocked from "components/Sections/ModuleLocked";
import MBTIindicators from "variables/mbtiIndicators";

import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';


const M1Ex3Summary = (props) => {
    const [ indicators, setIndicators ] = useState({})

    useEffect(() => { setIndicators(props.exercise3) }, [props.exercise3])

    const type = MBTIindicators.find((x) => x.result === indicators.personality);
    const paragraphText = (paragraph) => {
      return paragraph.split('\n').map((str,index) => <h5 className="text-dark" key={index}>{str}</h5>);
    }
  
    return (
      <>
      { props.progress < 70
      ? <ModuleLocked message={'Exercise Locked'}/> 
      : <>
          <Row className="justify-content-center mb-1">
            <Col md='10' xs='12'>
              <h4 className="title-weight"> Your MBTI: {indicators.personality}</h4>
              <h4 className="text-info">{type && type.type} <label className="information" id="helper"></label></h4>
              {type && paragraphText(type.careerTypes)}
            </Col>
            <UncontrolledTooltip placement="top" target="helper">
              Remember that your MBTI result is not finite and doesn’t mean the characteristics, traits and career paths are set in stone. There is room to do something else that you find more interesting.
            </UncontrolledTooltip>
          </Row>
          <Row className="justify-content-center">
            <Col md='10' xs='12'>
              <ListGroup className="thead-light">
                <ListGroupItem className="list-head">
                  <Row>
                    <Col md="6" xs="6">MBTI INDICATOR</Col>
                    <Col md="6" xs="6">Result</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="list-results">
                  <Row>
                    <Col md="6" xs="6"><p>Type </p></Col>
                    <Col md="6" xs="6">{indicators.personality}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="6" xs="6"><p>Extraverted</p></Col>
                    <Col md="6" xs="6">{indicators.mbtiTest && indicators.mbtiTest.extraverted + ' %'}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="6" xs="6"><p>Introverted</p></Col>
                    <Col md="6" xs="6">{indicators.mbtiTest && indicators.mbtiTest.introverted + ' %'}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="6" xs="6"><p>Intuitive</p></Col>
                    <Col md="6" xs="6">{indicators.mbtiTest && indicators.mbtiTest.intuitive + ' %'}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="6" xs="6"><p>Observant</p></Col>
                    <Col md="6" xs="6">{indicators.mbtiTest && indicators.mbtiTest.observant + ' %'}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="6" xs="6"><p>Thinking</p></Col>
                    <Col md="6" xs="6">{indicators.mbtiTest && indicators.mbtiTest.thinking + ' %'}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="6" xs="6"><p>Feeling</p></Col>
                    <Col md="6" xs="6">{indicators.mbtiTest && indicators.mbtiTest.feeling + ' %'}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="6" xs="6"><p>Judging</p></Col>
                    <Col md="6" xs="6">{indicators.mbtiTest && indicators.mbtiTest.judging + ' %'}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="6" xs="6"><p>Prospecting</p></Col>
                    <Col md="6" xs="6">{indicators.mbtiTest && indicators.mbtiTest.prospecting + ' %'}</Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Col>
          </Row>
        </>
      }
      </>
    )
}


const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    exercise3 : state.firestore.data.exercise3 || {}
  }
};

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module1",
          subcollections: [{ collection: "exercises", doc: "exercise3"}],
        }],
        storeAs: 'exercise3'
      }]
    }),
)(M1Ex3Summary);
