import React from 'react';
import Logo from '../../assets/img/brand/logo4a.png';
import { Container, Card, CardBody, Row, Col } from 'reactstrap';

const Slider2 = () => {
    return (
        <Container className="mt-9" fluid>
          <Row className="justify-content-center">
            <Col className="card-wrapper" lg="8" md="8">
              <Card className="bg-slider mt-5 pt-4">    
                <CardBody>
                  <Row className="justify-content-center">
                    <Col md='10' className="text-center">
                      <img src={Logo} alt='Logo' style={{width:'50%'}}/>
                    </Col> 
                  </Row>
                  <Row className="justify-content-center mt-5">
                    <Col md='8' className="text-center">
                      <h3 className="text-dark">Pathways is an adeventure that allows you to</h3>
                    </Col> 
                  </Row>

                  <Row className="justify-content-center mt-3 text-dark">
                    <Col sm='2' md='3' className="text-center">
                      <img alt="man icon" src={require("assets/img/icons/man.svg")} style={{width:'65px'}}/>
                      <p>Determine your values</p>
                    </Col>
                    <Col sm='2' md='3' className="text-center">
                      <img alt="mission icon" src={require("assets/img/icons/mission.svg")} style={{width:'65px'}}/>
                      <p>Set your goals and intentions</p>
                    </Col>
                    <Col sm='2' md='3' className="text-center">
                      <img alt="superhero icon" src={require("assets/img/icons/superhero.svg")} style={{width:'65px'}}/>
                      <p>Find your hidden Superpowers</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row> 
        </Container>
    )
}

export default Slider2;
