import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import ModuleLocked from "components/Sections/ModuleLocked"

import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';

const M4Ex2Summary = (props) => {
    const [ data, setData ] = useState({})
    const { progress, moduleBlocked } = props;

    useEffect(() => { setData(props.exercise2m4) }, [props.exercise2m4])

    const userAttributes = data.attributes && data.attributes.map((i, index) => {
      return(
        <ul key={index}>
          <li key={index}><p className="list-results text-dark">{i.content}</p></li>
        </ul>)
    })

    return (
      <>
      { progress < 33 || moduleBlocked === true
      ? <ModuleLocked message={'Exercise Locked'}/> 
      : <>
          <Row className="justify-content-center">
            <Col md='8' xs='12' className='mt-2'>
            <h4 className="title-weight">Your Highlighted Superpowers</h4>
              {userAttributes}       
            </Col>
          </Row>
        </>
      }
      </>
    )
}


const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    exercise2m4 : state.firestore.data.exercise2m4 || {}
  }
};

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module4",
          subcollections: [{ collection: "exercises", doc: "exercise2"}],
        }],
        storeAs: 'exercise2m4'
      }]
    }),
)(M4Ex2Summary);