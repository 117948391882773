import React, {Fragment} from "react";
import { Row, Col, Card, CardHeader,CardBody, Table, Button } from "reactstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ModuleLocked from "../../../../components/Sections/ModuleLocked";
import NewBadgeModal from '../../../../components/Modals/NewBadgeModal';
import ItemsToDrag from "./ItemsToDrag";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { submitExercise2c } from "redux/actions/module1Actions";

const grid = 8;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  //padding: grid * 1,
  //margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "#3a6a88 " : "#efcb87",
  color: isDragging ? "white" : "",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#ea9b82" : "#efcb87",
  padding: grid,
  width: 290,
  borderRadius: 10,
  margin: `0 auto`,
});

const makeNestedList = (array) => {
  const mp = {}
  array.forEach(c => {
    if (!c.count) return 
  
    if (!mp[c.count]) mp[c.count] = [{ count: c.count, value: c.value }]
    else mp[c.count].push({ count: c.count, value: c.value })
  })
  
  const SahirArray = Object.keys(mp).map(i => ({ subItems: mp[i], id: parseInt(i) }))
  SahirArray.sort((a, b) => {
    return b.id - a.id;
  });

  return SahirArray
}

const destructureList = (array) => {
  let nn = []
  JSON.stringify(array, (key, value) => {
    if (key === 'value') nn.push(value);
    return value;
  });
  return nn
}

class Exercise2C extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ranking: [],
      valuesTop6: null,
      badgeModal: false
    };
  };

  componentDidUpdate(prevProps) { 
    if (this.props.exercise2 !== prevProps.exercise2) {
      const nestedValues = this.props.exercise2.valuesRanking && makeNestedList(this.props.exercise2.valuesRanking.slice(0,6))
      this.setState({
        ranking: nestedValues,
        valuesTop6: this.props.exercise2.valuesTop6
      })
    }
  }
  
  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    if (result.type === "droppableItem") {
      const ranking = reorder(
        this.state.ranking,
        result.source.index,
        result.destination.index
      );

      this.setState({
        ranking
      });
    } else if (result.type.includes("droppableSubItem")) {
      const parentId = parseInt(result.type.split("-")[1]);
      const itemSubItemMap = this.state.ranking.reduce((acc, item) => {
        acc[item.id] = item.subItems;
        return acc;
      }, {});
      const subItemsForCorrespondingParent = itemSubItemMap[parentId];
      const reorderedSubItems = reorder(
        subItemsForCorrespondingParent,
        result.source.index,
        result.destination.index
      );
      let newItems = [...this.state.ranking];
      newItems = newItems.map(item => {
        if (item.id === parentId) {
          item.subItems = reorderedSubItems;
        }
        return item;
      });
      this.setState({
        ranking: newItems
      });
    }
  }

  toggleModal = () => {
    this.setState({ badgeModal: !this.state.badgeModal })
  }

  handleFinish = () => {
    const { ranking } = this.state;
    const data = {
      userID: this.props.uid,
      valuesTop6: destructureList(ranking)
    }

    this.props.submitExercise2c(data)
    this.toggleModal()
  }

  render() {
    const { ranking, valuesTop6, badgeModal } = this.state;
    const data = valuesTop6 ? valuesTop6 : destructureList(ranking);
    const valuesColumn = ranking && data.map((i, index) => {
      return (
        <Fragment key={index}>
          <tr>
            <th scope="row" className="text-center"><span className="title-weight">{index + 1 }</span></th>
            <td><span className="name mb-0 text-sm">{i}</span></td>
          </tr>
        </Fragment>
      )
    });

    return (
      <>
        { this.props.progress < 42
        ? <ModuleLocked message={'Please complete previous exercise.'}/> 
        : <>   
            <Card>
              <CardHeader className="text-center">
                <h3 className="mb-2"> Module 1 - Right Now</h3>
                <h4>Ex - 2c</h4>
              </CardHeader>
              <CardBody>
                <p className="mb-2">
                  Based on your previous answers, here is the Top 6 of your values. Use the table on the right to re-order the values tied if you want.
                </p> 
              </CardBody>
            </Card>     

            <Row className="justify-content-center">
              <Col md='6' sm='6' className="mt-3">
                <Card>
                  <Table id="classes" className="align-items-center table-flush" responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">Ranking</th>
                        <th className="text-center">Your Values</th>
                      </tr>
                    </thead>
                    <tbody>
                      { valuesColumn }
                    </tbody>
                  </Table>
                </Card>
              </Col>
            
              <Col md='6' sm='6'>
                <p>- Drag and drop your tied values below.</p>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="droppable" type="droppableItem">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        { ranking && ranking.map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id.toString()} index={index} isDragDisabled={true}>
                            {(provided, snapshot) => (               
                              <div>
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <ItemsToDrag
                                    subItems={item.subItems}
                                    type={item.id.toString()}
                                    position={index + 1}
                                  />
                                </div>
                                {provided.placeholder}
                              </div>               
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>   
              </Col>
            </Row>
            <hr/>

            <Row className="justify-content-center my-3">
              <Col md='6' xs='6'>
                <Button block color="success" size="lg" type="button" onClick={this.handleFinish}>
                  Finish
                </Button>
              </Col>
            </Row>
            
            <NewBadgeModal
              isOpen={badgeModal}
              toggle={this.toggleModal}
              history={this.props.history}
              message={'You earned a second badge, one last exercise before you finish this module.'}
              next={'ex3'}
            /> 
          </>       
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    module1 : state.firestore.data.module1 || {},
    exercise2 : state.firestore.data.exercise2 || {}
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitExercise2c: (data) => dispatch(submitExercise2c(data))
  }
}

export default compose( 
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module1"}],
        storeAs: 'module1'
      },
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module1",
          subcollections: [{ collection: "exercises", doc: "exercise2"}],
        }],
        storeAs: 'exercise2'
      }
    ]
  }),
)(Exercise2C);


/**
 * const myBigArray = [
    { 
      id:1,
      subItems: [{count: 9, value: "Wisdom"}]
    },
    {
      id:2,
      subItems: [{count: 8, value: "Truth-seeking"}]
    },
    { id:3,
      subItems:
      [
        {value: "Trustworthiness", count: 6},
        {value: "Success", count: 6}
      ]
    },
    { 
      id:4,
      subItems: 
      [
        {count: 4, value: "Strength"},
        {count: 4, value: "Thoughtfulness"},
        {count: 4, value: "Thoroughness"}
      ]
    },
    { 
      id:5,
      subItems:
      [{count: 3, value: "Teamwork"}]
    },
    { id:6,
      subItems:
      [{count: 1, value: "Wealth"}]
    }
]
 */