import Home from "views/superAdminViews/SuperAdminHome";
import Settings from "views/superAdminViews/SuperAdminSettings";
import AddSuperAdmin from "views/superAdminViews/AddSuperAdmin";
import AllUsers from "views/superAdminViews/AllUsers";
import AllInstitutions from "views/superAdminViews/InstitutionalUsers";
import AllUsersProgress from "views/superAdminViews/AllUsersProgress";
import Module1Responses from "views/superAdminViews/modules/module1data/Module1Responses";
import Module2Responses from "views/superAdminViews/modules/module2data/Module2Responses";
import Module3Responses from "views/superAdminViews/modules/module3data/Module3Responses";
import Module4Responses from "views/superAdminViews/modules/module4data/Module4Responses";
import Module5Responses from "views/superAdminViews/modules/module5data/Module5Responses";

const superAdminRoutes = [
    {
      path: "/home",
      name: "Home",
      icon: "fas fa-home text-indigo",
      component: Home,
      layout: "/lbb-admin"
    },
    {
      path: "/institutions",
      name: "Organizations",
      icon: "fas fa-university text-indigo",
      component: AllInstitutions,
      layout: "/lbb-admin"
    },
    {
      path: "/allusers",
      name: "Users",
      icon: "fas fa-users text-indigo",
      component: AllUsers,
      layout: "/lbb-admin"
    },
    {
      collapse: true,
      name: "Modules Data",
      icon: "ni ni-paper-diploma text-indigo",
      state: "modulesCollapse",
      views: [
        {
          path: "/module1",
          name: "Module 1",
          miniName: "1",
          component: Module1Responses,
          layout: "/lbb-admin",
          subpath: "/:id"
        },
        {
          path: "/module2",
          name: "Module 2",
          miniName: "2",
          component: Module2Responses,
          layout: "/lbb-admin",
          subpath: "/:id"
        },
        {
          path: "/module3",
          name: "Module 3",
          miniName: "3",
          component: Module3Responses,
          layout: "/lbb-admin",
          subpath: "/:id"
        },
        {
          path: "/module4",
          name: "Module 4",
          miniName: "4",
          component: Module4Responses,
          layout: "/lbb-admin",
          subpath: "/:id"
        },
        {
          path: "/module5",
          name: "Module 5",
          miniName: "5",
          component: Module5Responses,
          layout: "/lbb-admin",
          subpath: "/:id"
        }
      ]
    },
    {
      path: "/add-superadmin",
      name: "Super Admin",
      icon: "fas fa-user-plus text-indigo",
      component: AddSuperAdmin,
      layout: "/lbb-admin"
    },
    {
      path: "/userprogress",
      name: "Users Progress",
      icon: "ni ni-sound-wave text-indigo",
      component: AllUsersProgress,
      layout: "/lbb-admin"
    },
    {
      path: "/settings",
      name: "Settings",
      icon: "ni ni-settings-gear-65 text-indigo",
      component: Settings,
      layout: "/lbb-admin"
    },
]

export default superAdminRoutes;