import React, {useState, useEffect} from 'react';
import { Card, CardHeader, CardBody, Collapse, Row, Button } from "reactstrap";
import { M1Ex1Summary, M1Ex2Summary, M1Ex3Summary } from "components/Tables";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const CollapseM1 = (props) => {

    const [ openedCollapses, setOpenedCollapses] = useState([])
    const [ module1, setModule1] = useState({})

    useEffect(() => { 
      setModule1(props.module1);
    }, [props.module1])

    const collapsesToggle = (collapse) => {
      if (openedCollapses.includes(collapse)) {
        setOpenedCollapses([]);
      } else {
        setOpenedCollapses([collapse]);
      }
    };

    return (
      <>
        <div className="accordion">
          <Card className="card-plain">
            <CardHeader
              role="tab"
              onClick={() => collapsesToggle("collapseOne")}
              aria-expanded={openedCollapses.includes( "collapseOne" )}
            >
              <h5 className="mb-0">Exercise 1</h5>
            </CardHeader>
            <Collapse role="tabpanel" isOpen={openedCollapses.includes("collapseOne")}>
              <CardBody>
                <M1Ex1Summary/>
                  <Row className="justify-content-end mr-2 my-2">
                    <Button color="primary" type="button" size="sm" onClick={() => props.history.push('/user/module1')}>
                      Continue
                    </Button>
                  </Row> 
              </CardBody>
            </Collapse>
          </Card>
          <Card className="card-plain">
            <CardHeader
              role="tab"
              onClick={() => collapsesToggle("collapseTwo")}
              aria-expanded={openedCollapses.includes( "collapseTwo" )}
            >
              <h5 className="mb-0">Exercise 2</h5>
            </CardHeader>
            <Collapse role="tabpanel" isOpen={openedCollapses.includes("collapseTwo")}>
              <CardBody>
                <M1Ex2Summary history={props.history} progress={module1.progress}/>
                <Row className="justify-content-end mr-2 my-2">
                  <Button color="primary" type="button" size="sm" onClick={() => props.history.push('/user/module1/ex2')}>
                    Continue
                  </Button>
                </Row>
              </CardBody>
            </Collapse>
          </Card>
          <Card className="card-plain">
            <CardHeader
              role="tab"
              onClick={() => collapsesToggle("collapseThree")}
              aria-expanded={ openedCollapses.includes("collapseThree") }
            >
              <h5 className="mb-0">Exercise 3</h5>
            </CardHeader>
            <Collapse role="tabpanel"isOpen={openedCollapses.includes("collapseThree")}>
              <CardBody>
                <M1Ex3Summary history={props.history} progress={module1.progress}/>
                <Row className="justify-content-end mr-2 my-2">
                  <Button color="primary" type="button" size="sm" onClick={() => props.history.push('/user/module1/ex3')}>
                      Continue
                  </Button>
                </Row>
              </CardBody>
            </Collapse>
          </Card>
        </div>
      </>
    )
}


const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    module1 : state.firestore.data.module1 || {},
  }
};

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module1"}],
        storeAs: 'module1'
      }]
    }),
)(CollapseM1);