import React from 'react';
import BadgeNull from "assets/img/icons/BadgeNull.svg";
import Badge1 from "assets/img/icons/Badge1.svg";
import Badge2 from "assets/img/icons/Badge2.svg";
import Badge3 from "assets/img/icons/Badge3.svg";
import Badge4 from "assets/img/icons/Badge4.svg";

const BadgeBook = (props) => {

    return (      
        <div className="badges-book-container">
          <img 
            alt='Badges Book' 
            src={require("assets/img/theme/BadgesBook.svg")}/>

            <div className="position1 badgeIcon">
              <img alt='...' src={props.overallProgress >= 10 ? Badge1 : BadgeNull}/> 
            </div> 
            <div className="textp1 badge-title text-center">
              Getting Started Exercise 1
            </div>
            <div className="position2 badgeIcon">
              <img alt='...' src={props.overallProgress >= 15 ? Badge1 : BadgeNull}/> 
            </div> 
            <div className="textp2 badge-title text-center">
              Your Values Badge
            </div>
            <div className="position3 badgeIcon">
              <img alt='...' src={props.overallProgress >= 20 ? Badge2 : BadgeNull}/> 
            </div> 
            <div className="textp3 badge-title text-center">
              Module 2 Unlocked
            </div>
            <div className="position4 badgeIcon">
              <img alt='...' src={props.overallProgress >= 40 ? Badge2 : BadgeNull}/> 
            </div> 
            <div className="textp4 badge-title text-center">
              Module 3 Unlocked
            </div>
            <div className="position5 badgeIcon">
              <img alt='...' src={props.overallProgress >= 55 ? Badge3 : BadgeNull}/> 
            </div> 
            <div className="textp5 badge-title text-center">
              Superpowers & Gifts Badge
            </div>
            <span className="position6 badgeIcon">
              <img alt='...' src={props.overallProgress >= 60 ? Badge2 : BadgeNull}/> 
            </span> 
            <div className="textp6 badge-title text-center">
              Module 4 Unlocked
            </div>
            <span className="position7 badgeIcon">
              <img alt='...' src={props.overallProgress >= 80 ? Badge2 : BadgeNull}/> 
            </span> 
            <div className="textp7 badge-title text-center">
              Module 5 Unlocked
            </div>
            <span className="position8 badgeIcon">
              <img alt='...' src={props.overallProgress === 100 ? Badge4 : BadgeNull}/> 
            </span> 
            <div className="textp8 badge-title text-center title-weight">
              You made it!
            </div>
        </div>

    )
}

export default BadgeBook;
