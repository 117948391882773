import authReducer from './authReducer';
import adminReducer from './adminReducer';
import profileReducer from './profileReducer';
import superAdminReducer from './superAdminReducer';
import module1Reducer from './module1Reducer';
import module2Reducer from './module2Reducer';
import module3Reducer from './module3Reducer';
import module4Reducer from './module4Reducer';
import module5Reducer from './module5Reducer';

import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  auth: authReducer,
  admin: adminReducer,
  userProfile: profileReducer,
  superAdminReducer: superAdminReducer,
  module1: module1Reducer,
  module2: module2Reducer,
  module3: module3Reducer,
  module4: module4Reducer,
  module5: module5Reducer
});

export default rootReducer