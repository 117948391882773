import React from "react";
import { Container, Card, CardHeader, CardBody, CardTitle, Row, Col, Collapse } from "reactstrap";
import { CollapseM1, CollapseM2, CollapseM3, CollapseM4, CollapseM5 } from "components/Collapses";
import PathwaysHeader from "components/Headers/PathwaysHeader";
import UpgradeMembershipModal from 'components/Modals/UpgradeMembershipModal';

import { connect } from 'react-redux';

class Summary extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openedCollapses: []
    };
  }

  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: []
      });
    } else {
      this.setState({
        openedCollapses: [collapse]
      });
    }
  };

  render() {
    const { profile, history } = this.props;
    const isIndividualUser = profile.institution_userID === null || profile.institution_userID === 'na' ? true : false;
    const isStarter = profile.token && profile.token.claims.stripeRole !== 'premium' ? true : false
  
    return (
      <>
        <PathwaysHeader location={this.props.location.pathname} /> 
          <Container className="mt--6" fluid>
            <div className="content">
              <Row>
              <Col md="12" className="mr-auto ml-auto">
                <h1 className="mb-2"> Summary</h1> 
                <p className="mb-2">
                  Hi <span className="title-weight mr-1">{profile.displayName}!</span><br/>
                  Thanks for being bart of Pathways, here you will be able to see an overview of your progress.
                </p>
                  <div className="accordion mt-4">
                    <Card className="card-plain">
                      <CardHeader
                        role="tab"
                        onClick={() => this.collapsesToggle("collapseOne")}
                        aria-expanded={this.state.openedCollapses.includes("collapseOne")}
                      >
                        <CardTitle className="mb-0" tag="h3">
                          <span className="title-weight mr-1">Module 1 -</span>
                          <span className="text-primary">Right Now</span>
                        </CardTitle>
                      </CardHeader>
                      <Collapse role="tabpanel" isOpen={this.state.openedCollapses.includes("collapseOne")}>
                        <CardBody>
                          <CollapseM1 history={history}/>    
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                      <CardHeader
                        role="tab"
                        onClick={() => this.collapsesToggle("collapseTwo")}
                        aria-expanded={this.state.openedCollapses.includes("collapseTwo")}
                      >
                        <CardTitle className="mb-0" tag="h3">
                          <span className="title-weight mr-1">Module 2 -</span>
                          <span className="text-cyan">Setting Goals</span>
                        </CardTitle>
                      </CardHeader>
                      <Collapse role="tabpanel" isOpen={this.state.openedCollapses.includes("collapseTwo")}>
                        <CardBody>
                          <CollapseM2 history={history}/>    
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                      <CardHeader
                        role="tab"
                        onClick={() => this.collapsesToggle("collapseThree")}
                        aria-expanded={this.state.openedCollapses.includes("collapseThree")}
                      >
                        <CardTitle className="mb-0" tag="h3">
                          <span className="title-weight mr-1">Module 3 -</span>
                          <span className="text-blue2">Superpowers & Gifts</span>
                        </CardTitle>
                      </CardHeader>
                      <Collapse role="tabpanel" isOpen={this.state.openedCollapses.includes("collapseThree")}>
                        <CardBody>
                          <CollapseM3 history={history}/>
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                      <CardHeader
                        role="tab"
                        onClick={() => this.collapsesToggle("collapseFour")}
                        aria-expanded={this.state.openedCollapses.includes("collapseFour")}
                      >
                        <CardTitle className="mb-0" tag="h3">
                          <span className="title-weight mr-1">Module 4 -</span>
                          <span className="text-blue">All About You</span>
                        </CardTitle>
                      </CardHeader>
                      <Collapse role="tabpanel" isOpen={this.state.openedCollapses.includes("collapseFour")}>
                        <CardBody>
                          <CollapseM4 history={history}/>
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                      <CardHeader
                        role="tab"
                        onClick={() => this.collapsesToggle("collapseFive")}
                        aria-expanded={this.state.openedCollapses.includes("collapseFive")}
                      >
                        <CardTitle className="mb-0" tag="h3">
                          <span className="title-weight mr-1">Module 5 -</span>
                          <span className="">Begin Your Career Path</span>
                        </CardTitle>
                      </CardHeader>
                      <Collapse role="tabpanel" isOpen={this.state.openedCollapses.includes("collapseFive")}>
                        <CardBody>
                          <CollapseM5 history={history}/>
                        </CardBody>
                      </Collapse>
                    </Card>
                  </div>
                </Col>
              </Row>       
            </div>      
          </Container>

          <UpgradeMembershipModal
            isOpen={isIndividualUser && isStarter}
            history={this.props.history}
            message={'Seems like you must upgrade your account before continuing. Please go to settings and subscribe.'}
            redirectTo={'/user/settings'}
          />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      profile: state.firebase.profile,
  }
}

export default connect(mapStateToProps, null)(Summary);
