const skills = [
    { 
        id:1,
        skill: "Administration and Management",
        questionText: 'How much do you know about business planning and leadership?',
        answerOptions: [
          { level: 'Not Applicable', answerText: '', isChecked: false },
          { level: 'Beginner', answerText: 'Complete a Timesheet', isChecked: false },
          { level: 'Basic', answerText: '', isChecked: false },
          { level: 'Skilled', answerText: 'Monitor project progress for timely completion', isChecked: false },
          { level: 'Advanced', answerText: '', isChecked: false },
          { level: 'Expert', answerText: 'Manage a $10m company', isChecked: false },
        ],
      },
      {
        id:2,
        skill: "Biology",
        questionText: 'How much do you know about plant, animal and cell functions?',
        answerOptions: [
          { level: 'Not Applicable', answerText: '', isChecked: false },
          { level: 'Beginner', answerText: 'Care for a pet', isChecked: false },
          { level: 'Basic', answerText: '', isChecked: false },
          { level: 'Skilled', answerText: 'Investigate effects of pollution on plants', isChecked: false },
          { level: 'Advanced', answerText: '', isChecked: false },
          { level: 'Expert', answerText: 'Identify a new virus', isChecked: false },
        ],
      },
      {
        id:3,
        skill: "Spatial Ability (Building & Construction)",
        questionText: 'How much do you know about construction materials, methods, and tools?',
        answerOptions: [
          { level: 'Not Applicable', answerText: '', isChecked: false },
          { level: 'Beginner', answerText: 'Hang a picture', isChecked: false },
          { level: 'Basic', answerText: '', isChecked: false },
          { level: 'Skilled', answerText: 'Fix a plumbing leak in a ceiling', isChecked: false },
          { level: 'Advanced', answerText: '', isChecked: false },
          { level: 'Expert', answerText: 'Build a high-rise structure', isChecked: false },
        ],
      },
      {
        id:4,
        skill: "Physical Coordintation & Athletic Skills",
        questionText: 'How well can you coordinate moving your arms, legs, and torso together?',
        answerOptions: [
          { level: 'Not Applicable', answerText: '', isChecked: false },
          { level: 'Beginner', answerText: 'Get in and out of a truck', isChecked: false },
          { level: 'Basic', answerText: '', isChecked: false },
          { level: 'Skilled', answerText: 'Swim one pool length, or play a ball sport', isChecked: false },
          { level: 'Advanced', answerText: '', isChecked: false },
          { level: 'Expert', answerText: 'Perform ballet choreography', isChecked: false },
        ],
      },
      {
        id:5,
        skill: "Chemistry",
        questionText: 'How well do you know chemistry?',
        answerOptions: [
          { level: 'Not Applicable', answerText: '', isChecked: false },
          { level: 'Beginner', answerText: 'Use a household chemical safely', isChecked: false },
          { level: 'Basic', answerText: '', isChecked: false },
          { level: 'Skilled', answerText: 'Use proper chlorine concentration to purify water source', isChecked: false },
          { level: 'Advanced', answerText: '', isChecked: false },
          { level: 'Expert', answerText: 'Develop a formula for a safe cleaner', isChecked: false },
        ],
      },
      {
        id:6,
        skill: "Clerical",
        questionText: 'How much do you know about administrative tasks and processes?',
        answerOptions: [
          { level: 'Not Applicable', answerText: '', isChecked: false },
          { level: 'Beginner', answerText: 'File forms', isChecked: false },
          { level: 'Basic', answerText: '', isChecked: false },
          { level: 'Skilled', answerText: 'Type 500 words in 10 minutes', isChecked: false },
          { level: 'Advanced', answerText: '', isChecked: false },
          { level: 'Expert', answerText: 'Design an online office-wide storage system', isChecked: false },
        ],
      },
      {
        id:7,
        skill: "Complex Problem Solving",
        questionText: 'How good are you at figuring out the best way to solve a problem?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Gather tools for a household job', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Redesign a floor plan around new equipment', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Develop a local disaster response plan', isChecked: false },
        ],
      },
      {
        id:8,
        skill: "Information Technology (IT) Skills",
        questionText: 'How effective are you at using computing and information systems?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Open a program on a computer', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Can set up an at-home network', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Create a virus-scanning program', isChecked: false },
        ],
      },
      {
        id:9,
        skill: "Customer Care & Service",
        questionText: 'How well do you know how to handle customer needs and resolve service problems?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Process a dry cleaning order', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Able to manage customer problems & offer solutions', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Plan customer outreach after data breach or privacy breach', isChecked: false },
        ],
      },
      {
        id:10,
        skill: "Economics and Accounting",
        questionText: 'How well do you know banking and financial systems?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Answer billing questions', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Develop an investment plan for clients', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: `Oversee a large firm's finances`, isChecked: false },
        ],
      },
      {
        id:11,
        skill: "Fine Arts",
        questionText: 'How well do you know the theory and techniques of an art form such as music, painting, drama, or another?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Attend a concert', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Play a minor part in a local theater play', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Teach the theory of an art form', isChecked: false },
        ],
      },
      {
        id:12,
        skill: "Foreign Language",
        questionText: 'How well do you know a foreign language?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Say "please" and "thank you', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Ask for directions in a foreign city', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Critique a work of literature', isChecked: false },
        ],
      },
      {
        id:13,
        skill: "Empathy & Helping People",
        questionText: 'How good are you at understanding how to help people?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Ask clients if they would like coffee', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Make travel arrangements for customers', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Direct relief operations in a disaster area', isChecked: false },
        ],
      },
      {
        id:14,
        skill: "Instructing",
        questionText: 'How skilled are you at teaching people how to do something?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Show someone how to bowl', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Instruct a coworker in how to operate a software program', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Demonstrate surgery to interns', isChecked: false },
        ],
      },
      {
        id:15,
        skill: "Management of Financial Resources",
        questionText: 'What level of financial decisions can you make?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Record office supply purchases', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Prepare and manage a budget for a short-term project', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Oversee yearly budgets for a large firm', isChecked: false },
        ],
      },
      {
        id:16,
        skill: "Mathematics",
        questionText: 'What is your level of skill in math?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Add two large numbers', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Calculate the square footage of a home', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Derive a complex formula', isChecked: false },
        ],
      },
      {
        id:17,
        skill: "Mechanical",
        questionText: 'How well can you design, use, repair, and maintain machines?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Replace a battery in a smoke detector', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Replace a valve on a steam pipe', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Overhaul a jet engine', isChecked: false },
        ],
      },
      {
        id:18,
        skill: "Medicine and Dentistry",
        questionText: 'How well do you know how to provide health care?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Treat a cut', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Fill a tooth cavity', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Perform open-heart surgery', isChecked: false },
        ],
      },
      {
        id:19,
        skill: "Memorization",
        questionText: 'How good is your memory?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Remember your bus number', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Recite the first names of 5 people you just met', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Quickly memorize and recite a complex equation', isChecked: false },
        ],
      },
      {
        id:20,
        skill: "People Management",
        questionText: 'How well can you assess your or others’ work?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Keep track of what employees do and the results of their work', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Make adjustments to a meeting to ensure key goals are met', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: `Make plans to increase an organization's productivity`, isChecked: false },
        ],
      },
      {
        id:21,
        skill: "Negotiation",
        questionText: 'How well do you persuade others to compromise or see different points of view?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Justify a new work schedule to your manager', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Contract with a wholesaler to sell items at a given cost', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Negotiate a foreign treaty as an ambassador', isChecked: false },
        ],
      },
      {
        id:22,
        skill: "Operation Analysis",
        questionText: 'How well can you analyze needs and requirements, then make a plan that meets them?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Select a copy machine for the office', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Suggest software changes to make a system more user friendly', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Identify system needs for a new process production plant', isChecked: false },
        ],
      },
      {
        id:23,
        skill: "Personnel and Human Capital Management",
        questionText: 'How well do you know the principles and procedures for recruiting, hiring, and training employees?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Fill out a medical claim form', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Interview applicants for a new position', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Design a new HR system for a large firm', isChecked: false },
        ],
      },
      {
        id:24,
        skill: "Production and Processing",
        questionText: 'How well can you oversee manufacturing and distribution processes?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Repack a computer in original box', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Supervise an appliance assembly line', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Maintain an international distribution center', isChecked: false },
        ],
      },
      {
        id:25,
        skill: "Programming",
        questionText: 'How well can you write computer programs?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Create an Excel spreadsheet', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Write a statistical program to analyze demographic data', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Write a program to analyze data for probability results', isChecked: false },
        ],
      },
      {
        id:26,
        skill: "Psychology",
        questionText: 'How well do you know the methods of research, assessment, and treatment of human behavior?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Observing human behavior', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Understand the impact of addiction on human responses', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Treat a person with severe mental illness', isChecked: false },
        ],
      },
      {
        id:27,
        skill: "Public Safety and Security",
        questionText: 'How well do you know the equipment, policies, procedures, and strategies used to promote security operations?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Follow work safety guidelines', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Inspect a building site for safety violations', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Command a military operation', isChecked: false },
        ],
      },
      {
        id:28,
        skill: "Quality Control Analysis",
        questionText: 'How well can you develop and use testing processes?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Compare two ways to make a recipe', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Measure new part requirement for tolerance to specifications', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Plan and run a test for a new computer program', isChecked: false },
        ],
      },
      {
        id:29,
        skill: "Repairing",
        questionText: 'How well can you repair machines or systems using the needed tools?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Tighten the correct screw to get a door to close properly', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Replace a faulty hydraulic valve', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Repair structural damage after an earthquake', isChecked: false },
        ],
      },
      {
        id:30,
        skill: "Sales and Marketing",
        questionText: 'How well can you perform marketing strategies and tactics?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Sell cakes at a bake sale', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Call a list of clients to introduce them to a new product line', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Develop a marketing plan for a large firm', isChecked: false },
        ],
      },
      {
        id:31,
        skill: "Science Process Skills",
        questionText: 'How well can you use the scientific methods to solve problems?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Care for house plants', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Conduct product tests to ensure safety standards are met', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Analyze aerodynamic systems for aircraft design', isChecked: false },
        ],
      },
      {
        id:32,
        skill: "Sociology and Anthropology",
        questionText: 'How well do you know the theories of group behavior, societal trends and influences, and human culture?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Identify two different cultures', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Write an article about cultural differences', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: `Create a new theory of civilization's development`, isChecked: false },
        ],
      },
      {
        id:33,
        skill: "Speaking (Oral communication)",
        questionText: 'How well do you convey information when you speak?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Greet customers', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Interview applicants to obtain personal and work history', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Argue a legal case before the Supreme Court', isChecked: false },
        ],
      },
      {
        id:34,
        skill: "Systems Analysis",
        questionText: 'How well can you can you analyze systems?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Manage work with a missing teammate', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Determine how new equipment will affect production rates', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Identify impact of tax changes across industries', isChecked: false },
        ],
      },
      {
        id:35,
        skill: "Teaching & Course Design",
        questionText: 'How well do you know the principles and methods of instruction?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Teach workers how to use a time clock', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Lead a quality improvement seminar', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Design a training program for new employees', isChecked: false },
        ],
      },
      {
        id:36,
        skill: "Telecommunications",
        questionText: 'How well do you understand telecom systems?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Use a business phone', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Install a satellite TV dish', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Develop a worldwide telecom network', isChecked: false },
        ],
      },
      {
        id:37,
        skill: "Therapy & Counseling",
        questionText: 'How well do you know the principles and methods used in counseling?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Offer sympathy to a stranger', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Provide job counseling to the unemployed', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Supervise experienced therapists', isChecked: false },
        ],
      },
      {
        id:38,
        skill: "Time Management",
        questionText: `How well do you manage your and others' time?`,
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Keep a monthly appointment calendar', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Allocate staff time to projects for the coming week', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Allocate scientists’ time to multiple multi-stage projects', isChecked: false },
        ],
      },
      {
        id:39,
        skill: "Troubleshooting",
        questionText: 'How well can you identify and fix problems in machines or technology?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Identify the source of a leak on the outside of a machine', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Identify the circuit causing an electrical system to fail', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Oversee code debugging for new operating system', isChecked: false },
        ],
      },
      {
        id:40,
        skill: "Writing",
        questionText: `How well does your writing effectively communicate to an audience's particular need?`,
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Take a telephone message', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Write a memo to staff outlining new directives', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Write a technical manual', isChecked: false },
        ],
      },
      {
        id:41,
        skill: "Attention to Detail",
        questionText: 'How well do you pay attention to detail?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'You proofread and review your work prior to turning it in', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'You ask yourself critical questions as you are taking in new information (studying, reading or working)', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'You like to "get your hands dirty" in all aspects of your work, even if someone else can do it. You know all aspects of your subordinates’ jobs', isChecked: false },
        ],
      },
      {
        id:42,
        skill: "Track & Assess",
        questionText: 'How well can you assess your or others’ work?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Proofread a letter', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Make adjustments to a meeting to ensure key goals are met', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: `Measure and plan to increase a firm's productivity`, isChecked: false },
        ],
      },
      {
        id:43,
        skill: "Critical Thinking",
        questionText: 'How well do you gather information, interpreting it and also skeptically evaluate data?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'You weigh pros and cons when making a decision', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Your decision-making process includes and considers evaluating all available information', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'You know how to ask deep, probing, thoughtful questions to get more information about a situation or system', isChecked: false },
        ],
      },
      {
        id:44,
        skill: "Child care",
        questionText: 'How well do you interact with children?',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Monitor kids on a playground', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Supervise 10 children at a daycare', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Manage staff and child learning within a school', isChecked: false },
        ],
      },
      {
        id:45,
        skill: "Data Analysis",
        questionText: 'How well do you analyze data',
        answerOptions: [
          { level: 'Not Applicable', answerText: ' ', isChecked: false },
          { level: 'Beginner', answerText: 'Conduct simple analysis using spreadsheets and accurately interpret and communicate results', isChecked: false },
          { level: 'Basic', answerText: ' ', isChecked: false },
          { level: 'Skilled', answerText: 'Conduct advanced analysis using specialized software or analysis tools', isChecked: false },
          { level: 'Advanced', answerText: ' ', isChecked: false },
          { level: 'Expert', answerText: 'Design and implement innovative/original models and analysis tools', isChecked: false },
        ],
      },
]

export default skills;