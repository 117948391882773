import React from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col, Spinner } from "reactstrap";
import { AllUsersTable } from "components/Tables";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const AllUsers = (props) => {

    const { profile } = props;
    if ( profile.isEmpty ) return <div className="text-center"><Spinner color="primary" size="md"/> </div> 
    if ( profile.token && profile.token.claims.student ) return <Redirect direct to="/user/home"/>

    return (
          <Container className="mt-2" fluid>
            <Row className="justify-content-center">
              <Col xl="12" className="text-center">
                <h1 className="text-dark title-weight my-2">Pathways Users</h1>
              </Col>
            </Row>

            <Row className="justify-content-center mt-4">
              <Col md="12">
                <AllUsersTable 
                  usersData={props.users}
                  schoolsData={props.schools}
                />
              </Col>
            </Row>
          </Container>
      )
}


const mapStateToProps = (state) => {
  return {
      uid: state.firebase.auth.uid,
      profile: state.firebase.profile,
      users: state.firestore.ordered.users || [],
      schools: state.firestore.ordered.schools || [],
  }
}

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        storeAs: 'users'
      },
      {
        collection:'schools', 
        storeAs: 'schools'
      }]
    }),
 )(AllUsers);