const initState = {
  createSuperAdminError: null,
  createSuperAdminSuccess: null,
  allResponses: {},
};

const superAdminReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_SUPER_ADMIN_SUCCESS":
      return {
        ...state,
        createSuperAdminError: null,
        createSuperAdminSuccess: action.payload.data.message,
      };
    case "CREATE_SUPER_ADMIN_ERROR":
      return {
        ...state,
        createSuperAdminError: action.err,
        createSuperAdminSuccess: null,
      };
    case "GET_ALL_RESPONSES_SUCCESS":
      return { ...state, allResponses: action.payload.data };
    case "GET_ALL_RESPONSES_ERROR":
      console.log("error: ", action.err);
      return state;
    default:
      return state;
  }
};

export default superAdminReducer;
