import React, { useState } from 'react';
import { Collapse, Button, CardBody, Card } from 'reactstrap';

const Collapse2 = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Button color="info" size="sm" onClick={toggle} style={{ marginBottom: '1rem' }}>More</Button>
      <Collapse isOpen={isOpen}>
        <Card>
          <CardBody>
            <h5 className="title-weight">Extrovert (E) or Introvert (I) </h5>
            <h5> Your favorite world is a descriptor of how you allocate your energy.</h5>
            <ul>
              <li><h5> If you prefer the outer world: other people, nature, external environment, then you are an <span className="title-weight">Extrovert (E)</span>. You are likely outgoing, comfortable in groups, and energetic around other people.</h5></li>
              <li><h5>Do you draw your energy from your inner world? Then, you are an <span className="title-weight">Introvert (I)</span>. It is likely you like doing things alone, like to reflect and are usually reserved.</h5></li>
            </ul>

            <h5 className="title-weight">Sensing (S) or Intuition (N) </h5>
            <h5> This has to do with how you collect and interpret information in your world.</h5>
            <ul>
              <li><h5> A <span className="title-weight">Sensing (S)</span>personality type pays attention to physical reality when collecting information. If you’re a sensor, you trust experience first, are pragmatic, and rely on facts.</h5></li>
              <li><h5> A person who collects information using their <span className="title-weight">Intuition (N) </span>pays more attention to patterns in the information, rather than hard facts. They like to work with symbols, theories, and look at the bigger picture.</h5></li>
            </ul>

            <h5 className="title-weight">Thinking (T) or Feeling (F) </h5>
            <h5> The third factor pair describes how you make decisions.</h5>
            <ul>
              <li><h5> A <span className="title-weight">Thinking (T)</span> type makes decisions based on objective principles and facts. You enjoy technical and scientific subjects, where logic and facts are important. You are task-oriented, think checklists. They can come across as “uncaring” or “untactful” when interacting with other</h5></li>
              <li><h5><span className="title-weight">Feeling (F)</span> types tend to make their decisions by putting more weight on the people involved. You’re all about maintaining harmony and making others happy. You are caring, tactful, but can sometimes be too idealistic.</h5></li>
            </ul>

            <h5 className="title-weight">Judging (J) or Perceiving (P) </h5>
            <h5> Structure is all about what behaviors others tend to see from you in the “outer” world.</h5>
            <ul>
              <li><h5> <span className="title-weight">Judging (J) </span>types are structured and decided. Do you prioritize work before play, start things well before the deadline, and enjoy making to-do lists?</h5></li>
              <li><h5> <span className="title-weight">Perceiving (P)</span> types tend to be flexible and adaptable. Traits include working in bursts of energy, procrastinate, and hate planning.</h5></li>
            </ul>
          
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
}

export default Collapse2;