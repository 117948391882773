import React from 'react';
import { Button, Modal } from "reactstrap";

const SuperpowersModal = (props) => {

    return ( 
        <Modal className="modal-dialog-centered" isOpen={props.isOpen} toggle={props.toggle}>
          <div className="modal-header">
            <h6 className="modal-title">
              Examples
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => props.toggle()}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <div className="modal-body">        
            <ul>
              <li>
                <h4 className="text-dark">As a student, I was a part of a research team for a project. We had trouble with making sure everyone was contributing. I used my optimizer superpower to motivate the rest of the team and get everyone involved to contribute their best work.</h4>
              </li>
              <li>
                <h4 className="text-dark">As a Help Desk Technician, I was able to help customers solve their computer issues using my problem-solving skills I was able to help them solve their computer issues.</h4>
              </li>
              <li>
                <h4 className="text-dark">Google organizes the world’s information and makes it universally accessible.</h4>
              </li>
            </ul>
          </div>

          <div className="modal-footer">
            <Button color="primary" size="sm" type="button" onClick={() => props.toggle()}>
              Ok, Got it
            </Button>
          </div>
        </Modal> 
    )
}
export default SuperpowersModal;