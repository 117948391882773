import React from "react";
import classnames from "classnames";
import { Button, Card, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col, Alert} from "reactstrap";

import AuthHeader from "components/Headers/AuthHeader.js";
import MainNavbar from "components/Navbars/MainNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

import Fire from "../../Fire"

class Lock extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      successMessage: false,
      errorMessage: null,
      visible: true
    };
  }

  handleChange = (e) => {
    const itemName = e.target.name;
    const itemValue = e.target.value;
    this.setState({ [itemName]: itemValue });
  };

  handleSendEmail = () => {
    const auth = Fire.shared.auth;
    const emailAddress = this.state.email;
    auth.sendPasswordResetEmail(emailAddress)
    .then(() => {
        this.setState({ successMessage: true, email: '', visible: true})
    })
    .catch((error) => { 
        this.setState({ errorMessage : error.message, visible: true }) 
    });
  };

  onDismiss = () => {
    this.setState({
      errorMessage: null,
      successMessage: null,
      visible: false
    })
  }

  render() {
    const { email, successMessage, errorMessage, visible } = this.state;
    return (
      <>
        <div className="bg-indigo main-content" ref="mainContent">
          <MainNavbar />
          <AuthHeader title="Reset Password" lead="Don't worry, we'll send you a message to help you reset your password" />
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Col lg="5" md="7" xs="12">
                <Card className="bg-secondary">
                 
                  <CardBody className="pt-5 px-4"> 
                    { successMessage &&
                      <Alert color="success" isOpen={visible} toggle={this.onDismiss}>
                        <strong>Great!</strong> Password reset email sent successfully, please check your email.
                      </Alert>
                    }

                    <div className="text-center mb-4">
                      <h5>Please enter the email you use to sign in</h5>
                    </div>
                    <Form role="form">
                      <FormGroup className={classnames({ focused: this.state.focused })}>
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            type="email"
                            name="email"
                            onFocus={() => this.setState({ focused: true })}
                            onBlur={() => this.setState({ focused: false })} 
                            value={email} 
                            onChange={this.handleChange}
                          />
                        </InputGroup>
                      </FormGroup>
                     { errorMessage &&                
                      <Alert color="danger" isOpen={visible} toggle={this.onDismiss}>
                        <strong>Ops!</strong> {errorMessage}
                      </Alert>
                     }
                      <div className="text-center">
                        <Button 
                          className="mt-2" 
                          color="info" 
                          type="button"
                          onClick={this.handleSendEmail}>
                            Unlock
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <AuthFooter/>
      </>
    );
  }
}

export default Lock;
