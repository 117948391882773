import React from 'react';
import { Button } from 'reactstrap';

const ContinueButton = (props) => {
    
  const goToExercise = () => {
    const overallProgress = props.overallProgress
    switch (overallProgress) {
      case 0:
        return props.history.push('/user/module1/ex1')
      case 10:
        return props.history.push('/user/module1/ex2');
      case 12:
        return props.history.push('/user/module1/ex2B');
      case 13:
        return props.history.push('/user/module1/ex2C');
      case 15:
        return props.history.push('/user/module1/ex3');
      case 20:
        return props.history.push('/user/module2/ex1');  
      case 25:
        return props.history.push('/user/module2/ex2');  
      case 30:
        return props.history.push('/user/module2/ex3');  
      case 40:
        return props.history.push('/user/module3/ex1');  
      case 45:
        return props.history.push('/user/module3/ex2');
      case 50:
        return props.history.push('/user/module3/ex3A');  
      case 52:
        return props.history.push('/user/module3/ex3B');    
      case 55:
        return props.history.push('/user/module3/ex4'); 
      case 60:
        return props.history.push('/user/module4/ex1');
      case 65:
        return props.history.push('/user/module4/ex2');
      case 70:
        return props.history.push('/user/module4/ex3');
      case 80:
        return props.history.push('/user/module5');  
      case 100:
        return props.history.push('/user/module5/final-report');            
      default:
        return [];
    }
  };  
  
  return (
    <Button color="info" size="sm" outline type="button" onClick={goToExercise}>
        CONTINUE
    </Button>
  )
}

export default ContinueButton;