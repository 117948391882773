import React, { useState } from "react";
import { Col, Row, Card, CardHeader, CardBody, CardFooter, Tooltip, Button, Spinner } from "reactstrap";
import { ModuleLocked } from "components/Sections";
import Collapse1 from "components/Alerts/Collapse1";

const IntroModule2 = (props) => {

  const [tooltip1, setTooltip1] = useState(false);
  const [tooltip2, setTooltip2] = useState(false);
  const [tooltip3, setTooltip3] = useState(false);
  const [tooltip4, setTooltip4] = useState(false);
  const [tooltip5, setTooltip5] = useState(false);
  const [tooltip6, setTooltip6] = useState(false);
  const [tooltip7, setTooltip7] = useState(false);

  if (props.moduleBlocked === null) return <div className="text-center"><Spinner color="primary" size="md"/> </div> 

  if (props.moduleBlocked === true && props.moduleBlocked !== undefined) {
    return  <ModuleLocked message={'Complete previous module to unlock.'}/>
  }

  return (
      <>
        <Card>
          <CardHeader className="text-center">
            <h2 className="mb-2"> Module 2 - Goal Setting</h2> 
          </CardHeader>
          <CardBody>
            <iframe className="youtube" title="sp video" src="https://www.youtube.com/embed/uKvgBaK86pg?controls=0&autoplay=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <p className="mb-2">
              <span className="title-weight mr-1 mb-1">Do you know what you want?</span> 
              <br/>As you move towards your new career path and start to think about what you want in the long term (5-10 years and beyond) and what you would like to achieve in the shorter term (3-6 months). Setting goals for yourself is an excellent way to start thinking about what you want your future to look like. Even if things don’t play out exactly the way you want or intended you will likely be heading in the right direction.
            </p> 
            <p className="mb-2">
              Goals are often tied to actions, deadlines and a plan. Although the “how” is not always identified in the goal, the plan part (the deadline and accomplishment) makes it more tangible and real in an individual’s life.
            </p>
            <p className="mb-2">
              On this platform we will set goals that end in an accomplishment of some sort for your career. In the end, our suggested approach to goal setting is to set <span className="title-weight">SMARTER</span> goals. <br/> <span className="title-weight">- SMARTER</span> goal implies:
            </p>
            <Row>
            <Col md="4">
            <ul>
              <li id="t1"><p> Specific</p> </li>
                <Tooltip placement="bottom" isOpen={tooltip1} target="t1" toggle={() => setTooltip1(!tooltip1)}>
                  Include details in your goal, make it specific to you and your life
                </Tooltip>
              <li id="t2"><p> Measurable</p></li>
                <Tooltip placement="right" isOpen={tooltip2} target="t2" toggle={() => setTooltip2(!tooltip2)}>
                  Try to make the goal something you can measure, so that you can determine when or if it has been accomplished. Caveat, this is not always doable in the case of lofty, people-ly goals like “It is my aim to raise children who are independent thinkers
                </Tooltip>
              <li id="t3"><p> Actionable</p></li>
                <Tooltip placement="top" isOpen={tooltip3} target="t3" toggle={() => setTooltip3(!tooltip3)}>
                  Make sure they are something you can take action.
                </Tooltip>
              <li id="t4"><p>Risky</p></li>
                <Tooltip placement="top" isOpen={tooltip4} target="t4" toggle={() => setTooltip4(!tooltip4)}>
                  You will act on it if there is something to lose. Try to make the goals a little risky and outside your comfort zone, something you have to strive for.
                </Tooltip>
              <li id="t5"><p>Time-Bound</p></li>
                <Tooltip placement="top" isOpen={tooltip5} target="t5" toggle={() => setTooltip5(!tooltip5)}>
                  Come up with a deadline by which you want to accomplish the goal. If it is a habit goal, come up with the frequency, the time and date(days) that you will complete the goal.
                </Tooltip>
              <li id="t6"><p> Exciting</p></li>
                <Tooltip placement="bottom" isOpen={tooltip6} target="t6" toggle={() => setTooltip6(!tooltip6)}>
                  Does it give you chills? “Eat 5 servings of vegetables a day isn’t exciting” but “Increase my servings of vegetables daily to 5 so that I can have more energy to play with my kids” might be.
                </Tooltip>
              <li id="t7"><p> Relevant</p></li>
                <Tooltip placement="bottom" isOpen={tooltip7} target="t7" toggle={() => setTooltip7(!tooltip7)}>
                  Make it relevant and important to you. Make sure the goal is in alignment with your values. Don’t eat more vegetables because the FDA says that is important, do it because it has a direct impact on the quality of your life and what is important to YOU. If it is not important to you, chances are you won’t accomplish it when the going gets tough. And the going always gets tough eventually. 
                </Tooltip>
            </ul>
            </Col>
            <Col md="8">
              <Collapse1/>
            </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row className="justify-content-end">
              <Button color="default" outline type="button" onClick={() => props.history.push('/user/module2/ex1')}>
                Continue
              </Button>
            </Row> 
          </CardFooter> 
        </Card>  
      </>
  );
}

export default IntroModule2;