const initState = {}
    
  const module4Reducer = (state = initState, action) => {
      switch(action.type){
        case 'SUBMIT_EXERCISE1M4_SUCCESS':
          console.log('Exercise 1 Module4 success');
          return state
        case 'SUBMIT_EXERCISE1M4_ERROR':
          console.log('Exercise 1 Module4 Error Submission');
          return state 
        case 'SUBMIT_EXERCISE2M4_SUCCESS':
          console.log('Exercise 2 Module 4 success');
          return state
        case 'SUBMIT_EXERCISE2M4_ERROR':
          console.log('Exercise 2 Module 4 Error Submission');
          return state
        case 'SUBMIT_EXERCISE3M4_SUCCESS':
          console.log('Exercise 3 Module 4 success');
          return state
        case 'SUBMIT_EXERCISE3M4_ERROR':
          console.log('Exercise 3 Module 4 Error Submission');
          return state         
        default:
          return state
      }
  };
    
  export default module4Reducer;