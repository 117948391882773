import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { NavItem, NavLink, Nav, Container, Row, Col} from "reactstrap";

class PathwaysHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      navPills: this.props.location
    };
  }

  toggleNavs = (state, index) => {
    this.setState({
      [state]: index
    });
  };

  render() {
    return (
      <>
        <div className="special-heading bg-info mb-8">
          <Container fluid>
            <div className="header-body">
            <Row className="align-items-center justify-content-center py-1 mb-1">
              <Col md="10">
                <Nav
                    className="nav-fill flex-sm-row"
                    id="tabs-text"
                    pills
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        aria-selected={this.state.navPills === '/user/map'}
                        className={classnames("mb-sm--3 mb-md-0 mr-1", {
                          active: this.state.navPills === '/user/map'
                        })}
                        onClick={() => this.toggleNavs("navPills", '/user/map')}
                        to="/user/map" tag={Link}
                        role="tab"
                      >
                        MAP
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        aria-selected={this.state.navPills === '/user/home'}
                        className={classnames("mb-sm--3 mb-md-0 mr-1 ml-1", {
                          active: this.state.navPills === '/user/home'
                        })}
                        onClick={() => this.toggleNavs("navPills", '/user/home')}
                        to="/user/home" tag={Link}
                        role="tab"
                      >
                        HOME
                      </NavLink>
                    </NavItem>
                    <NavItem >
                      <NavLink
                        aria-selected={this.state.navPills === '/user/user-profile'}
                        className={classnames("mb-sm--3 mb-md-0 ml-1", {
                          active: this.state.navPills === '/user/user-profile'
                        })}
                        onClick={() => this.toggleNavs("navPills", '/user/user-profile')}
                        to="/user/user-profile" tag={Link}
                        role="tab"
                      >
                        PROFILE
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

PathwaysHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string
};

export default PathwaysHeader;
