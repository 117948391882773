import React, { useRef, useEffect, useState } from 'react';
import ReactToPrint from "react-to-print";

import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button, ButtonGroup, Card, CardHeader, Row, Col, Media, Spinner, UncontrolledTooltip } from "reactstrap";


const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: false,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        users.
      </label>
    </div>
  )
})
const { SearchBar } = Search;

const AllUsersTable = (props) => {

  const componentRef = useRef();
  const [isFetching, setIsFetching] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => { 
    setData(props.usersData)
    setIsFetching(false)
  }, [props.usersData])

  const final_array = data && data.map((i) => {
    let data = props.schoolsData.find(school => school.id === i.schoolID);
    return { ...data, ...i };
  });

  const displayData = final_array.map((i, index) => ({ 
    name: i.displayName,
    avatar: {
      photoURL: i.photoURL, 
      firstnameInit: i.firstname && i.firstname.charAt(0).toUpperCase(), 
      lastnameInit: i.lastname && i.lastname.charAt(0).toUpperCase()
    },
    email: i.email,
    role: i.type,
    school: i.school || 'N/A',
    userID: i.id,
    joined: i.joined ? moment(i.joined.toDate()).format('MMM YYYY') : ''
  }));

  /*const actionsButtons = (key) => {
    return (
      <div className="actions-right">
        <Button
          color="success"
          size="sm"
          className="btn-icon"
          onClick={() => this.replaceModalItem(key)}
        >
          <i className="fa fa-edit" />
        </Button>{" "}
    
        <Button
          onClick={() => {
            this.setState({
              alert: (
                <ReactBSAlert
                  warning
                  style={{ display: "block", marginTop: "-100px" }}
                  title="Are you sure?"
                  onConfirm={() => this.successDelete(key)}
                  onCancel={() => this.hideAlert()}
                  confirmBtnBsStyle="default"
                  cancelBtnBsStyle="danger"
                  confirmBtnText="Yes, delete it!"
                  cancelBtnText="Cancel"
                  showCancel
                  btnSize=""
                >
                  You will not be able to recover this record.
                </ReactBSAlert>
              ),
            })
          }}
          color="danger"
          size="sm"
          className="btn-icon remove"
        >
          <i className="fa fa-times" />
        </Button>{" "}
      </div>
    )
  };*/

  const avatarField = (key) => {
    return  <Media className="align-items-center">
              <span className="avatar avatar-sm rounded-circle bg-cyan mr-2">
                {key.photoURL 
                ? <img
                    alt="avatar"
                    src={key.photoURL}
                  /> 
                : <h5 style={{ marginTop:'5px', color:'#fff' }}>
                    {key.firstnameInit + key.lastnameInit}
                  </h5>
                }
              </span>
            </Media>
  }
  
  if (isFetching) return <div className="text-center"><Spinner color="primary"></Spinner></div>
  return (
      <Card>
        <CardHeader>
          <h3 className="mb-0">Users</h3>
          <p className="text-sm mb-0">
            Global List
          </p>
        </CardHeader>
        <ToolkitProvider
          data={displayData || []}
          keyField="userID"
          columns={[
            {
              dataField: "avatar",
              text: "",
              sort: false,
              formatter: avatarField,
            },
            {
              dataField: "name",
              text: "Name",
              sort: true,
            },
            {
              dataField: "email",
              text: "Email",
              sort: false
            },
            {
              dataField: "role",
              text: "Role",
              sort: true
            },
            {
              dataField: "school",
              text: "Institution",
              sort: false
            },
            {
              dataField: "userID",
              text: "User ID",
              sort: false
            },
            {
              dataField: "joined",
              text: "Joined",
              sort: false,
            },
          ]}
          search
        >
          {props => (
            <div className="py-4 table-responsive">
              <div
                id="datatable-basic_filter"
                className="dataTables_filter px-4 pb-1"
              >
                <label>
                  Search:
                  <SearchBar
                    className="form-control-sm"
                    placeholder=""
                    {...props.searchProps}
                  />
                </label>
              </div>
              <BootstrapTable
                ref={componentRef}
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                id="react-bs-table"
              />
            </div>
          )}
        </ToolkitProvider>
        <Col>
          <Row className="justify-content-end"> 
            <ButtonGroup>
              <ReactToPrint
                trigger={() => (
                  <Button
                    color="default"
                    size="sm"
                    className="buttons-copy buttons-html5 mx-2 my-2"
                    id="print-tooltip"
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </ButtonGroup>
            <UncontrolledTooltip placement="top" target="print-tooltip">
              Print or save to PDF.
            </UncontrolledTooltip>    
          </Row>
        </Col>     
      </Card>
    )
}

        
export default AllUsersTable;