import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Button,
  ButtonGroup,
  Card,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import { connect } from "react-redux";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: false,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        users.
      </label>
    </div>
  ),
});
const { SearchBar } = Search;

const Ex1Table = (props) => {
  const componentRef = useRef();

  const displayData = props.allResponses?.ex1?.map((i, index) => ({
    key: index,
    uuid: i.uuid,
    userPaths: i.userPaths?.filter(i => {return i.checked === true}).map(i => i.approach).join(", \n"),
  }));


  return (
    <Card>
      <ToolkitProvider
        data={displayData ?? []}
        keyField="key"
        columns={[
          {
            dataField: "uuid",
            text: "User id",
            sort: false,
          },
          {
            dataField: "userPaths",
            text: "user paths & approach",
            sort: false,
            style: {
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
            },
          },
        ]}
        search
      >
        {(props) => (
          <div className="py-4 table-responsive">
            <div
              id="datatable-basic_filter"
              className="dataTables_filter px-4 pb-1"
            >
              <label>
                Search:
                <SearchBar
                  className="form-control-sm"
                  placeholder=""
                  {...props.searchProps}
                />
              </label>
            </div>
            <BootstrapTable
              ref={componentRef}
              {...props.baseProps}
              bootstrap4={true}
              pagination={pagination}
              bordered={false}
              id="react-bs-table"
            />
          </div>
        )}
      </ToolkitProvider>
      <Col>
        <Row className="justify-content-end">
          <ButtonGroup>
            <ReactToPrint
              trigger={() => (
                <Button
                  color="default"
                  size="sm"
                  className="buttons-copy buttons-html5 mx-2 my-2"
                  id="print-tooltip"
                >
                  Print
                </Button>
              )}
              content={() => componentRef.current}
            />
          </ButtonGroup>
          <UncontrolledTooltip placement="top" target="print-tooltip">
            Print or save to PDF.
          </UncontrolledTooltip>
        </Row>
      </Col>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    allResponses: state.superAdminReducer.allResponses,
  };
};

export default connect(mapStateToProps, null)(Ex1Table);
