import React from "react";
import { Route, Switch } from "react-router-dom";
import { Col, Row, Container } from "reactstrap";
import { ModuleProgressBar, Module5Map, IntroModule5 } from "components/Sections";
import UpgradeMembershipModal from 'components/Modals/UpgradeMembershipModal';
import ModuleHeader from "components/Headers/ModuleHeader";
import Exercise1 from "./Exercise1";
import Final from "./Final";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

class Module5 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      module5: {},
      progress: 0,
      blocked: null
    }
  }

  componentDidUpdate(prevProps) { 
    if (this.props.module5 !== prevProps.module5) {
      this.setState({
        module5: this.props.module5,
        blocked: this.props.module5.blocked,
        progress: this.props.module5.progress,
      })
    }
  }

  render() {
    const { progress, blocked } = this.state;
    const { profile, history, module5 } = this.props;
    const isIndividualUser = profile.institution_userID === null || profile.institution_userID === 'na' ? true : false;
    const isStarter = profile.token && profile.token.claims.stripeRole !== 'premium' ? true : false

    return (
      <>
       <ModuleHeader color={'module5'}/>
       <Container className="bg-mobile-5 mt-7" fluid>
          <Row>
            <Col md="4" className="text-center d-none d-lg-block">
              <ModuleProgressBar value={module5.progress || 0}/>
              <Module5Map history={history} progress={module5.progress}/>   
            </Col>

            <Switch>
              <Route exact path="/user/module5" render={props => <IntroModule5 {...props} history={history} progress={progress} moduleBlocked={blocked}/>}/>
              <Route exact path="/user/module5/ex1" render={props => <Exercise1 {...props} history={history} progress={progress} moduleBlocked={blocked}/>}/>
              <Route exact path="/user/module5/final-report" render={props => <Final {...props} history={history} progress={progress} moduleBlocked={blocked}/>}/>
            </Switch>
          </Row>       
        </Container>

        <UpgradeMembershipModal
          isOpen={isIndividualUser && isStarter}
          history={history}
          message={'Seems like you must upgrade your account before continuing. Please go to settings and subscribe.'}
          redirectTo={'/user/settings'}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    profile: state.firebase.profile,
    module5 : state.firestore.data.module5 || {}
  }
};

export default compose( 
  connect(mapStateToProps),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module5"}],
        storeAs: 'module5'
      }]
    }),
)(Module5);
