import React from 'react';
import { Row, Col, UncontrolledTooltip } from "reactstrap";

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }
  render(){
    return (
      <>
        <h1 className="mb-2">Your Elevator Pitch</h1> 
        <Row className="justify-content-center">
          <Col md="10" xs="11">
            <Row>
              <h3 className="text-info mb-2">Why you need a pitch</h3> 
              <p className="mb-2">It’s an answer you give to the <span className="title-weight">“So… What do you do?”</span> or <span className="title-weight">“Tell me about yourself” </span>question.</p> 
              <p> Pitches are short-and-sweet sales messages so compelling that, once you’ve finished, whoever you’re talking to likes you enough to hire you or give you money.</p> 
              <p> What you need to know about a professional or personal pitch, especially if you are networking or doing job interviews. </p>  
              <p><span className="title-weight">Keep it short</span></p>
              <ul>
                <li><p>Either bound the pitch by time, no more than 30-60 seconds.</p></li>
                <li><p>Or bound it by length, no more than 140 words.</p></li>
              </ul>  
              <p><span className="title-weight">Make it compelling and interesting</span> enough to spark the listener's interest in your idea or background. Identify one thing you want your audience to remember. Steve Jobs, famed Apple CEO was a genius at this, the original iPod allowed you to carry “1,000 songs in your pocket.”</p> 
              <p><span className="title-weight">Include your best skills, qualifications and superpower(s)</span> and why they add value to the organization.</p>   
              <h5>Hint<label className="information" id="helper"></label></h5>
              <UncontrolledTooltip placement="top" target="helper">
                 Practice it, practice it, practice it. <br/>Find a friend - Record it - Practice in front of a mirror.
              </UncontrolledTooltip>
            </Row>
          </Col>
        </Row>
      </>
    )
  }
}


export default Step1;