import React from 'react';
import {Row} from "reactstrap";
import LockMarker from "../../assets/img/icons/common/LockMarker.svg";
import UnlockedMarker from "../../assets/img/icons/common/UnlockedMarker.svg";

const Module5Map = (props) => {
    return (
      <Row className="justify-content-center">
        <div className="module-map-container">
          <img 
            alt='Module 5 Route Map' 
            src={require("assets/img/theme/MapLevel5.svg")}/> 
            <span className="btn1-m5" onClick={() => props.history.push('/user/module5')}>
              <img alt='...' src={UnlockedMarker}/> 
            </span> 
            <span className="btn2-m5" onClick={() => props.history.push('/user/module5/ex1')}>
              <img alt='...' src={props.progress <= 0 ? LockMarker : UnlockedMarker}/> 
            </span> 
  
            <span className="btn4-m5" onClick={() => props.history.push('/user/module5/final-report')}>
              <img alt='...' src={props.progress !== 100 ? LockMarker : UnlockedMarker}/> 
            </span> 
        </div>
      </Row>
    )
};

export default Module5Map;