import React from "react";
import { Container, Button, Card, CardHeader, CardBody, CardFooter, FormGroup, Form, Input, Row, Col, UncontrolledAlert } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ErrorMessage from "../../components/Alerts/ErrorMessage";
import ImageUpload from "../../components/Avatar/ImageUpload";
import EditPasswordModal from '../../components/Modals/EditPasswordModal';
import { PaymentForm, CouponForm, AdminMembershipCard } from "components/Sections"

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { profileUpdate } from 'redux/actions/profileActions';
import { updateTotalSeats, redeemCoupon } from 'redux/actions/adminActions';
import Fire from '../../Fire';

const api_key = process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(api_key);

class AdminSettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      displayName: this.props.userData.displayName,
      email: this.props.userData.email,
      school: this.props.userData.school,
      firstname: this.props.userData.firstname,
      lastname: this.props.userData.lastname,
      currentPassword: '',

      successMessage: false,
      passwordModal: false,
      errorMessage: null,
      alert: null,
    };
  }

  componentDidUpdate(prevProps){
    if (this.props.userData !== prevProps.userData) {
      this.setState({ 
        displayName: this.props.userData.name,
        email: this.props.userData.email,
        school: this.props.userData.school,
        firstname: this.props.userData.firstname,
        lastname: this.props.userData.lastname,
        membership: this.props.userData.membership
      })
    }
  }

  handleChange = (e) => {
    const itemName = e.target.name;
    const itemValue = e.target.value;
    this.setState({ [itemName]: itemValue, errorMessage: null });
  }

  toggleEditPasswordModal = () => {
    this.setState({ passwordModal: !this.state.passwordModal })
  }

  reauthenticate = (currentPassword) => {
    let user = Fire.shared.auth.currentUser;
    let cred = Fire.shared.EmailAuthProvider.credential(user.email, currentPassword);
    return user.reauthenticateWithCredential(cred);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const profileInfo = {
      uid: this.props.auth.uid,
      email: this.state.email,
      school: this.state.school,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
    }
    this.reauthenticate(this.state.currentPassword)
    .then(() => {
      let user = Fire.shared.auth.currentUser;
      user.updateEmail(this.state.email)
      .then(() => {
        this.props.profileUpdate(profileInfo);
        this.setState({ successMessage: true, currentPassword: '', errorMessage: null });
      })
      .catch((error) => { 
        this.setState({ errorMessage : error.message })  
      });
    }).catch((error) => { 
      this.setState({ errorMessage : error.message })    
    });
  }

  onSuccessfulCheckout = async () => {
    const data = {
      schoolID : this.props.schoolData.schoolID,
      seatsNumber: this.props.adminPlan.seatsNumber,
      couponCode : this.props.adminPlan.tempCouponCode,
    }
    try {
      await this.props.updateTotalSeats(data)
      if (data.couponCode){
        this.props.redeemCoupon(data)
      }
      this.successAlert()
    } catch (err) {
      console.log(err)
    }
  }

  successAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "100px" }}
          title="Payment Successful"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize="sm"
        >
          Thank You. Your payment was received. 
        </ReactBSAlert>
      ),
    });
  }

  hideAlert = () => {
    this.setState({ alert: null });
    window.location.reload(true);
  }

  render() {
    const { userData, schoolData, adminPlan } = this.props;
    const { email, school, firstname, lastname, currentPassword, errorMessage, successMessage } = this.state;
    return (
      <>
       <Elements stripe={stripePromise}>
       {this.state.alert}
          <Container className="mt-0 bg-indigo" fluid>
            <div className="content">
              <Row className="justify-content-center">
                <Col md="4" className="mt-6">
                  <Card className="card-user">
                    <CardHeader> 
                      <h4 className="title-weight">Edit Picture</h4>
                    </CardHeader>
                    <CardBody className="mt-6 text-center">
                      <ImageUpload avatar userAvatar={userData.photoURL}/>       
                    </CardBody>
                    <CardFooter>
                      <h5 className="title text-center">{userData.displayName}</h5>
                    </CardFooter>
                  </Card>     
                </Col>
                <Col md="7" className="mt-6">
                  <Form>
                    <Card>
                      <CardHeader>
                        <h4 className="title-weight">Edit Profile</h4>
                        
                        { errorMessage && <ErrorMessage message={errorMessage}/> } 
                        { successMessage && 
                        <UncontrolledAlert color="success" fade={false}>
                          <span>
                            <b>Nice! - </b>
                            Your profile info has been updated successfully.
                          </span>
                        </UncontrolledAlert>}
                      </CardHeader>
                      <CardBody>        
                        <h6 className="heading-small text-muted mb-4">
                          User information
                        </h6>
                        <div className="pl-lg-4">
                          <Row>  
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label" htmlFor="input-email">
                                  Email address
                                </label>
                                <Input 
                                  id="input-email" 
                                  placeholder="Email" 
                                  type="email" 
                                  name="email" 
                                  value={email || ''} 
                                  onChange={this.handleChange}/>
                              </FormGroup>
                            </Col>      
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label" htmlFor="input-school">
                                  Institution
                                </label>
                                <Input
                                  id="input-school"
                                  type="text"
                                  name="school" 
                                  value={school || ''} 
                                  onChange={this.handleChange}/>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label" htmlFor="input-first-name">
                                  First name
                                </label>
                                <Input 
                                  id="input-first-name" 
                                  placeholder="First Name" 
                                  type="text" 
                                  name="firstname" 
                                  value={firstname || ''} 
                                  onChange={this.handleChange}/>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label" htmlFor="input-last-name">
                                  Last name
                                </label>
                                <Input
                                  id="input-last-name"
                                  placeholder="Last Name"
                                  type="text"
                                  name="lastname" 
                                  value={lastname || ''} 
                                  onChange={this.handleChange}/>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label" htmlFor="input-current-password">
                                  Current Password *
                                </label>
                                <Input
                                  id="input-current-password"
                                  placeholder="******"
                                  type="password"
                                  autoComplete="off"
                                  name="currentPassword" 
                                  value={currentPassword} 
                                  onChange={this.handleChange}/>
                                <p className="form-control-label">
                                  {'  '}* Please enter your current password to perform any change *</p>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                        <hr className="my-4" />
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label">
                                  New Password
                                </label>
                                <Input
                                  type="password"
                                  autoComplete="off"
                                  onClick={() => this.setState({ passwordModal: true })}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </CardBody>
                      <CardFooter>
                        <Col md="6" xs="12" className="ml-auto">
                          <Row className="justify-content-end">
                            <Button className="btn-round m-2" color="danger">Cancel</Button>
                            <Button className="btn-round my-2" color="success" onClick={this.handleSubmit}>Save Changes</Button>
                          </Row>   
                        </Col>
                      </CardFooter>     
                    </Card>
                  </Form>
                </Col>
              </Row>  
              <Row className="justify-content-center">
                <Col md="4">
                  <AdminMembershipCard schoolData={schoolData}/>
                </Col>
                <Col md="7">
                  <CouponForm/>
                  <PaymentForm 
                    userData={userData} 
                    schoolData={schoolData}
                    amount={adminPlan.total} 
                    onSuccessfulCheckout={this.onSuccessfulCheckout}/>
                </Col>
              </Row>     
            </div>
            <EditPasswordModal
              isOpen={this.state.passwordModal}
              toggle={this.toggleEditPasswordModal}
              reauthenticate={this.reauthenticate}
              email={this.state.email}
              displayName={this.state.displayName}
            />        
          </Container>
        </Elements>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
      auth: state.firebase.auth,
      profileError: state.userProfile.profileError,
      userData: state.firestore.data.userData || {},
      schoolData: state.firestore.data.schoolData || {},
      adminPlan: state.admin
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    profileUpdate: (user) => dispatch(profileUpdate(user)),
    updateTotalSeats: (data) => dispatch(updateTotalSeats(data)),
    redeemCoupon: (data) => dispatch(redeemCoupon(data))
  }
}

export default compose( 
  connect(mapStateToProps,mapDispatchToProps),
  firestoreConnect((props)=> {
    if (!props.auth.uid) return []
    return [
      {
        collection:'users', 
        doc: props.auth.uid,
        storeAs: 'userData'
      },
      {
        collection:'schools', 
        doc: props.auth.uid,
        storeAs: 'schoolData'
      }]
    }),
 )(AdminSettings);
