import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, CardHeader, Form, FormGroup, Input, Row, Col } from "reactstrap";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import ErrorMessage from 'components/Alerts/ErrorMessage';

const formatNumber = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}

const PaymentForm = (props) => {
  const [isProcessing, setProcessingTo] = useState(false);
  const [name, setName] = useState(props.userData.displayName);
  const [email, setEmail] = useState(props.userData.email);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [usstate, setUsState] = useState("");
  const [zip, setZip] = useState("");
  const [checkoutError, setCheckoutError] = useState();

  useEffect(() => { setName(props.userData.displayName);}, [props.userData.displayName])
  useEffect(() => { setEmail(props.userData.email);}, [props.userData.email])
  useEffect(() => { 
    setAddress(props.schoolData.address || '');
    setCity(props.schoolData.city);
    setUsState(props.schoolData.state);
    setZip(props.schoolData.zip)
  }, [props.schoolData.address,props.schoolData.city, props.schoolData.state, props.schoolData.zip])

  const stripe = useStripe();
  const elements = useElements();

  const handleFormSubmit = async (ev) => {
    ev.preventDefault();
    setProcessingTo(true);

    const billingDetails = {
      name: name,
      email: email,
      address: {
        city: city,
        line1: address,
        state: usstate,
        postal_code: zip
      }
    };

    try {      
      const { data: clientSecret } = await axios.post('https://us-central1-pathways-f7c3a.cloudfunctions.net/stripeCharge', {
        amount: props.amount, receiptEmail: email
      })
      
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: billingDetails
      })

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      }

      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodReq.paymentMethod.id
      });

      if (error) {
        setCheckoutError(error.message);
        setProcessingTo(false);
        return;
      }
      
      if (!error){
        setProcessingTo(false);
        props.onSuccessfulCheckout();      
      }

    } catch (error) {
      setCheckoutError(error.message);
      return;
    }
  };

  const cardElementOpts = {
    style: {
      base: {
        color: "#172b4d",
        fontSize: "15px",
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        iconColor: "#172b4d",
        "::placeholder": {
          color: "#383e56",
        }
      },
      invalid: {
        iconColor: "#f5365c",
        color: "#f5365c"
      },
      complete: {
        iconColor: "#2dce89"
      }},
    hidePostalCode: true
  };

  return (
      <>
        <Card>
          <CardHeader>
            <h4 className="title-weight">Billing Info</h4>  
          </CardHeader>
          <CardBody>        
            <Form>
              <Row>  
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-address">Address</label>
                    <Input 
                      id="input-address" 
                      type="text" 
                      name="address" 
                      value={address} 
                      onChange={(e) => setAddress(e.target.value)}/>
                  </FormGroup>
                </Col>      
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-city">City</label>
                    <Input
                      id="input-city"
                      type="text" 
                      name="city" 
                      value={city || ''} 
                      onChange={(e) => setCity(e.target.value)} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>  
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-state">State</label>
                    <Input 
                      id="input-state"  
                      type="text" 
                      name="state" 
                      value={usstate || ''} 
                      onChange={(e) => setUsState(e.target.value)}/>
                  </FormGroup>
                </Col>      
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-zip">Zip Code</label>
                    <Input
                      id="input-zip"
                      type="text" 
                      name="zip" 
                      value={zip || ''} 
                      onChange={(e) => setZip(e.target.value)} />
                  </FormGroup>
                </Col>
              </Row> 
              <Row className="justify-content-between align-items-center mt-2">  
                <Col md="6" xs="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-zip">Credit Card Information</label>
                    <CardElement options={cardElementOpts}/>
                  </FormGroup>  
                </Col> 
                <Col md="6" xs="12">
                  <Row className="justify-content-end">
                    <Button className="btn-round m-3" color="success" disabled={isProcessing} onClick={handleFormSubmit}>
                      {isProcessing ? "Processing..." : `Pay $ ${formatNumber(props.amount/100)}`}
                    </Button>
                  </Row>
                </Col> 
              </Row> 
            </Form>
            {checkoutError && <ErrorMessage message={checkoutError}/>}
          </CardBody>
        </Card>     
      </>
  );
};

export default PaymentForm;