import React from 'react';
import { Row, Col, Button } from "reactstrap";
import UserTopSkillsModal from "components/Modals/UserTopSkillsModal";
import Pitch1ExamplesModal from "components/Modals/Pitch1ExamplesModal";

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Q1: '',
      Q2: '', 
      blank1: '',
      skillsModal: false,
      examplesModal: false
    }
  }

  componentDidUpdate(prevProps) { 
    if (this.props.profile !== prevProps.profile || this.props.exercise3m4 !== prevProps.exercise3m4) {
      this.setState({
        Q1: this.props.exercise3m4.Q1,
        Q2: this.props.exercise3m4.Q2,
        Q1State: this.props.exercise3m4.Q1 && 'valid',
        Q2State: this.props.exercise3m4.Q2 && 'valid',
        blank1: this.props.aspirationalJob && this.props.aspirationalJob.label 
      })
    }
  }

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  toggleModal = () => {
    this.setState({ skillsModal: !this.state.skillsModal})
  }

  toggleModal2 = () => {
    this.setState({ examplesModal: !this.state.examplesModal})
  }

  change = (e, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "length":
        if (this.verifyLength(e.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "valid" });
        } else {
          this.setState({ [stateName + "State"]: "invalid" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: e.target.value });
  }

  isValidated = () => {
    if ( 
      this.state.Q1State === "valid" &&
      this.state.Q2State === "valid"
    ) {
      return true;
    } else {
      if (this.state.Q1State !== "valid") {
        this.setState({ Q1State: "invalid" });
      }
      if (this.state.Q2State !== "valid") {
        this.setState({ Q2State: "invalid" });
      }
      return false
    }
  }

  render() {
    const { Q1, Q2, blank1, Q1State, Q2State, skillsModal, examplesModal } = this.state;
    const { profile } = this.props;
    return (
        <>
          <h2>Pitch Part 1 - Introduction</h2>
          <h3 className="text-info">Fill in the blanks</h3>
            <Row className="justify-content-center">
              <Col md="10" xs="12">
                <p>My name is <span className="title-weight">{ profile && profile.displayName }</span>, 
                  I am looking for work or to transition to <span className="pitch-blank">{blank1} </span>(aspirational job). 
                  I have a background in{' '}
                  <input
                    className="special-input"
                    placeholder="enter you current industry or role."
                    type="text"
                    value={Q1 || ""}
                    onChange={e => this.change(e, "Q1", "length", 3)}
                  /> {Q1State === "invalid" && <label className="warning"></label>}
                  (current or past industry & role) and I want to leverage my skills in{' '} 
                  <input
                    className="special-input-2"
                    placeholder="select 2-3 tranferable skills"
                    type="text"
                    value={Q2 || ""}
                    onChange={e => this.change(e, "Q2", "length", 3)}
                  /> 
                  {Q2State === "invalid" && <label className="warning"></label>} <label className="information" onClick={() => this.toggleModal()}></label> .
                </p>
                
                      
              </Col>
            </Row> 
            <Row className="justify-content-end align-items-center mt-3">
              <Col md="5">
                <h5 className="text-dark mb-2">
                  In addition to the type of work you want to do; you will want to mention how your superpower(s) helps you complete that work.
                </h5>
              </Col>
              <Col md="2">
                <Button outline color="info" size="sm" onClick={() => this.toggleModal2()}>Examples</Button>
              </Col>
            </Row> 

            <UserTopSkillsModal
              isOpen={skillsModal}
              toggle={this.toggleModal} 
            /> 
            <Pitch1ExamplesModal
              isOpen={examplesModal}
              toggle={this.toggleModal2} 
            /> 
        </>
    )
  }
}

export default Step2;
