import React from 'react';
import classnames from "classnames";
import { FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Col, Row } from "reactstrap";
import ErrorMessage from "components/Alerts/ErrorMessage"

class ThirdStep extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        email: '',
        emailState: null,
        password: '',
        passwordState: null,
        confirmPassword: '',
        confirmPasswordState: null,
        
        checkbox: false,
        errorMessage: null
      };
    }

    verifyEmail = (value) => {
      var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRex.test(value)) {
        return true;
      }
      return false;
    };

    verifyLength = (value, length) => {
      if (value.length >= length) {
        return true;
      }
      return false;
    };

    compare = (string1, string2) => {
      if (string1 === string2) {
        return true;
      }
      return false;
    };

    change = (e, stateName, type, stateNameEqualTo) => {
      switch (type) {
        case "email":
          if (this.verifyEmail(e.target.value)) {
            this.setState({ [stateName + "State"]: "valid" });
          } else {
            this.setState({ [stateName + "State"]: "invalid" });
          }
          break;
        case "length":
          if (this.verifyLength(e.target.value, stateNameEqualTo)) {
            this.setState({ [stateName + "State"]: "valid" });
          } else {
            this.setState({ [stateName + "State"]: "invalid" });
          }
          break;
        case "equalTo":
          if (this.compare(e.target.value, this.state[stateNameEqualTo])) {
            this.setState({ [stateName + "State"]: "valid" });
          } else {
            this.setState({ [stateName + "State"]: "invalid" });
          }
          break;  
        default:
          break;
        }
        this.setState({ [stateName]: e.target.value });
    };

    isValidated = () => {
      if (
        this.state.emailState === "valid" &&
        this.state.passwordState === "valid" &&
        this.state.confirmPasswordState === "valid" &&
        this.state.checkbox === true 
      ) {
        return true;
      } else {
        if (this.state.emailState !== "valid") {
          this.setState({ emailState: "invalid" });
        }
        if (this.state.passwordState !== "valid") {
          this.setState({ passwordState: "invalid" });
        }
        if (this.state.confirmPasswordState !== "valid") {
          this.setState({ confirmPasswordState: "invalid" });
        }
        if (this.state.checkbox !== true) {
          this.setState({ errorMessage: "Please accept our terms and conditions." });
        }
        return false;
      }
    }

    render() {
      const { errorMessage } = this.state
      return (
        <>
        <div className="mb-2 text-center"><h4 className="text-dark">Your Credentials</h4></div>
        <Col className="wizard-pills mr-auto ml-auto">
          <Form className="needs-validation" noValidate role="form">
            <FormGroup className={classnames({focused: this.state.focusedEmail })}>
              <InputGroup className="input-group-merge input-group-alternative mb-2">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Email"
                  type="email"
                  autoComplete="username"
                  onFocus={() => this.setState({ focusedEmail: true })}
                  onBlur={() => this.setState({ focusedEmail: false })}
                  valid={ this.state.emailState === "valid"}
                  invalid={ this.state.emailState === "invalid"}
                  onChange={e => this.change(e, "email", "email")}
                />
                <div className="invalid-feedback text-center">
                  Please enter a valid email.
                </div>
              </InputGroup>
            </FormGroup>
            <FormGroup className={classnames({ focused: this.state.focusedPassword})}>
              <InputGroup className="input-group-merge input-group-alternative mb-2" >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Password"
                  autoComplete="new-password"
                  type="password"
                  onFocus={() => this.setState({ focusedPassword: true })}
                  onBlur={() => this.setState({ focusedPassword: false })}
                  valid={this.state.passwordState === "valid"}
                  invalid={this.state.passwordState === "invalid"}
                  onChange={e => this.change(e, "password", "length", 6)}
                />
                <div className="invalid-feedback text-center">
                    Must contain at least 7 characters
                </div>
              </InputGroup>
            </FormGroup>
            <FormGroup className={classnames({ focused: this.state.focusedConfirmPassword})}>
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Confirm Password"
                  autoComplete="new-password"
                  type="password"
                  onFocus={() => this.setState({ focusedConfirmPassword: true })}
                  onBlur={() => this.setState({ focusedConfirmPassword: false })}
                  valid={ this.state.confirmPasswordState === "valid"}
                  invalid={ this.state.confirmPasswordState === "invalid"}
                  onChange={e => this.change(e, "confirmPassword", "equalTo", "password")}
                />
                <div className="invalid-feedback text-center">
                    Password doesn't match.
                </div>
              </InputGroup>
            </FormGroup>

            <Row className="my-4">
              <Col xs="12">
                <div className="custom-control custom-checkbox">
                  <input 
                    className="custom-control-input"  
                    type="checkbox" 
                    id="customCheckRegister"
                    defaultValue={false}
                    required
                    checked={this.state.checkbox}
                    onChange={() => this.setState({ checkbox: !this.state.checkbox, errorMessage: null })}
                  />
                  <label className="custom-control-label" htmlFor="customCheckRegister">
                    <span className="text-muted">
                      I agree with the{" "}
                      <a href="https://careerpathways.app/terms-conditions">
                        Terms and Conditions
                      </a>
                    </span>
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="mt-2">
                {errorMessage && <ErrorMessage message={errorMessage}/>}   
              </Col>
            </Row>
          </Form>
        </Col>
        </>
      );
    }
  }

export default ThirdStep;