const initState = {}
    
  const module3Reducer = (state = initState, action) => {
      switch(action.type){
        case 'SUBMIT_EXERCISE1M3_SUCCESS':
          console.log('Exercise 1 Module3 success');
          return state
        case 'SUBMIT_EXERCISE1M3_ERROR':
          console.log('Exercise 1 Module3 Error Submission');
          return state
        case 'SUBMIT_EXERCISE2M3_SUCCESS':
          console.log('Exercise 2 Module3 success');
          return state
        case 'SUBMIT_EXERCISE2M3_ERROR':
          console.log('Exercise 2 Module3 Error Submission');
          return state
        case 'SUBMIT_EXERCISE3AM3_SUCCESS':
          console.log('Exercise 3A Module3 success');
          return state
        case 'SUBMIT_EXERCISE3AM3_ERROR':
          console.log('Exercise 3A Module3 Error Submission');
          return state
        case 'SUBMIT_EXERCISE3B_M3_SUCCESS':
          console.log('Exercise 3B Module3 success');
          return state
        case 'SUBMIT_EXERCISE3B_M3_ERROR':
          console.log('Exercise 3B Module3 Error Submission');
          return state
        case 'SUBMIT_EXERCISE4_M3_SUCCESS':
          console.log('Exercise 4 Module3 success');
          return state
        case 'SUBMIT_EXERCISE4_M3_ERROR':
          console.log('Exercise 4 Module3 Error Submission');
          return state            
        default:
          return state
      }
  };
    
  export default module3Reducer;