const superpowers = [
 {
   "superpower": "Analytical Thinker",
   "definition": "Search for reasons and causes. Able to think about all of the factors that might effect a situation."
 },
 {
   "superpower": "Sankofa (Connect to the Past)",
   "definition": "Understand present by connecting it to the past."
 },
 {
   "superpower": "Future Visualization",
   "definition": "Inspired by the future and what it could be. Inspire others with your visions of the future."
 },
 {
   "superpower": "Ideating",
   "definition": "Fascinated by ideas, find connections between things that seem completely unrelated. Excellent at brainstorming."
 },
 {
   "superpower": "Collecting Info",
   "definition": "Like to accumulate information, ideas, things, possibly relationships"
 },
 {
   "superpower": "Intellectual",
   "definition": "High intellectual activity, introspective, appreciate intellectual discussions"
 },
 {
   "superpower": "Life-long Learner",
   "definition": "Loves to learn and continuously improve, process of learning not outcome is appealing"
 },
 {
   "superpower": "Strategizer",
   "definition": "Comes up with alternate ways to proceed easily. Easily spots relevant patterns and connections given any scenario."
 },
 {
   "superpower": "Adaptive",
   "definition": "Take each day and moment as it comes. Reacts and adjusts to given situations."
 },
 {
   "superpower": "Connection & Meaning",
   "definition": "Believe there are few coincidences and almost everything has a meaning. Sees links in all things."
 },
 {
   "superpower": "Natural Teacher",
   "definition": "Sees and cultivates the potential in others. Can see small progress in others and gains satisfaction from that progress in others."
 },
 {
   "superpower": "The Empath (High EQ)",
   "definition": "Can easily sense other people's emotions, adept at putting themselves in others' shoes."
 },
 {
   "superpower": "Consensus Builder (Harmony)",
   "definition": "Look for harmony and seek areas of agreement in most situations. Don't enjoy conflict."
 },
 {
   "superpower": "All-in & Inclusive",
   "definition": "Big on accepting others. Aware of those who feel left out and works to include them."
 },
 {
   "superpower": "Individualize People",
   "definition": "Intrigued with the unique qualities of each person. You have a knack for figuring out how different people can work together."
 },
 {
   "superpower": "Positive Outlook",
   "definition": "Have a contagious enthusiasm. You are upbeat and get others excited about they are doing."
 },
 {
   "superpower": "Deep relationships",
   "definition": "You build deep meaningful relationships."
 },
 {
   "superpower": "Get-it-started",
   "definition": "You want to do things now and like to take action. You make it happen by turning thoughts into action."
 },
 {
   "superpower": "Take Charge",
   "definition": "You have a commanding presence, take control of situations and make decisions."
 },
 {
   "superpower": "Orator (Communication)",
   "definition": "It is easy to put your thoughts into words. Great conversationalists and presenters."
 },
 {
   "superpower": "Gold Medalist (Competition)",
   "definition": "You measure your progress against others. You love contests and competition. You strive to be #1."
 },
 {
   "superpower": "Optimizer",
   "definition": "You focus on the strengths of individuals to maximize group excellence. You strive to turn something from \"good\" to \"excellent\"."
 },
 {
   "superpower": "Confident",
   "definition": "Confident in your ability to take risks and mange your own life. You have a strong sense of certainty in your decisions and actions."
 },
 {
   "superpower": "Make it Count (Influencer)",
   "definition": "You want to make a big impact. You prioritize work based on how much influence it has on people around you, your organization or the communities that you are a part of."
 },
 {
   "superpower": "Charisma (Winning Others Over)",
   "definition": "Love the challenge of meeting new people and winning them over. Breaking the ice and making initial connection is satisfying to you."
 },
 {
   "superpower": "Getting-Things-Done",
   "definition": "As a hard-worker, you find it satisfying to be productive and busy. You love to \"do\" things and have a great deal of stamina."
 },
 {
   "superpower": "Organizer",
   "definition": "You like to organize and rearrange things and people. Ultimately you like to determine how all of the pieces and resources can be arranged for maximum productivity. You do so with flexibility and are preemptive in your organizing skills."
 },
 {
   "superpower": "Belief",
   "definition": "You have set of core values that you do not waiver from. You have a defined sense of purpose that is derived from your values."
 },
 {
   "superpower": "Steadiness",
   "definition": "You crave and create stable routines and clear rules and procedures. Very aware of the need to treat everyone the same."
 },
 {
   "superpower": "Thoughtful Consideration",
   "definition": "You take great care in making decisions and anticipate obstacles."
 },
 {
   "superpower": "Order Creation",
   "definition": "Disciplined in the routine and structure you provide and create."
 },
 {
   "superpower": "Focused",
   "definition": "Your follow-through is immaculate and you are known to make corrections as necessary to stay on track. You take direction, prioritize and act."
 },
 {
   "superpower": "Ownership & responsibility",
   "definition": "Take ownership of what you say you will do. Honest and loyal."
 },
 {
   "superpower": "Problem-solver",
   "definition": "Good at figuring out what is wrong and fixing it."
 }
]

module.exports = superpowers