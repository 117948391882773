import React from "react";
import ReactWizard from "react-bootstrap-wizard";
import moment from "moment";

import Step1 from "./Step1";
//import Step2 from "./Step2";
import Step2v2 from "./Step2v2";
//import Step3 from "./Step3";
//import Step4 from "./Step4";
import Step4v2 from "./Step4v2";
import NewBadgeModal from '../../../../components/Modals/NewBadgeModal'

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { submitExercise1 } from "redux/actions/module1Actions";
  
const steps = [
  { stepName: "1", component: Step1 },
  { stepName: "2", component: Step2v2},
  //{ stepName: "3", component: Step3 },
  { stepName: "4", component: Step4v2 },
];

class Exercise1 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      steps: steps,
      badgeModal: false
    }
  }

  componentDidUpdate(prevProps) { 
    if (this.props.exercise1 !== prevProps.exercise1) {
      this.setState({
        steps: [
          { stepName: "1", component: Step1 },
          { stepName: "2", component: Step2v2, stepProps: this.props.exercise1},
          //{ stepName: "3", component: Step3, stepProps: this.props.exercise1},
          { stepName: "4", component: Step4v2, stepProps: this.props.exercise1},
        ]
      })
    }
  }

  finishButtonClick = (allStates) => {
    const data = {
      userID: this.props.uid,
      //Q1: allStates["2"].Q1,
      Q2: allStates["2"].Q2,
      //Q3: allStates["2"].Q3,
      //Q4: allStates["2"].Q4,
      //Q5: allStates["2"].Q5,
      //Q6: allStates["2"].Q6.value,
      Q7: moment(allStates["2"].Q7).format('MM/DD/YYYY'),
      //Q8: allStates["2"].Q8,
      //Q9: allStates["2"].Q9,
      //Q10: allStates["3"].Q10.value,
      //Q11: allStates["3"].Q11,
      //Q12: allStates["3"].Q12,
      //Q13: allStates["3"].Q13,
      //Q14: allStates["3"].Q14,
      //Q15: allStates["4"].Q15.value,
      //Q16: allStates["4"].Q16.value,
      //Q17: allStates["4"].Q17,
      Q18: allStates["2"].Q18,
      Q19: allStates["2"].Q19.value,
      Q20: allStates["2"].Q20,
      race: allStates["4"].race.value,
      gender: allStates["4"].gender.value,
      disability: allStates["4"].disability.value,
    }
    this.toggleModal()
    this.props.submitExercise1(data)
  }

  toggleModal = () => {
    this.setState({ badgeModal: !this.state.badgeModal })
  }

  render() {  
    return (
      <>
        <ReactWizard
          steps={this.state.steps}
          navSteps
          validate
          progressbar
          title="Module 1 - Right Now"
          description="Ex - 1"
          headerTextCenter
          finishButtonClasses="btn-default"
          nextButtonClasses="btn-sm-default"
          previousButtonClasses="btn-danger"
          finishButtonClick={this.finishButtonClick}
        />
        <NewBadgeModal
          isOpen={this.state.badgeModal}
          toggle={this.toggleModal}
          history={this.props.history}
          message={'You earned your first badge.'}
          next={'ex2'}
        /> 
      </>
    )
  }

}  

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    module1 : state.firestore.data.module1 || {},
    exercise1 : state.firestore.data.exercise1 || {}
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitExercise1: (data) => dispatch(submitExercise1(data))
  }
}

export default compose( 
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module1"}],
        storeAs: 'module1'
      },
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module1",
          subcollections: [{ collection: "exercises", doc: "exercise1"}],
        }],
        storeAs: 'exercise1'
      }
    ]
  }),
)(Exercise1);
