import React from "react";
import { Container } from "reactstrap";
import PathwaysHeader from "../../components/Headers/PathwaysHeader";
import StartMarker from "../../assets/img/icons/common/StartMarker.svg";
import LockMarker from "../../assets/img/icons/common/LockMarker2.svg";
import LockMarkerMobile from "../../assets/img/icons/common/LockMarkerMobile.svg";
import UnlockedMarker from "../../assets/img/icons/common/UnlockedMarker2.svg";
import UnlockedMarkerMobile from "../../assets/img/icons/common/UnlockedMarkerMobile.svg";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

class Map extends React.Component {
 
  render() {
    const overallProgress = this.props.userData.op;

    return (
      <>
        <PathwaysHeader location={this.props.location.pathname} />
        <Container className="mt--6 bg-map d-none d-lg-block" fluid>
          <img 
            alt='Module 1 Route Map' 
            src={require("assets/img/theme/Map.svg")}/> 

          <span className="btn1" onClick={() => this.props.history.push('/user/module1/ex1')}>
              <img alt='...' src={StartMarker}/> 
          </span> 
          <span className="btn2" onClick={() => this.props.history.push('/user/module1/ex2')}>
              <img alt='...' src={overallProgress < 10 ? LockMarker : UnlockedMarker}/> 
          </span> 
          <span className="btn3" onClick={() => this.props.history.push('/user/module1/ex3')}>
              <img alt='...' src={overallProgress < 15 ? LockMarker : UnlockedMarker}/> 
          </span> 
          <span className="btn4" onClick={() => this.props.history.push('/user/module2/ex1')}>
              <img alt='...' src={overallProgress < 20 ? LockMarker : UnlockedMarker}/> 
          </span> 
          <span className="btn5" onClick={() => this.props.history.push('/user/module2/ex2')}>
              <img alt='...' src={overallProgress < 25 ? LockMarker : UnlockedMarker}/> 
          </span> 
          <span className="btn6" onClick={() => this.props.history.push('/user/module2/ex3')}>
              <img alt='...' src={overallProgress < 30 ? LockMarker : UnlockedMarker}/> 
          </span>
          <span className="btn7" onClick={() => this.props.history.push('/user/module3/ex1')}>
              <img alt='...' src={overallProgress < 40 ? LockMarker : UnlockedMarker}/> 
          </span>
          <span className="btn8" onClick={() => this.props.history.push('/user/module3/ex2')}>
              <img alt='...' src={overallProgress < 45 ? LockMarker : UnlockedMarker}/> 
          </span>
          <span className="btn9" onClick={() => this.props.history.push('/user/module3/ex3a')}>
              <img alt='...' src={overallProgress < 50 ? LockMarker : UnlockedMarker}/> 
          </span>
          <span className="btn10" onClick={() => this.props.history.push('/user/module3/ex4')}>
              <img alt='...' src={overallProgress < 55 ? LockMarker : UnlockedMarker}/> 
          </span>
          <span className="btn11" onClick={() => this.props.history.push('/user/module4/ex1')}>
              <img alt='...' src={overallProgress < 60 ? LockMarker : UnlockedMarker}/> 
          </span>
          <span className="btn12" onClick={() => this.props.history.push('/user/module4/ex2')}>
              <img alt='...' src={overallProgress < 65 ? LockMarker : UnlockedMarker}/> 
          </span>
          <span className="btn13" onClick={() => this.props.history.push('/user/module4/ex3')}>
              <img alt='...' src={overallProgress < 70 ? LockMarker : UnlockedMarker}/> 
          </span>
          <span className="btn14" onClick={() => this.props.history.push('/user/module5')}>
              <img alt='...' src={overallProgress < 80 ? LockMarker : UnlockedMarker}/> 
          </span>
          <span className="btn15" onClick={() => this.props.history.push('/user/module5/ex1')}>
              <img alt='...' src={overallProgress < 80 ? LockMarker : UnlockedMarker}/> 
          </span>
          <span className="btn16" onClick={() => this.props.history.push('/user/module5/final-report')}>
              <img alt='...' src={overallProgress < 100 ? LockMarker : UnlockedMarker}/> 
          </span>        
        </Container>
        <Container className="bg-map d-inline-block d-block d-sm-none my--6 mx-0 px-0" fluid>
          <img 
            alt='Module 1 Route Map' 
            src={require("assets/img/theme/MobileMap.svg")}/> 
          <span className="btn1" onClick={() => this.props.history.push('/user/module1/ex1')}>
              <img alt='...' src={StartMarker}/> 
          </span> 
          <span className="btn2" onClick={() => this.props.history.push('/user/module1/ex2')}>
              <img alt='...' src={overallProgress < 10 ? LockMarkerMobile : UnlockedMarkerMobile}/> 
          </span>
          <span className="btn3" onClick={() => this.props.history.push('/user/module1/ex3')}>
              <img alt='...' src={overallProgress < 15 ? LockMarkerMobile : UnlockedMarkerMobile}/> 
          </span>
          <span className="btn4" onClick={() => this.props.history.push('/user/module2/ex1')}>
              <img alt='...' src={overallProgress < 20 ? LockMarkerMobile : UnlockedMarkerMobile}/> 
          </span>
          <span className="btn5" onClick={() => this.props.history.push('/user/module2/ex2')}>
              <img alt='...' src={overallProgress < 25 ? LockMarkerMobile : UnlockedMarkerMobile}/> 
          </span>
          <span className="btn6" onClick={() => this.props.history.push('/user/module2/ex3')}>
              <img alt='...' src={overallProgress < 30 ? LockMarkerMobile : UnlockedMarkerMobile}/> 
          </span>
          <span className="btn7" onClick={() => this.props.history.push('/user/module3/ex1')}>
              <img alt='...' src={overallProgress < 40 ? LockMarkerMobile : UnlockedMarkerMobile}/> 
          </span>
          <span className="btn8" onClick={() => this.props.history.push('/user/module3/ex2')}>
              <img alt='...' src={overallProgress < 45 ? LockMarkerMobile : UnlockedMarkerMobile}/> 
          </span>
          <span className="btn9" onClick={() => this.props.history.push('/user/module3/ex3a')}>
              <img alt='...' src={overallProgress < 50 ? LockMarkerMobile : UnlockedMarkerMobile}/> 
          </span>
          <span className="btn10" onClick={() => this.props.history.push('/user/module3/ex4')}>
              <img alt='...' src={overallProgress < 55 ? LockMarkerMobile : UnlockedMarkerMobile}/> 
          </span>
          <span className="btn11" onClick={() => this.props.history.push('/user/module4/ex1')}>
              <img alt='...' src={overallProgress < 60 ? LockMarkerMobile : UnlockedMarkerMobile}/> 
          </span>
          <span className="btn12" onClick={() => this.props.history.push('/user/module4/ex2')}>
              <img alt='...' src={overallProgress < 65 ? LockMarkerMobile : UnlockedMarkerMobile}/> 
          </span>
          <span className="btn13" onClick={() => this.props.history.push('/user/module4/ex3')}>
              <img alt='...' src={overallProgress < 70 ? LockMarkerMobile : UnlockedMarkerMobile}/> 
          </span>
          <span className="btn14" onClick={() => this.props.history.push('/user/module5')}>
              <img alt='...' src={overallProgress < 80 ? LockMarkerMobile : UnlockedMarkerMobile}/> 
          </span>
          <span className="btn15" onClick={() => this.props.history.push('/user/module5/ex1')}>
              <img alt='...' src={overallProgress < 80 ? LockMarkerMobile : UnlockedMarkerMobile}/> 
          </span>
          
          <span className="btn17" onClick={() => this.props.history.push('/user/module5/final-report')}>
              <img alt='...' src={overallProgress < 100 ? LockMarkerMobile : UnlockedMarkerMobile}/> 
          </span>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    userData: state.firebase.profile,
    module1 : state.firestore.data.module1 || {},
  }
};

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module1"}],
        storeAs: 'module1'
      }]
    }),
)(Map);
