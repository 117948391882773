import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Collapse, Button, Row } from "reactstrap";
import { M3Ex1Summary, M3Ex2Summary, M3Ex3Summary, M3Ex4Summary } from "components/Tables";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const CollapseM3 = (props) => {

    const [ openedCollapses, setOpenedCollapses ] = useState([])
    const [ module3, setModule3 ] = useState({})
    //const { module3 } = props;
    useEffect(() => { 
      setModule3(props.module3);
    }, [props.module3])

    const collapsesToggle = (collapse) => {
      if (openedCollapses.includes(collapse)) {
        setOpenedCollapses([]);
      } else {
        setOpenedCollapses([collapse]);
      }
    };

    return (
      <>
        <div className="accordion">
          <Card className="card-plain">
            <CardHeader
              role="tab"
              onClick={() => collapsesToggle("collapseOne")}
              aria-expanded={openedCollapses.includes( "collapseOne" )}
            >
              <h5 className="mb-0">Exercise 1</h5>
            </CardHeader>
            <Collapse role="tabpanel" isOpen={openedCollapses.includes("collapseOne")}>
              <CardBody>
                <M3Ex1Summary history={props.history} progress={module3.progress} moduleBlocked={module3.blocked}/>
                  <Row className="justify-content-end mr-1 my-2">
                    <Button color="primary" size="sm" onClick={() => props.history.push('/user/module3/ex1')}>
                        Continue
                    </Button>
                  </Row>
              </CardBody>
            </Collapse>
          </Card>

          <Card className="card-plain">
            <CardHeader
              role="tab"
              onClick={() => collapsesToggle("collapseTwo")}
              aria-expanded={openedCollapses.includes( "collapseTwo" )}
            >
              <h5 className="mb-0">Exercise 2</h5>
            </CardHeader>
            <Collapse role="tabpanel" isOpen={openedCollapses.includes("collapseTwo")}>
              <CardBody>
                <M3Ex2Summary history={props.history} progress={module3.progress} moduleBlocked={module3.blocked}/>
                  <Row className="justify-content-end mr-1 my-2">
                    <Button color="primary" type="button" size="sm" onClick={() => props.history.push('/user/module3/ex2')}>
                        Continue
                    </Button>
                  </Row>
              </CardBody>
            </Collapse>
          </Card>
          
          <Card className="card-plain">
            <CardHeader
              role="tab"
              onClick={() => collapsesToggle("collapseThree")}
              aria-expanded={ openedCollapses.includes("collapseThree") }
            >
              <h5 className="mb-0">Exercise 3</h5>
            </CardHeader>
            <Collapse role="tabpanel"isOpen={openedCollapses.includes("collapseThree")}>
              <CardBody>
                <M3Ex3Summary history={props.history} progress={module3.progress} moduleBlocked={module3.blocked}/>
                  <Row className="justify-content-end mr-1 my-2">
                    <Button color="primary" type="button" size="sm" onClick={() => props.history.push('/user/module3/ex3a')}>
                        Continue
                    </Button>
                  </Row>
              </CardBody>
            </Collapse>
          </Card>

          <Card className="card-plain">
            <CardHeader
              role="tab"
              onClick={() => collapsesToggle("collapseFour")}
              aria-expanded={ openedCollapses.includes("collapseFour") }
            >
              <h5 className="mb-0">Exercise 4</h5>
            </CardHeader>
            <Collapse role="tabpanel"isOpen={openedCollapses.includes("collapseFour")}>
              <CardBody>
                <M3Ex4Summary history={props.history} progress={module3.progress} moduleBlocked={module3.blocked}/>
                  <Row className="justify-content-end mr-1 my-2">
                    <Button color="primary" type="button" size="sm" onClick={() => props.history.push('/user/module3/ex4')}>
                        Continue
                    </Button>
                  </Row>
              </CardBody>
            </Collapse>
          </Card>
        </div>
      </>
    )
}


const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    module3 : state.firestore.data.module3 || {},
  }
};

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module3"}],
        storeAs: 'module3'
      }]
    }),
)(CollapseM3);