import React, { useRef } from 'react';
import ReactToPrint from "react-to-print";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button, ButtonGroup, Card, Row, Col, UncontrolledTooltip } from "reactstrap";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: false,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        users.
      </label>
    </div>
  )
})
const { SearchBar } = Search;

const M2Table = (props) => {

  const componentRef = useRef();

  const displayData = props.data && props.data?.map((i, index) => ({ 
    key: index,
    uuid: i.uuid,
    goal1: i.goal1,
    goal2: i.goal2,
  }));
  
  return (
      <Card>
        <ToolkitProvider
          data={displayData || []}
          keyField="key"
          columns={[
            {
              dataField: "uuid",
              text: "User id",
              sort: false,
            },
            {
              dataField: "goal1",
              text: "Goal 1",
              sort: false,
              style: {inlineSize: "400px", whiteSpace: "pre-wrap",overflowWrap: "break-word"}
            },
            {
              dataField: "goal2",
              text: "Goal 2",
              sort: false,
              style: {inlineSize: "400px", whiteSpace: "pre-wrap", overflowWrap: "break-word"}
            },
          ]}
          search
        >
          {props => (
            <div className="py-4 table-responsive">
              <div
                id="datatable-basic_filter"
                className="dataTables_filter px-4 pb-1"
              >
                <label>
                  Search:
                  <SearchBar
                    className="form-control-sm"
                    placeholder=""
                    {...props.searchProps}
                  />
                </label>
              </div>
              <BootstrapTable
                ref={componentRef}
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                id="react-bs-table"
                style={{inlineSize: "400px", overflowWrap: "break-word"}}
              />
            </div>
          )}
        </ToolkitProvider>
        <Col>
          <Row className="justify-content-end"> 
            <ButtonGroup>
              <ReactToPrint
                trigger={() => (
                  <Button
                    color="default"
                    size="sm"
                    className="buttons-copy buttons-html5 mx-2 my-2"
                    id="print-tooltip"
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </ButtonGroup>
            <UncontrolledTooltip placement="top" target="print-tooltip">
              Print or save to PDF.
            </UncontrolledTooltip>    
          </Row>
        </Col>     
      </Card>
    )
}
        
export default M2Table;

