import React, {Fragment} from 'react';
import { Button, Modal, Table } from "reactstrap";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const UserSuperpowersModal = (props) => {

    const tableRows = props.exercise3m3.superpowersTop3 && props.exercise3m3.superpowersTop3.map((i, index) => {
      return (
        <Fragment key={index}>
          <tr>
            <th className="text-dark title-weight text-center">{index + 1}</th>
            <th className="title-weight">{i}</th>
          </tr>
        </Fragment>
        )
      });

    return ( 
        <Modal className="modal-dialog-centered" size="sm" isOpen={props.isOpen} toggle={props.toggle}>
          <div className="modal-header">
            <h6 className="modal-title">
              Your Top 3 Superpowers
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => props.toggle()}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <div className="modal-body">        
          <Table responsive>
              <thead className="thead-light">
                <tr>
                  <th className="text-center">Ranking</th>
                  <th>Superpower</th>
                </tr>
              </thead>
              <tbody>
                {tableRows}
              </tbody>
            </Table>
          </div>

          <div className="modal-footer">
            <Button color="primary" type="button" size="sm" onClick={() => props.toggle()}>
              Ok, thanks
            </Button>
          </div>
        </Modal> 
    )
}

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    exercise3m3 : state.firestore.data.exercise3m3 || {}
  }
};

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module3",
          subcollections: [{ collection: "exercises", doc: "exercise3"}],
        }],
        storeAs: 'exercise3m3'
      }
    ]
  }),
)(UserSuperpowersModal);