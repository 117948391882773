import React from "react";
import { Button, Card, CardBody,Container, Row, Col } from "reactstrap";
import AuthFooter from "components/Footers/AuthFooter";
import access_denied_icon from "assets/img/icons/access-denied.png";

const AccessDenied = (props) => {

    return(
      <>
        <div className="bg-indigo main-content">
          <div className="header bg-indigo py-7 py-lg-8 pt-lg-9">
            <Container>
              <div className="header-body text-center mb-7">
                <Row className="justify-content-center">
                  <Col className="px-2" lg="6" md="8" xl="5">
                    <img
                      alt="Pathways Logo"
                      style={{height:'60px', maxWidth:'305px'}}
                      src={require("assets/img/brand/logo2.png")}
                    />                    
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <Container className="mt--8 pb-6">
            <Row className="justify-content-center">
              <Col lg="5" md="7">
                <Card className="bg-cyan border-0 mb-0">
                  <CardBody className="text-center px-lg-5 py-lg-5">
                    <div className="mb-1">
                      <h3 className="text-white">Oh no! Seems like you don't have access to this part of the site.</h3>
                    </div>
                    <Row>
                      <Col className="mr-auto ml-auto" lg="12" md="12">
                        <img src={access_denied_icon} alt="icon" width="100%"/>
                      </Col>
                    </Row>
                    <Button color="default" onClick={() => props.history.push("/login")}>
                        Go Back
                    </Button>  
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      <AuthFooter/>
    </>  
    )
}

export default AccessDenied;
