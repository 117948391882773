const matrixData = [
    {
      position: "1.1",
      option: "Option1",
      areaName: "Computer & Mathematics",
      shortName: "computer",
      entry: "Gig",
      description: "<p><b>Project or Gig-Based</b> </p><ul><li><p>Build a website repository or portfolio of projects you’ve completed.</p></li><li><p>Set up profiles at freelancer sites like</p> <ul> <li><h4><a href='https://www.fiverr.com/'>Fiverr</a></h4></li><li><h4><a href='https://www.mturk.com/'>Mturk</a></h4></li></ul></li><li><p> If you are 18+ years old posting your services on sites like freelancer.com and craigslist.com are options as well.</p><ul> <li><h4><a href='https://www.peopleperhour.com/'>Peopleperhour.com</a></h4></li>"
      + "<li><h4>Guru, (after you’ve built up some experience)[AK2] </h4></li><li><h4>Github.com, (after you’ve built up some experience)[AK3] </h4></li><li><h4>Freelancermap.com[AK4]  </h4></li></ul></li>"
      + "<li><p>If you are at a school or institution</p><ul> <li><h4>Check with administration and departments to see if there are any projects that you can pick up or do for them.</h4></li><li><h4>Find out if you can align your work study job and hours within this occupational track or building the skills you need or desire within this track.</h4></li></ul></li></ul>",
    },
    {
      position: "1.2",
      option: "Option2",
      areaName:"Business & Finance",
      shortName:"business",
      entry: "Gig",
      description: "<p><b>Project or Gig-Based</b> </p><ul><li><p>Set up a profile at freelancer sites like:</p> <ul> <li><h4><a href='https://www.fiverr.com/'>Fiverr</a></h4></li><li><h4><a href='https://www.mturk.com/'>Mturk</a></h4></li></ul></li><li><p> If you are 18+ years old posting your services on sites like freelancer.com and craigslist.com are options as well.</p>"
      + "<li><p>If you are at a school or institution</p><ul> <li><p>Check with administration and departments to see if there are any projects that you can pick up or do for them.</p></li><li><p>Find out if you can align your work study job and hours within this occupational track or building the skills you need or desire within this track.</p></li></ul></li></ul>",
    },
    {
      position: "1.3",
      option: "Option3",
      areaName:"Tech Sales and Management",
      shortName:"tech",
      entry: "Gig",
      description: ""
    },
    {
      position: "2.1",
      option: "Option4",
      areaName: "Computer & Mathematics",
      shortName: "computer",
      entry: "Apprenticeship",
      description: "<p><b>Apprenticeship</b> </p><p>Apprenticeships are possible in this occupational track, however not common. To find an apprenticeship in your locality, start with this <a href='https://www.apprenticeship.gov/apprenticeship-job-finder'><span className='text-primary'>Apprenticeship Job Finder</span></a> to search for apprenticeships near you. In addition to that, set up an appointment with your local <a href='https://www.careeronestop.org/LocalHelp/AmericanJobCenters/find-american-job-centers.aspx'><span className='text-primary'>One-Stop Career Center</span></a> to find out what apprenticeship programs they are partnered with.</p>"
    },
    {
      position: "2.2",
      option: "Option5",
      areaName:"Business & Finance",
      shortName:"business",
      entry: "Apprenticeship",
      description: "<p><b>Apprenticeship</b></p> <p>Apprenticeships are possible in this occupational track, however not common. To find an apprenticeship near you, start with this <a href='https://www.apprenticeship.gov/apprenticeship-job-finder'><span className='text-primary'>Apprenticeship Job Finder</span></a> to search for apprenticeships near you. In addition to that, set up an appointment with your local <a href='https://www.careeronestop.org/LocalHelp/AmericanJobCenters/find-american-job-centers.aspx'><span className='text-primary'>One-Stop Career Center</span></a>  to find out what apprenticeship programs they are partnered with.</p>"
    },
    {
      position: "2.3",
      option: "Option6",
      areaName:"Tech Sales and Management",
      shortName:"tech",
      entry: "Apprenticeship",
      description: ""
    },
    {
      position: "2.4",
      option: "Option7",
      areaName:"Skill Trades",
      shortName:"trade",
      entry: "Apprenticeship",
      description: "<p><b>Apprenticeship</b></p> <p>Apprenticeships are possible in this occupational track, however not common. To find an apprenticeship near you, start with this <a href='https://www.apprenticeship.gov/apprenticeship-job-finder'><span className='text-primary'>Apprenticeship Job Finder</span></a> to search for apprenticeships near you. In addition to that, set up an appointment with your local <a href='https://www.careeronestop.org/LocalHelp/AmericanJobCenters/find-american-job-centers.aspx'><span className='text-primary'>One-Stop Career Center</span></a> to find out what apprenticeship programs they are partnered with.</p>"
    }, 
    {
      position: "3.1",
      option: "Option8",
      areaName: "Computer & Mathematics",
      shortName: "computer",
      entry: "Entry-level",
      description: "<p><b>Entry-Level Position</b> </p><p>Start by checking with the career center at the school you are currently attending to find out what companies are recruiting at your school and apply to the positions and companies you are interested in. Make a list of the companies that you would like to work for, then make a list of people who you know at those companies, reach out to them and set up an <a href='https://www.themuse.com/advice/3-steps-to-a-perfect-informational-interview'><span className='text-primary'>informational interview </span></a> to learn more about the company, how they got their job and if there are any opportunities they know about. Starting your career in the armed forces is another viable path. This will give you hands-on experience and training while getting paid.</p>"
    },
    {
      position: "3.2",
      option: "Option9",
      areaName:"Business & Finance",
      shortName:"business",
      entry: "Entry-level",
      description: "<p><b>Entry-Level Position</b> </p><p>Start by checking with the career center at the school you are currently attending to find out what companies are recruiting at your school and apply to the positions and companies you are interested in. Make a list of the companies that you would like to work for, then make a list of people who you know at those companies, reach out to them and set up an <a href='https://www.themuse.com/advice/3-steps-to-a-perfect-informational-interview'><span className='text-primary'>informational interview </span></a> to learn more about the company, how they got their job and if there are any opportunities they know about.</p>"
    },
    {
      position: "3.3",
      option: "Option10",
      areaName:"Tech Sales and Management",
      shortName:"tech",
      entry: "Entry-level",
      description: "<p><b>Entry-Level Position</b> </p><p>Start by checking with the career center at the school you are currently attending to find out what companies are recruiting at your school and apply to the positions and companies you are interested in. Make a list of the companies that you would like to work for, then make a list of people who you know at those companies, reach out to them and set up an <a href='https://www.themuse.com/advice/3-steps-to-a-perfect-informational-interview'><span className='text-primary'>informational interview </span></a> to learn more about the company, how they got their job and if there are any opportunities they know about.</p>"
    },
    {
      position: "3.4",
      option: "Option11",
      areaName:"Skill Trades",
      shortName:"trade",
      entry: "Entry-level",
      description: "<p><b>Entry-Level Position</b> </p><p>Start by checking with the career center at the school you are currently attending to find out what companies are recruiting at your school and apply to the positions and companies you are interested in. Make a list of the companies that you would like to work for, then make a list of people who you know at those companies, reach out to them and set up an <a href='https://www.themuse.com/advice/3-steps-to-a-perfect-informational-interview'><span className='text-primary'>informational interview </span></a> to learn more about the company, how they got their job and if there are any opportunities they know about.</p>"
    },
    {
      position: "3.5",
      option: "Option12",
      areaName:"Healthcare",
      shortName:"healthcare",
      entry: "Entry-level",
      description: "<p><b>Entry-Level Position</b> </p><p>Start by checking with the career center at the school you are currently attending to find out what companies are recruiting at your school and apply to the positions and companies you are interested in. Make a list of the companies that you would like to work for, then make a list of people who you know at those companies, reach out to them and set up an <a href='https://www.themuse.com/advice/3-steps-to-a-perfect-informational-interview'><span className='text-primary'>informational interview </span></a> to learn more about the company, how they got their job and if there are any opportunities they know about.</p>"
    }
]

export default matrixData;