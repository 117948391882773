import React, {Fragment} from 'react';
import { Button, Modal, Table } from "reactstrap";
import superpowers from "variables/superpowers";

const SuperpowersModal = (props) => {

  const tableRows = superpowers.map((i, index) => {
    return (
      <Fragment key={index}>
        <tr>
          <th className="text-dark title-weight">{i.superpower}</th>
          <td>{i.definition}</td>
        </tr>
      </Fragment>
      )
    });

    return ( 
        <Modal className="modal-dialog-centered" size="xl" isOpen={props.isOpen} toggle={props.toggle}>
          <div className="modal-header">
            <h6 className="modal-title">
              Definitions
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => props.toggle()}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <div className="modal-body">        
            <Table responsive>
              <thead className="thead-light">
                <tr>
                  <th>Superpower</th>
                  <th>Definition</th>
                </tr>
              </thead>
              <tbody>
                {tableRows}
              </tbody>
            </Table>
          </div>

          <div className="modal-footer">
            <Button color="primary" type="button" onClick={() => props.toggle()}>
              Ok, Got it
            </Button>
          </div>
        </Modal> 
    )
}
export default SuperpowersModal;