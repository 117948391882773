import React, { Fragment } from "react";
import { Table, Row, Col } from "reactstrap";
import ModuleLocked from "components/Sections/ModuleLocked"

import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';


const M1Ex2Summary = (props) => {
  const { exercise2 } = props;
  const valuesRanking = exercise2.valuesTop6 && exercise2.valuesTop6.map((i, index) => {
    return (
      <Fragment key={index}>
        <tr>
          <th className="text-center">{index + 1}</th>
          <td>{i}</td>
        </tr>
      </Fragment>
      )
    });
    return (
      <>
      { props.progress < 33
      ? <ModuleLocked message={'Exercise Locked'}/> 
      : <>
          <Row className="justify-content-center">
            <Col md='8'>
              <h4 className="title-weight"> Your Top 6 Values</h4>
              <Table id="classes" responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="text-center">Ranking</th>
                    <th>Your Values</th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {valuesRanking}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      }
      </> 
    )
}


const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    exercise2 : state.firestore.data.exercise2 || {}
  }
};

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module1",
          subcollections: [{ collection: "exercises", doc: "exercise2"}],
        }],
        storeAs: 'exercise2'
      }]
    }),
)(M1Ex2Summary);
