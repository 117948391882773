import React, {useState, useEffect}from 'react';
import { Button, Modal, Row, Col, CardTitle } from "reactstrap";
import { connect } from 'react-redux';
import { selectApproach } from 'redux/actions/module5Actions';
import { Markup } from 'interweave'

const MatrixM4ContentModal = (props) => {

    const { data } = props
    const [ checkMark, setCheckMark ] = useState(data.checked)
    useEffect(() => { setCheckMark(data.checked) }, [data])

    const selectOption = () => {
      props.selectApproach(data.position)
      setCheckMark(!checkMark)
    }

    const renderButton = () => {
      if (checkMark === true) {
        return  <div className="icon icon-shape bg-success text-dark rounded-circle shadow" onClick={selectOption}>
                  <i className="ni ni-check-bold" />
                </div>
      } 
      return <div className="icon icon-shape bg-blue text-white rounded-circle shadow grow-icon" onClick={selectOption}>
                <i className="ni ni-fat-delete" />
              </div>
    }

    const articleContent = data.description || ""

    return ( 
        <Modal className="modal-dialog-centered" isOpen={props.isOpen} toggle={props.toggle} size="lg">
          <div className="modal-header">
            <h6 className="modal-title">
              Career Path Approach
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => props.toggle()}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <div className="modal-body">  
            <CardTitle className="text-uppercase text-blue mb-1">
              {props.data && props.data.areaName}
            </CardTitle>
            <Row>
              <div className="col">
                <h5 className="text-muted">Approach</h5>
              </div>
              <Col className="col-auto">
              <h6 className="text-center">Select</h6>
                {renderButton()}
              </Col>
            </Row>   
            <Markup content={articleContent} />
          </div>

          <div className="modal-footer">
            <Button color="primary" size="sm" type="button" onClick={() => props.toggle()}>
              Close
            </Button>
          </div>
        </Modal> 
    )
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectApproach: (approach) => dispatch(selectApproach(approach)),
  }
}

export default connect( null, mapDispatchToProps)(MatrixM4ContentModal);