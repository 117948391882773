import React from "react";
import { Container, Button, Card, CardHeader, CardBody, CardFooter, FormGroup, Form, Input, Row, Col, Spinner, UncontrolledAlert } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import PathwaysHeader from "components/Headers/PathwaysHeader";
import ErrorMessage from "components/Alerts/ErrorMessage";
import ImageUpload from "components/Avatar/ImageUpload";
import EditPasswordModal from 'components/Modals/EditPasswordModal';
import { UserProviderForm, MembershipCard } from "components/Sections"

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { profileUpdate, updateMembership } from '../../redux/actions/profileActions';
import Fire from '../../Fire';

const api_key = process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY;
//const api_key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
const stripePromise = loadStripe(api_key);

class UserAccount extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      displayName: this.props.profile.displayName,
      email: this.props.profile.email,
      school: this.props.profile.school,
      firstname: this.props.profile.firstname,
      lastname: this.props.profile.lastname,
      membership: this.props.profile.membership,
      currentPassword: '',

      successMessage: false,
      passwordModal: false,
      errorMessage: null,
      alert: null,
      isLoading: false,
      portalLoading: false
    };
  }

  componentDidUpdate(prevProps){
    if (this.props.profile !== prevProps.profile) {
      this.setState({ 
        displayName: this.props.profile.displayName,
        email: this.props.profile.email,
        school: this.props.profile.school,
        firstname: this.props.profile.firstname,
        lastname: this.props.profile.lastname,
        membership: this.props.profile.membership
      })
    }
  }

  handleChange = (e) => {
    const itemName = e.target.name;
    const itemValue = e.target.value;
    this.setState({ [itemName]: itemValue, errorMessage: null });
  }

  toggleEditPasswordModal = () => {
    this.setState({ passwordModal: !this.state.passwordModal })
  }

  reauthenticate = (currentPassword) => {
    let user = Fire.shared.auth.currentUser;
    let cred = Fire.shared.EmailAuthProvider.credential(user.email, currentPassword);
    return user.reauthenticateWithCredential(cred);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const profileInfo = {
      //displayName: this.state.displayName,
      uid: this.props.auth.uid,
      email: this.state.email,
      school: this.state.school,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
    }
    this.reauthenticate(this.state.currentPassword)
    .then(() => {
      let user = Fire.shared.auth.currentUser;
      user.updateEmail(this.state.email)
      .then(() => {
        this.props.profileUpdate(profileInfo);
        this.setState({ successMessage: true, currentPassword: '', errorMessage: null });
      })
      .catch((error) => { 
        this.setState({ errorMessage : error.message })  
      });
    }).catch((error) => { 
      this.setState({ errorMessage : error.message })    
    });
  }

  onSuccessfulCheckout = async () => {
    try {
      await this.props.updateMembership(this.props.auth.uid)
      this.successAlert()
    } catch (err) {
      console.log(err)
    }
  }

  handleNewSubscription = async () => {
    this.setState({ isLoading: true })
    const docRef = await Fire.shared.firestore.collection('users')
        .doc(this.props.auth.uid)
        .collection('checkout_sessions')
        .add({
          price: 'price_1IiPR3AtgfZN3ZgK380yx2lR',
          allow_promotion_codes: true,
          success_url: 'https://careerpathways.app/user/settings',
          cancel_url: 'https://careerpathways.app/user/settings',
          //price: 'price_1IgvftAtgfZN3ZgKjr8jWunX',
          //success_url: 'http://localhost:3000/user/settings',
          //cancel_url: 'http://localhost:3000/user/settings'
        });

    docRef.onSnapshot(async (snap) => {
      const { error, sessionId } = snap.data();
      if (error) {
        console.log(error.message)
        //alert(`An error occured: ${error.message}`);
      }
      if (sessionId) {
        const stripe = await loadStripe(api_key);
        stripe.redirectToCheckout({ sessionId });
        this.setState({ isLoading: false })
      }
    });
  }

  handleBillingPortal = async () => {
    this.setState({ portalLoading: true })
    const functionRef = Fire.shared.app.functions('us-central1').httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    const { data } = await functionRef({ returnUrl: 'https://careerpathways.app/user/settings' })
    window.location.assign(data.url);
  }

  successAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "100px" }}
          title="Payment Successful"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize="sm"
        >
          Thank You. Your account has been upgraded. 
        </ReactBSAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  render() {
  
    const { profile } = this.props;
    const { displayName, email, school, firstname, lastname, currentPassword, errorMessage, successMessage, isLoading, portalLoading } = this.state;

    const hasSubscription = this.props.hasSub && this.props.hasSub.length > 0 ? true : false;
    const isIndividualUser = profile.institution_userID === null || profile.institution_userID === 'na' ? true : false
  
    if (profile.isEmpty) return <div className="text-center"><Spinner>Wait...</Spinner></div>

    return (
      <>
       <Elements stripe={stripePromise}>
       {this.state.alert}
        <PathwaysHeader location={this.props.location.pathname} /> 
          <Container className="mt--6" fluid>
            <div className="content">
              <Row className="justify-content-center">
                <Col md="3">
                  <Card className="card-user">
                    <CardHeader> 
                      <h4 className="title-weight">Edit Picture</h4>
                    </CardHeader>
                    <CardBody className="mt-6 text-center">
                      <ImageUpload avatar userAvatar={profile.photoURL}/>       
                    </CardBody>
                    <CardFooter>
                      <h5 className="title text-center">{profile.displayName}</h5>
                    </CardFooter>
                  </Card>     
                </Col>
                { profile.token.signInProvider === 'password' ?
                  <Col md="8">
                    <Form>
                      <Card>
                        <CardHeader>
                          <h4 className="title-weight">Edit Profile</h4>
                          
                          { errorMessage && <ErrorMessage message={errorMessage}/> } 
                          { successMessage && 
                          <UncontrolledAlert color="success" fade={false}>
                            <span>
                              <b>Nice! - </b>
                              Your profile info has been updated successfully.
                            </span>
                          </UncontrolledAlert>}
                        </CardHeader>
                        <CardBody>        
                          <h6 className="heading-small text-muted mb-4">
                            User information
                          </h6>
                          <div className="pl-lg-4">
                            <Row>  
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label" htmlFor="input-email">
                                    Email Address
                                  </label>
                                  <Input 
                                    id="input-email" 
                                    placeholder="Email" 
                                    type="email" 
                                    name="email" 
                                    value={email || ''} 
                                    onChange={this.handleChange}/>
                                </FormGroup>
                              </Col>      
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label" htmlFor="input-school">
                                    Institution
                                  </label>
                                  <Input
                                    id="input-school"
                                    type="text"
                                    name="school" 
                                    value={school || ''} 
                                    onChange={this.handleChange}/>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label" htmlFor="input-first-name">
                                    First name
                                  </label>
                                  <Input 
                                    id="input-first-name" 
                                    placeholder="First Name" 
                                    type="text" 
                                    name="firstname" 
                                    value={firstname || ''} 
                                    onChange={this.handleChange}/>
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label" htmlFor="input-last-name">
                                    Last name
                                  </label>
                                  <Input
                                    id="input-last-name"
                                    placeholder="Last Name"
                                    type="text"
                                    name="lastname" 
                                    value={lastname || ''} 
                                    onChange={this.handleChange}/>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label" htmlFor="input-current-password">
                                    Current Password *
                                  </label>
                                  <Input
                                    id="input-current-password"
                                    placeholder="******"
                                    type="password"
                                    autoComplete="off"
                                    name="currentPassword" 
                                    value={currentPassword} 
                                    onChange={this.handleChange}/>
                                  <p className="form-control-label">
                                    {'  '}* Please enter your current password to perform any change *</p>
                                </FormGroup>
                              </Col> 
                            </Row>
                          </div>
                          <hr className="my-4" />
                          <div className="pl-lg-4">
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label">
                                    New Password
                                  </label>
                                  <Input
                                    type="password"
                                    autoComplete="off"
                                    onClick={() => this.setState({ passwordModal: true })}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </CardBody>
                        <CardFooter>
                          <Col md="6" xs="12" className="ml-auto">
                            <Row className="justify-content-end">
                              <Button className="btn-round m-2" color="danger">Cancel</Button>
                              <Button className="btn-round my-2" color="success" onClick={this.handleSubmit}>Save Changes</Button>
                            </Row>   
                          </Col>
                        </CardFooter>     
                      </Card>
                    </Form>
                  </Col> 
                  :
                  <UserProviderForm 
                    email={email} 
                    displayName={displayName}
                    signInProvider={profile.token.signInProvider}
                  />
                }
              </Row>  
              <Row className="justify-content-center">
                <MembershipCard/>
                <Col md="8">
                  <Card className="mb-6">
                    <CardHeader>
                      <h4 className="title-weight">Subscription</h4>
                    </CardHeader>
                    <CardBody>
                      {isIndividualUser ?
                        <Row className="justify-content-center my-3">
                          {hasSubscription ? 
                            <Col lg="8" xs="12" className="text-center" >
                              <Button block className="btn-round my-0" color="warning" disabled={portalLoading} onClick={this.handleBillingPortal}>
                                {portalLoading ? <Spinner/> : "Manage"}
                              </Button>
                            </Col> :
                             <Col lg="8" xs="12" className="text-center">
                              <Button block className="btn-round my-0" color="success" disabled={isLoading} onClick={this.handleNewSubscription}>
                                {isLoading ? <Spinner/> : "Subscribe"}
                              </Button>
                            </Col>}                     
                        </Row> 
                      :
                        <h4>You have been granted Premium access.</h4>
                      }       
                    </CardBody>
                  </Card>

                </Col>
              </Row>   
            </div>
            <EditPasswordModal
              isOpen={this.state.passwordModal}
              toggle={this.toggleEditPasswordModal}
              reauthenticate={this.reauthenticate}
              email={email}
              displayName={displayName}
            />        
          </Container>
        </Elements>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      hasSub: state.firestore.ordered.subscription,
      profileError: state.userProfile.profileError
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    profileUpdate: (user) => dispatch(profileUpdate(user)),
    updateMembership: (user) => dispatch(updateMembership(user))
  }
}

export default compose( 
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props)=> {
    if (!props.auth.uid) return []
    return [
      {
        collection:'users', 
        doc: props.auth.uid,
        subcollections: [{ collection: "subscriptions", where:[['status','in',['trialing', 'active']]] }],
        storeAs: 'subscription'
      }]
    }),
)(UserAccount);

/**
 * <PaymentForm 
    userData={profile} 
    schoolData={{}}
    amount={3999} 
    onSuccessfulCheckout={this.onSuccessfulCheckout}/>
 */
//<CouponFormIndividual/>