import React, { useRef } from 'react';
import ReactToPrint from "react-to-print";

import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button, ButtonGroup, Card, CardHeader, Row, Col, UncontrolledTooltip } from "reactstrap";


const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: false,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>
        }{" "}
        organizations.
      </label>
    </div>
  )
})
const { SearchBar } = Search;

const AllOrganizationsTable = (props) => {

  const componentRef = useRef();
  const { schoolsData } = props

  const displayData = schoolsData && schoolsData.map((i, index) => ({ 
    school: i.school,
    admin: i.adminName,
    email: i.adminEmail,
    totalSeats: i.totalSeats,
    invited: i.students.length,
    address: i.address,
    city: i.city,
    state: i.state,
    zip: i.zip,
    id: i.id,
    joined: i.joined ? moment(i.joined.toDate()).format('MMM YYYY') : ''
  }));
  
  return (
      <Card>
        <CardHeader>
          <h3 className="mb-0">Organizations</h3>
          <p className="text-sm mb-0">
            Global List
          </p>
        </CardHeader>
        <ToolkitProvider
          data={displayData || []}
          keyField="id"
          columns={[
            {
              dataField: "school",
              text: "Name",
              sort: true,
            },
            {
              dataField: "admin",
              text: "Admin",
              sort: false
            },
            {
              dataField: "email",
              text: "Admin Email",
              sort: true
            },
            {
              dataField: "totalSeats",
              text: "Total Seats",
              sort: true
            },
            {
              dataField: "invited",
              text: "Invited",
              sort: true
            },
            {
              dataField: "address",
              text: "Address",
              sort: false
            },
            {
              dataField: "city",
              text: "City",
              sort: false
            },
            {
              dataField: "state",
              text: "State",
              sort: false
            },
            {
              dataField: "zip",
              text: "Zip",
              sort: false
            },
            {
              dataField: "id",
              text: "Organization ID",
              sort: false
            },
            {
              dataField: "joined",
              text: "Joined",
              sort: false,
            },
          ]}
          search
        >
          {props => (
            <div className="py-4 table-responsive">
              <div
                id="datatable-basic_filter"
                className="dataTables_filter px-4 pb-1"
              >
                <label>
                  Search:
                  <SearchBar
                    className="form-control-sm"
                    placeholder=""
                    {...props.searchProps}
                  />
                </label>
              </div>
              <BootstrapTable
                ref={componentRef}
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                id="react-bs-table"
              />
            </div>
          )}
        </ToolkitProvider>
        <Col>
          <Row className="justify-content-end"> 
            <ButtonGroup>
              <ReactToPrint
                trigger={() => (
                  <Button
                    color="default"
                    size="sm"
                    className="buttons-copy buttons-html5 mx-2 my-2"
                    id="print-tooltip"
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </ButtonGroup>
            <UncontrolledTooltip placement="top" target="print-tooltip">
              Print or save to PDF.
            </UncontrolledTooltip>    
          </Row>
        </Col>     
      </Card>
    )
}

        
export default AllOrganizationsTable;