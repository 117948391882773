import React from "react";
import { Row, Col } from "reactstrap";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }
  
  render() {
    
    return (
      <>
        <h1 className="mb-2">Introduction</h1>   
        <Row className="justify-content-center">
          <Col lg="10">
            <Row>
                <p><span className="title-weight">What are values?</span> An incongruence of values with the way you live your life can be a source of stress, dissatisfaction, and general unhappiness.  Knowing and understanding your values can help you prioritize what is important to you as well in making major and small life choices. </p>
                <p><span className="title-weight">Values</span> are usually fairly stable, yet they don't have strict limits or boundaries. As you move through life, your values may change and shift. For example, when you start your career, success – measured by money and status – might be a top priority. But after you have a family, balance may be what you value more.</p>
                <p>As your definition of success changes and your priorities change, so do your personal values. This is why keeping in touch with your values is a lifelong exercise. This is something that can be continuously revisited over time. Especially when major life-changing events happen and particularly if you start to feel unbalanced in your normal day-to-day flow (or just consistently off) and you can't quite figure out why</p>
                <p>Next I’ve given a list of some core values that have been compiled from various sources. This list is not exhaustive, but it will give you an idea of some common core values (also called personal core values). In an effort to hone in on your personal <span className="title-weight">core values,</span> please follow the instructions given that will aid you in identifying values that speak to you and then prioritizing them into your top 6.</p>
                <p>For more on personal values, go <a href="https://littleblackbuddha.com/your-core-values" target="_blank" rel="noopener noreferrer">here.</a></p>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default Wizard;