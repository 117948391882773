export const submitExercise1 = (data) => {
    return (dispatch, getState, {getFirestore}) => {
      const firestore = getFirestore();
      const batch = firestore.batch();
      let userRef = firestore.collection('users').doc(data.userID);
      let moduleRef = userRef.collection('modules').doc('module1');
      let exerciseRef = moduleRef.collection('exercises').doc('exercise1')

      batch.set(exerciseRef, {
          ...data
      });
      batch.commit().then(() => {
        dispatch({ type: 'SUBMIT_EXERCISE1_SUCCESS', data });
      }).catch(err => {
        dispatch({ type: 'SUBMIT_EXERCISE1_ERROR' , err});
      });
    }
};

export const submitExercise2a = (data) => {
    return (dispatch, getState, {getFirestore}) => {
      const firestore = getFirestore();
      const batch = firestore.batch();
      let userRef = firestore.collection('users').doc(data.userID);
      let moduleRef = userRef.collection('modules').doc('module1');
      let exerciseRef = moduleRef.collection('exercises').doc('exercise2')
      batch.update(exerciseRef, {
          userValues: data.userValues
      });
      batch.commit().then(() => {
        dispatch({ type: 'SUBMIT_EXERCISE2A_SUCCESS', data });
      }).catch(err => {
        dispatch({ type: 'SUBMIT_EXERCISE2A_ERROR', err});
      });
    }
}  

export const submitRanking = (data) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    const batch = firestore.batch();
    let userRef = firestore.collection('users').doc(data.userID);
    let moduleRef = userRef.collection('modules').doc('module1');
    let exerciseRef = moduleRef.collection('exercises').doc('exercise2')

    batch.update(exerciseRef, {
        valuesRanking: data.valuesRanking,
        comparisons: data.comparisons
    });
    batch.commit().then(() => {
      dispatch({ type: 'SUBMIT_EXERCISE2B_SUCCESS', data });
    }).catch(err => {
      dispatch({ type: 'SUBMIT_EXERCISE2B_ERROR', err});
    });
  }
}  

export const submitExercise2c = (data) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    const batch = firestore.batch();
    let userRef = firestore.collection('users').doc(data.userID);
    let moduleRef = userRef.collection('modules').doc('module1');
    let exerciseRef = moduleRef.collection('exercises').doc('exercise2')
    
    //batch.update(moduleRef, {progress: 70});
    batch.update(exerciseRef, {
        valuesTop6: data.valuesTop6,
    });
    batch.commit().then(() => {
      dispatch({ type: 'SUBMIT_EXERCISE2C_SUCCESS', data });
    }).catch(err => {
      dispatch({ type: 'SUBMIT_EXERCISE2C_ERROR', err});
    });
  }
} 

export const submitExercise3 = (data) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    const batch = firestore.batch();
    let userRef = firestore.collection('users').doc(data.userID);
    let moduleRef = userRef.collection('modules').doc('module1');
    let exerciseRef = moduleRef.collection('exercises').doc('exercise3')

    batch.update(exerciseRef, {
        mbtiTest: data.mbtiTest,
        personality: data.personality
    });
    batch.commit().then(() => {
      dispatch({ type: 'SUBMIT_EXERCISE3_SUCCESS', data });
    }).catch(err => {
      dispatch({ type: 'SUBMIT_EXERCISE3_ERROR', err});
    });
  }
} 