import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "reactstrap";
import ModuleLocked from "components/Sections/ModuleLocked";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { submitExercise2 } from "redux/actions/module4Actions";

const objectListGenerator = (array) => {
  let list = array && array.map(i => ({ id: i, content: i }));
  return list
}  

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

// Moves an item from one list to another list.
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: grid * 1,
    margin: `0 0 ${grid}px 0`,
    borderRadius: '2px',

    background: isDragging ? "#3a6a88" : "white",
    fontSize: "14px",
    color: isDragging ? "white" : "#215270",
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    borderRadius: '2px',
    background: isDraggingOver ? "#ea9b82" : "#215270",
    padding: grid,
    width: window.screen.width >= 1280 ? 250 : 140
});

class Exercise2 extends Component {

    constructor(props) {
      super(props);
      this.state = {
        items:[],
        selected: [],
        alert: null
      };
    };

    id2List = {
      droppable: 'items',
      droppable2: 'selected'
    };

    componentDidMount(){
      const { exercise2m4, exercise2m1, exercise3m3 } = this.props;
      const userTopValues =  objectListGenerator(exercise2m1.valuesTop6)
      const superPowers =  objectListGenerator(exercise3m3.superpowersTop3)
      if (exercise2m4.name) {
        this.setState({
          items: exercise2m4.notSelected,
          selected: exercise2m4.attributes
        })
      } else if (!exercise2m4.name ){
        this.setState({
          items: userTopValues,
          selected: superPowers
        })

      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.exercise2m1 !== prevProps.exercise2m1 || 
          this.props.exercise3m3 !== prevProps.exercise3m3 ||
          this.props.exercise2m4 !== prevProps.exercise2m4) {
        const { exercise2m4, exercise2m1, exercise3m3 } = this.props;
        const userTopValues =  objectListGenerator(exercise2m1.valuesTop6)
        const superPowers =  objectListGenerator(exercise3m3.superpowersTop3)
        if (exercise2m4.name) {
          this.setState({
            items: exercise2m4.notSelected,
            selected: exercise2m4.attributes
          })
        } else if (!exercise2m4.name){
          this.setState({
            items: userTopValues,
            selected: superPowers
          })
  
        }
      }
    }

    getList = id => this.state[this.id2List[id]];

    onDragEnd = result => {
      const { source, destination } = result;

      // dropped outside the list
      if (!destination) {
          return;
      }

      if (source.droppableId === destination.droppableId) {
          const items = reorder(
              this.getList(source.droppableId),
              source.index,
              destination.index
          );

          let state = { items };

          if (source.droppableId === 'droppable2') {
              state = { selected: items };
          }

          this.setState(state);
      } else {
          const result = move(
              this.getList(source.droppableId),
              this.getList(destination.droppableId),
              source,
              destination
          );

          this.setState({
              items: result.droppable,
              selected: result.droppable2
          });
      }
    }

    handleSave = () => {
      const data = {
        userID: this.props.uid,
        attributes: this.state.selected,
        notSelected: this.state.items
      }
      if (this.isValidated()){
        this.props.submitExercise2(data)
        this.successAlert()
      } else {
        this.setState({
          alert: (
            <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "100px" }}
              title=""
              onConfirm={() => this.hideAlert()}
              confirmBtnBsStyle="default"
              confirmBtnText="Try Again"
              btnSize="sm"
            >
              Ops! You must complete 5 superpowers.
            </ReactBSAlert>
          )
        }) 
      }
    }

    isValidated = () => {
      const { selected } = this.state;
      if (selected.length >= 5 ) {
        return true
      }
      return false
    }

    successAlert = () => {
      this.setState({
        alert:
          (<ReactBSAlert
            success
            style={{ display: "block", marginTop: "100px" }}
            title=""
            onConfirm={() => this.props.history.push('/user/module4/ex3')}
            confirmBtnBsStyle="success"
            confirmBtnText="Continue"
            btnSize="sm"
          >
            Submission Successful
          </ReactBSAlert>
        )
      })
    }

    hideAlert = () => {
      this.setState({ alert: null })
    }

    render() {
      const { alert, items, selected } = this.state
      const { progress, moduleBlocked } = this.props;
        return (
          <>
          { alert }
          { progress < 33 || moduleBlocked === true
          ? <ModuleLocked message={'Please complete previous exercise.'}/> 
          : <> 
              <Col md="8" className="wizard-pills mr-auto ml-auto">
                <Card>
                  <CardHeader className="text-center">
                    <h3 className="mb-2"> Module 4 - All About You</h3>
                    <h4>Ex - 2</h4>
                  </CardHeader>
                  <CardBody>
                  <h3 className="mb-2"> Path Alignment</h3>  
                    <p>Superpowers and values are some of your strongest attributes as a person and should be highlighted in your Pitch. We have grouped a list of your 
                      <span className="title-weight"> top values</span> as well as your <span className="title-weight">top superpowers</span> below. </p> 
                    <p>From the list of personal values, drag 2 values that you consider can transform into superpowers. Please complete a list of at least 5 attributes that will later be implemented in your pitch.</p>
                      
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Row className="justify-content-center">
                        <Col md="5" xs="6" className="ml-auto">
                          <h4 className="text-info">PERSONAL VALUES</h4>
                          <Droppable droppableId="droppable">
                              {(provided, snapshot) => (
                                  <div
                                      className="text-center"
                                      ref={provided.innerRef}
                                      style={getListStyle(snapshot.isDraggingOver)}>
                                      {items && this.state.items.map((item, index) => (
                                          <Draggable
                                              key={item.id}
                                              draggableId={item.id}
                                              index={index}>
                                              {(provided, snapshot) => (
                                                  <div
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      style={getItemStyle(
                                                          snapshot.isDragging,
                                                          provided.draggableProps.style
                                                      )}>
                                                      {item.content}
                                                  </div>
                                              )}
                                          </Draggable>
                                      ))}
                                      {provided.placeholder}
                                  </div>
                              )}
                          </Droppable>
                        </Col>
                        <Col md="5" xs="6" className="ml-auto">
                          <h4 className="text-info">SUPERPOWERS</h4>
                          <Droppable droppableId="droppable2">
                              {(provided, snapshot) => (
                                  <div  
                                      className="text-center"
                                      ref={provided.innerRef}
                                      style={getListStyle(snapshot.isDraggingOver)}>
                                      {selected && this.state.selected.map((item, index) => (
                                          <Draggable
                                              key={item.id}
                                              draggableId={item.id}
                                              //isDragDisabled={true}
                                              index={index}>
                                              {(provided, snapshot) => (
                                                  <div
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      style={getItemStyle(
                                                          snapshot.isDragging,
                                                          provided.draggableProps.style
                                                      )}>
                                                      {item.content}
                                                  </div>
                                              )}
                                          </Draggable>
                                      ))}
                                      {provided.placeholder}
                                  </div>
                              )}
                          </Droppable>
                        </Col>
                      </Row>
                    </DragDropContext>
                      
                    <p className="mt-4">
                      <span className="title-weight">Note: </span>
                      Try to incorporate these values into your work life and vocation. In your next job and throughout your career, make sure these values are a guiding light for you in deciding whether the next action or career move is right for you
                    </p>  
                  </CardBody>
                </Card> 
              
                <hr/>
    
                <Row className="justify-content-center my-3">
                  <Col md='5' xs='6'>
                    <Button block color="success" size="lg" type="button" onClick={() => this.handleSave()} >
                      Save & Continue
                    </Button>
                  </Col>
                </Row>

                <hr/>
              </Col>  
            </>       
          }
        </>
        )
    }
}


const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    exercise2m1 : state.firestore.data.exercise2m1 || {},
    exercise3m3 : state.firestore.data.exercise3m3 || {},
    exercise2m4 : state.firestore.data.exercise2m4 || {}
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitExercise2: (data) => dispatch(submitExercise2(data))
  }
}

export default compose( 
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module1",
          subcollections: [{ collection: "exercises", doc: "exercise2"}],
        }],
        storeAs: 'exercise2m1'
      },
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module3",
          subcollections: [{ collection: "exercises", doc: "exercise3"}],
        }],
        storeAs: 'exercise3m3'
      },
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module4",
          subcollections: [{ collection: "exercises", doc: "exercise2"}],
        }],
        storeAs: 'exercise2m4'
      }
    ]
  }),
)(Exercise2);