import React from "react";
import { Col, Spinner } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactWizard from "react-bootstrap-wizard";
import ModuleLocked from "components/Sections/ModuleLocked";
import ModalBadge from 'components/Modals/Module1BadgeModal';

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { submitExercise4 } from "redux/actions/module3Actions";

const steps = [
  { stepName: "1", component: Step1 },
  { stepName: "2", component: Step2 },
  { stepName: "3", component: Step3 },
  { stepName: "4", component: Step4 }
];

const filterBetterSkills = (allSkills) => {
  const allowed = ['Skilled', 'Advanced', 'Expert'];
  let obj = {}

  for(let x in allSkills){
    if(allowed.includes(allSkills[x])){
      obj[x] = allSkills[x]
    }
  }
  return Object.keys(obj)
};

class Exercise4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: steps,
      alert: null,
      badgeModal: false
    };
  };

  componentDidMount() {
    this.setState({
      steps: [
        { stepName: "1", component: Step1, stepProps: this.props.exercise4},
        { stepName: "2", component: Step2, stepProps: this.props.exercise4},
        { stepName: "3", component: Step3, stepProps: this.props.exercise4},
        { stepName: "4", component: Step4, stepProps: this.props.exercise4}
      ]
    })
  }

  componentDidUpdate(prevProps) { 
    if (this.props.exercise4 !== prevProps.exercise4) {
      this.setState({
        steps: [
          { stepName: "1", component: Step1, stepProps: this.props.exercise4},
          { stepName: "2", component: Step2, stepProps: this.props.exercise4},
          { stepName: "3", component: Step3, stepProps: this.props.exercise4},
          { stepName: "4", component: Step4, stepProps: this.props.exercise4}
        ]
      })
    }
  }

  toggleModal = () => {
    this.setState({ 
      badgeModal: !this.state.badgeModal,
      alert: null
    })
  }

  finishButtonClick = (allStates) => {
    const { questions1, validationState1, ...batch1 } = allStates['1'];
    const { questions2, validationState2, ...batch2 } = allStates['2'];
    const { questions3, validationState3, ...batch3 } = allStates['3'];
    const { questions4, validationState4, ...batch4 } = allStates['4'];
    const userResponses = {
      ...batch1,
      ...batch2,
      ...batch3,
      ...batch4,
    }
    const data = {
      topSkills: filterBetterSkills(userResponses),
      batch1: batch1,
      batch2: batch2,
      batch3: batch3,
      batch4: batch4,
    }
    this.props.submitExercise4(data, this.props.uid)
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "100px" }}
          title="Great!"
          onConfirm={() => this.toggleModal()}
          confirmBtnBsStyle="success"
          confirmBtnText="Continue"
          btnSize=""
        >
          <h4 className="title-weight">Your Top Transferable Skills</h4>    
            {filterBetterSkills(userResponses) && filterBetterSkills(userResponses).map((i, index) => 
              <p className="list-results text-dark" key={index}>{i}</p>)
            }       
        </ReactBSAlert>
      )
    });
  }

  render() {  
    const { alert, steps, badgeModal } = this.state;
    const { profile, progress, moduleBlocked } = this.props;
    if (profile.isEmpty || moduleBlocked === null) return <div className="mr-auto ml-auto"><Spinner color="primary" size="md"/> </div> 
    return (
      <>
        <Col md="12" className="wizard-pills mr-auto ml-auto"> 
          { alert }
          { progress < 75 || moduleBlocked === true
          ? <ModuleLocked message={'Please complete exercise 3 of this module.'}/> 
          : <>   
              <ReactWizard
                steps={steps}
                navSteps
                validate
                progressbar
                title="Module 3 - Super Powers"
                description="Ex - 4"
                headerTextCenter
                finishButtonClasses="btn-default"
                nextButtonClasses="btn-sm-default"
                previousButtonClasses="btn-danger"
                finishButtonClick={this.finishButtonClick}
              />
              <ModalBadge
                isOpen={badgeModal}
                toggle={this.toggleModal}
                history={this.props.history}
                title={'Module 3 Completed'}
                message={'You earned another red badge'}
                next={'/user/module4'}
              />       
            </>       
          }
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    profile: state.firebase.profile,
    module3 : state.firestore.data.module3 || {},
    exercise4 : state.firestore.data.exercise4 || {}
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitExercise4: (data, userID) => dispatch(submitExercise4(data, userID))
  }
}

export default compose( 
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module3"}],
        storeAs: 'module3'
      },
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module3",
          subcollections: [{ collection: "exercises", doc: "exercise4"}],
        }],
        storeAs: 'exercise4'
      }
    ]
  }),
)(Exercise4);