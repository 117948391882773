import React, { Fragment } from "react";
import { Row, Col, Table } from "reactstrap";

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: []
    };
  };

  componentDidMount(){
    this.setState({
      list: this.props.userValues
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.userValues !== prevProps.userValues) {  
      this.setState({
        list: this.props.userValues
      })
    }
  }

  render() {
    const { list } = this.state;
    const valuesList = list && list.map((i, index) => {
      return (
        <Fragment key={index}>
          <tr>
            <th scope="row">--</th>
            <td className="text-center">{i}</td>
          </tr>
        </Fragment>
      )
    });

    return (
      <>
        <Row className="ml-1">
          <Col md='10'>
            <h2>Your 10 Values</h2>
            <p>In no particular order, here is a list of your ten most important values</p>
          </Col>
        </Row>   
        <Row className="justify-content-center">
          <Col md='8'>
            <Table id="classes" responsive>
              <thead className="text-primary">
                <tr>
                  <th>-</th>
                  <th className="text-center">Value</th>
                </tr>
              </thead>
              <tbody>
                {valuesList}
              </tbody>
            </Table>
           </Col>
        </Row>
      </>
    );
  }
}

export default Step3;