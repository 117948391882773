import React from 'react';
import { Input, FormGroup, Row, Col } from "reactstrap";

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Q5: '',
      Q6: '',
      Q7: '',
      Q8: '',
      Q9: ''    
    }
  }

  componentDidMount() {
    this.setState({
      Q5: this.props.Q5,
      Q6: this.props.Q6,
      Q7: this.props.Q7,
      Q8: this.props.Q8,
      Q9: this.props.Q9,
      Q5State : this.props.Q5 && 'valid',
      Q6State : this.props.Q6 && 'valid',
      Q7State : this.props.Q7 && 'valid',
      Q8State : this.props.Q8 && 'valid',
      Q9State : this.props.Q9 && 'valid',
    })
  }

  componentDidUpdate(prevProps) { 
    if (this.props.Q5 !== prevProps.Q5) {
      this.setState({
        Q5: this.props.Q5,
        Q6: this.props.Q6,
        Q7: this.props.Q7,
        Q8: this.props.Q8,
        Q9: this.props.Q9,
        Q5State : this.props.Q5 && 'valid',
        Q6State : this.props.Q6 && 'valid',
        Q7State : this.props.Q7 && 'valid',
        Q8State : this.props.Q8 && 'valid',
        Q9State : this.props.Q9 && 'valid',
      })
    }
  }

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  selectValue = (stateName, value) => {
    this.setState({ [stateName]: value });
    this.setState({ [stateName + "State"]: "valid" });
  }

  change = (e, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "length":
        if (this.verifyLength(e.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "valid" });
        } else {
          this.setState({ [stateName + "State"]: "invalid" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: e.target.value });
  }

  isValidated = () => {
    if (
      this.state.Q5State === "valid" &&
      this.state.Q6State === "valid" &&
      this.state.Q7State === "valid" &&
      this.state.Q8State === "valid" &&
      this.state.Q9State === "valid"
    ) {
      return true;
    } else {
      if (this.state.Q5State !== "valid") {
        this.setState({ Q5State: "invalid" });
      }
      if (this.state.Q6State !== "valid") {
        this.setState({ Q6State: "invalid" });
      }
      if (this.state.Q7State !== "valid") {
        this.setState({ Q7State: "invalid" });
      }
      if (this.state.Q8State !== "valid") {
        this.setState({ Q8State: "invalid" });
      }
      if (this.state.Q9State !== "valid") {
        this.setState({ Q9State: "invalid" });
      }
      return false;
    }
  }

  render(){
    const { Q5, Q6, Q7, Q8, Q9, Q5State, Q6State, Q7State, Q8State, Q9State } = this.state;
    return (
        <>
          <h1 className="mb-2">Your Gifts -</h1>  
          <p>Please answer the questions below</p>
          <Row>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label">
                  5. What life experiences set you apart from everyone else?
                </label>
                <Input
                  rows="3"
                  type="textarea"
                  value={Q5}
                  onChange={e => this.change(e, "Q5", "length", 4)}
                />
                {Q5State === "invalid" && <label className="form-control-label text-primary warning">This field is required.</label>}   
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label">
                  6. How is your personal story unique?
                </label>
                <Input
                  rows="3"
                  type="textarea"
                  value={Q6}
                  onChange={e => this.change(e, "Q6", "length", 4)}
                />
                {Q6State === "invalid" && <label className="form-control-label text-primary warning">This field is required.</label>}   
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label">
                  7. What have you always been good at?
                </label>
                <Input
                  type="textarea"
                  rows="2"
                  value={Q7}
                  onChange={e => this.change(e, "Q7", "length", 4)}
                />
                {Q7State === "invalid" && <label className="form-control-label text-primary warning">This field is required.</label>}   
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label">
                  8. What made you stand out as a child?
                </label>
                <Input
                  type="textarea"
                  rows="2"
                  value={Q8}
                  onChange={e => this.change(e, "Q8", "length", 4)}
                />
                {Q8State === "invalid" && <label className="form-control-label text-primary warning">This field is required.</label>}   
              </FormGroup>
            </Col>
          </Row> 

          <Row>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label">
                  9. What makes you feel strong?
                </label>
                <Input
                  type="textarea"
                  rows="2"
                  value={Q9}
                  onChange={e => this.change(e, "Q9", "length", 4)}
                />
                {Q9State === "invalid" && <label className="form-control-label text-primary warning">This field is required.</label>}   
              </FormGroup>
            </Col>
          </Row> 
        </>
    )
  }
}

export default Step2;

