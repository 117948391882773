export const createSuperAdmin = (data) => {
    return  async (dispatch, getState, {getFirebase}) => { 
      const firebase = getFirebase();
      try { 
        const createSuperAdmin = firebase.functions().httpsCallable('createSuperAdminUser');
        const response = await createSuperAdmin({ 
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            password: data.password
          })
        dispatch({ type: 'CREATE_SUPER_ADMIN_SUCCESS', payload: response });
      } catch (err) {
        dispatch({ type: 'CREATE_SUPER_ADMIN_ERROR', err});
      }
    }
  }

export const getAllResponses = (data) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    try {
      const getAllResponses = firebase.functions().httpsCallable("getAllResponses");
      const response = await getAllResponses({
        module: data.module,
      });
      dispatch({ type: "GET_ALL_RESPONSES_SUCCESS", payload: response });
    } catch (err) {
      dispatch({ type: "GET_ALL_RESPONSES_ERROR", err });
    }
  };
};