import React from "react";
import { Container, Row, Col } from "reactstrap";

const ProfileHeader = (props) => {

    const institution_name = props.institution || 'an institution';
    const institution_final_name = institution_name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

    return (
      <>
        <div
          className="header pb-6 d-flex align-items-center"
          style={{
            minHeight: "500px",
            backgroundImage:
              'url("' + require("assets/img/theme/SplashLanding.png") + '")',
            backgroundSize: "cover",
            backgroundPosition: "center 20%"
          }}
        >
          <span className="mask bg-gradient-info opacity-8" />

          <Container className="d-flex justify-content-center align-items-center" fluid>
            <Row>
              <Col lg="7" md="6">
                <h1 className="display-2 text-white">Hello,</h1>
                <p className="text-white mt-0 mb-5">
                  You have been invited to join {institution_final_name} on Pathways. Please complete the signup process below.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
}

export default ProfileHeader;
