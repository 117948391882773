import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col, Spinner } from "reactstrap";
import { AllOrganizationsTable } from "components/Tables";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const InstitutionalUsers = (props) => {

    const [isFetching, setIsFetching] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => { 
      setData(props.schools)
      setIsFetching(false)
    }, [props.schools])

    const { profile } = props;
    if ( profile.isEmpty ) return <div className="text-center"><Spinner color="primary" size="md"/> </div> 
    if ( profile.token && profile.token.claims.student ) return <Redirect direct to="/user/home"/>
    if ( profile.token && profile.token.claims.admin ) return <Redirect direct to="/admin/home"/>

    if (isFetching) return <div className="text-center"><Spinner color="primary"></Spinner></div>
    return (
          <Container className="mt-2" fluid>
            <Row className="justify-content-center">
              <Col xl="12" className="text-center">
                <h1 className="text-dark title-weight my-2">Pathways Organizations</h1>
              </Col>
            </Row>

            <Row className="justify-content-center mt-4">
              <Col md="12">
                <AllOrganizationsTable
                  schoolsData={data}
                />
              </Col>
            </Row>
          </Container>
      )
}


const mapStateToProps = (state) => {
  return {
      uid: state.firebase.auth.uid,
      profile: state.firebase.profile,
      schools: state.firestore.ordered.schools || [],
  }
}

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'schools', 
        storeAs: 'schools'
      }]
    }),
 )(InstitutionalUsers);