import React from 'react';
import { FormGroup, Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import Select from "react-select";
import SuperpowersModal from "components/Modals/SuperpowersModal"
import superpowers from "variables/superpowers";

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Q1 : '',
      Q2 : '',
      Q3 : '',
      Q4 : '',
      Q5 : '',
      definitionsModal: false
    };
  };

  componentDidMount() {
    this.setState({
      Q1: this.props.Q1,
      Q2: this.props.Q2,
      Q3: this.props.Q3,
      Q4: this.props.Q4,
      Q5: this.props.Q5,
      Q1State : this.props.Q1 && 'valid',
      Q2State : this.props.Q2 && 'valid',
      Q3State : this.props.Q3 && 'valid',
      Q4State : this.props.Q4 && 'valid',
      Q5State : this.props.Q5 && 'valid',
    })
  }

  componentDidUpdate(prevProps) { 
    if (this.props.Q1 !== prevProps.Q1) {
      this.setState({
        Q1: this.props.Q1,
        Q2: this.props.Q2,
        Q3: this.props.Q3,
        Q4: this.props.Q4,
        Q5: this.props.Q5,

        Q1State : this.props.Q1 && 'valid',
        Q2State : this.props.Q2 && 'valid',
        Q3State : this.props.Q3 && 'valid',
        Q4State : this.props.Q4 && 'valid',
        Q5State : this.props.Q5 && 'valid',
      })
    }
  }

  dropdownOptions = () => {
    let options = superpowers.map(i => ({ label: i.superpower, value: i.superpower }));
    return options;
  }

  selectValue = (stateName, value) => {
    this.setState({ [stateName]: value });
    this.setState({ [stateName + "State"]: "valid" });
  };

  isValidated = () => {
    if (
      this.state.Q1State === "valid" &&
      this.state.Q2State === "valid" &&
      this.state.Q3State === "valid" &&
      this.state.Q4State === "valid" &&
      this.state.Q5State === "valid" 
    ) {
      return true;
    } else {
      if (this.state.Q1State !== "valid") {
        this.setState({ Q1State: "invalid" });
      }
      if (this.state.Q2State !== "valid") {
        this.setState({ Q2State: "invalid" });
      }
      if (this.state.Q3State !== "valid") {
        this.setState({ Q3State: "invalid" });
      }
      if (this.state.Q4State !== "valid") {
        this.setState({ Q4State: "invalid" });
      }
      if (this.state.Q5State !== "valid") {
        this.setState({ Q5State: "invalid" });
      }
      return false;
    }
  };

  toggleModal = () => {
    this.setState({
      definitionsModal: !this.state.definitionsModal
    })
  }

  render() {
    const { Q1, Q2, Q3, Q4, Q5, Q1State, Q2State, Q3State, Q4State, Q5State, definitionsModal } = this.state;
  
    return (
        <>
          <h1 className="mb-2">Five attributes to find your Super Powers & talent</h1>  
            <Row className="justify-content-between mt-3">
              <Col md="10">
                <h4 className="text-dark mb-2">
                  Select the options that better reflect your traits on each prompt below:
                </h4>
              </Col>
              <Col md="2">
                <Button outline color="info" size="sm" onClick={() => this.toggleModal()}>Definitions</Button>
              </Col>
            </Row>

            <Row className="justify-content-center mt-3">
              <Col md="6">
                <label>1. Yearning:</label>
                <label className="question" id="yearning"></label>
                <UncontrolledTooltip placement="right" target="yearning">
                  Something you are naturally drawn.
                </UncontrolledTooltip>
                <FormGroup>
                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    value={Q1}
                    required
                    onChange={(value) => this.selectValue('Q1', value)}
                    options={this.dropdownOptions()}
                  /> 
                  {Q1State === "invalid" && <label className="form-control-label text-primary warning"> </label>}
                </FormGroup> 
              </Col>
              <Col md="6">
                <label>2. Rapid Learning:</label>
                <label className="question" id="rapid-learning"></label>
                <UncontrolledTooltip placement="top" target="rapid-learning">
                   Things you pick up quickly.
                </UncontrolledTooltip>  
                <FormGroup>
                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    value={Q2}
                    required
                    onChange={(value) => this.selectValue('Q2', value)}
                    options={this.dropdownOptions()}
                  />
                  {Q2State === "invalid" && <label className="form-control-label text-primary warning"></label>} 
                </FormGroup>
              </Col>       
            </Row>

            <Row className="justify-content-center">
              <Col md="6">
                <label>3. Flow:</label>
                <label className="question" id="flow"></label>
                <UncontrolledTooltip placement="right" target="flow">
                  - You seem to automatically know the steps to be taken. <br/> - When you lose yourself in or lose track of time.
                </UncontrolledTooltip>        
                <FormGroup>
                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    value={Q3}
                    required
                    onChange={(value) => this.selectValue('Q3', value)}
                    options={this.dropdownOptions()}
                  />
                  {Q3State === "invalid" && <label className="form-control-label text-primary warning"></label>} 
                </FormGroup>
              </Col>
              <Col md="6">
                <label>4. Glimpses of Excellence</label>
                <label className="question" id="excellence"></label>
                <UncontrolledTooltip placement="right" target="excellence">
                  When you have moments of subconscious excellence, when you wonder, ”How did I do that?”
                </UncontrolledTooltip>        
                <FormGroup>
                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    value={Q4}
                    required
                    onChange={(value) => this.selectValue('Q4', value)}
                    options={this.dropdownOptions()}
                  />
                  {Q4State === "invalid" && <label className="form-control-label text-primary warning"></label>} 
                </FormGroup>
              </Col>
            </Row>

          <Row className="justify-content-center">
            <Col md="6">
              <label>5. Satisfaction</label>
              <label className="question" id="satisfaction"></label>
              <UncontrolledTooltip placement="top" target="satisfaction">
                  What activities are your jam? <br/> While doing them or after finishing them, you think “When can I do that again?!?!”
              </UncontrolledTooltip>        
              <FormGroup>
                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    value={Q5}
                    required
                    onChange={(value) => this.selectValue('Q5', value)}
                    options={this.dropdownOptions()}
                  />
                {Q5State === "invalid" && <label className="form-control-label text-primary warning"></label>} 
              </FormGroup>
            </Col>
          </Row> 

          <SuperpowersModal
            isOpen={definitionsModal}
            toggle={this.toggleModal}
          />  
        </>
    );
  }
}

export default Step1;