import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// bootstrap rtl for rtl support page
import "assets/vendor/bootstrap-rtl/bootstrap-rtl.scss";
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// plugins styles downloaded
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.1.0";

import PrivateRoute from "./layouts/PrivateRoute";
import UserLayout from "./layouts/User";
import AdminLayout from "./layouts/Admin";
import SuperAdminLayout from "./layouts/SuperAdmin";
import Landing from "views/pages/Landing";
import About from "views/pages/About";
import Login from "views/pages/Login";
import Register from "views/pages/Register.js";
import AdminLogin from "views/pages/AdminLogin";
import LBBAdminLogin from "views/pages/LBB_AdminLogin";
import RegisterInstitutions from "views/pages/RegisterInstitutions";
import InviteRegistration from "views/pages/InviteRegistration";
import LockScreen from "views/pages/Lock.js";
import Splash from "views/pages/Splash";
import AccessDenied from "views/pages/AccessDenied";
import PathwaysTerms from "views/pages/PathwaysTerms";
import PathwaysPrivacy from "views/pages/PathwaysPrivacy";
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

import { createStore, applyMiddleware, compose } from 'redux';
import { Provider, connect } from 'react-redux';
import { reduxFirestore, getFirestore, createFirestoreInstance } from 'redux-firestore';
import { ReactReduxFirebaseProvider , getFirebase } from 'react-redux-firebase';
import rootReducer from './redux/reducers/rootReducer';
import thunk from 'redux-thunk';
import Fire from './Fire';
import firebase from "firebase/app";

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument({getFirebase, getFirestore})),
    reduxFirestore(firebase, Fire) // redux bindings for firestore
  )
);
// react-redux-firebase config
const rrfConfig = {
  userProfile: "users",
  enableClaims: true,
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
};
// react-redux-firebase props
const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
};

const mapStateToProps = (state) => ({
  authIsLoaded: state.firebase.auth && state.firebase.auth.isLoaded,
  isAuthenticated: state.firebase.auth.uid
});

const WaitTillAuth = connect(mapStateToProps)(({ authIsLoaded, isAuthenticated }) => {
  if (!authIsLoaded) return <div className="text-center mt-4">Loading...</div>;
  return (
    <Switch>
      <Route exact path="/" render={props => <Landing {...props} />} />
      <Route exact path="/splash" render={props => <Splash {...props} />}/>
      <Route path="/about" render={props => <About {...props} />}/>
      <Route path="/login" render={props => <Login {...props} />}/>
      <Route path="/register" render={props => <Register {...props} />}/>
      <Route path="/linkedin" render={props => <LinkedInPopUp {...props} />}/>
      <Route path="/lock-screen" render={props => <LockScreen {...props} />}/>
      <Route path="/admin-login" render={props => <AdminLogin {...props} />}/>
      <Route path="/lbb-login" render={props => <LBBAdminLogin {...props} />}/>
      <Route path='/access-denied' render={props => <AccessDenied {...props} />} />
      <Route path='/privacy-policy' render= {props => <PathwaysPrivacy {...props} />} />
      <Route path='/terms-conditions' render={props => <PathwaysTerms {...props} />} />
      <Route path="/c/:institutionID/:institutionUserID?" render={props => <InviteRegistration {...props} />}/>
      <Route path="/register-institutions" render={props => <RegisterInstitutions {...props} />}/>
      <PrivateRoute path="/user" component={UserLayout} isAuthenticated={isAuthenticated}/>
      <PrivateRoute path="/admin" component={AdminLayout} isAuthenticated={isAuthenticated}/>
      <PrivateRoute path="/lbb-admin" component={SuperAdminLayout} isAuthenticated={isAuthenticated}/>
      <Redirect from="*" to="/login" />
    </Switch>
  );
});

const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <WaitTillAuth />
        </ReactReduxFirebaseProvider>
      </Provider>
    </BrowserRouter>
  )
}

ReactDOM.render(<App/>, document.getElementById("root"));
