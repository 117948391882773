import React from "react";
import classnames from "classnames";
import { Redirect } from 'react-router-dom';
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col, Spinner } from "reactstrap";
import { LinkedIn } from 'react-linkedin-login-oauth2';

import AuthFooter from "components/Footers/AuthFooter.js";
import Header from "components/Headers/ProfileHeader";
import MainNavbar from "components/Navbars/MainNavbar";
import ErrorMessage from "components/Alerts/ErrorMessage";

import { connect } from 'react-redux';
import Fire from '../../Fire';
import axios from "axios";

class InviteRegistration extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      displayName: '',
      displayNameState: null,
      email: '',
      emailState: null,
      password: '',
      passwordState: null,
      confirmPassword: '',
      confirmPasswordState: null,
      checkbox: false,

      errorMessage: null,
      loading: false,
      isFetching: true,
    };
  }

  componentDidMount() {
    const institutionID = this.props.match.params.institutionID;
    const institutionUserID = this.props.match.params.institutionUserID;
    axios.post('https://us-central1-pathways-f7c3a.cloudfunctions.net/getSchoolInfo_Onboarding', {schoolID: institutionID, institutionUserID: institutionUserID})
    .then(res => res.data)
    .then(result => {
      this.setState({
        isFetching: false,
        schoolData: result
      });
    });
  }

  verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  compare = (string1, string2) => {
    if (string1 === string2) {
      return true;
    }
    return false;
  };

  change = (e, stateName, type, stateNameEqualTo) => {
    switch (type) {
      case "email":
        if (this.verifyEmail(e.target.value)) {
          this.setState({ [stateName + "State"]: "valid" });
        } else {
          this.setState({ [stateName + "State"]: "invalid" });
        }
        break;
      case "length":
        if (this.verifyLength(e.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "valid" });
        } else {
          this.setState({ [stateName + "State"]: "invalid" });
        }
        break;
      case "equalTo":
        if (this.compare(e.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "State"]: "valid" });
          //this.setState({ [stateNameEqualTo + "State"]: "valid" });
        } else {
          this.setState({ [stateName + "State"]: "invalid" });
          //this.setState({ [stateNameEqualTo + "State"]: "invalid" });
        }
        break;  
      default:
        break;
      }
      this.setState({ [stateName]: e.target.value });
  };

  gmailLogin = () => {
    const provider = Fire.shared.provider;
    const membership = 'premium';
    let registrationInfo = {
      schoolID : this.props.match.params.institutionID,
      institution_userID : this.props.match.params.institutionUserID
    }
    const schoolID = this.props.match.params.institutionID;
    const institution_userID = this.props.match.params.institutionUserID;

    Fire.shared.auth.signInWithPopup(provider)
    .then( async (result) => {
        let user = result.user;
        await Fire.shared.gmailUserDBAction(user, membership, schoolID, institution_userID)
        await Fire.shared.updateIntitutionUserCollection(registrationInfo)
    })
    .catch((error) => {
        console.log(error.message)
    });
  };

  handleCredentialsSignUp = async (e) => {
    e.preventDefault();
    let registrationInfo = {
      displayName: this.state.displayName,
      email: this.state.email,
      password: this.state.password,
      membership: 'premium',
      schoolID: this.props.match.params.institutionID,
      institution_userID: this.props.match.params.institutionUserID,
    };
    try {
      if (this.state.checkbox !== false) {
        await Fire.shared.createUser(registrationInfo);
        await Fire.shared.updateIntitutionUserCollection(registrationInfo)
      }
      this.setState({ errorMessage: 'You must accept our terms and conditions.'})
    } catch (error) {
      this.setState({ errorMessage: error.message });
    }
  }

  handleLinkedInSignIn = (token) => {
    let registrationInfo = {
      schoolID : this.props.match.params.institutionID,
      institution_userID : this.props.match.params.institutionUserID
    }
    Fire.shared.auth.signInWithCustomToken(token)
      .then((user) => {
        this.props.history.push('/user/home')
        Fire.shared.updateIntitutionUserCollection(registrationInfo)
      })
      .catch((error) => {
        if (error.message !== null) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState({ errorMessage: null });
        }
      })
  }

  handleLinkedInSuccess = async (data) => {
    let registrationInfo = {
      schoolID : this.props.match.params.institutionID,
      institution_userID : this.props.match.params.institutionUserID
    }
    try {  
      this.setState({ loading: true })    
      const { data: linkedinID } = await axios.post('https://us-central1-pathways-f7c3a.cloudfunctions.net/linkedInAPItoken?code='+ data.code +'&state=sahir123&membership=premium&schoolid='+ registrationInfo.schoolID +'&institutionuserid=' + registrationInfo.institution_userID)
      await this.handleLinkedInSignIn(linkedinID);
      await Fire.shared.updateIntitutionUserCollection(registrationInfo)
      this.setState({
        errorMessage: null,
        loading: false
      });
    } catch (error) {
      this.setState({
        errorMessage: error.response.data.message
      })
    }
  }

  handleLinkedInFailure = (error) => {
    console.log(error.message)
    this.setState({
      errorMessage: error.errorMessage,
    });
  }
 
  render() {
    const { errorMessage, loading, isFetching, schoolData } = this.state;
    const { profile } = this.props;
    if (isFetching) return <div className="my-4 text-center"><Spinner color="primary" className="mx-4"/></div>
    return (
      <>
        { profile.token && profile.token.claims.student ? <Redirect direct to="/user/home"/> : 
        <>
          <div className="bg-indigo main-content" ref="mainContent">
            <MainNavbar />   
            <Header institution={schoolData.school}/>
            <Container className="mt--8 pb-5 register-page">
              <Row className="justify-content-center">
                <Col lg="6" md="8">
                  {schoolData.isValid ?
                  <>
                  <Card className="bg-secondary border-0">
                    <CardHeader className="bg-transparent pb-5">
                      <div className="text-muted text-center mt-2 mb-4">
                        <small>Sign up with</small>
                      </div>
                      <div className="btn-wrapper text-center">
                        <LinkedIn
                          clientId="77rzmtcoq1xd33"
                          onFailure={this.handleLinkedInFailure}
                          onSuccess={this.handleLinkedInSuccess}
                          redirectUri="https://careerpathways.app/linkedin"
                          scope="r_liteprofile r_emailaddress"
                          state="sahir123"
                          renderElement={({ onClick, disabled }) => (
                            <Button className="btn-neutral btn-icon" color="default" onClick={onClick} disabled={disabled}>
                            {loading 
                              ? <Spinner color="primary" size="sm" className="mx-4"/> 
                              : <>
                                  <span className="btn-inner--icon">
                                    <img alt="..." src={require("assets/img/icons/common/linkedin-icon.svg")}/>
                                  </span>
                                  <span className="btn-inner--text">LinkedIn</span>
                                </>
                            }
                            </Button> 
                          )}
                        /> 
                        <Button className="btn-neutral btn-icon" color="default" onClick={this.gmailLogin}>
                          <span className="btn-inner--icon mr-1">
                            <img
                              alt="..."
                              src={require("assets/img/icons/common/google.svg")}
                            />
                          </span>
                          <span className="btn-inner--text">Google</span>
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Or sign up with new credentials</small>
                      </div>
                      <Form className="needs-validation" noValidate role="form" onSubmit={this.handleCredentialsSignUp}>
                        <FormGroup className={classnames({ focused: this.state.focusedName })}>
                          <InputGroup className="input-group-merge input-group-alternative mb-2">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-hat-3" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Full Name"
                              type="text"
                              onFocus={() => this.setState({ focusedName: true })}
                              onBlur={() => this.setState({ focusedName: false })}
                              valid={ this.state.displayNameState === "valid" }
                              invalid={ this.state.displayNameState === "invalid" }
                              onChange={e => this.change(e, "displayName", "length", 1)}
                            />
                            <div className="invalid-feedback text-center">
                                This field is required.
                            </div>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className={classnames({focused: this.state.focusedEmail })}>
                          <InputGroup className="input-group-merge input-group-alternative mb-2">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Email"
                              type="email"
                              autoComplete="email"
                              onFocus={() => this.setState({ focusedEmail: true })}
                              onBlur={() => this.setState({ focusedEmail: false })}
                              valid={ this.state.emailState === "valid"}
                              invalid={ this.state.emailState === "invalid"}
                              onChange={e => this.change(e, "email", "email")}
                            />
                            <div className="invalid-feedback text-center">
                              Please enter a valid email.
                            </div>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className={classnames({ focused: this.state.focusedPassword})}>
                          <InputGroup className="input-group-merge input-group-alternative mb-2" >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Password"
                              type="password"
                              autoComplete="new-password"
                              onFocus={() => this.setState({ focusedPassword: true })}
                              onBlur={() => this.setState({ focusedPassword: false })}
                              valid={this.state.passwordState === "valid"}
                              invalid={this.state.passwordState === "invalid"}
                              onChange={e => this.change(e, "password", "length", 6)}
                            />
                            <div className="invalid-feedback text-center">
                              Must contain at least 6 characters.
                            </div>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className={classnames({ focused: this.state.focusedConfirmPassword})}>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Confirm Password"
                              type="password"
                              autoComplete="new-password"
                              onFocus={() => this.setState({ focusedConfirmPassword: true })}
                              onBlur={() => this.setState({ focusedConfirmPassword: false })}
                              valid={ this.state.confirmPasswordState === "valid"}
                              invalid={ this.state.confirmPasswordState === "invalid"}
                              onChange={e => this.change(e, "confirmPassword", "equalTo", "password")}
                            />
                            <div className="invalid-feedback text-center">
                              Password doesn't match.
                            </div>
                          </InputGroup>
                        </FormGroup>
                      
                        <Row className="my-4">
                          <Col xs="12">
                            <div className="custom-control custom-checkbox">
                              <input 
                                className="custom-control-input"  
                                type="checkbox" 
                                id="customCheckRegister"
                                defaultValue=""
                                required
                                checked={this.state.checkbox}
                                onChange={() => this.setState({checkbox: !this.state.checkbox, errorMessage: null})}
                              />
                              <label className="custom-control-label" htmlFor="customCheckRegister">
                                <span className="text-muted">
                                  I agree with the{" "}
                                  <a href="https://littleblackbuddha.com/products/">
                                    Terms and Conditions
                                  </a>
                                </span>
                              </label>
                            </div>
                          </Col>
                        </Row>
                        {errorMessage && <ErrorMessage message={errorMessage}/>}     
                        <div className="text-center">  
                          <Button className="mt-4" color="info" type="submit">
                            Create account
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Row className="mt--1">
                    <Col xs="6">
                      <span className="text-light" onClick={() => {this.props.history.push('/login')}}>
                        <small>Already Have An Account?</small>
                      </span>
                    </Col>   
                  </Row> 
                  </> 
                  : 
                  <Card className="bg-secondary border-0 py-2 mb-lg-6">
                     <CardBody className="px-lg-5 py-lg-5 my-2">
                      <h4 className="display-4">Oh no! <br/> Unfortunately this link is no longer valid.</h4>
                     </CardBody>
                  </Card>
                  }
                </Col>
              </Row>
            </Container>
          </div>
          <AuthFooter/>  
        </> 
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile || {}
  }
}

export default connect(mapStateToProps)(InviteRegistration);
