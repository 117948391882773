import React from 'react';

const Avatar = (props) => {
    
  return (
      <span>
        { props.photoURL 
        ? <img alt='user pic' src={props.photoURL} style={{width: props.width, height: props.width, borderRadius: props.width/2}}></img> 
        : <div     
            style={{ 
              textAlign:'center',
              padding: '2px 0',
              width: props.width, 
              height: props.width, 
              borderRadius: props.width/2, 
              backgroundColor: '#87bfa1'}}
          >
          <p style={{ fontSize: props.fontSize, color:'#fff' }}>
            {props.firstname.charAt(0).toUpperCase() + props.lastname.charAt(0).toUpperCase()}
          </p>
        </div>
        }
      </span>
  )
};

export default Avatar;