export const submitExercise1 = (userID, data) => {
    return (dispatch, getState, {getFirestore}) => {
      const firestore = getFirestore();
      const batch = firestore.batch();
      let userRef = firestore.collection('users').doc(userID);
      let moduleRef = userRef.collection('modules').doc('module4');
      let exerciseRef = moduleRef.collection('exercises').doc('exercise1')

      batch.update(exerciseRef, {
        ...data,
        name: 'Occupational Tracks',      
      });
      batch.commit().then(() => {
        dispatch({ type: 'SUBMIT_EXERCISE1M4_SUCCESS', data });
      }).catch(err => {
        dispatch({ type: 'SUBMIT_EXERCISE1M4_ERROR', err});
      });
    }
}

export const submitExercise2 = (data) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    const batch = firestore.batch();
    let userRef = firestore.collection('users').doc(data.userID);
    let moduleRef = userRef.collection('modules').doc('module4');
    let exerciseRef = moduleRef.collection('exercises').doc('exercise2')

    batch.update(exerciseRef, {
      attributes: data.attributes,
      notSelected: data.notSelected,
      name: 'Superpowers and Values',      
    });
    batch.commit().then(() => {
      dispatch({ type: 'SUBMIT_EXERCISE2M4_SUCCESS', data });
    }).catch(err => {
      dispatch({ type: 'SUBMIT_EXERCISE2M4_ERROR', err});
    });
  }
}

export const submitExercise3 = (data) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    const batch = firestore.batch();
    let userRef = firestore.collection('users').doc(data.userID);
    let moduleRef = userRef.collection('modules').doc('module4');
    let exerciseRef = moduleRef.collection('exercises').doc('exercise3')

    batch.update(exerciseRef, {
      name: 'Pitch Generation',    
      Q1: data.Q1,
      Q2: data.Q2,
      Q3: data.Q3,
      Q4: data.Q4,
      Q5: data.Q5,
      Q6: data.Q6,
      Q7: data.Q7,
      Q8: data.Q8,
      Q9: data.Q9,
      pitchOutput : data.pitchOutput,
    });
    batch.commit().then(() => {
      dispatch({ type: 'SUBMIT_EXERCISE3M4_SUCCESS', data });
    }).catch(err => {
      dispatch({ type: 'SUBMIT_EXERCISE3M4_ERROR', err});
    });
  }
}