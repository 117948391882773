import React from "react";
import { Route, Switch } from "react-router-dom";
import { Col, Row, Container } from "reactstrap";
import { ModuleProgressBar, Module4Map, IntroModule4 } from "components/Sections";
import UpgradeMembershipModal from 'components/Modals/UpgradeMembershipModal';
import ModuleHeader from "components/Headers/ModuleHeader";
import Exercise1 from "./Exercise1";
import Exercise2 from "./Exercise2";
import Exercise3 from "./exercise3/Exercise3";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

class Module4 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      module4: {},
      progress: 0,
      blocked: null
    }
  }

  componentDidUpdate(prevProps) { 
    if (this.props.module4 !== prevProps.module4) {
      this.setState({
        module4: this.props.module4,
        blocked: this.props.module4.blocked,
        progress: this.props.module4.progress,
      })
    }
  }

  render() {
    const { progress, blocked } = this.state;
    const { profile, history, module4, module5 } = this.props;
    const isIndividualUser = profile.institution_userID === null || profile.institution_userID === 'na' ? true : false;
    const isStarter = profile.token && profile.token.claims.stripeRole !== 'premium' ? true : false

    return (
      <>
       <ModuleHeader color={'module4'}/>
       <Container className="bg-mobile-4 mt-7" fluid>
          <Row>
            <Col md="4" className="text-center d-none d-lg-block">
              <ModuleProgressBar value={module4.progress || 0}/>
              <Module4Map history={history} progress={module4.progress} nextModuleBlocked={module5.blocked}/>   
            </Col>

            <Switch>
              <Route exact path="/user/module4" render={props => <IntroModule4 {...props} history={history} progress={progress} moduleBlocked={blocked}/>}/>
              <Route exact path="/user/module4/ex1" render={props => <Exercise1 {...props} history={history} progress={progress} moduleBlocked={blocked}/>}/>
              <Route exact path="/user/module4/ex2" render={props => <Exercise2 {...props} history={history} progress={progress} moduleBlocked={blocked}/>}/>
              <Route exact path="/user/module4/ex3" render={props => <Exercise3 {...props} history={history} progress={progress} moduleBlocked={blocked}/>}/>
            </Switch>
          </Row>       
        </Container>

        <UpgradeMembershipModal
          isOpen={isIndividualUser && isStarter}
          history={this.props.history}
          message={'Seems like you must upgrade your account before continuing. Please go to settings and subscribe.'}
          redirectTo={'/user/settings'}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    profile: state.firebase.profile,
    module4 : state.firestore.data.module4 || {},
    module5 : state.firestore.data.module5 || {}
  }
};

export default compose( 
  connect(mapStateToProps),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module4"}],
        storeAs: 'module4'
      },
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module5"}],
        storeAs: 'module5'
      }]
    }),
)(Module4);