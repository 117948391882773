export const submitExercise1 = (data) => {
    return (dispatch, getState, {getFirestore}) => {
      const firestore = getFirestore();
      const batch = firestore.batch();
      let userRef = firestore.collection('users').doc(data.userID);
      let moduleRef = userRef.collection('modules').doc('module2');
      let exerciseRef = moduleRef.collection('exercises').doc('exercise1')

      batch.update(exerciseRef, {
          name: 'Short Term Goals',
          goal1: data.goal1, 
          goal2: data.goal2       
      });
      batch.commit().then(() => {
        dispatch({ type: 'SUBMIT_EXERCISE1M2_SUCCESS', data });
      }).catch(err => {
        dispatch({ type: 'SUBMIT_EXERCISE1M2_ERROR', err});
      });
    }
} 

export const submitExercise2 = (data) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    const batch = firestore.batch();
    let userRef = firestore.collection('users').doc(data.userID);
    let moduleRef = userRef.collection('modules').doc('module2');
    let exerciseRef = moduleRef.collection('exercises').doc('exercise2')

    batch.update(exerciseRef, {
        name: 'Medium Duration Goals',
        goal1: data.goal1, 
        goal2: data.goal2       
    });
    batch.commit().then(() => {
      dispatch({ type: 'SUBMIT_EXERCISE2M2_SUCCESS', data });
    }).catch(err => {
      dispatch({ type: 'SUBMIT_EXERCISE2M2_ERROR', err});
    });
  }
} 

export const submitExercise3 = (data) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    const batch = firestore.batch();
    let userRef = firestore.collection('users').doc(data.userID);
    let module2Ref = userRef.collection('modules').doc('module2');
    let exerciseRef = module2Ref.collection('exercises').doc('exercise3')

    batch.update(exerciseRef, {
      name: 'Long Term Goals',
      goal1: data.goal1, 
      goal2: data.goal2       
    });
    batch.commit().then(() => {
      dispatch({ type: 'SUBMIT_EXERCISE3M2_SUCCESS', data });
    }).catch(err => {
      dispatch({ type: 'SUBMIT_EXERCISE3M2_ERROR', err});
    });
  }
} 