export const submitExercise1 = (data) => {
    return (dispatch, getState, {getFirestore}) => {
      const firestore = getFirestore();
      const batch = firestore.batch();
      let userRef = firestore.collection('users').doc(data.userID);
      let moduleRef = userRef.collection('modules').doc('module3');
      let exerciseRef = moduleRef.collection('exercises').doc('exercise1')

      batch.update(exerciseRef, {
          name: 'Super Powers',
          Q1: data.Q1, 
          Q2: data.Q2,
          Q3: data.Q3,  
          Q4: data.Q4, 
          Q5: data.Q5,
          Q6: data.Q6,
          Q7: data.Q7        
      });
      batch.commit().then(() => {
        dispatch({ type: 'SUBMIT_EXERCISE1M3_SUCCESS', data });
      }).catch(err => {
        dispatch({ type: 'SUBMIT_EXERCISE1M3_ERROR', err});
      });
    }
} 

export const submitExercise2 = (data) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    const batch = firestore.batch();
    let userRef = firestore.collection('users').doc(data.userID);
    let moduleRef = userRef.collection('modules').doc('module3');
    let exerciseRef = moduleRef.collection('exercises').doc('exercise2')

    /*batch.update(userRef, {
        currentLevel: 'Level 3',
        op: 50
    });
    batch.update(moduleRef, { progress: 66 });*/
    batch.update(exerciseRef, {
        name: 'Gifts',
        Q1: data.Q1, 
        Q2A: data.Q2A,
        Q2B: data.Q2B,  
        Q3: data.Q3,  
        Q4: data.Q4,
        Q5: data.Q5,  
        Q6: data.Q6,  
        Q7: data.Q7,  
        Q8: data.Q8,
        Q9: data.Q9,            
    });
    batch.commit().then(() => {
      dispatch({ type: 'SUBMIT_EXERCISE2M3_SUCCESS', data });
    }).catch(err => {
      dispatch({ type: 'SUBMIT_EXERCISE2M3_ERROR', err});
    });
  }
} 

export const submitExercise3A = (data) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    const batch = firestore.batch();
    let userRef = firestore.collection('users').doc(data.userID);
    let moduleRef = userRef.collection('modules').doc('module3');
    let exerciseRef = moduleRef.collection('exercises').doc('exercise3')

    batch.update(exerciseRef, {
        name: 'Top 3',
        '1': data['1'], 
        '2': data['2'],
        '3': data['3'],   
        why1: data.why1,
        why2: data.why2,  
        why3: data.why3,
        superpowersTop3: data.superpowersTop3            
    });
    batch.commit().then(() => {
      dispatch({ type: 'SUBMIT_EXERCISE3AM3_SUCCESS', data });
    }).catch(err => {
      dispatch({ type: 'SUBMIT_EXERCISE3AM3_ERROR', err});
    });
  }
} 

export const submitExercise3B = (data) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    const batch = firestore.batch();
    let userRef = firestore.collection('users').doc(data.userID);
    let moduleRef = userRef.collection('modules').doc('module3');
    let exerciseRef = moduleRef.collection('exercises').doc('exercise3')

    batch.update(exerciseRef, {
        yourStory: data.yourStory,           
    });
    batch.commit().then(() => {
      dispatch({ type: 'SUBMIT_EXERCISE3B_M3_SUCCESS', data });
    }).catch(err => {
      dispatch({ type: 'SUBMIT_EXERCISE3B_M3_ERROR', err});
    });
  }
} 

export const submitExercise4 = (data, userID) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    const batch = firestore.batch();
    let userRef = firestore.collection('users').doc(userID);
    let moduleRef = userRef.collection('modules').doc('module3');
    let exerciseRef = moduleRef.collection('exercises').doc('exercise4')

    batch.update(exerciseRef, {
      ...data          
    });
    batch.commit().then(() => {
      dispatch({ type: 'SUBMIT_EXERCISE4_M3_SUCCESS', data });
    }).catch(err => {
      dispatch({ type: 'SUBMIT_EXERCISE4_M3_ERROR', err});
    });
  }
} 
