import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from "reactstrap";
import { CircularProgressbar,  buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ModulesProgress = (props) => {

    const modules = props.modules;

    return (
        <>
          <div className="img-levels-abs-right d-none d-md-block">
            <img alt='...' src={require("assets/img/theme/LevelsOverlay.svg")}/>  
          </div>
          <div className="img-levels-abs-right d-sm-none">
            <img alt='...' src={require("assets/img/theme/LevelsOverlayMobile.svg")} />  
          </div>

            <Row className="align-content-center mt-4">
              <Col md="10" lg="10" className="ml-auto mr-auto pt-0">    
                <Card className="bg-warning bg-level-cards">  
                  <CardBody className="align-contents-center">
                    <Row className="align-items-center">
                      <Col md="2" xs="5" className="align-self-center ml-md-6 mr-md-3">
                        <p className="text-dark">{modules[0].name}</p>
                      </Col>

                      <Col md="2" xs="3" className="align-self-center ml--4">
                        { !modules[0].blocked 
                        ? <div className="circular-progress-wrapper">
                            <CircularProgressbar 
                              value={modules[0].progress}
                              text={`${modules[0].progress.toString()} %`} 
                              styles={buildStyles({pathColor: '#fff', textSize: '20px', textColor: '#000', trailColor: '#efcb87'})}
                              strokeWidth={10}
                            />   
                          </div>
                        : <div className="circular-progress-wrapper text-center">
                            <i className="fas fa-lock text-white lock-icon"/>
                          </div> } 
                      </Col>
                    </Row>
                  
                  </CardBody>
                </Card>
              </Col>
              <Col md="10" lg="10" className="ml-auto mr-auto pt-0">    
                <Card className="bg-cyan bg-level-cards">  
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md="2" xs="5" className="align-self-center ml-md-6 mr-md-3">
                        <p className="text-dark">{modules[1].name}</p>
                      </Col>

                      <Col md="2" xs="3" className="align-self-center ml--4">
                        { !modules[1].blocked  
                        ? <div className="circular-progress-wrapper">
                            <CircularProgressbar 
                              value={modules[1].progress}
                              text={`${modules[1].progress.toString()} %`} 
                              styles={buildStyles({pathColor: '#fff', textSize: '20px', textColor: '#000', trailColor: '#87bfa1'})}
                              strokeWidth={10}
                            />   
                          </div>
                        : <div className="circular-progress-wrapper text-center">
                            <i className="fas fa-lock text-white lock-icon"/>
                          </div> }  
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="10" lg="10" className="ml-auto mr-auto pt-0">    
                <Card className="bg-blue2 bg-level-cards">  
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md="2" xs="5" className="align-self-center ml-md-6 mr-md-3">
                        <p className="text-dark">{modules[2].name}</p>
                      </Col>

                      <Col md="2" xs="3" className="align-self-center ml--4">
                        { !modules[2].blocked  
                        ? <div className="circular-progress-wrapper">
                            <CircularProgressbar 
                              value={modules[2].progress}
                              text={`${modules[2].progress.toString()} %`} 
                              styles={buildStyles({pathColor: '#fff', textSize: '20px', textColor: '#000', trailColor: '#5CA5B2'})}
                              strokeWidth={10}
                            />   
                          </div>
                        : <div className="circular-progress-wrapper text-center">
                            <i className="fas fa-lock text-white lock-icon"/>
                          </div> } 
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="10" lg="10" className="ml-auto mr-auto pt-0">    
                <Card className="bg-blue bg-level-cards">  
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md="2" xs="5" className="align-self-center ml-md-6 mr-md-3">
                        <p className="text-dark">{modules[3].name}</p>
                      </Col>

                      <Col md="2" xs="3" className="align-self-center ml--4">
                        { !modules[3].blocked  
                        ? <div className="circular-progress-wrapper">
                            <CircularProgressbar 
                              value={modules[3].progress}
                              text={`${modules[3].progress.toString()} %`} 
                              styles={buildStyles({pathColor: '#fff', textSize: '20px', textColor: '#000', trailColor: '#3a6a88'})}
                              strokeWidth={10}
                            />   
                          </div>
                        : <div className="circular-progress-wrapper text-center">
                            <i className="fas fa-lock text-white lock-icon"/>
                          </div> }  
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="10" lg="10" className="ml-auto mr-auto pt-0">    
                <Card className="bg-indigo bg-level-cards">  
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md="2" xs="5" className="align-self-center ml-md-6 mr-md-3">
                        <p className="text-dark">{modules[4].name}</p>
                      </Col>

                      <Col md="2" xs="3" className="align-self-center ml--4">      
                        { !modules[4].blocked  
                        ? <div className="circular-progress-wrapper">
                            <CircularProgressbar 
                              value={modules[4].progress}
                              text={`${modules[4].progress.toString()} %`} 
                              styles={buildStyles({pathColor: '#fff', textSize: '20px', textColor: '#000', trailColor: '#215270'})}
                              strokeWidth={10}
                            />   
                          </div>
                        : <div className="circular-progress-wrapper text-center">
                            <i className="fas fa-lock text-white lock-icon"/>
                          </div> } 
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
          </Row>
        </>
    )
}

ModulesProgress.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    progress: PropTypes.number,
    unlocked: PropTypes.bool
  }))
};

export default ModulesProgress;
