import React from "react";
import { Row, Col, ListGroup, ListGroupItem } from "reactstrap";

import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';

class M1Ex1Summary extends React.Component {
  

  renderObject = (answerObj, name) => {
    return Object.entries(answerObj).map(([key, value], i) => {
      if (key === name) {
        return <Col key={i} md="6" xs="6">{value.value}</Col>
      }
      return null
    })
  }

  render() {
    const { exercise1 } = this.props;
    return (
        <Row className="justify-content-center">
          <Col md='10' xs='12'>
            <ListGroup className="thead-light mt-3">
              <ListGroupItem className="list-head">
                <Row>
                  <Col md="6" xs="6">Question</Col>
                  <Col md="6" xs="6">Response</Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem className="list-results">
                <Row>
                  <Col md="6" xs="6"><p>1. Current job or position</p></Col>
                  <Col md="6" xs="6">{exercise1.Q2}</Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem>
                <Row className="list-results">
                  <Col md="6" xs="6"><p>2 Dream job or position</p></Col>
                  <Col md="6" xs="6">{exercise1.Q20}</Col>
                </Row>
              </ListGroupItem>
            </ListGroup> 
          </Col>
        </Row>    
      );
     }
  }

  const mapStateToProps = (state) => {
    return {
      uid: state.firebase.auth.uid,
      exercise1 : state.firestore.data.exercise1 || {}
    }
  };
  
  export default compose( 
    connect(mapStateToProps, null),
    firestoreConnect((props)=> {
      if (!props.uid) return []
      return [
        {
          collection:'users', 
          doc: props.uid,
          subcollections: [{ 
            collection: "modules", 
            doc: "module1",
            subcollections: [{ collection: "exercises", doc: "exercise1"}],
          }],
          storeAs: 'exercise1'
        }]
      }),
  )(M1Ex1Summary);
