import React from "react";
import { Redirect } from 'react-router-dom';
import { Card, CardBody, Container, Row, Col, Spinner } from "reactstrap";
import { LeavesImg } from "components/Sections";
import ModuleHeader from "components/Headers/ModuleHeader";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const SuperAdminHome = (props) => {
  
    const { profile, history } = props;
    if ( profile.isEmpty ) return <div className="text-center"><Spinner color="primary" size="md"/> </div> 
    if ( profile.token && profile.token.claims.student ) return <Redirect direct to="/user/home"/>
    if ( profile.token && profile.token.claims.admin ) return <Redirect direct to="/admin/home"/>
    return (
      <>
        <div className="special-heading bg-info mb-7">
          <Container fluid>
            <ModuleHeader color={'module5'}/>
          </Container>
        </div>
        <LeavesImg/>
         
        <Container className="mt-0" fluid>
          <Row className="justify-content-center">
            <Col className="ml-auto mr-auto my-3 text-center">
              <h6 className="h2 text-dark">
                Welcome, <span className="title-weight">{profile.displayName}</span>! 
              </h6>
            </Col>
          </Row>  
          <Row className="justify-content-center my-2">
            <Col md='8' className="text-dark">
              <h3 className="title-weight d-inline-block"> What do you want to do today?</h3>
            </Col> 
          </Row>
          <Row className="align-items-center justify-content-center my-4">
            <Col lg="4" xs="12">
              <Card className="bg-primary text-center">
                <CardBody onClick={() => {history.push('/lbb-admin/institutions')}}>
                  <h3 className="mb-2 text-center text-white">Organizations</h3>
                  <i className="fas fa-university fa-7x text-white"></i>
                  <p className="mb-1 text-center text-white">Institutional Users </p>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" xs="12">
              <Card className="bg-warning text-center">
                <CardBody onClick={() => {history.push('/lbb-admin/allusers')}}>
                  <h3 className="mb-2 text-center text-dark">Users</h3>
                  <i className="fas fa-users fa-7x text-white"></i>
                  <p className="mb-1 text-center text-dark">Global Users</p>
                </CardBody>
              </Card>
            </Col>
          </Row> 
          <Row className="align-items-center justify-content-center my-4">
            <Col lg="4" xs="12">
              <Card className="bg-cyan text-center">
                <CardBody onClick={() => {history.push('/lbb-admin/add-superadmin')}}>
                  <h3 className="mb-2 text-center text-dark">Super Admin</h3>
                  <i className="fas fa-user-plus fa-7x text-white"></i>
                  <p className="mb-1 text-center text-dark">Add Super Admin </p>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" xs="12">
              <Card className="bg-blue text-center">
                <CardBody onClick={() => {history.push('/lbb-admin/userprogress')}}>
                  <h3 className="mb-2 text-center text-white">Progress</h3>
                  <i className="far fa-chart-bar fa-7x text-white"></i>
                  <p className="mb-1 text-center text-white">Pathways Explorers</p>
                </CardBody>
              </Card>
            </Col>
          </Row> 
        </Container>

          
      </>
    );
}

const mapStateToProps = (state) => {
  return { 
    uid: state.firebase.auth.uid,
    profile: state.firebase.profile,
    schoolData: state.firestore.data.schoolData || {}
  }
}

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'schools', 
        doc: props.uid,
        storeAs: 'schoolData'
      }]
    }),
 )(SuperAdminHome);
