import React from "react";
import { Container, Card, CardBody, Row, Col, Progress } from "reactstrap";
import PathwaysHeader from "../../components/Headers/PathwaysHeader";
import ModulesProgress from "../../components/Sections/ModulesProgress";

import Avatar from '../../components/Avatar/Avatar';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const modulesTemp = [
  {
    name: 'Module 1',
    progress: 100
  },
  {
    name: 'Module 2',
    progress: 0
  },
  {
    name: 'Module 3',
    progress: 0
  },
  {
    name: 'Module 4',
    progress: 0
  },
  {
    name: 'Module 5',
    progress: 0
  },
]

class UserProfile extends React.Component {
  state={
    errorMessage: null
  }

  render() {

    const { profile, modules } = this.props
    return (
      <>
        <PathwaysHeader location={this.props.location.pathname}/> 
          <Container className="mt--6" fluid>
            <Row className="align-content-center">
              <Col md="10" lg="10" className="ml-auto mr-auto">    
                <Card className="mb-4 bg-blue">  
                  <CardBody>
                    <Row className="justify-content-start text-left">
                      <Col md="3" xs="4">
                        <div className="card-profile-pic">
                          <a href="#lbb" onClick={()=> {this.props.history.push('/user/settings')}}>
                            <Avatar firstname={profile.firstname || ''} lastname={profile.lastname || ''} photoURL={profile.photoURL} width={100} fontSize={50}></Avatar>
                          </a>
                        </div>
                      </Col>
                      <Col md="8" xs="8" className="align-self-center">
                        <h2 className="text-white user-profile-name">
                          {profile.displayName} {' '} <i className="fas fa-pen text-white" style={{fontSize: '20px'}} onClick={() => this.props.history.push('/user/settings')}></i>
                        </h2>
                      </Col>    
                    </Row>

                    <Row className="justify-content-center">
                      <Col lg="10" md="10" >      
                        <h4 className="heading-title text-muted text-right mb-0">
                          {profile.currentLevel}
                        </h4>
                        <Progress max="100" value={profile.op} color="green" />
                      </Col>
                    </Row>

                    <Row className="justify-content-between mt-2">
                      <Col md="6" className="text-center">
                        <p className="text-white"><i className="ni ni-chart-pie-35 mr-2"/>Overall Progress: {profile.op} %</p>
                      </Col>
                      <Col md="6" className="text-center">
                        <p className="text-white"><i className="ni ni-calendar-grid-58 mr-2"/>Joined: Jan 2021</p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>  
              </Col>
            </Row> 
        </Container>
        <Container className="mt-0 mb-4" fluid>
          <Row>
            <Col lg="12" xs="12" className="subtitle text-center">
              <h6 className="h2 text-dark text-center mt-4">
                <span>Your Journey</span>
              </h6>
            </Col>
          </Row>        
          <ModulesProgress modules={modules}/>   
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
    uid: state.firebase.auth.uid,
    modules : state.firestore.ordered.modules || modulesTemp
  }
};

export default compose( 
  connect(mapStateToProps),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules" }],
        storeAs: 'modules'
      }]
    }),
)(UserProfile);