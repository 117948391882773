import React from 'react';
import { Card, CardHeader, CardBody, Form, FormGroup, Input, Row, Col } from "reactstrap";

const UserProviderForm = (props) => {

    const { email, displayName } = props

    const renderLogoProvider = () => {
      const signInProvider = props.signInProvider
      switch (signInProvider) {
        case "google.com":
          return  (<div>
                    <span className="btn-inner--icon mr-1">
                      <img alt="Google Logo" src={require("assets/img/icons/common/google.svg")}/>
                    </span>
                    <span className="btn-inner--text">Google{''}</span>
                  </div>)
        case "custom":
          return (<div>
                    <span className="btn-inner--icon">
                      <img alt="LinkedIn Logo" src={require("assets/img/icons/common/linkedin-icon.svg")}/>
                    </span>
                  </div>)
        default:
          return null;
      }
    }
 
    return (
        <>
          <Col md="8">
            <Form>
              <Card>
                <CardHeader><h4 className="title-weight">Profile</h4></CardHeader>
                  <CardBody>        
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>  
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-email">Email Address</label>
                            <Input 
                              id="input-email" 
                              placeholder="Email" 
                              type="email" 
                              name="email" 
                              disabled
                              value={email || ''} 
                            />
                          </FormGroup>
                        </Col>      
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">Name</label>
                            <Input
                              id="input-name"
                              type="text"
                              name="name" 
                              disabled
                              value={displayName || ''} 
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    
                      <Row>  
                        <Col lg="6">
                          <label className="form-control-label" htmlFor="input-email">
                            Credentials
                          </label>
                          {renderLogoProvider()}
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
              </Card>
            </Form>
          </Col> 
        </>
    )
}



export default UserProviderForm;