export const createStudent = (student) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
      const firestore = getFirestore();
      const firebase = getFirebase();
      try { 
        const batch = firestore.batch();
        let schoolRef = firestore.collection('schools').doc(student.schoolID);
        let userRef = schoolRef.collection('institution_users').doc();
        const inviteEmail = firebase.functions().httpsCallable('sendStudentInviteEmail');

        batch.update(schoolRef, {
          students: firebase.firestore.FieldValue.arrayUnion(student.email),
        });
        batch.set(userRef, {
            email: student.email,
            registered: false,
            createdAt: new Date()
        });
        await batch.commit()
        await inviteEmail({ email: student.email, school: student.school, institutionID: student.schoolID, userID: userRef.id })
        dispatch({ type: 'CREATE_STUDENT_SUCCESS', student });
      } catch (err) {
        dispatch({ type: 'CREATE_STUDENT_ERROR', err});
      }
    }
}

export const deleteUnregisteredUser = (student) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    try { 
      const batch = firestore.batch();
      const schoolRef = firestore.collection('schools').doc(student.schoolID);
      const userRef = schoolRef.collection('institution_users').doc(student.id);

      batch.update(schoolRef, {
        students: firebase.firestore.FieldValue.arrayRemove(student.email),
      });
      batch.delete(userRef);

      await batch.commit()
      dispatch({ type: 'DELETE_UNREGISTERED_USER_SUCCESS', student });
    } catch (err) {
      dispatch({ type: 'DELETE_UNREGISTERED_USER_ERROR', err});
    }
  }
}

export const selectSeats = (plan) => {
    return (dispatch) => {
        dispatch({ type: 'SELECT_NUMBER_SEATS', payload: plan })
    }
}

export const getPlanSelected = () => {
  return (dispatch) => {
      console.log("getting plan selected");
      dispatch({ type: 'GET_PLAN_SELECTED'})
  }
}

export const updateTotalSeats = (data) => {
  return  async (dispatch, getState, {getFirebase, getFirestore}) => { 
    const firestore = getFirestore();
    const firebase = getFirebase();
    try { 
      const increment = firebase.firestore.FieldValue.increment(data.seatsNumber);
      const schoolRef = firestore.collection('schools').doc(data.schoolID);
      await schoolRef.update({
        totalSeats: increment
      })
      dispatch({ type: 'UPDATE_SEATS_NUMBER_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'UPDATE_SEATS_NUMBER_ERROR', err});
    }
  }
}

export const resendInvite = (student) => {
  return async (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();
    const inviteEmail = firebase.functions().httpsCallable('sendStudentInviteEmail');
    try { 
      await inviteEmail({ email: student.email, school: student.school, institutionID: student.schoolID, userID: student.id})
      dispatch({ type: 'RESEND_INVITE_SUCCESS', student });
    } catch (err) {
       dispatch({ type: 'RESEND_INVITE_ERROR', err});
    }
  }
}

export const sendCoupon = (data) => {
  return  async (dispatch, getState, {getFirebase}) => { 
    const firebase = getFirebase();
    try { 
      const sendCouponCode = firebase.functions().httpsCallable('validateCouponCode');
      const response = await sendCouponCode({ schoolID: data.schoolID, couponCode: data.couponCode })
      dispatch({ type: 'SUBMIT_COUPON_SUCCESS', payload: response });
    } catch (err) {
      dispatch({ type: 'SUBMIT_COUPON_ERROR', err});
    }
  }
}

export const redeemCoupon = (data) => {
  return  async (dispatch, getState, {getFirestore}) => { 
    const firestore = getFirestore();
    try { 
      const schoolRef = firestore.collection('schools').doc(data.schoolID);
      await schoolRef.collection("coupons").doc(data.couponCode).set({
        implemented: true,
        date: new Date(),
      });
      dispatch({ type: 'REDEEM_COUPON_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'REDEEM_COUPON_ERROR', err});
    }
  }
}