import React from 'react';
import Logo from '../../assets/img/brand/logo4a.png';
import { Container, Card, CardBody, Row, Col } from 'reactstrap';

const Slider1 = () => {
    return (
        <Container className="mt-9" fluid>
          <Row className="justify-content-center">
            <Col className="card-wrapper" lg="8" md="8">
              <Card className="bg-slider mt-5 pt-5">    
                <CardBody>
                  <Row className="justify-content-center">
                    <Col md='10' className="text-center">
                      <img src={Logo} alt='Logo' style={{width:'60%'}}/>
                    </Col> 
                  </Row>
                  <Row className="justify-content-center mt-6">
                    <Col md='10' className="text-center">
                      <h2 className="text-dark">Let's get started</h2>
                    </Col> 
                  </Row>

                  <Row className="justify-content-center mt-5">
                    <Col md='8' className="text-dark text-center">
                      <p>Get ready to begin your biggest adventure yet - the journey of discovering yourself.</p>
                    </Col> 
                  </Row>

                </CardBody>
              </Card>
            </Col>
          </Row> 
        </Container>
    )
}

export default Slider1;
