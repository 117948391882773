import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardHeader, CardBody,FormGroup, Input, Button } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ModuleLocked from "components/Sections/ModuleLocked";
import Module2BadgeModal from 'components/Modals/Module1BadgeModal';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { submitExercise3 } from "redux/actions/module2Actions";

const Exercise3 = (props) => {

    const [Q1, setQ1] = useState("");
    const [Q2, setQ2] = useState("");
    const [Q1State, setQ1State] = useState("");
    const [Q2State, setQ2State] = useState("");
    const [badgeModal, setBadgeModal] = useState(false);
    const [alert, setAlert] = useState(null)
    const { progress, moduleBlocked } = props;
    
    useEffect(() => { 
      setQ1(props.exercise3.goal1);
      setQ2(props.exercise3.goal2);
      setQ1State(props.exercise3.goal1 && 'valid')
      setQ2State(props.exercise3.goal2 && 'valid')
    }, [props.exercise3])

    const verifyLength = (value, length) => {
      if (value.length >= length) {
        return true;
      }
      return false;
    }

    const change = (e, stateName, stateNameEqualTo) => {
      switch (stateName) {
        case "Q1":
          if (verifyLength(e.target.value, stateNameEqualTo)) {
            setQ1(e.target.value)
            setQ1State("valid")
          } else {
            setQ1(e.target.value)
            setQ1State("invalid")
          }
          break;
        case "Q2":
          if (verifyLength(e.target.value, stateNameEqualTo)) {
            setQ2(e.target.value)
            setQ2State("valid")
          } else {
            setQ2(e.target.value)
            setQ2State("invalid")
          }
          break;
        default:
          break;
      }
    }

    const handleSave = () => {
      const data = {
        userID: props.uid,
        goal1: Q1,
        goal2: Q2
      }
      if (isValidated()){
        props.submitExercise3(data)
        toggleModal()
      } else {
        setAlert((
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "100px" }}
            title=""
            onConfirm={() => hideAlert()}
            confirmBtnBsStyle="default"
            confirmBtnText="Ok"
            btnSize=""
          >
            Ops! Please complete writing your goals.
          </ReactBSAlert>
        ))

      }
    }

    const isValidated = () => {
      if (
        Q1State === "valid" &&
        Q2State === "valid" 
      ) {
        return true;
      } else {
        if (Q1State !== "valid") {
          setQ1State("invalid")
        }
        if (Q2State !== "valid") {
          setQ2State("invalid")
        }
        return false;
      }
    }

    const toggleModal = () => {
      setBadgeModal(!badgeModal)
    }

    const hideAlert = () => {
      setAlert(null)
    }
    
    return (
      <>
      { alert }
      { progress < 66 || moduleBlocked === true
      ? <ModuleLocked message={'Please complete previous exercise.'}/> 
      : <>   
          <Card>
            <CardHeader className="text-center">
              <h3 className="mb-2"> Module 2 - Goal Setting</h3>
              <h4>Ex - 3</h4>
            </CardHeader>
            <CardBody>
            <h1 className="mb-2">Long Term Goals</h1>  
              <p className="mb-2">
              Write at least 2 SMARTER Goals that you want to achieve in the next 5 years concerning your career.
              </p>
              <Row className="justify-content-center mt-3">
                <Col md="3">
                  <label>Smarter Goal 1:</label>
                </Col>
                <Col md="8">
                  <FormGroup>
                    <Input
                      rows="3"
                      type="textarea"
                      value={Q1}
                      onChange={e => change(e, "Q1", 4)}
                    />
                    {Q1State === "invalid" && <label className="form-control-label text-primary warning"> </label>} 
                  </FormGroup>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md="3">
                  <label>Smarter Goal 2:</label>
                </Col>
                <Col md="8">
                  <FormGroup>
                    <Input
                      rows="3"
                      type="textarea"
                      value={Q2}
                      onChange={e => change(e, "Q2", 4)}
                    />
                    {Q2State === "invalid" && <label className="form-control-label text-primary warning"></label>} 
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card> 
        
          <hr/>

          <Row className="justify-content-center my-3">
            <Col md='6' xs='6'>
              <Button block color="success" size="lg" type="button" onClick={handleSave} >
                Save
              </Button>
            </Col>
          </Row>

          <Module2BadgeModal
            isOpen={badgeModal}
            toggle={toggleModal}
            history={props.history}
            title={'Module 2 Achieved'}
            message={'Fantastic, you earned a second red badge, Module 3 is now unlocked'}
            next={'/user/module3'}
          /> 
        </>       
      }
    </>
    )
};

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    module2 : state.firestore.data.module2 || {},
    exercise3 : state.firestore.data.exercise3 || {}
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitExercise3: (data) => dispatch(submitExercise3(data))
  }
}

export default compose( 
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module2"}],
        storeAs: 'module2'
      },
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module2",
          subcollections: [{ collection: "exercises", doc: "exercise3"}],
        }],
        storeAs: 'exercise3'
      }
    ]
  }),
)(Exercise3);