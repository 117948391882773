const initState = {}
    
  const module2Reducer = (state = initState, action) => {
      switch(action.type){
        case 'SUBMIT_EXERCISE1M2_SUCCESS':
          console.log('Exercise 1 Module2 success');
          return state
        case 'SUBMIT_EXERCISE1M2_ERROR':
          console.log('Exercise 1 Module2 Error Submission');
          return state
        case 'SUBMIT_EXERCISE2M2_SUCCESS':
          console.log('Exercise 2 Module2 success');
          return state
        case 'SUBMIT_EXERCISE2M2_ERROR':
          console.log('Exercise 2 Module2 Error Submission');
          return state
        case 'SUBMIT_EXERCISE3M2_SUCCESS':
          console.log('Exercise 3 Module2 success');
          return state
        case 'SUBMIT_EXERCISE3M2_ERROR':
          console.log('Exercise 3 Module2 Error Submission');
          return state        
        default:
          return state
      }
  };
    
  export default module2Reducer;