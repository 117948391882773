const indicators = [
    {
      result: "INTP",
      type: "The Rationalist",
      careerTypes: "Rationalists have straightforward intelligence.  Their conceptualized solution-seeking skills make them excellent workers in the areas of the arts, computerized technology, engineering and architecture. The artistic INTP types are often drawn to written forms of expression, making them exceptional editors and craft artists. Some of their best qualities are creativity, a passion for theoretical methods and ideas, and an entrepreneurial, innovative spirit. Rationalists are self-driven and have very high personal standards – “good enough” is never good enough. \n" 
        + "They are often hard for more people-centric types to understand. INTPs live primarily in their own heads, and have little interest in social distractions like “small talk” and motivational speeches. They struggle to understand emotional exchanges, and service-oriented positions will prove baffling and exhausting for them. If an INTP can smile and shake hands just long enough to establish themselves as the brilliant innovators that they are, Rationalists will find that whatever the expectations for social conduct, it is the qualities unique to them that are truly in demand.",
      occupationalTracks : [ "Information Technology", "Computer & Mathematical Occupations", "Skill Trades", "Construction"],
      jobs: [ "Solar Photovoltaic Installer ($44,890/year)", "Wind Turbine Technician ($52,910/year)", "Electrician ($56,180/year)", "Cybersecurity Analyst ($65,000/year)","Web Developer ($72,220/year)", "MRI Technologists ($73,410/year)","Respiratory Therapist ($61,000/year)"]
    },
    {
      result: "INFP",
      type: "The Mediator",
      careerTypes: "INFPs are quiet yet extremely open-minded, imaginative, and caring. They tend to be warm, idealistic individuals who genuinely want to make a difference in the world and be part of a greater good. They are driven by work that contributes to their personal growth and development as well as the growth and development of others, especially those who are vulnerable. INFPs care deeply about people and their emotional and psychological needs. They build close, long-lasting interpersonal relationships with many different kinds of people, and are genuinely curious about others’ values and stories. They are also highly perceptive, often seeing relationships and patterns in others’ lives and behaviors that they may not be conscious of themselves. INFPs value creativity of thought and expression. \n"
        + "As free idealists, the best careers for INFPs will offer opportunities for expressing their creativity and making a genuine difference for others. Because of their introverted nature, the best careers for INFPs will provide ample opportunity to work alone. In the workplace, INFPs are imaginative and non-judgmental. They have a way of transforming a group of individuals into a team. They long for a career that doesn't just take care of the bills but also feels fulfilling.\n Whatever they do, people with this personality type want to feel that their work is helping others. As a result, some Mediators find it gratifying to work with clients face-to-face. Service careers, such as massage therapy, physical rehabilitation, counseling, social work, psychology, and even teaching can be exceptionally rewarding for Mediators, who take pride in the progress and growth that they help foster.",
      occupationalTracks : [ "Healthcare and Technical Occupations", "Technical Sales & Tech Sales Management", "Computer & Mathematical Occupations", "Information Technology"],
      jobs: [
        "Help Desk Technician ($53,616/year)",
        "Web Developer ($72,220/year)",
        "UX Designer ($74,408/year)",
        "MRI Technologists ($73,410/year)",
        "Surgical Technologists ($48,300/year)",
        "Licensed Practical Nurse ($47,000/year)",
        "Physical Therapist Assistant ($59,000/year)",
        "Tech Sales ($67,542/year base)"
        ]
    },
    {
      result: "ISTP",
      type: "The Crafter",
      careerTypes: "The ISTP’s unbiased and fact-oriented mind make them exemplary employees in fields such as computers, engineering, production, and technology, where their ability to solve predicaments is needed on a daily basis. They like to study how things work and have a natural aptitude for mastering the use of machines and other equipment. They are attentive to detail and responsive to the needs of others. ISTPs like to put their technical knowledge to use and are easily bored by theory, so the best ISTP career matches involve building things: products, software, food, furniture, and more. They aspire to achieve technical expertise and look for practical solutions to problems. ISTPs desire the freedom to be spontaneous and follow their own impulses, and they treasure their personal space.",
      occupationalTracks : [
        "Information Technology",
        "Computer & Mathematical Occupations",
        "Skill Trades",
        "Construction" 
      ],
      jobs: [
        "Solar Photovoltaic Installer ($44,890/year)",
        "Wind Turbine Technician ($52,910/year)",
        "Electrician ($56,180/year)",
        "Electrical Powerline Technician ($65,700/year)",
        "Cybersecurity Analyst ($65,000/year)",
        "Web Developer ($72,220/year)",
        "AutoCAD Drafter ($44,695/year)",
        "MRI Technologists ($73,410/year)",
        "Respiratory Therapist ($61,000/year)"
        ]
    },
    {
      result: "ISFP",
      type: "The Adventurer",
      careerTypes: "The ISFP type’s kind-hearted nature and desire to help others acts as a natural preparation for careers as veterinary assistants, veterinary technicians, nurse’s aides, teachers, counselors or psychology. However, long-term planning and extensive training are difficult for this type to focus on long-term. An easier route revolves around freelance and consulting work in just about any industry that Adventurers enjoy. Similarly, working towards combating an immediate problem is a theme in many ISFP-popular occupations, such as electrical power installer, general maintenance repairer, telephone installer, and recreation worker.",
      occupationalTracks : [
        "Healthcare and Technical Occupations", 
        "Skill Trades", 
        "Construction", 
        "Information Technology",
        "Computer & Mathematical Occupations"
      ],
      jobs: [
        "Help Desk Technician ($53,616/year)",
        "Web Developer ($72,220/year)",
        "UX Designer ($74,408/year)",
        "MRI Technologists ($73,410/year)",
        "Surgical Technologists ($48,300/year)",
        "Licensed Practical Nurse ($47,000/year)",
        "Physical Therapist Assistant ($59,000/year)",
        "Tech Sales ($67,542/year base)"
        ]
    },
    {
      result: "ISTJ",
      type: "The Inspector",
      careerTypes: "Because ISTJs are fact-oriented and analytical, careers requiring “objective analysis and problem solving” are often popular. ISTJs usually lean toward careers in scientific fields, especially engineering. Inspectors are also known to seek employment in careers where their duty is to oversee others’ work, such as an authoritative or supervisory position. Skills like organization and embracing structure are especially important in these jobs. This type is much more focused on building long-term, stable careers, what they crave is dependability. Job titles like pilot and infantry member are also popular choices for those ranked as ISTJ.",
      occupationalTracks : [
        "Information Technology",
        "Computer & Mathematical Occupations"
      ],
      jobs: [
        "Junior Data Analyst ($45,094/year)",
        "Computer Programmer ($49,525/year)",
        "Cybersecurity Analyst ($65,000)",
        "Web Developer ($72,220/year)",
        "AutoCAD Drafter ($44,695/year)",
        "Radiology Technologist ($61,000/year)",
        "Surgical Technologists ($48,300/year)"
        ]
    },
    {
      result: "ISFJ",
      type: "The Protector",
      careerTypes: "With a wealth of information in their heads and an overall desire to make others happy, ISFJs have advanced people skills and emotional intelligence. The Protector succeeds in a variety of different occupations, especially those that involve directly helping others, including nurses, medical or dental assistants, clerical workers, physicians, and other careers in health care. ISFJ types pay particular attention to the feelings of others, their fact and detail-oriented minds aid them in jobs involving money, such as bookkeeper or bank teller. Their attention to detail is also relevant in occupations involving highly organized and structured procedures and daily duties.",
      occupationalTracks : [
        "Healthcare and Technical Occupations",
        "Information Technology",
        "Computer & Mathematical Occupations"
      ],
      jobs: [
        "Help Desk Technician ($53,616/year)",
        "Web Developer ($72,220/year)",
        "UX Designer ($74,408)",
        "MRI Technologists ($73,410/year)",
        "Surgical Technologists ($48,300/year)",
        "Licensed Practical Nurse ($47,000/year)",
        "Physical Therapist Assistant ($59,000/year)",
        "Tech Sales ($67,542/year"
      ]
    },
    {
      result: "INFJ",
      type: "The Advocate",
      careerTypes: "Advocates tend to seek a career path that aligns with their values rather than one that offers status and material gain. The Advocate thirsts for significance in their careers and their people-reading skills serve them well in various occupations, with the four most popular being education, the arts, medicine, and science. INFJs are drawn to careers that involve helping others, including surgeon, pathologist, or physician. They also thrive in creative fields that involve understanding the human processes, desires, and admirations, such as multimedia artist, interior designer, and writer. Advocates can excel in a range of fields. Wherever they work, people INFJs can find ways to help others. They can also find ways to use their creativity in nearly any position. No matter what it says on their business cards, Advocates’ insight can enable them to spot unusual patterns and come up with out-of-the-box solutions, creating real change in others’ lives.",
      occupationalTracks : [
        "Healthcare and Technical Occupations", 
        "Information Technology", 
        "Computer & Mathematical Occupations",
      ],
      jobs:  [
        "Help Desk Technician ($53,616/year)",
        "Web Developer ($72,220/year)",
        "UX Designer ($74,408)",
        "Digital Marketer ($48,946/year)",
        "MRI Technologists ($73,410/year)",
        "Surgical Technologists ($48,300/year)",
        "Licensed Practical Nurse ($47,000/year)",
        "Physical Therapist Assistant ($59,000/year)",
        "Tech Sales ($67,542/year base)"
      ]
    },
    {
      result: "INTJ",
      type: "The Architect",
      careerTypes: "INTJs use their big-picture thinking along with their logical problem-solving skills to succeed in a variety of occupations, usually those requiring scientific reasoning and those that involve building something functional. They want to tackle meaningful challenges and find elegant solutions to important problems, not just tinker with figures in a spreadsheet. For these reasons, Architects often find themselves choosing careers such as plant scientist, engineer, medical scientist, internist, or architect. Architects also lean toward those professions that require them to hold an authoritative position or a leadership role, such as a management consultant or a top executive.",
      occupationalTracks : [
        "Healthcare and Technical Occupations", 
        "Information Technology", 
        "Computer & Mathematical Occupations", 
      ],
      jobs: [
        "Junior Data Analyst ($45,094/year)",
        "Computer Programmer ($49,525/year)",
        "Cybersecurity Analyst ($65,000/year)",
        "Web Developer ($72,220/year)",
        "AutoCAD Drafter ($44,695/year) ",
        "Radiology Technologist ($61,000/year)",
        "Surgical Technologists ($48,300/year)"
      ]
    },
    {
      result: "ESTP",
      type: "The Entrepreneur",
      careerTypes: "Due to their creative inclinations and the enjoyment they receive from hands-on activities, ESTPs thrive in jobs that require technological operation, installation, and reparation. Employment in certain military occupations is also a great way for the ESTP personality type to fulfill their need to solve problems with their own ideas. ESTPs also work well in positions that require them to work with others to provide selling points and promotions for products and services, whether this be advertising or direct sales. This social intelligence, combined with Entrepreneurs’ natural boldness and improvisational skill, makes sales, business negotiations, marketing, even acting – any tense, competitive environment – a great fit. ESTP personalities trust themselves to make the right call. Restrictions, rules, highly structured environments – these are great ways to drive Entrepreneurs crazy. People with this personality type live life on their own terms, and this makes them brilliant business people and freelancers.",
      occupationalTracks : [
        "Information Technology",
        "Computer & Mathematical Occupations", 
        "Technical Sales & Sales Management",
        "Healthcare and Technical Occupations",
        "Skill Trades"],
      jobs: [
        "Digital Marketer ($48,946/year)",
        "Web Developer ($72,220/year)",
        "MRI Technologists ($73,410/year)",
        "Respiratory Therapist ($61,000/year)",
        "Dental Hygienist ($76,000/year)",
        "Tech Sales ($67,542/year base)",
        "Inside Sales Representative ($44,725/year base)",
        "Pipefitters/Steamfitters ($55,160/year)"
      ]
    },
    {
      result: "ESFP",
      type: "The Performer",
      careerTypes: "ESFPs thrive in professional environments where they are either using their skills to aid others or where they can utilize their people skills. Just about any career that focuses on working with others is a good fit, and is even essential for ESFPs to be happy and productive. Common career fields include hospitality, food service, healthcare, and other occupations that require a personal relationship with others. They thrive in medical professions and make brilliant medical professionals, especially as EMTs, paramedics, and nurses.\n"
        + "Those exhibiting the ESFP type have a thirst for adventure and an overwhelming adoration for life. They are attentive, forthright, efficient, eloquent, spirited, and perceptive. In a work environment, ESFP types are adept at finding out-of-the-box solutions to meet the needs of the company and their peers. Careers like technical writing or data analysis are poor fits, structures like schedules and 9-5 administrator work don't work well for this type. To be fulfilled ESFPs need novelty, freedom and human contact.",
      occupationalTracks : [
        "Healthcare and Technical Occupations", 
        "Tech Sales Management", 
        "Tech Sales"
      ],
      jobs: [
        "MRI Technologists ($73,410/year)",
        "Respiratory Therapist ($61,000/year)",
        "Dental Hygienist ($76,000/year)",
        "Cardiovascular Technologists ($68,750/year)",
        "Massage Therapists ($47,180/year)",
        "Tech Sales ($67,542/year base)",
        "Inside Sales Representative ($44,725/year base)"
      ]
    },
    {
      result: "ENFP",
      type: "The Champion",
      careerTypes: "ENFP personality types finds themselves thriving in careers that involve helping others or creating something artistic. Popular among Champions are counselor positions in areas such as mental health or clinical and educational psychology, where the ENFP is able to directly aid the growth of another. The most creative among the ENFP type find themselves becoming artists, actors, dancers, musicians, singers, composers, or directors. In order to best thrive in their present or future occupation, ENFP need to actively create long-term goals with straightforward plans on how to achieve them.\n"
        + "Champion types are able to apply logic to human interactions and networks, using their social perception and skills to find out what makes people act the way they do. This lends this type a solid foothold in any human science or service, from psychology, counseling and teaching to politics, diplomacy and detective work. All of these fields have another important similarity: they are in constant development, shifting, presenting new angles and new approaches. Environments that do not work well for this type are strict regimented and hierarchical such as military service.",
      occupationalTracks : [
        "Healthcare and Technical Occupations"
      ],
      jobs: [
        "MRI Technologists ($73,410/year)",
        "Respiratory Therapist ($61,000/year)",
        "Dental Hygienist ($76,000/year)",
        "Cardiovascular Technologists ($68,750/year)",
        "Massage Therapists ($47,180)",
        "Diagnostic Medical Sonographer ($74,000/year)",
        "Occupational Therapy Assistant ($62,000/year)",
        "Physical Therapist Assistant ($59,000/year)",
        "Licensed Practical Nurse ($47,000/year)"
      ]
    },
    {
      result: "ENTP",
      type: "The Debater",
      careerTypes: "This type’s imagination and Socratic skills can make them successful in a wide range of careers. Often, those with the ENTP type preference find that they work well in occupations involving using objects to create something tangible. These occupations could include architect, drafter, or art director. They enjoy working with others, especially in positions of leadership or where they get to devise new projects and opportunities for the company. These titles often include the term “executive” and exist across a variety of fields, like arts, sports, media, education, transportation, and sales.\n"
        + "For Debaters, it all comes down to a sense of personal freedom. Knowing that they are allowed to apply themselves fully to understanding and solving the problems that interest them, without having to figure out what makes other people “tick”. Routine, structure and formal rules all feel like unnecessary hindrances and this type may find that their best careers allow them to engage their intellectual pursuits on their own terms, as freelance consultants or software engineers.",
      occupationalTracks : [
        "Healthcare and Technical Occupations",
        "Business and Financial Operations",
        "Tech sales and Tech Sales management"
      ],
      jobs: [
        "MRI Technologists ($73,410/year)",
        "Dental Hygienist ($76,000/year)",
        "Massage Therapists ($47,180)",
        "Tech Sales ($67,542/year base)",
        "Inside Sales Representative ($44,725/year base)",
        "Financial Clerk ($40,413/year)",
        "Executive Assistant ($56,429/year)"
      ]
    },
    {
      result: "ESTJ",
      type: "The Director",
      careerTypes: "ESTJ types are successful in a wide variety of fields, such as technology, agriculture, business, and production, especially those where they may be placed in a leadership position. Though there are many directions they can choose to go, people with this type almost always end up in situations where they have the opportunity to exercise their affinity for organization, structure, and follow-through. Providing further focus, they share a profound respect for tradition, stability and security, qualities which lend themselves well to progressing along clear paths to increased responsibility and dependability. ESTJs are incredibly efficient, their love of structure and thoroughness leaving a record of accurate, complete work that is on time and on budget. Directors are natural auditors, financial officers and business administrators, and any such and similar roles are excellent choices for them. Directors are also great in sales positions whether in basic retail positions, part of office teams, or as independent agents",
      occupationalTracks : [
        "Business & Finance Operations", 
        "Skill Trades", 
        "Construction"
      ],
      jobs: [
        "Client Services ($59,438/year)",
        "Executive Assistant ($56,429/year)",
        "Translator ($43,804/year)",
        "Virtual Assistant ($40,809/year)",
        "Telecommunication Technician ($53,380/year)",
        "Geological and Petroleum Technician ($51,130/year)",
        "Elevator Mechanic ($84,990/year)",
        "Rotary Drill Operator ($54,980/year)"
      ]
    },
    {
      result: "ESFJ",
      type: "The Caregiver",
      careerTypes: "The ESFJ personality type’s love for their fellow human helps them excel in a variety of careers aimed at serving the needs of others. They work best in environments with clear, predictable hierarchies and tasks. Monotony and routine work are preferred. These types are well-organized and enjoy bringing order and structure to their workplaces.  ESFJs are among the most organized personality types, deriving a great deal of enjoyment from organizing people and situations and then coordinating them to complete tasks in an efficient manner. They radiate warmth and energy, and are drawn towards positive people and situations, they are uncomfortable with conflict.", 
      occupationalTracks : ["Healthcare and Technical Occupations", "Business & Finance Operations"],
      jobs:  [
        "Client Services ($59,438/year)",
        "Executive Assistant ($56,429/year)",
        "Translator ($43,804/year)",
        "Virtual Assistant ($40,809/year)",
        "Occupational Therapy Assistant ($62,000/year)",
        "Physical Therapist Assistant ($59,000/year)",
        "Licensed Practical Nurse ($47,000/year)",
        "Diagnostic Medical Sonographer ($74,000/year)"
      ]
    },
    {
      result: "ENFJ",
      type: "The Giver",
      careerTypes: "The ENFJ personality type’s desire to make others happy and aid them in becoming better people makes them exceptional in careers that develop others and society. The Giver have a genuine love of other people, approaching them with warm sociability and a helpful earnestness that rarely goes unnoticed. Advising careers of all sorts are popular avenues, giving ENFJs a chance to help others learn, grow, and become more independent. They tend to be the “person who knows everyone” which can be adapted to anything that helps a community or organization operate better. Their visionary ideas also set them up to succeed in careers involving the arts, such as interior designer and craft artist. The desire to be around people makes business a common area of interest among the ENFJs as well, so roles such as event planner, broadcast technician, and public relations specialist are also common. ENFJs are able to express themselves both creatively and honestly, allowing them to approach positions as sales representatives and advertising consultants from a certain idealistic perspective, intuitively picking up on the needs and wants of their customers, and working to make them happier.",
      occupationalTracks : ["Healthcare and Technical Occupations"],
      jobs: [
        "Occupational Therapy Assistant ($62,000/year)",
        "Physical Therapist Assistant ($59,000/year)",
        "Licensed Practical Nurse ($47,000/year)",
        "Diagnostic Medical Sonographer ($74,000/year)",
        "Dental Hygienist ($76,000/year)",
        "MRI Technologists ($73,410/year)",
        "Tech Sales ($67,542/year base)",
        "Inside Sales Representative ($44,725/year base)"
      ]
    },
    {
      result: "ENTJ",
      type: "The Commander",
      careerTypes: "The ENTJ type’s mental scope and natural leadership skills make them exemplary employees in scientific or medical fields, especially in occupations where they are in charge. They make great executives in legal, architectural, engineering, scientific, transportation, sales, arts, sports, and media fields. They are talented at developing different scenarios for problems and finding how to best and most efficiently solve them --- excellent Strategists. Commanders work well in positions where they systemize people, procedures, or objects. Commanders are well suited to be the respected leader of an organization or team, and no other personality type enjoys it quite so much. Combining their vision, intelligence and determination to push ideas through to completion no matter the obstacles, Commanders are a force to be reckoned with.\n"
        + "It takes time for Commanders’ skills to be recognized, stagnating as low-level employees will have them deeply unhappy, to get to the top leadership positions ENTJs need to be patient. ENTJs crave leadership and responsibility, growth and opportunity, and genuinely enjoy managing others to get a job done. Commanders are visionaries and leaders, not grunts or day-to-day administrators. Getting that to be recognized is not always easy.",   
      occupationalTracks : ["Tech sales and Tech Sales management"],
      jobs: [
        "Occupational Therapy Assistant ($62,000/year)",
        "Physical Therapist Assistant ($59,000/year)",
        "Licensed Practical Nurse ($47,000/year)",
        "Diagnostic Medical Sonographer ($74,000/year)",
        "Dental Hygienist ($76,000/year)",
        "MRI Technologists ($73,410/year)",
        "Tech Sales ($67,542/year base)",
        "Inside Sales Representative ($44,725/year base)"
      ]
    },
];


export default indicators;