import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Progress } from "reactstrap";

const ModuleProgressBar = ({value}) => {
    return (
        <>
          <Row className="align-items-center">
            <Col md="6" sm="6" className="text-center">
              <h1 className="text-blue mr-0">Progress</h1>
            </Col>
            <Col md="6" sm="6">
              <Progress max="100" value={value} color="indigo" className="progress-sm" />
            </Col>
          </Row>    
        </>
    )
}

export default ModuleProgressBar;

ModuleProgressBar.propTypes = {
    value: PropTypes.number.isRequired
};
