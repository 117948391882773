import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col } from "reactstrap";

import { connect } from 'react-redux';
import { selectSeats } from 'redux/actions/adminActions';

const formatNumber = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}

const AdminMembershipCard = (props) => {

    const [adminPlans, setPlans] = useState(props.adminPlans);
    
    const { schoolData } = props;
    useEffect(() => { 
      setPlans(props.adminPlans);
    }, [props.adminPlans])

    return (    
          <Card className="bg-gradient-default">
            <CardBody>
              <div className="mb-1">
                <sup className="text-white">Current Total Seats: </sup>{" "}
                <span className="h2 text-success">{schoolData.totalSeats}</span>
                <div className="text-light mt-2 text-sm">
                  Empty seats
                </div>
                <div>
                  <span className="text-warning font-weight-600">
                  {schoolData.students && schoolData.totalSeats - schoolData.students.length}
                  </span>
                </div>
              </div>
            </CardBody>
            <CardBody>
              <div className="mb-1">
                  <p className="text-white">Buy more seats</p>
              </div>
              <Row className="mb-3">
                <Col md="7" xs="7">
                  <small className="text-primary">Select Number of Seats</small>
                </Col>
                <Col md="5" xs="5">
                  <small className="text-primary">Price</small>
                </Col>
              </Row>
              <Row>
                <div className="col-7">
                  <div className="custom-control custom-radio mb-3">
                    <input
                      className="custom-control-input"
                      id="option1"
                      name="option1"
                      type="radio"
                      checked={adminPlans.optionSelected === 'Option1'}
                      onChange={() => props.selectSeats('Option1')}
                    />
                    <label className="custom-control-label text-white" htmlFor="option1">
                      {adminPlans.plans.Option1.name}
                    </label>
                  </div>
                </div>
                <div className="col-5">
                  <div className="text-light text-sm">
                    $ {formatNumber(adminPlans.plans.Option1.price/100)}
                  </div>
                </div>
              </Row>
              <Row>
                <div className="col-7">
                  <div className="custom-control custom-radio mb-3">
                    <input
                      className="custom-control-input"
                      id="option2"
                      name="option2"
                      type="radio"
                      checked={adminPlans.optionSelected === 'Option2'}
                      onChange={() => props.selectSeats('Option2')}
                    />
                    <label className="custom-control-label text-white" htmlFor="option2">
                      {adminPlans.plans.Option2.name}
                    </label>
                  </div>
                </div>
                <div className="col-5">
                  <div className="text-light text-sm">
                    $ {formatNumber(adminPlans.plans.Option2.price/100)}
                  </div>
                </div>
              </Row>
              <Row>
                <div className="col-7">
                  <div className="custom-control custom-radio mb-3">
                    <input
                      className="custom-control-input"
                      id="option3"
                      name="option3"
                      type="radio"
                      checked={adminPlans.optionSelected === 'Option3'}
                      onChange={() => props.selectSeats('Option3')}
                    />
                    <label className="custom-control-label text-white" htmlFor="option3">
                      {adminPlans.plans.Option3.name}
                    </label>
                  </div>
                </div>
                <div className="col-5">
                  <div className="text-light text-sm">
                    $ {formatNumber(adminPlans.plans.Option3.price/100)}
                  </div>
                </div>
              </Row>
              <Row>
                <div className="col-7">
                  <div className="custom-control custom-radio mb-3">
                    <input
                      className="custom-control-input"
                      id="option4"
                      name="option4"
                      type="radio"
                      checked={adminPlans.optionSelected === 'Option4'}
                      onChange={() => props.selectSeats('Option4')}
                    />
                    <label className="custom-control-label text-white" htmlFor="option4">
                      {adminPlans.plans.Option4.name}
                    </label>
                  </div>    
                </div>
                <div className="col-5">
                  <div className="text-light text-sm">
                    $ {formatNumber(adminPlans.plans.Option4.price/100)}
                  </div>
                </div>
              </Row>
              <div className="row justify-content-end mr-1 my-2 ">
                <small className="text-light text-sm">Total: $ {formatNumber(adminPlans.total/100)}</small>
              </div>
            </CardBody>
          </Card> 
    )
}


const mapStateToProps = (state) => {
  return {
      adminPlans : state.admin
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectSeats: (plan) => dispatch(selectSeats(plan)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(AdminMembershipCard);
