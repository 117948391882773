import React, {useState, useEffect} from "react";
import { Row, Col, ListGroup, ListGroupItem, } from "reactstrap";
import ModuleLocked from "components/Sections/ModuleLocked"

import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';


const M3Ex3Summary = (props) => {
    const [ answers, setAnswers ] = useState({})

    useEffect(() => { setAnswers(props.exercise3m3) }, [props.exercise3m3])
    const { progress, moduleBlocked } = props;

    const renderObject = (answerObj, name) => {
      return Object.entries(answerObj).map(([key, value], i) => {
        if (key === name) {
          return <Col key={i} md="8" xs="8">{value.value}</Col>
        }
        return null
      })
    }

    return (
      <>
      { progress < 66 || moduleBlocked === true
      ? <ModuleLocked message={'Exercise Locked'}/> 
      : <>
          <Row className="justify-content-center">
            <Col md='10' xs='12' className='mt-2'>
              <h4 className="title-weight">Your Top Superpowers</h4>
              <ListGroup className="thead-light">
                <ListGroupItem className="list-head">
                  <Row>
                    <Col md="8" xs="12">Top 3 Superpowers</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="list-results">
                  <Row>
                    <Col md="4" xs="4"><p>1.</p></Col>
                    {renderObject(answers, '1')}
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="list-results">
                  <Row>
                    <Col md="4" xs="4"><p>Why</p></Col>
                    <Col md="8" xs="8">{answers.why1}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="list-results">
                  <Row>
                    <Col md="4" xs="4"><p>2.</p></Col>
                    {renderObject(answers, '2')}
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="list-results">
                  <Row>
                    <Col md="4" xs="4"><p>Why</p></Col>
                    <Col md="8" xs="8">{answers.why2}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="list-results">
                  <Row>
                    <Col md="4" xs="4"><p>3.</p></Col>
                    {renderObject(answers, '3')}
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="list-results">
                  <Row>
                    <Col md="4" xs="4"><p>Why</p></Col>
                    <Col md="8" xs="8">{answers.why3}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="list-head">
                  <Row>
                    <Col md="8">Your Story</Col>
                  </Row>
                </ListGroupItem>
                
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="12">{answers.yourStory}</Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>        
            </Col>
          </Row>
        </>
      }
      </>
    )
}


const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    exercise3m3 : state.firestore.data.exercise3m3 || {}
  }
};

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module3",
          subcollections: [{ collection: "exercises", doc: "exercise3"}],
        }],
        storeAs: 'exercise3m3'
      }]
    }),
)(M3Ex3Summary);