import React from 'react';
import { Container, Col, Row, Button } from "reactstrap";
import MainNavbar from "components/Navbars/MainNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import PathwaysLogo from 'assets/img/brand/logo4a.png';

const Landing = () => {
    
    return (
        <>
          <MainNavbar lightMode/>
            <div className="main-content">
              <section className="py-4 pb-6">    
              </section>

              <section className="py-6 pb-8">
                <Container fluid>
                  <Row className="justify-content-center text-center">
                    <Col md="6">
                      <h4 className="display-4 text-default text-center mb-4">
                          Are you ready to level up from a job to a career?
                      </h4>
                      <div> 
                        <img src={PathwaysLogo} alt='Pathways Logo' style={{ width:'60%'}}/>
                      </div>
                      <p className="text-primary my-4 title-weight">
                        Helping Middle Skill Workers Build Careers
                      </p>
                      <Button href="https://careerpathways.app/register">
                          Link to registration 
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </section>
              <section className="py-5">
                <Container>
                  <Row className="row-grid align-items-center">
                    <Col className="order-md-2 text-center" md="6">
                      <img
                        alt=".."
                        className="img-fluid grow-icon"
                        style={{ width:'85%'}}
                        src={require("assets/img/vectors/landing1.svg")}
                      />
                    </Col>
                    <Col className="order-md-1" md="6">
                      <div className="pr-md-5">
                        <h1 className="display-3 title-weight text-blue">Align</h1>
                        <p>
                          Aligning strengths, superpowers & transferable skills with career options. 
                          Identify your values and align what is important to you.       
                        </p>
                        <a href="https://careerpathways.app/splash"> 
                          <p className="title-weight text-default mt-4">
                            Explore
                          </p>
                        </a>        
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
              <section className="py-5">
                <Container>
                  <Row className="row-grid align-items-center">
                    <Col className="text-center" md="6">
                      <img
                        alt=".."
                        className="img-fluid grow-icon"
                        style={{ width:'90%'}}
                        src={require("assets/img/vectors/landing2B.svg")}
                      />
                    </Col>
                    <Col md="6">
                      <div className="pr-md-5">
                        <h1 className="display-3 title-weight text-blue">Build</h1>
                        <p>
                          Build a career path. Begin your quest, level up, earn badges, and uncover your pathway.
                          Learn what you strengths, superpower & gifts are and how to leverage them.
                        </p>
                        <a href="https://careerpathways.app/splash">
                          <p className="title-weight text-warning mt-4">
                            Explore
                          </p>
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
              <section className="py-5">
                <Container>
                  <Row className="row-grid align-items-center">
                    <Col className="order-md-2" md="6">
                      <img
                        alt=".."
                        className="img-fluid grow-icon"
                        src={require("assets/img/vectors/landing3B.svg")}
                      />
                    </Col>
                    <Col className="order-md-1" md="6">
                      <div className="pr-md-5">
                        <h1 className="display-3 title-weight text-blue">Connect</h1>
                        <p>
                          Connect to real well paid middle skills jobs, and discover multiple career paths.
                        </p>
                        <a href="https://careerpathways.app/splash"> 
                          <p className="title-weight text-info mt-4">
                            Explore
                          </p>
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
              <section className="py-5 mb-5">
                <Container>
                  <Row className="row-grid align-items-center">
                    <Col md="6">
                      <img
                        alt=".."
                        className="img-fluid grow-icon"
                        style={{ width:'90%'}}
                        src={require("assets/img/vectors/landing4B.svg")}
                      />
                    </Col>
                    <Col md="6">
                      <div className="pr-md-5">
                        <h1 className="display-3 title-weight text-blue">Market</h1>
                        <p>
                          Learn how to market yourself to potential employers 
                          and get ready for interviews.
                        </p>
                        <a href="https://careerpathways.app/splash"> 
                          <p className="title-weight text-default mt-4">
                            Explore
                          </p>
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
              <Container fluid>
                <p className="title-weight text-center">
                  Start your  career journey today
                </p>
                <Row className="align-items-center justify-content-center my-3">
                  <Button color="primary" href="https://careerpathways.app/register">
                      Link to registration 
                  </Button>
                </Row>
              </Container>
              <h2 className="display-3 text-primary text-center mb-4">
                  Hope. Heart. Hustle.
              </h2>
            </div>  
          <AuthFooter/>
        </>
    )
}

export default Landing;
