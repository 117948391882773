import React from "react";
import { Card, Progress, CardBody, Container, Row, Col } from "reactstrap";
import PathwaysHeader from "components/Headers/PathwaysHeader";
import LeavesImg from "components/Sections/LeavesImg";
import ContinueButton from "components/Buttons/ContinueButton";

import { connect } from 'react-redux';

class Home extends React.Component {
 
  render() {
    const { profile, history } = this.props
    return (
      <>
        <PathwaysHeader location={this.props.location.pathname} />
          <LeavesImg/>
          <Container className="mt--7 d-none d-lg-block" fluid>   
            <Row className="align-items-center pb-8">
              <Col lg="6" xs="12">
                <h6 className="h2 text-dark d-inline-block">
                  Welcome, <span className="title-weight">{profile.displayName}</span>! 
                </h6>
              </Col>  
            </Row>
          </Container>
         
        <Container className="mt--7 bg-home" fluid>
          <Row className="align-items-center">
            <Col lg="6" xs="12">
              <h6 className="mobile-margin-t h2 text-dark d-inline-block d-block d-sm-none mb-2">
                  Welcome, <span className="title-weight">{profile.displayName}</span>!  
              </h6>
            </Col>  
          </Row>

          <Row className="justify-content-center d-none d-lg-block">
            <Col lg="12" xs="12" className="ml-auto mr-auto my-6">
              <h2 className="DISCOVER-YOURSELF text-dark text-center mb-0">
                DISCOVER YOURSELF 
              </h2>
              <h2 className="DISCOVER-YOUR-Future text-white text-center">
                DISCOVER YOUR FUTURE  
              </h2>
            </Col>
          </Row>

          <Row className="card-wrapper align-items-center mt-1 mobile-margin-t">
            <Col lg="6" md="6" className="ml-auto mr-auto mb-4">
              <Card className="mb-4 pt-0">
                <CardBody>
                  <h3 className="mb-1 text-center text-dark">Your Progress</h3>
                  <hr className="mt-0"></hr>
                  <Row>
                    <Col md="10" sm="6" className="mt--1">
                      <h4 className="title-weight">Current Status: <span className="text-muted">{profile.currentLevel} </span></h4>
                    </Col>
                  </Row>

                  <Row className="align-items-center justify-items-center">
                    <Col md="9" sm="12">
                      <p className="mb--1">Overall {profile.op || 1}% Complete</p>
                      <Progress max="100" value={profile.op} color="indigo" />
                    </Col>
                    <Col md="3" sm="12" className="text-center">
                      <ContinueButton history={history} overallProgress={profile.op}/>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>     
          </Row>  
        </Container>
        <Container className="mt-0" fluid>
          <Row className="align-items-center">
              <Col lg="6" xs="12">
                <h6 className="h2 text-dark d-inline-block mt-6">
                   Your Potential
                </h6>
              </Col>
          </Row> 
          <Row className="justify-content-center mt-2">
            <Col md='8' className="text-dark text-center">
              <p>The more levels you complete the more accurate these career paths become.</p>
            </Col> 
          </Row>
          <Row className="justify-content-center my-2">
            <Col md='8' className="text-dark">
              <h3 className="title-weight d-inline-block">Others with similar results have careers in:</h3>
            </Col> 
          </Row>
          <Row className="align-items-center">
            <Col lg="4" xs="12">
              <Card className="mb-4 bg-cyan">
                <CardBody>
                  <h3 className="mb-2 text-center text-dark">Teaching</h3>
                  <p className="mb-1 text-center text-dark">Teachers want to help people. And there are many ways you can help someone as a teacher. These individuals aspire to educate, inspire, learn, and affect positive change in others and their community.</p>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" xs="12">
              <Card className="mb-4 bg-info">
                <CardBody>
                  <h3 className="mb-2 text-center text-dark">Nursing</h3>
                  <p className="mb-1 text-center text-dark">Nurses have a fundamental respect for human dignity and acute intuition. Due to the vast range of specialisms and complex skills, each nurse will have specific strengths, passions, and expertise.</p>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" xs="12">
              <Card className="mb-4 bg-warning">
                <CardBody>
                  <h3 className="mb-2 text-center text-dark">Counselor</h3>
                  <p className="mb-1 text-center text-dark">Counselors have a fundamental respect for human dignity and acute intuition. Due to the vast range of specialisms and complex skills, each nurse will have specific strengths, passions, and expertise.</p>
                </CardBody>
              </Card>
            </Col>
          </Row> 
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { 
    profile: state.firebase.profile
  }
}

export default connect(mapStateToProps)(Home);
