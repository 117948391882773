import React from "react";
import ReactWizard from "react-bootstrap-wizard";
import ModuleLocked from "components/Sections/ModuleLocked";
import { Spinner } from "reactstrap";

import Step3 from "./Step3";
import Step4 from "./Step4";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { submitRanking } from "redux/actions/module1Actions";

const steps = [
  { stepName: "3", component: Step3 },
  { stepName: "4", component: Step4},
];

const orderAnswers = (array) => {
  let counter = {}
  array.forEach(i => { counter[i] = counter[i] + 1 || 1});   // Counts how many times each value was selected
  const objectArray = Object.keys(counter).map(key => ({ value: key, count: counter[key] }))
  
  // Sort array based on the Count value
  objectArray.sort((a, b) => {
    return b.count - a.count;
  });

  return objectArray
}

class Exercise2B extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: steps,
      badgeModal: false
    };
  };

  componentDidUpdate(prevProps) { 
    if (this.props.exercise2.userValues !== prevProps.exercise2.userValues) {
      this.setState({
        steps: [
          { stepName: "3", component: Step3, stepProps: this.props.exercise2 },
          { stepName: "4", component: Step4, stepProps: this.props.exercise2 },
        ]
      })
    }
  }
  
  finishButtonClick = (allStates) => {
    const questions = [...Array(45)].map((_,i) => 'a'+(i+1));
    const obj = questions.reduce((obj, key) => ({ ...obj, [key]: allStates["4"][key] }), {});
    const propertyValues = Object.values(obj);    
    let counts = orderAnswers(propertyValues)

    const data = {
      userID: this.props.uid,
      comparisons: obj,
      valuesRanking: counts,
    }

    this.props.submitRanking(data)
    this.props.history.push('/user/module1/ex2C')
  }

  render() {
    const { steps } = this.state;
    if (this.props.profile.isEmpty) return <div className="text-center"><Spinner color="primary" size="md"/></div>
    return (
      <>
        { this.props.progress < 40
        ? <ModuleLocked message={'Please complete previous exercise.'}/> 
        : <>        
            <ReactWizard
              steps={steps}
              navSteps
              validate
              progressbar
              title="Module 1 - Right Now"
              description="Ex - 2B"
              headerTextCenter
              finishButtonClasses="btn-default"
              finishButtonText="Save & Continue"
              nextButtonClasses="btn-sm-default"
              previousButtonClasses="btn-danger"
              finishButtonClick={this.finishButtonClick}
            />         
          </>       
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    profile: state.firebase.profile,
    module1 : state.firestore.data.module1 || {},
    exercise2 : state.firestore.data.exercise2 || {}
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitRanking: (data) => dispatch(submitRanking(data))
  }
}

export default compose( 
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module1"}],
        storeAs: 'module1'
      },
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module1",
          subcollections: [{ collection: "exercises", doc: "exercise2"}],
        }],
        storeAs: 'exercise2'
      }
    ]
  }),
)(Exercise2B);
