import React from 'react';
import { Col } from "reactstrap";

const ModuleLocked = (props) => {
    return (
        <>
          <Col md="8" className="mr-auto ml-auto text-center">
            <h2>{props.message}</h2>
            <div className="image-container mt-4">
              <img className="lock-icon2" alt='...' src={require("assets/img/icons/lock.svg")}/>  
            </div>
          </Col>
        </>
    )
}

export default ModuleLocked;