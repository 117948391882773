import React from 'react';
import classnames from "classnames";
import { FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Col } from "reactstrap";
import Select from "react-select";
import states from "variables/states";

class SecondStep extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        address: '',
        city: '',
        state: '',
        zip: ''    
      };
    }

    verifyLength = (value, length) => {
      if (value.length >= length) {
        return true;
      }
      return false;
    };

    change = (e, stateName, type, stateNameEqualTo) => {
      switch (type) {
        case "length":
          if (this.verifyLength(e.target.value, stateNameEqualTo)) {
            this.setState({ [stateName + "State"]: "valid" });
          } else {
            this.setState({ [stateName + "State"]: "invalid" });
          }
          break;
        default:
          break;
        }
        this.setState({ [stateName]: e.target.value });
    };

    isValidated = () => {
      if (
        this.state.addressState === "valid" &&
        this.state.cityState === "valid" &&
        this.state.stateState === "valid" &&
        this.state.zipState === "valid"
      ) {
        return true;
      } else {
        if (this.state.addressState !== "valid") {
          this.setState({ addressState: "invalid" });
        }
        if (this.state.cityState !== "valid") {
          this.setState({ cityState: "invalid" });
        }
        if (this.state.stateState !== "valid") {
          this.setState({ stateState: "invalid" });
        }
        if (this.state.zipState !== "valid") {
          this.setState({ zipState: "invalid" });
        }
        return false;
      }
    };

    render() {
      return (
        <>
        <div className="mb-3 text-center text-primary">Almost there! Please enter a bit more of information.</div>
        <Col className="wizard-pills mr-auto ml-auto" xs="12">
          <Form className="needs-validation" noValidate role="form">
            <FormGroup className={classnames({focused: this.state.focusedAddress })}>
              <InputGroup className="input-group-merge input-group-alternative mb-2">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-shop" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Address"
                  type="text"
                  onFocus={() => this.setState({ focusedAddress: true })}
                  onBlur={() => this.setState({ focusedAddress: false })}
                  valid={ this.state.addressState === "valid"}
                  invalid={ this.state.addressState === "invalid"}
                  onChange={e => this.change(e, "address", "length", 2)}
                />
                <div className="invalid-feedback text-center">
                  This field is required.
                </div>
              </InputGroup>
            </FormGroup>
            <FormGroup className={classnames({ focused: this.state.focusedCity})}>
              <InputGroup className="input-group-merge input-group-alternative mb-2" >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-square-pin" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="City"
                  type="text"
                  onFocus={() => this.setState({ focusedCity: true })}
                  onBlur={() => this.setState({ focusedCity: false })}
                  valid={ this.state.cityState === "valid"}
                  invalid={ this.state.cityState === "invalid"}
                  onChange={e => this.change(e, "city", "length", 2)}
                />
                <div className="invalid-feedback text-center">
                  This field is required.
                </div>
              </InputGroup>
            </FormGroup>
            <FormGroup className={classnames({ focused: this.state.focusedState})}>    
              <Select
                placeholder="Select State"
                className="react-select"
                classNamePrefix="react-select"
                onFocus={() => this.setState({ focusedState: true })}
                onBlur={() => this.setState({ focusedState: false })}
                value={this.state.state}
                onChange={(value) => this.setState({ state: value, stateState: "valid" })}
                options={states}
              />
              {this.state.stateState === "invalid" && <label className="form-control-label text-danger warning">This field is required.</label>}
            </FormGroup>
            <FormGroup className={classnames({ focused: this.state.focusedZip})}>
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-pin-3" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Zip"
                  type="text"
                  onFocus={() => this.setState({ focusedZip: true })}
                  onBlur={() => this.setState({ focusedZip: false })}
                  valid={ this.state.zipState === "valid"}
                  invalid={ this.state.zipState === "invalid"}
                  onChange={e => this.change(e, "zip", "length", 5)}
                />
                <div className="invalid-feedback text-center">
                  This field is required.
                </div>
              </InputGroup>
            </FormGroup>
          </Form>
        </Col>
        </>);
    }
}

export default SecondStep;
