import React, { useState } from 'react';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import CardHeader from 'reactstrap/lib/CardHeader';

const Collapse1 = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Button color="info" size="sm" onClick={toggle} style={{ marginBottom: '1rem' }}>Example</Button>
      <Collapse isOpen={isOpen}>
        <Card>
          <CardHeader>
          A third year journalism major student at a four year university
          </CardHeader>
          <CardBody>
            <p>
              <span className="title-weight">Not so great goal: </span>I want to do well in school this semester.<br/>
              <span className="title-weight">Improvement: </span> I will earn a 3.75/ 4.0 grade point average in my courses during the spring semester. As a result, I will receive a spring break vacation in Costa Rica from my parents. If I do not hit this mark, I will have to quit my job at the local super market which is currently covering my grocery expenses.
            </p>
            <p>* The improvement is specific and measurable by the student’s grade. The goal is actionable because the student can work to earn the grades he desires. It is risky because he will have to quit his job if he doesn’t meet his goal he will have to find another way to get food. The twelve week semester gives him a time bound for his goal. The reward of Costa Rica makes the goal exciting. Lastly, the fact that it is a goal pertaining to his status as a student and the completion of his degree.</p>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
}

export default Collapse1;