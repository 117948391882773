import React from 'react';
import { Button, Modal } from "reactstrap";

const NewBadgeModal = (props) => {
    return (
        <>
          <Modal
            className="modal-dialog-centered"
            isOpen={props.isOpen}
            toggle={() => {props.history.push(`/user/module1/${props.next}`)}}
          >
            <div className="modal-header">
              <h4 className="modal-title" id="modal-title-default">
                Your Values
              </h4>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => props.history.push(`/user/module1/${props.next}`)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <h2 className="mb-2">
                Congratulations!<br></br> {props.message}
              </h2>
              <img alt={'BadgeIcon'} src={require("assets/img/icons/Badge1.svg")}/>
              <p>
                Keep up your progress and collect them all!
              </p>
            </div>
            <div className="modal-footer">
              <Button color="default" type="button" 
                onClick={() => props.history.push(`/user/module1/${props.next}`)}
              >
                Continue
              </Button>
              <Button
                className="ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => props.toggle()}
              >
                Close
              </Button>
            </div>
          </Modal>          
        </>
    )
}

export default NewBadgeModal;
