import React, { useState, useEffect } from 'react';
import { Row, Col, UncontrolledTooltip, Card, CardHeader, CardBody,FormGroup, Button } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ModuleLocked from "components/Sections/ModuleLocked";
import Select from "react-select";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { submitExercise1 } from "redux/actions/module4Actions";

const displayArray = (arr) => {
  const newArray = arr && arr.map((i) => ({ value: i, label: i}));
  return newArray
}

const getMax = (object) => {
  return Object.keys(object).filter(x => {
       return object[x] === Math.max.apply(null, 
       Object.values(object));
 });
}

const Exercise1 = (props) => {

    //const [indicators, setIndicators] = useState({})
    const [topSkills, setTopSkills] = useState([])

    const [healthcare, setQ1] = useState([]);
    const [business, setQ2] = useState([]);
    const [computer, setQ3] = useState([]);
    const [tech, setQ4] = useState([]);
    const [trade, setQ5] = useState([]);
    const [entrepreneurship, setQ6] = useState([]);

    const [alert, setAlert] = useState(null)
    const { progress, moduleBlocked } = props;

    useEffect(() => { 
      setTopSkills(displayArray(props.exercise4m3.topSkills));
    }, [props.exercise4m3])

    useEffect(() => { 
      setQ1(props.exercise1.healthcare || []);
      setQ2(props.exercise1.business || []);
      setQ3(props.exercise1.computer || []);
      setQ4(props.exercise1.tech || []);
      setQ5(props.exercise1.trade || []);
      setQ6(props.exercise1.entrepreneurship || []);
    }, [props.exercise1])

    const selectQ1Skills = (value) => {
      setQ1(value)
    }
    const selectQ2Skills = (value) => {
      setQ2(value)
    }
    const selectQ3Skills = (value) => {
      setQ3(value)
    }
    const selectQ4Skills = (value) => {
      setQ4(value)
    }
    const selectQ5Skills = (value) => {
      setQ5(value)
    }
    const selectQ6Skills = (value) => {
      setQ6(value)
    }

    const handleSave = () => {
      const userID = props.uid;
      const answers = {
        healthcare: healthcare,
        business: business,
        computer:computer,
        tech: tech,
        trade: trade,
        entrepreneurship: entrepreneurship
      }
      const areas = Object.keys(answers)
      const skilledAreas = areas.reduce((obj, key) => ({ ...obj, [key]: answers[key] && answers[key].length }), {});
      const data = {
        ...answers,
        skilledAreas: getMax(skilledAreas),
      }
      if (isValidated()){
        props.submitExercise1(userID, data)
        successAlert()
      } else {
        setAlert(
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "100px" }}
            title=""
            onConfirm={() => hideAlert()}
            confirmBtnBsStyle="default"
            confirmBtnText="Try Again"
            btnSize="sm"
          >
            Ops! Something is not right.
          </ReactBSAlert>
        )
      }
    }

    const dropdownOptions = () => {
      const defaultOption = {
        label: "n/a",
        value: "n/a",
      };
      let userSkills = topSkills || [];
      userSkills = [ defaultOption, ...userSkills]
      return userSkills;
    }

    const isValidated = () => {
      if (
        (healthcare && healthcare.length) > 0 ||
        (business && business.length) > 0 ||
        (computer && computer.length) > 0 ||
        (tech && tech.length) > 0 ||
        (trade && trade.length) > 0 ||
        (entrepreneurship && entrepreneurship.length) > 0
      ) {
        return true;
      } else {
        return false;
      }
    }

    const successAlert = () => {
      setAlert(
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "100px" }}
            title=""
            onConfirm={() => props.history.push('/user/module4/ex2')}
            confirmBtnBsStyle="success"
            confirmBtnText="Continue"
            btnSize="sm"
          >
            Submission Successful
          </ReactBSAlert>
      )
    }

    const hideAlert = () => {
      setAlert(null)
    }

    return (
      <>
      { alert }
      { progress < 0 || moduleBlocked === true
      ? <ModuleLocked message={'Please complete previous exercise.'}/> 
      : <> 
          <Col md="8" className="wizard-pills mr-auto ml-auto">
            <Card>
              <CardHeader className="text-center">
                <h3 className="mb-2"> Module 4 - All About You</h3>
                <h4>Ex - 1</h4>
              </CardHeader>

              <CardBody>
                <h3 className="mb-2"> Your top transferable skills are most valuable to you in these occupational areas</h3>  
                <p className="mb-2"> Select the transferable skills you consider can be leveraged in each of the suggested occupational tracks. Leave blank if not applicable.<label className="information" id="helper"></label></p>
                <UncontrolledTooltip placement="top" target="helper">
                  Transferable skills can be leveraged in any occupational area, you just need to know how to leverage and sell them. Further, different skills are used in different jobs and competencies.
                </UncontrolledTooltip>
               
                <Row className="justify-content-center mt-3">
                  <Col md="3">
                    <h4>Healthcare</h4>
                  </Col>
                  <Col md="8">
                    <FormGroup>
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder="Choose your transferable skills"
                        name="skills1"
                        closeMenuOnSelect={false}
                        required
                        isMulti
                        value={healthcare}
                        onChange={selectQ1Skills}
                        options={dropdownOptions()}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <h4>Business & Finance</h4>
                  </Col>
                  <Col md="8">
                    <FormGroup>
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder="Choose your transferable skills"
                        name="skills2"
                        closeMenuOnSelect={false}
                        required
                        isMulti
                        value={business}
                        onChange={selectQ2Skills}
                        options={dropdownOptions()}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <h4>Computer & Mathematics</h4>
                  </Col>
                  <Col md="8">
                    <FormGroup>
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder="Choose your transferable skills"
                        name="skills3"
                        closeMenuOnSelect={false}
                        required
                        isMulti
                        value={computer}
                        onChange={selectQ3Skills}
                        options={dropdownOptions()}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <h4>Tech Sales and Management</h4>
                  </Col>
                  <Col md="8">
                    <FormGroup>
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder="Choose your transferable skills"
                        name="skills4"
                        closeMenuOnSelect={false}
                        required
                        isMulti
                        value={tech}
                        onChange={selectQ4Skills}
                        options={dropdownOptions()}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <h4>Skill Trades</h4>
                  </Col>
                  <Col md="8">
                    <FormGroup>
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder="Choose your transferable skills"
                        name="skills5"
                        closeMenuOnSelect={false}
                        required
                        isMulti
                        value={trade}
                        onChange={selectQ5Skills}
                        options={dropdownOptions()}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <h4>Entrepreneurship</h4>
                  </Col>
                  <Col md="8">
                    <FormGroup>
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder="Choose your transferable skills"
                        name="skills6"
                        closeMenuOnSelect={false}
                        required
                        isMulti
                        value={entrepreneurship}
                        onChange={selectQ6Skills}
                        options={dropdownOptions()}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card> 
          
            <hr/>

            <Row className="justify-content-center my-3">
              <Col md='5' xs='6'>
                <Button block color="success" size="lg" type="button" onClick={handleSave}>
                  Save
                </Button>
              </Col>
            </Row>
          </Col>  
        </>       
      }
    </>
    )
};

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    exercise4m3 : state.firestore.data.exercise4m3 || {},
    exercise1 : state.firestore.data.exercise1 || {}
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitExercise1: (userID, data) => dispatch(submitExercise1(userID, data))
  }
}

export default compose( 
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module3",
          subcollections: [{ collection: "exercises", doc: "exercise4"}],
        }],
        storeAs: 'exercise4m3'
      },
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module4",
          subcollections: [{ collection: "exercises", doc: "exercise1"}],
        }],
        storeAs: 'exercise1'
      }
    ]
  }),
)(Exercise1);