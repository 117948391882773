import React from 'react';
import {Row} from "reactstrap";
import LockMarker from "../../assets/img/icons/common/LockMarker.svg";
import StartMarker from "../../assets/img/icons/common/StartMarker.svg";
import UnlockedMarker from "../../assets/img/icons/common/UnlockedMarker.svg";

const Module1Map = (props) => {
    return (
      <Row className="justify-content-center">
        <div className="module-map-container">
          <img 
            alt='Module 1 Route Map' 
            src={require("assets/img/theme/MapLevel1.svg")}/> 
            <span className="btn1" onClick={() => props.history.push('/user/module1/ex1')}>
              <img alt='...' src={StartMarker}/> 
            </span> 
            <span className="btn2" onClick={() => props.history.push('/user/module1/ex2')}>
              <img alt='...' src={props.progress < 33 ? LockMarker : UnlockedMarker}/> 
            </span> 
            <span className="btn3" onClick={() => props.history.push('/user/module1/ex3')}>
              <img alt='...' src={props.progress < 70 ? LockMarker : UnlockedMarker}/> 
            </span> 
            <span className="btn4" onClick={() => props.history.push('/user/module2')}>
              <img alt='...' src={props.nextModuleBlocked ? LockMarker : UnlockedMarker}/> 
            </span> 
        </div>
      </Row>
    )
};

export default Module1Map;
