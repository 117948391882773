import React from "react";
import { Container, Row, Col, Spinner, Card, CardHeader, CardBody, CardTitle, Collapse } from "reactstrap";

import Ex1Table from "./Ex1Table";
import Ex2Table from "./Ex2Table";
import Ex3Table from "./Ex3Table";

import { connect } from "react-redux";
import { getAllResponses } from "../../../../redux/actions/superAdminActions";

const Module1Responses = (props) => {
  const [openedCollapses, setOpenedCollapses] = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(false)
  const { getAllResponses } = props

  const handleGetAllResponses = React.useCallback(async () => {
    try {
      setIsFetching(true)
      await getAllResponses({ module: "module1" });
      setIsFetching(false)
    } catch (error) {
      console.log(error.message);
      return;
    }
  }, [getAllResponses]);


  React.useEffect(() => {
    handleGetAllResponses()
    return () => {}
  },[handleGetAllResponses])

  const collapsesToggle = (collapse) => {
    if (openedCollapses.includes(collapse)) {
      setOpenedCollapses([]);
    } else {
      setOpenedCollapses([collapse]);
    }
  };

  return (
    <Container className="mt-2" fluid>
      <Row className="justify-content-center">
        <Col xl="12" className="text-center">
          <h1 className="text-dark title-weight my-2">Module 1</h1>
        </Col>
      </Row>

      {isFetching ? (
        <Row
          className="justify-content-center align-items-center mt-4"
          style={{ height: "80vh" }}
        >
          <Spinner />
        </Row>
      ) : (
        <Row className="justify-content-center mt-4">
          <Col md="12">
            <div className="accordion mt-4">
              <Card className="card-plain">
                <CardHeader
                  role="tab"
                  onClick={() => collapsesToggle("collapseOne")}
                  aria-expanded={openedCollapses.includes("collapseOne")}
                >
                  <CardTitle className="mb-0" tag="h3">
                    <span className="title-weight mr-1">Exercise 1 -</span>
                    <span className="text-primary">User Info</span>
                  </CardTitle>
                </CardHeader>
                <Collapse
                  role="tabpanel"
                  isOpen={openedCollapses.includes("collapseOne")}
                >
                  <CardBody>
                    <Ex1Table />
                  </CardBody>
                </Collapse>
              </Card>
              <Card className="card-plain">
                <CardHeader
                  role="tab"
                  onClick={() => collapsesToggle("collapseTwo")}
                  aria-expanded={openedCollapses.includes("collapseTwo")}
                >
                  <CardTitle className="mb-0" tag="h3">
                    <span className="title-weight mr-1">Exercise 2 -</span>
                    <span className="text-cyan">Top Values</span>
                  </CardTitle>
                </CardHeader>
                <Collapse
                  role="tabpanel"
                  isOpen={openedCollapses.includes("collapseTwo")}
                >
                  <CardBody>
                    <Ex2Table />
                  </CardBody>
                </Collapse>
              </Card>
              <Card className="card-plain">
                <CardHeader
                  role="tab"
                  onClick={() => collapsesToggle("collapseThree")}
                  aria-expanded={openedCollapses.includes("collapseThree")}
                >
                  <CardTitle className="mb-0" tag="h3">
                    <span className="title-weight mr-1">Exercise 3 -</span>
                    <span className="text-blue2">MBTI Assestment</span>
                  </CardTitle>
                </CardHeader>
                <Collapse
                  role="tabpanel"
                  isOpen={openedCollapses.includes("collapseThree")}
                >
                  <CardBody>
                    <Ex3Table />
                  </CardBody>
                </Collapse>
              </Card>
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    profile: state.firebase.profile,
    allResponses: state.superAdminReducer.allResponses
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllResponses: (data) => dispatch(getAllResponses(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Module1Responses);
