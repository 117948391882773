import React from "react";
import { Route, Switch } from "react-router-dom";
import { Col, Row, Container } from "reactstrap";

import ModuleHeader from "components/Headers/ModuleHeader";
import { ModuleProgressBar, IntroModule3, Module3Map } from "components/Sections";
import UpgradeMembershipModal from 'components/Modals/UpgradeMembershipModal';
import Exercise1 from "./exercise1/Exercise1";
import Exercise2 from "./exercise2/Exercise2";
import Exercise3A from "./exercise3/Exercise3A";
import Exercise3B from "./exercise3/Exercise3B";
import Exercise4 from "./exercise4/Exercise4";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

class Module3 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      module3: {},
      progress: 0,
      blocked: null
    }
  }

  componentDidUpdate(prevProps) { 
    if (this.props.module3 !== prevProps.module3) {
      this.setState({
        module3: this.props.module3,
        blocked: this.props.module3.blocked,
        progress: this.props.module3.progress,
      })
    }
  }

  render() {
    const { progress, blocked } = this.state;
    const { profile, history, module3, module4 } = this.props;
    const isIndividualUser = profile.institution_userID === null || profile.institution_userID === 'na' ? true : false;
    const isStarter = profile.token && profile.token.claims.stripeRole !== 'premium' ? true : false

    return (
      <>
       <ModuleHeader color={'module3'}/>
        <Container className="bg-mobile-3 mt-7" fluid>
            <Row>
              {this.props.match.params.id !== "ex4" &&
                <Col md="4" className="text-center d-none d-lg-block">
                  <ModuleProgressBar value={module3.progress || 0}/>
                  <Module3Map history={history} progress={module3.progress} nextModuleBlocked={module4.blocked}/>     
                </Col> 
              }       
              <Switch>
                <Route exact path="/user/module3" render={props => <IntroModule3 {...props} history={history} progress={progress} moduleBlocked={blocked}/>}/>
                <Route exact path="/user/module3/ex1" render={props => <Exercise1 {...props} history={history} progress={progress} moduleBlocked={blocked}/>}/>
                <Route exact path="/user/module3/ex2" render={props => <Exercise2 {...props} history={history} progress={progress} moduleBlocked={blocked}/>}/>
                <Route exact path="/user/module3/ex3A" render={props => <Exercise3A {...props} history={history} progress={progress} moduleBlocked={blocked}/>}/>
                <Route exact path="/user/module3/ex3B" render={props => <Exercise3B {...props} history={history} progress={progress} moduleBlocked={blocked}/>}/>
                <Route exact path="/user/module3/ex4" render={props => <Exercise4 {...props} history={history} progress={progress} moduleBlocked={blocked}/>}/>       
              </Switch>                     
            </Row>     
        </Container>

        <UpgradeMembershipModal
          isOpen={isIndividualUser && isStarter}
          history={history}
          message={'Seems like you must upgrade your account before continuing. Please go to settings and subscribe.'}
          redirectTo={'/user/settings'}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    profile: state.firebase.profile,
    module3 : state.firestore.data.module3 || {},
    module4 : state.firestore.data.module4 || {}
  }
};

export default compose( 
  connect(mapStateToProps),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module3"}],
        storeAs: 'module3'
      },
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module4"}],
        storeAs: 'module4'
      }]
    }),
)(Module3);
