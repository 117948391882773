import Home from "views/pages/Home.js";
import Map from "views/pages/PathwaysMap.js";
import UserProfile from "views/pages/UserProfile.js";
import Badges from "views/pages/Badges";
import Settings from "views/pages/UserAccount.js";
import Module1 from "views/modules/module1/Module1.js";
import Module2 from "views/modules/module2/Module2.js";
import Module3 from "views/modules/module3/Module3.js";
import Module4 from "views/modules/module4/Module4.js";
import Module5 from "views/modules/module5/Module5.js";
import Summary from "views/pages/Summary.js";
/*import Alternative from "views/pages/dashboards/Alternative.js";
import Buttons from "views/pages/components/Buttons.js";
import Cards from "views/pages/components/Cards.js";
import Charts from "views/pages/Charts.js";
import Components from "views/pages/forms/Components.js";
import Dashboard from "views/pages/dashboards/Dashboard.js";
import Elements from "views/pages/forms/Elements.js";
import Grid from "views/pages/components/Grid.js";
import Icons from "views/pages/components/Icons.js";
import Lock from "views/pages/Lock.js";
import Login from "views/pages/Login.js";
import Notifications from "views/pages/components/Notifications.js";
import Pricing from "views/pages/examples/Pricing.js";
import Profile from "views/pages/examples/Profile.js";
import ReactBSTables from "views/pages/tables/ReactBSTables.js";
import Register from "views/pages/Register.js";
import RTLSupport from "views/pages/examples/RTLSupport.js";
import Sortable from "views/pages/tables/Sortable.js";
import Tables from "views/pages/tables/Tables.js";
import Timeline from "views/pages/examples/Timeline.js";
import Typography from "views/pages/components/Typography.js";
import Validation from "views/pages/forms/Validation.js";
/*import Vector from "views/pages/maps/Vector.js";
import Widgets from "views/pages/Widgets.js";
import Google from "views/pages/maps/Google.js";
import Calendar from "views/pages/Calendar.js";*/

const routes = [
  {
    path: "/home",
    name: "Home",
    icon: "fas fa-home text-indigo",
    component: Home,
    layout: "/user"
  },
  {
    path: "/map",
    name: "Map",
    icon: "ni ni-map-big text-indigo",
    component: Map,
    layout: "/user"
  },
  {
    path: "/user-profile",
    name: "Profile",
    icon: "ni ni-single-02 text-indigo",
    component: UserProfile,
    layout: "/user"
  },
  {
    collapse: true,
    name: "Modules",
    icon: "ni ni-paper-diploma text-indigo",
    state: "modulesCollapse",
    views: [
      {
        path: "/module1",
        name: "Module 1",
        miniName: "1",
        component: Module1,
        layout: "/user",
        subpath: "/:id"
      },
      {
        path: "/module2",
        name: "Module 2",
        miniName: "2",
        component: Module2,
        layout: "/user",
        subpath: "/:id"
      },
      {
        path: "/module3",
        name: "Module 3",
        miniName: "3",
        component: Module3,
        layout: "/user",
        subpath: "/:id"
      },
      {
        path: "/module4",
        name: "Module 4",
        miniName: "4",
        component: Module4,
        layout: "/user",
        subpath: "/:id"
      },
      {
        path: "/module5",
        name: "Module 5",
        miniName: "5",
        component: Module5,
        layout: "/user",
        subpath: "/:id"
      }
    ]
  },
  {
    path: "/badges",
    name: "Badges",
    icon: "ni ni-trophy text-indigo",
    component: Badges,
    layout: "/user"
  },
  {
    path: "/user-summary",
    name: "User Summary",
    icon: "ni ni-folder-17 text-indigo",
    component: Summary,
    layout: "/user"
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "ni ni-settings-gear-65 text-indigo",
    component: Settings,
    layout: "/user"
  },/*
  {
    collapse: true,
    name: "Dashboards",
    icon: "ni ni-shop text-indigo",
    state: "dashboardsCollapse",
    views: [
      {
        path: "/dashboard",
        name: "Dashboard",
        miniName: "D",
        component: Dashboard,
        layout: "/user"
      },
      {
        path: "/alternative-dashboard",
        name: "Alternative",
        miniName: "A",
        component: Alternative,
        layout: "/user"
      }
    ]
  },
  {
    collapse: true,
    name: "Examples",
    icon: "ni ni-ungroup text-indigo",
    state: "examplesCollapse",
    views: [
      {
        path: "/pricing",
        name: "Pricing",
        miniName: "P",
        component: Pricing,
        layout: "/auth"
      },
      {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth"
      },
      {
        path: "/register",
        name: "Register",
        miniName: "R",
        component: Register,
        layout: "/auth"
      },
      {
        path: "/lock",
        name: "Lock",
        miniName: "L",
        component: Lock,
        layout: "/auth"
      },
      {
        path: "/timeline",
        name: "Timeline",
        miniName: "T",
        component: Timeline,
        layout: "/user"
      },
      {
        path: "/profile",
        name: "Profile",
        miniName: "P",
        component: Profile,
        layout: "/user"
      },
      {
        path: "/rtl-support",
        name: "RTL Support",
        miniName: "RS",
        component: RTLSupport,
        layout: "/rtl"
      }
    ]
  },
  {
    collapse: true,
    name: "Components",
    icon: "ni ni-ui-04 text-indigo",
    state: "componentsCollapse",
    views: [
      {
        path: "/buttons",
        name: "Buttons",
        miniName: "B",
        component: Buttons,
        layout: "/user"
      },
      {
        path: "/cards",
        name: "Cards",
        miniName: "C",
        component: Cards,
        layout: "/user"
      },
      {
        path: "/grid",
        name: "Grid",
        miniName: "G",
        component: Grid,
        layout: "/user"
      },
      {
        path: "/notifications",
        name: "Notifications",
        miniName: "N",
        component: Notifications,
        layout: "/user"
      },
      {
        path: "/icons",
        name: "Icons",
        miniName: "I",
        component: Icons,
        layout: "/user"
      },
      {
        path: "/typography",
        name: "Typography",
        miniName: "T",
        component: Typography,
        layout: "/user"
      },
      {
        collapse: true,
        name: "Multi Level",
        miniName: "M",
        state: "multiCollapse",
        views: [
          {
            path: "#pablo",
            name: "Third level menu",
            component: () => {},
            layout: "/"
          },
          {
            path: "#pablo",
            name: "Just another link",
            component: () => {},
            layout: "/"
          },
          {
            path: "#pablo",
            name: "One last link",
            component: () => {},
            layout: "/"
          }
        ]
      }
    ]
  },
  {
    collapse: true,
    name: "Forms",
    icon: "ni ni-single-copy-04 text-indigo",
    state: "formsCollapse",
    views: [
      {
        path: "/elements",
        name: "Elements",
        miniName: "E",
        component: Elements,
        layout: "/user"
      },
      {
        path: "/components",
        name: "Components",
        miniName: "C",
        component: Components,
        layout: "/user"
      },
      {
        path: "/validation",
        name: "Validation",
        miniName: "V",
        component: Validation,
        layout: "/user"
      }
    ]
  },
  {
    collapse: true,
    name: "Tables",
    icon: "ni ni-align-left-2 text-default",
    state: "tablesCollapse",
    views: [
      {
        path: "/tables",
        name: "Tables",
        miniName: "T",
        component: Tables,
        layout: "/user"
      },
      {
        path: "/sortable",
        name: "Sortable",
        miniName: "S",
        component: Sortable,
        layout: "/user"
      },
      {
        path: "/react-bs-table",
        name: "React BS Tables",
        miniName: "RBT",
        component: ReactBSTables,
        layout: "/user"
      }
    ]
  },
  {
    path: "/charts",
    name: "Charts",
    icon: "ni ni-chart-pie-35 text-indigo",
    component: Charts,
    layout: "/user"
  },/*
  {
    collapse: true,
    name: "Maps",
    icon: "ni ni-map-big text-primary",
    state: "mapsCollapse",
    views: [
      {
        path: "/google",
        name: "Google",
        miniName: "G",
        component: Google,
        layout: "/user"
      },
      {
        path: "/vector",
        name: "Vector",
        miniName: "V",
        component: Vector,
        layout: "/user"
      }
    ]
  },
  {
    path: "/widgets",
    name: "Widgets",
    icon: "ni ni-archive-2 text-green",
    component: Widgets,
    layout: "/user"
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: "ni ni-calendar-grid-58 text-red",
    component: Calendar,
    layout: "/user"
  }*/
];

export default routes;
