import React from "react";
import { Col, Row, Container } from "reactstrap";
import ModuleHeader from "components/Headers/ModuleHeader";
import { BadgeBook } from "components/Sections";

import { connect } from 'react-redux';

class Badges extends React.Component {
  
  render() {
    const { profile } = this.props
    return (
      <>
        <ModuleHeader color={'module3'}/>
        <Container fluid>
          <Row>
            <Col lg="6" xs="8" className="subtitle text-center ml-auto mr-auto">
              <h6 className="h2 text-dark text-center mt-2">
                <span>Your Badges</span>
              </h6>
            </Col>
          </Row> 
        </Container>
        <Container fluid> 
          <Row className="justify-content-center px-3 my-2">
            <BadgeBook overallProgress={profile.op || 0}/>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { 
    profile: state.firebase.profile
  }
}

export default connect(mapStateToProps)(Badges);