const initState = {
  profileError: null,
  successMessage: null,
  couponIndError: null,
  couponIndSuccess : null,
}

const profileReducer = (state = initState, action) => {
    //let discount = 1;
    switch (action.type) {
      case 'NAME_UPDATE_SUCCESS':
          console.log('Success, profile info updated');
          return state;
      case 'NAME_UPDATE_ERROR':
          console.log('Error: Profile info update', action.err);
          return {
            ...state,
            profileError: action.err
          }
      case 'UPDATE_PASSWORD_SUCCESS':
          return {
            ...state,
            successMessage: 'Your password has been updated successfully.',
            profileError: null
          }   
      case 'UPDATE_PASSWORD_ERROR':
          return {
            ...state,
            successMessage: null,
            profileError: action.err
          }  
      case 'UPDATE_SINGLE_USER_MEMBERSHIP_SUCCESS':
          console.log('Success, membership info updated');
          return state; 
      case 'UPDATE_SINGLE_USER_MEMBERSHIP_ERROR':
          console.log('Error updating membership', action.err);
          return state
      case 'SUBMIT_IND_COUPON_SUCCESS':
          if (action.payload.data.isDiscount){
            //discount = action.payload.data.discount
            return {
              ...state,
              couponIndError: null,
              //couponIndSuccess : 'This is a valid coupon. Price were updated.',
            };
          }
          return {
            ...state,
            couponIndError: null,
            couponIndSuccess : `This is a valid coupon. you've got premium access.`,
          } 
      case 'SUBMIT_IND_COUPON_ERROR':
        return {
          ...state,
          couponIndError: action.err,
          couponIndSuccess: null
        };                  
      default:
        return state;   
    }
};
  
export default profileReducer;