import React from "react";
import { Col, Spinner } from "reactstrap"
import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactWizard from "react-bootstrap-wizard";
import ModuleLocked from "components/Sections/ModuleLocked";

import Step1 from "./Step1";
import Step2 from "./Step2";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { submitExercise2 } from "redux/actions/module3Actions";

const steps = [
  { stepName: "1", component: Step1 },
  { stepName: "2", component: Step2}
];

class Exercise2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: steps,
      alert: null
    };
  };

  componentDidMount() {
    this.setState({
      steps: [
        { stepName: "1", component: Step1, stepProps: this.props.exercise2},
        { stepName: "2", component: Step2, stepProps: this.props.exercise2}
      ]
    })
  }

  componentDidUpdate(prevProps) { 
    if (this.props.exercise2 !== prevProps.exercise2) {
      this.setState({
        steps: [
          { stepName: "1", component: Step1, stepProps: this.props.exercise2},
          { stepName: "2", component: Step2, stepProps: this.props.exercise2}
        ]
      })
    }
  }

  finishButtonClick = (allStates) => {
    const data = {
      userID: this.props.uid,
      Q1: allStates["1"].Q1,
      Q2A: allStates["1"].Q2A,
      Q2B: allStates["1"].Q2B,
      Q3: allStates["1"].Q3,
      Q4: allStates["1"].Q4,
      Q5: allStates["2"].Q5,
      Q6: allStates["2"].Q6,
      Q7: allStates["2"].Q7,
      Q8: allStates["2"].Q8,
      Q9: allStates["2"].Q9,
    }
    this.props.submitExercise2(data)
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "100px" }}
          title="Great!"
          onConfirm={() => this.props.history.push('/user/module3/ex3a')}
          confirmBtnBsStyle="success"
          confirmBtnText="Continue"
          btnSize=""
        >
          Submission successful, you can continue now.
        </ReactBSAlert>
      )
    });
  }

  render() {  
    const { alert, steps } = this.state;
    const { progress, moduleBlocked } = this.props;
    if (this.props.profile.isEmpty) return <div className="text-center"><Spinner color="primary" size="md"/> </div> 
    return (
      <>
       <Col md="8" className="wizard-pills mr-auto ml-auto"> 
        {alert}
          { progress < 33 || moduleBlocked === true
          ? <ModuleLocked message={'Please complete the exercise 1 of this module.'}/> 
          : <>   
              <ReactWizard
                steps={steps}
                navSteps
                validate
                progressbar
                title="Module 3 - Super Powers"
                description="Ex - 2"
                headerTextCenter
                finishButtonClasses="btn-default"
                nextButtonClasses="btn-sm-default"
                previousButtonClasses="btn-danger"
                finishButtonClick={this.finishButtonClick}
              />      
            </>       
          }
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    profile: state.firebase.profile,
    module3 : state.firestore.data.module3 || {},
    exercise2 : state.firestore.data.exercise2 || {}
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitExercise2: (data) => dispatch(submitExercise2(data))
  }
}

export default compose( 
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module3"}],
        storeAs: 'module3'
      },
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module3",
          subcollections: [{ collection: "exercises", doc: "exercise2"}],
        }],
        storeAs: 'exercise2'
      }
    ]
  }),
)(Exercise2);