import React from "react";
import ReactWizard from "react-bootstrap-wizard";
import { Spinner } from "reactstrap";
import Step1 from "./Step1";
import Step2 from "./Step2";
import ModuleLocked from 'components/Sections/ModuleLocked';
import UpgradeMembershipModal from 'components/Modals/UpgradeMembershipModal';
import ReactBSAlert from "react-bootstrap-sweetalert";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { submitExercise2a } from "redux/actions/module1Actions";
  
const steps = [
  { stepName: "1", component: Step1 },
  { stepName: "2", component: Step2},
];

class Exercise2 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      steps: steps,
      alert: null,
    }
  }

  componentDidMount(){
    this.setState({
      steps: [
        { stepName: "1", component: Step1 },
        { stepName: "2", component: Step2, stepProps: this.props.exercise2 },
      ]
    })
  }

  componentDidUpdate(prevProps) { 
    if (this.props.exercise2 !== prevProps.exercise2 || this.props.profile !== prevProps.profile) {
      this.setState({
        steps: [
          { stepName: "1", component: Step1 },
          { stepName: "2", component: Step2, stepProps: this.props.exercise2 },
        ]
      })
    }
  }

  toggleModal = () => {
    this.setState({ 
      modalOpen: !this.state.modalOpen,
    })
  }

  finishButtonClick = (allStates) => {
    const data = {
      userID: this.props.uid,
      userValues: allStates["2"].list
    }
    this.props.submitExercise2a(data)
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "100px" }}
          title="Great!"
          onConfirm={() => this.props.history.push('/user/module1/ex2B')}
          confirmBtnBsStyle="success"
          confirmBtnText="Continue"
          btnSize=""
        >
          Submission succesful, you can continue now.
        </ReactBSAlert>
      )
    });
  }

  render() { 
    const { steps, alert } = this.state;
    const { profile, history } = this.props;
    const isIndividualUser = profile.institution_userID === null || profile.institution_userID === 'na' ? true : false;
    const isStarter = profile.token && profile.token.claims.stripeRole !== 'premium' ? true : false

    if (this.props.profile.isEmpty) return <div className="text-center"><Spinner color="primary" size="md"/> </div> 
    return (
        <>
        { this.props.progress < 33
        ? <ModuleLocked message={'Please complete Exercise 1 first.'}/> 
        : <>
            {alert}
            <ReactWizard
              steps={steps}
              navSteps
              validate
              progressbar
              title="Module 1 - Right Now"
              description="Ex - 2"
              headerTextCenter
              finishButtonClasses="btn-default"
              finishButtonText="Continue"
              nextButtonClasses="btn-sm-default"
              previousButtonClasses="btn-danger"
              finishButtonClick={this.finishButtonClick}
            />
          </>
        }
          <UpgradeMembershipModal
            isOpen={isIndividualUser && isStarter}
            history={history}
            message={'Seems like you must upgrade your account before continuing. Please go to settings and subscribe.'}
            redirectTo={'/user/settings'}
          />
        </>
    )
  }

}  

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    profile: state.firebase.profile,
    module1 : state.firestore.data.module1 || {},
    exercise2 : state.firestore.data.exercise2m1 || {}
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitExercise2a: (data) => dispatch(submitExercise2a(data))
  }
}

export default compose( 
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module1"}],
        storeAs: 'module1'
      },
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module1",
          subcollections: [{ collection: "exercises", doc: "exercise2"}],
        }],
        storeAs: 'exercise2m1'
      }
    ]
  }),
)(Exercise2);