import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Input, Button, Spinner } from "reactstrap";
import { StudentsTable } from "components/Tables";
import ReactBSAlert from "react-bootstrap-sweetalert";
import AddCreditsModal from 'components/Modals/UpgradeMembershipModal';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { createStudent } from 'redux/actions/adminActions';

const Students = (props) => {
    const [isProcessing, setProcessingTo] = useState(false);
    const [studentEmail, setStudentEmail ] = useState('')
    const [modal, setModal] = useState(false)
    const [alert, setAlert] = useState(null)

    const verifyEmail = (value) => {
      var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRex.test(value)) {
        return true;
      }
      return false;
    }
    const hideAlert = () => { 
      setAlert(null) 
    }
    const toggleModal = () => {
      setModal(!modal)
    }
    const seatsAvailable = () => {
      const { schoolData } = props;
      if ( schoolData.totalSeats - schoolData.students.length > 0) {
        return true
      } else {
        return false 
      }    
    }
    const handleSubmit = async () => {
      setProcessingTo(true);
      const student = {
        schoolID: props.uid,
        school: props.schoolData.school,
        email: studentEmail,
      }

      try {      
        if (!seatsAvailable()) {
          setModal(true)
          setProcessingTo(false);
          return
        }

        if (verifyEmail(student.email) && seatsAvailable()){
          await props.createStudent(student)
          setStudentEmail('')
          setAlert(
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "100px" }}
              title="Submission Succesful"
              onConfirm={() => hideAlert()}
              confirmBtnBsStyle="default"
              confirmBtnText="Continue"
              btnSize=""
            >
              Great! Your user will receive an email with an invitation link.
            </ReactBSAlert>
          )
          setProcessingTo(false);
        } else {
          setAlert(
            <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "100px" }}
              title=""
              onConfirm={() => hideAlert()}
              confirmBtnBsStyle="default"
              confirmBtnText="Try again."
              btnSize=""
            >
              Ops! Please enter a valid email.
            </ReactBSAlert>
          )
          setProcessingTo(false);
        } 
      } catch (error) {
        console.log(error.message)
        return
      }
    }

    const { profile } = props;
    if ( profile.isEmpty ) return <div className="text-center"><Spinner color="primary" size="md"/> </div> 
    if ( profile.token && profile.token.claims.student ) return <Redirect direct to="/user/home"/>

    return (
          <Container className="mt-2" fluid>
            { alert }
            <Row className="justify-content-center">
              <Col xl="12" className="text-center">
                <h1 className="text-dark title-weight my-2">Add User</h1>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md="5">
                <Card className="bg-primary">
                  <CardBody>
                    <Row className="align-items-center">
                      <Col lg="8" xs="9" className="pr-0">          
                        <Input 
                          placeholder="Enter new user email" 
                          type="text" 
                          name="studentEmail" 
                          value={studentEmail} 
                          onChange={(e) => setStudentEmail(e.target.value)}
                          />
                      </Col>
                      <Col lg="4" xs="3">
                        <Button 
                          className="btn-round" 
                          color="default" 
                          size="sm"
                          disabled={isProcessing}
                          onClick={handleSubmit}
                        >
                           {isProcessing ? "Processing..." : "Submit"}
                        </Button>
                      </Col>
                    </Row>    
                  </CardBody>
                </Card> 
              </Col>    
            </Row>

            <Row className="justify-content-center">
              <Col md="10">
                <StudentsTable schoolData={props.schoolData}/>
              </Col>
            </Row>

            <AddCreditsModal
              isOpen={modal}
              toggle={toggleModal}
              history={props.history}
              message={`Seems like you don't have empty seats available to add more users. Please go to settings and add more seats.`}
              redirectTo={'/admin/settings'}
            />
          </Container>
      )
}


const mapStateToProps = (state) => {
  return {
      uid: state.firebase.auth.uid,
      profile: state.firebase.profile,
      schoolData: state.firestore.data.schoolData || {}
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createStudent: (student) => dispatch(createStudent(student)),
  }
}

export default compose( 
  connect(mapStateToProps,mapDispatchToProps),
  firestoreConnect((props)=> {
    if (!props.profile.schoolID) return []
    return [
      {
        collection:'schools', 
        doc: props.profile.schoolID,
        storeAs: 'schoolData'
      }]
    }),
 )(Students);
