import React from 'react';
import { Button, Modal } from "reactstrap";

const UpgradeMembershipModal = (props) => {

    return (
        <>
          <Modal
            className="modal-dialog-centered modal-danger"
            contentClassName="bg-gradient-danger"
            isOpen={props.isOpen}
          >
            <div className="modal-header">
              <h6 className="modal-title">
                Your attention is required
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => props.history.push(props.redirectTo)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="py-1 text-center">          
                <h4 className="heading mt-4">Hello!</h4>
                <p>
                  {props.message}
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <Button className="btn-white" color="default" type="button" onClick={() => props.history.push(props.redirectTo)}>
                Ok, Got it
              </Button>
            </div>
          </Modal> 
        </>
    )
}
export default UpgradeMembershipModal;