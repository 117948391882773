const definitions = [
    { 
        id:1,
        skill: "Administration and Management",
        definition: "Knowledge of business and management principles involved in strategic planning, resource allocation, human resources modeling, leadership technique, production methods, and coordination of people and resources."
      },
      {
        id:2,
        skill: "Biology",
        definition: "Knowledge of plant and animal organisms, their tissues, cells, functions, interdependencies, and interactions with each other and the environment."
      },
      {
        id:3,
        skill: "Spatial Ability (Building & Construction)",
        definition: "Knowledge of materials, methods, and the tools involved in the construction or repair of houses, buildings, or other structures such as highways and roads."
      },
      {
        id:4,
        skill: "Physical Coordintation & Athletic Skills",
        definition: "Moving your arms, legs, and mid-section together while your whole body is moving."
      },
      {
        id:5,
        skill: "Chemistry",
        definition: "Knowledge of the chemical composition, structure, and properties of substances and of the chemical processes and transformations that they undergo. This includes uses of chemicals and their interactions, danger signs, production techniques, and disposal methods."
      },
      {
        id:6,
        skill: "Clerical",
        definition: "Knowledge of administrative and clerical procedures and systems such as word processing, managing files and records, stenography and transcription, designing forms, and other office procedures and terminology."
      },
      {
        id:7,
        skill: "Complex Problem Solving",
        definition: "Noticing a problem and figuring out the best way to solve it."
      },
      {
        id:8,
        skill: "Information Technology (IT) Skills",
        definition: "knowledge and talents related to the use, administration, development, design, architecture and management of technology."
      },
      {
        id:9,
        skill: "Customer Care & Service",
        definition: "Knowledge of principles and processes for providing customer and personal services. This includes customer needs assessment, meeting quality standards for services, and evaluation of customer satisfaction."
      },
      {
        id:10,
        skill: "Economics and Accounting",
        definition: "Knowledge of economic and accounting principles and practices, the financial markets, banking and the analysis and reporting of financial data."
      },
      {
        id:11,
        skill: "Fine Arts",
        definition: "Knowledge of the theory and techniques required to compose, produce, and perform works of music, dance, visual arts, drama, and sculpture."
      },
      {
        id:12,
        skill: "Foreign Language",
        definition: "Knowledge of the structure and content of a foreign (non-English) language including the meaning and spelling of words, rules of composition and grammar, and pronunciation."
      },
      {
        id:13,
        skill: "Empathy & Helping People",
        definition: "Looking for ways to help people."
      },
      {
        id:14,
        skill: "Instructing",
        definition: "Teaching people how to do something."
      },
      {
        id:15,
        skill: "Management of Financial Resources",
        definition: "Making spending decisions and keeping track of what is spent."
      },
      {
        id:16,
        skill: "Mathematics",
        definition: "Using math to solve problems."
      },
      {
        id:17,
        skill: "Mechanical",
        definition: "Knowledge of machines and tools, including their designs, uses, repair, and maintenance."
      },
      {
        id:18,
        skill: "Medicine and Dentistry",
        definition: "Knowledge of the information and techniques needed to diagnose and treat human injuries, diseases, and deformities. This includes symptoms, treatment alternatives, drug properties and interactions, and preventive health-care measures."
      },
      {
        id:19,
        skill: "Memorization",
        definition: "Remembering words, numbers, pictures, or steps."
      },
      {
        id:20,
        skill: "People Management",
        definition: "Process of training, motivating and directing employees to optimize workplace productivity and promote professional growth"
      },
      {
        id:21,
        skill: "Negotiation",
        definition: "Bringing people together to solve differences."
      },
      {
        id:22,
        skill: "Operation Analysis",
        definition: "Figuring out what a product or service needs to be able to do."
      },
      {
        id:23,
        skill: "Personnel and Human Capital Management",
        definition: "Knowledge of principles and procedures for personnel recruitment, selection, training, compensation and benefits, labor relations and negotiation, and personnel information systems."
      },
      {
        id:24,
        skill: "Production and Processing",
        definition: "Knowledge of raw materials, production processes, quality control, costs, and other techniques for maximizing the effective manufacture and distribution of goods."
      },
      {
        id:25,
        skill: "Programming",
        definition: "Writing computer programs."
      },
      {
        id:26,
        skill: "Psychology",
        definition: "Knowledge of human behavior and performance; individual differences in ability, personality, and interests; learning and motivation; psychological research methods; and the assessment and treatment of behavioral and affective disorders."
      },
      {
        id:27,
        skill: "Public Safety and Security",
        definition: "Knowledge of relevant equipment, policies, procedures, and strategies to promote effective local, state, or national security operations for the protection of people, data, property, and institutions."
      },
      {
        id:28,
        skill: "Quality Control Analysis",
        definition: "Testing how well a product or service works."
      },
      {
        id:29,
        skill: "Repairing",
        definition: "Repairing machines or systems using the right tools."
      },
      {
        id:30,
        skill: "Sales and Marketing",
        definition: "Knowledge of principles and methods for showing, promoting, and selling products or services. This includes marketing strategy and tactics, product demonstration, sales techniques, and sales control systems."
      },
      {
        id:31,
        skill: "Science Process Skills",
        definition: "Using scientific rules and strategies to solve problems."
      },
      {
        id:32,
        skill: "Sociology and Anthropology",
        definition: "Knowledge of group behavior and dynamics, societal trends and influences, human migrations, ethnicity, cultures and their history and origins."
      },
      {
        id:33,
        skill: "Speaking (Oral communication)",
        definition: "Talking to others."
      },
      {
        id:34,
        skill: "Systems Analysis",
        definition: "Figuring out how a system should work and how changes in the future will affect it."
      },
      {
        id:35,
        skill: "Teaching & Course Design",
        definition: "Knowledge of principles and methods for curriculum and training design, teaching and instruction for individuals and groups, and the measurement of training effects."
      },
      {
        id:36,
        skill: "Telecommunications",
        definition: "Knowledge of transmission, broadcasting, switching, control, and operation of telecommunications systems."
      },
      {
        id:37,
        skill: "Therapy & Counseling",
        definition: "Knowledge of principles, methods, and procedures for diagnosis, treatment, and rehabilitation of physical and mental dysfunctions, and for career counseling and guidance."
      },
      {
        id:38,
        skill: "Time Management",
        definition: "Managing your time and the time of other people."
      },
      {
        id:39,
        skill: "Troubleshooting",
        definition: "Figuring out what is causing equipment, machines, wiring, or computer programs to not work."
      },
      {
        id:40,
        skill: "Writing",
        definition: "Writing things for co-workers or customers."
      },
      {
        id:41,
        skill: "Attention to Detail",
        definition: "Ability to pay close attention and notice mior details. "
      },
      {
        id:42,
        skill: "Track & Assess",
        definition: "Anility to track metrics and other indicators and assess what the data is saying."
      },
      {
        id:43,
        skill: "Critical Thinking",
        definition: "The objective analysis and evaluation of an issue in order to form a judgment."
      },
      {
        id:44,
        skill: "Child care",
        definition: "The action or skill of looking after young people under the age of 18 years of age."
      },
      {
        id:45,
        skill: "Data Analysis",
        definition: "Knowledge of process of cleaning, transforming, and modeling data to discover useful information for decision-making."
      },
]

export default definitions;