import React, { useRef } from 'react';
import ReactToPrint from "react-to-print";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button, ButtonGroup, Card, Row, Col, UncontrolledTooltip } from "reactstrap";

import { connect } from "react-redux";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: false,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        users.
      </label>
    </div>
  )
})
const { SearchBar } = Search;

const Ex3Table = (props) => {
  const componentRef = useRef();

  const displayData = props.allResponses?.ex3?.map((i, index) => ({
    key: index,
    uuid: i.uuid,
    personality: i.personality,
    extraverted: i.mbtiTest?.extraverted,
    feeling: i.mbtiTest?.feeling,
    introverted: i.mbtiTest?.introverted,
    intuitive: i.mbtiTest?.intuitive,
    judging: i.mbtiTest?.judging,
    observant: i.mbtiTest?.observant,
    prospecting: i.mbtiTest?.prospecting,
    thinking: i.mbtiTest?.thinking
  }));
  
  return (
      <Card>
        <ToolkitProvider
          data={displayData || []}
          keyField="key"
          columns={[
            {
              dataField: "uuid",
              text: "User id",
              sort: false,
            },
            {
              dataField: "personality",
              text: "Personality",
              sort: true,
            },
            {
              dataField: "extraverted",
              text: "extraverted",
              sort: true
            },
            {
              dataField: "feeling",
              text: "feelin",
              sort: true
            },
            {
              dataField: "introverted",
              text: "introverted",
              sort: true
            },
            {
              dataField: "intuitive",
              text: "intuitive",
              sort: true
            },
            {
              dataField: "judging",
              text: "judging",
              sort: true,
            },
            {
              dataField: "observant",
              text: "observant",
              sort: true,
            },
            {
              dataField: "prospecting",
              text: "prospecting",
              sort: true,
            },
            {
              dataField: "thinking",
              text: "thinking",
              sort: true,
            },
          ]}
          search
        >
          {props => (
            <div className="py-4 table-responsive">
              <div
                id="datatable-basic_filter"
                className="dataTables_filter px-4 pb-1"
              >
                <label>
                  Search:
                  <SearchBar
                    className="form-control-sm"
                    placeholder=""
                    {...props.searchProps}
                  />
                </label>
              </div>
              <BootstrapTable
                ref={componentRef}
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                id="react-bs-table"
              />
            </div>
          )}
        </ToolkitProvider>
        <Col>
          <Row className="justify-content-end"> 
            <ButtonGroup>
              <ReactToPrint
                trigger={() => (
                  <Button
                    color="default"
                    size="sm"
                    className="buttons-copy buttons-html5 mx-2 my-2"
                    id="print-tooltip"
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </ButtonGroup>
            <UncontrolledTooltip placement="top" target="print-tooltip">
              Print or save to PDF.
            </UncontrolledTooltip>    
          </Row>
        </Col>     
      </Card>
    )
}

const mapStateToProps = (state) => {
  return {
    allResponses: state.superAdminReducer.allResponses
  };
};
        
export default connect(mapStateToProps, null)(Ex3Table);

