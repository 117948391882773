import React from "react";
import classnames from "classnames";
import { Redirect } from 'react-router-dom';
import { Button,Card, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col } from "reactstrap";

import LoginAdminHeader from "components/Headers/LoginAdminHeader.js";
import MainNavbar from "components/Navbars/MainNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import ErrorMessage from "components/Alerts/ErrorMessage";

import { connect } from 'react-redux';
import { signIn, signOut } from '../../redux/actions/authActions';
import Fire from ".././../Fire";

class AdminLogin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errorMessage: null
    };
  }

  change = (e, stateName) => {
    this.setState({ [stateName]: e.target.value, errorMessage: null });
  }

  handleSubmit = async (e) => {
    e.preventDefault();  
    let credentials = { email: this.state.email, password: this.state.password }
    try {
      await Fire.shared.auth.signInWithEmailAndPassword(credentials.email,credentials.password);
      await Fire.shared.auth.currentUser.getIdTokenResult().then(idTokenResult => {
        if (idTokenResult.claims.admin) {
          this.props.history.push("/admin/home");
        } else {
          this.props.signOut()
          this.props.history.push("/access-denied");
        }
      })
    } catch (error) {
      this.setState({ errorMessage: error.message });
    }
  }

  render() {
    const { errorMessage } = this.state
    const { history, profile } = this.props;
    return (
      <>
        { profile.token && profile.token.claims.admin ? <Redirect direct to="/admin/home"/> :
        <>
          <div className="bg-indigo main-content" ref="mainContent">
            <MainNavbar/>
            <LoginAdminHeader history={history}/>
            <Container className="mt--8 pb-6">
              <Row className="justify-content-center">
                <Col lg="5" md="7">
                  <Card className="bg-warning border-0 mb-0">
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-indigo mb-4">
                        <small>Sign in with your Admin credentials</small>
                      </div>
                      <Form role="form" onSubmit={this.handleSubmit}>
                        <FormGroup
                          className={classnames("mb-3", {
                            focused: this.state.focusedEmail
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Email"
                              type="email"
                              onFocus={() => this.setState({ focusedEmail: true })}
                              onBlur={() => this.setState({ focusedEmail: false })}
                              onChange={e => this.change(e, "email")}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup
                          className={classnames({
                            focused: this.state.focusedPassword
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Password"
                              type="password"
                              autoComplete="on"
                              onFocus={() => this.setState({ focusedPassword: true })}
                              onBlur={() => this.setState({ focusedPassword: false })}
                              onChange={e => this.change(e, "password")}
                            />
                          </InputGroup>
                        </FormGroup>
                        <div className="custom-control custom-control-alternative custom-checkbox mb-2">
                          <input
                            className="custom-control-input"
                            id=" customCheckLogin"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor=" customCheckLogin"
                          >
                            <span className="text-white">Remember me</span>
                          </label>
                        </div>
                        { errorMessage && <ErrorMessage message={errorMessage}/> } 
                        <div className="text-center">
                          <Button className="my-4" color="primary" type="submit">
                              Sign in
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Row className="mt-3">
                    <Col xs="6">
                      <span className="text-light" onClick={() => {this.props.history.push('/lock-screen')}}>
                        <small>Forgot password?</small>
                      </span>
                    </Col>
                    <Col className="text-right" xs="6">
                      <span className="text-light" onClick={() => {this.props.history.push('/register-institutions')}}>
                        <small>Don't have an account yet?</small>
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
          <AuthFooter/>
        </>
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
    profile: state.firebase.profile || {}
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (credentials) => dispatch(signIn(credentials)),
    signOut: () => dispatch(signOut())
  }
}

export default connect(mapStateToProps, mapDispatchToProps )(AdminLogin);