import React, {Fragment} from 'react';
import { Row, Col } from "reactstrap";
import skills from "variables/skills";

const skillsList = skills;

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions1: skillsList.slice(0, 11), 
      validationState1: "", 
    }
  }

  componentDidMount() {
    this.setState({ ...this.props.batch1 })
  }

  componentDidUpdate(prevProps) { 
    if (this.props.topSkills !== prevProps.topSkills) {
      this.setState({
        ...this.props.batch1
      })
    }
  }

  isValidated = () => {
    const answers = skillsList.slice(0, 11).map((i,index) => i.skill);
    const hasAllAnswers = answers.every(item => this.state.hasOwnProperty(item));
    if (hasAllAnswers) {
      this.setState({validationState1: "valid"})
      return true;
    } 
    this.setState({validationState1: "invalid"})
    return false;
  }

  render(){
    const { questions1, validationState1 } = this.state;
    
    const skillsQuestions = questions1 && questions1.map((i, index) => {
      return (  
        <Row className="justify-content-center list-skills-m4 mt-2" key={index}>
          <Col className="col-ex4-1" md="3">
            <p><span className="title-weight">
                {i.id + '. ' + i.skill}</span>
              <br/>{i.questionText}
            </p>
          </Col>
          { i.answerOptions.map((answerOption, subIndex) => 
            <Col className="col-ex4-2 text-center" key={subIndex}>      
              <div className="radio-box mb-2">
                  <input
                    id={'skill' + i.id + answerOption.level}
                    type="radio"
                    checked={this.state[i.skill] === answerOption.level}
                    onChange={() => this.setState({ [i.skill]: answerOption.level })}
                  />      
                  <label htmlFor={'skill' + i.id + answerOption.level}> 
                    {answerOption.answerText}
                  </label>        
              </div>            
            </Col>) }
        </Row>
      )
    })

    const mobileQuestions = questions1 && questions1.map((i, index) => {
      return ( 
        <Fragment key={index}>  
          <Row>
            <Col xs="12" className="list-skills-head-m4">
              <p><span className="title-weight text-dark">
                    {i.id + '. ' + i.skill}</span>
                  <br/>{i.questionText}
              </p>
            </Col>
          </Row> 

          { i.answerOptions.map((answerOption, subIndex) => 
            <Row key={subIndex}>
              <Col xs="5" className="list-skills-m4">      
                <div className="custom-control custom-radio mb-3 mt-1">
                    <input
                      className="custom-control-input"
                      id={'skill' + i.id + answerOption.level}
                      type="radio"
                      checked={this.state[i.skill] === answerOption.level}
                      onChange={() => this.setState({ [i.skill]: answerOption.level })}
                    />      
                    <label className="custom-control-label" htmlFor={'skill' + i.id + answerOption.level}> 
                      <p className="text-dark">{answerOption.level}</p>
                    </label>
                </div>            
              </Col>
              <Col xs="7" className="list-skills-m4">
                <p>{answerOption.answerText}</p>         
              </Col>     
            </Row>
            )}
        </Fragment>  
      )
    })

    return (
        <>
          <h1 className="mb-2">Identify Transferable Skills</h1>  
          <p>Use the examples to help choose your levels. Think about whether you have done the example activity, or something like it in your own field.</p>
          <Row className="justify-content-center d-none d-lg-block">
            <Col md='12' xs='12'>
              <Row className="list-head">
                <Col className="col-ex4-1" md="3">
                  Skill
                </Col>
                <Col className="text-center">
                  Not Applicable
                </Col>
                <Col className="text-center">
                  Beginner
                </Col>
                <Col className="text-center">
                  Basic
                </Col>
                <Col className="text-center">
                  Skilled
                </Col>
                <Col className="text-center">
                  Advanced
                </Col>
                <Col className="text-center">
                  Expert
                </Col>
              </Row>
              {skillsQuestions}
            </Col>
          </Row>

          <div className="justify-content-center d-inline-block d-block d-sm-none">
            {mobileQuestions}
          </div>
          {validationState1 === "invalid" && <label className="form-control-label text-primary warning">Ops! Seems like we are missing some responses.</label>}   
         
        </>
    )
  }
}

export default Step1;
