import React, {useState, useEffect} from "react";
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Button, Spinner, UncontrolledTooltip } from "reactstrap";
import ModuleLocked from "./ModuleLocked";
import MBTIindicators from "variables/mbtiIndicators";

import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';

const IntroModule4 = (props) => {

  const [ indicators, setIndicators ] = useState({})
  useEffect(() => { 
    setIndicators(props.exercise3);
  }, [props.exercise3])

  const MBTItype = MBTIindicators.find((x) => x.result === indicators.personality);
  
  if (props.moduleBlocked === null) {
    return <Col md="8" className="wizard-pills mr-auto ml-auto">
            <div className="text-center"><Spinner color="primary" size="md"/> </div> 
          </Col>
  }
  
  if (props.moduleBlocked === true && props.moduleBlocked !== undefined) {
    return  <Col md="8" className="wizard-pills mr-auto ml-auto">
              <ModuleLocked message={'Complete previous module to unlock.'}/>
            </Col>
  }

  return (
    <>
      <Col md="8" className="wizard-pills mr-auto ml-auto">
        <Card>
          <CardHeader className="text-center">
            <h2 className="mb-2"> Module 4 - All About You</h2> 
          </CardHeader>
          <CardBody>
            <h1 className="mb-1">Industry & Occupational Suggestions</h1>
            <iframe className="youtube" title="intro video to module 4" src="https://www.youtube.com/embed/VW89y8LRyVc?autoplay=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>   
            <p className="mb-3 text-dark">
              Your MBTI <span className="title-weight">{MBTItype && MBTItype.result}</span> is aligned with the following occupations: <label className="information" id="yearning"></label>
              <br/>What your MBTI Type looks like at work.
            </p> 
            <UncontrolledTooltip placement="top" target="yearning">
              Based on your MBTI results in module 1.
            </UncontrolledTooltip>
            <Row>
              <Col>
                <h3>Occupational Tracks to Consider</h3>
                <ul>
                  {MBTItype && MBTItype.occupationalTracks.map((i, index) => 
                  <li key={index}>
                    <h4 className="title-light-weight text-dark">{i}</h4>
                  </li>)}  
                </ul>
              </Col>
              <Col>
                <h3>Jobs to Aim For <label className="information" id="tooltip-salary"></label></h3>
                <ul>
                  {MBTItype && MBTItype.jobs.map((i, index) => 
                  <li key={index}>
                    <h4 className="title-light-weight text-dark">{i}</h4>
                  </li>)}  
                </ul>
              </Col>
            </Row>
            <UncontrolledTooltip placement="top" target="tooltip-salary">
              Median Salary for Job (not entry level)
            </UncontrolledTooltip>
          </CardBody>
          <CardFooter>
            <Row className="justify-content-end">
              <Button color="primary" outline type="button" onClick={() => props.history.push('/user/module4/ex1')}>
                Continue
              </Button>
            </Row> 
          </CardFooter> 
        </Card>  
      </Col> 
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    exercise3 : state.firestore.data.exercise3 || {}
  }
};

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module1",
          subcollections: [{ collection: "exercises", doc: "exercise3"}],
        }],
        storeAs: 'exercise3'
      }]
    }),
)(IntroModule4);