import React, { useRef, useEffect, useState } from 'react';
import classnames from "classnames";
import ReactToPrint from "react-to-print";
import ReactBSAlert from "react-bootstrap-sweetalert";

import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button, ButtonGroup, Card, CardHeader, Row, Col, Badge, UncontrolledTooltip, UncontrolledAlert, Spinner } from "reactstrap";

import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { resendInvite, deleteUnregisteredUser } from 'redux/actions/adminActions';

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: false,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        users.
      </label>
    </div>
  )
})
const { SearchBar } = Search;


const StudentsTable = (props) => {

  const componentRef = useRef();
  const [data, setData] = useState([]);
  const [successMessage, setSuccessMessage] = useState(false)
  const [alert, setAlert] = useState(null)
  
  useEffect(() => { 
    setData(props.usersList);
  }, [props.usersList])

  const displayData = data && data.map((i, index) => ({
      id: i.id, 
      email: i.email, 
      registered: i.registered,
      status: i.registered ? 'Completed' : 'Pending',
      createdAt: moment(i.createdAt.toDate()).format('MMM DD YYYY'),
      joined: i.joined ? moment(i.joined.toDate()).format('MMM DD YYYY') : '',
      resend: !i.registered
    }
  ));

  const handleActionButton = async (student) => {
    try {
      await props.resendInvite(student)
      setSuccessMessage(true)
    } catch (err) {
      console.log(err)
    }
  }

  const handleDeleteRecord = async (student) => {
    try {
      await props.deleteUnregisteredUser(student)
      hideAlert()
    } catch (err) {
      console.log(err)
    }
  }

  const actionButtons = (key, item) => {
    const student = {
      schoolID: props.schoolData.schoolID,
      school: props.schoolData.school,
      email: item.email,
      id: item.id
    }
    if (key === false) {
      return
    } else {
      return (
        <>
          <span 
            className="table-action" 
            style={{fontSize: '1.1em', color:'#215270'}} 
            onClick={() => handleActionButton(student)} 
            id="tooltipIcon"
          > 
            <i className="fas fa-paper-plane grow-icon"></i>
          </span>{" "}
          <span
            className="table-action"
            style={{fontSize: '1.1em'}} 
            id="tooltip12475020"
            onClick={() => warningDeleteMessage(student)}
          >
            <i className="fas fa-trash grow-icon text-danger" />
          </span>    
          <UncontrolledTooltip delay={0} target="tooltipIcon">
            Resend Invite
          </UncontrolledTooltip>
          <UncontrolledTooltip delay={0} target="tooltip12475020">
            Delete Unregistered User
          </UncontrolledTooltip>
        </>
      )
    }
  }

  const statusBadge = (key, item) => {
    return  <Badge color="" className="badge-dot mr-4">
              <i className={classnames(
                  {"bg-danger": item.registered === false },
                  {"bg-success": item.registered === true })}/>
              <span className="status">{item.registered ? 'Completed' : 'Pending'}</span>
            </Badge>
  }

  const warningDeleteMessage = (student) => {
    setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "100px" }}
          title="Are you sure?"
          onConfirm={() => handleDeleteRecord(student)}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Yes, delete"
          cancelBtnText="Cancel"
          showCancel
          btnSize="sm"
        >
          You will not be able to recover this record.
        </ReactBSAlert>
    )
  };

  const hideAlert = () => {
    setAlert(null)
  }

  if (props.profile.isEmpty) return <div className="mr-auto ml-auto"><Spinner color="primary" size="md"/></div> 

  return (
    <>
      { alert }
      { successMessage && 
        <UncontrolledAlert color="success" fade={false}>
          <span>
            <b>Nice! - </b>
            Your email has been resubmitted.
          </span>
        </UncontrolledAlert> }
      <Card>
        <CardHeader>
          <h3 className="mb-0">Users</h3>
          <p className="text-sm mb-0">
            Total: {displayData && displayData.length}
          </p>
        </CardHeader>
        <ToolkitProvider
          data={displayData || []}
          keyField="id"
          columns={[
            {
              dataField: "email",
              text: "Email",
              sort: true
            },
            {
              dataField: "status",
              text: "Registration Status",
              sort: true,
              formatter: statusBadge,
            },
            {
              dataField: "createdAt",
              text: "Invite Sent On",
              sort: false,
            },
            {
              dataField: "joined",
              text: "Joined",
              sort: false,
            },
            {
              dataField: "resend",
              text: "",
              sort: false,
              formatter: actionButtons
            },
          ]}
          search
        >
          {props => (
            <div className="py-4 table-responsive">
              <div
                id="datatable-basic_filter"
                className="dataTables_filter px-4 pb-1"
              >
                <label>
                  Search:
                  <SearchBar
                    className="form-control-sm"
                    placeholder=""
                    {...props.searchProps}
                  />
                </label>
              </div>
              <BootstrapTable
                ref={componentRef}
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                id="react-bs-table"
              />
            </div>
          )}
        </ToolkitProvider>
        <Col>
          <Row className="justify-content-end"> 
            <ButtonGroup>
              <ReactToPrint
                trigger={() => (
                  <Button
                    color="default"
                    size="sm"
                    className="buttons-copy buttons-html5 mx-2 my-2"
                    id="print-tooltip"
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </ButtonGroup>
            <UncontrolledTooltip placement="top" target="print-tooltip">
              Print or save to PDF.
            </UncontrolledTooltip>    
          </Row>
        </Col>     
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
      profile: state.firebase.profile,
      usersList: state.firestore.ordered.usersList || [],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resendInvite: (student) => dispatch(resendInvite(student)),
    deleteUnregisteredUser: (student) => dispatch(deleteUnregisteredUser(student))
  }
}
        
export default compose( 
connect(mapStateToProps, mapDispatchToProps),
firestoreConnect((props)=> {
  if (!props.profile.schoolID) return []
  return [
    {
      collection:'schools', 
      doc: props.profile.schoolID,
      subcollections: [{ collection: "institution_users"}],
      storeAs: 'usersList'
    }]
  }),
)(StudentsTable);