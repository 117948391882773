import React from 'react';
import { Row, Col } from "reactstrap";

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }
  render(){
    return (
      <>
        <h1 className="mb-2">Introduction</h1>   
        <Row className="justify-content-center">
          <Col lg="10">
            <Row>
                <p>You will begin your career journey with us here in Module 1. In Module 1, we will get a little familiar with some of the initial building blocks of what makes you, YOU! After we get the demographics out of the way, we will get into what you value and get to know a little bit about your personality type </p>         
            </Row>
          </Col>
        </Row>
      </>
    )
}
}


export default Step1;
