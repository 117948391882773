import React from "react";
import classnames from "classnames";
import { Row, Col, Button, FormGroup, Input, Progress } from "reactstrap";
import indicators from "variables/mbtiIndicators";

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      extraverted: '',
      introverted: '',
      intuitive: '',
      observant: '',
      thinking: '',
      feeling: '',
      judging: '',
      prospecting: '',
      personality: '',
    }
  };

  componentDidMount(){
    const { mbtiTest } = this.props;
    this.setState({
      extraverted: mbtiTest && mbtiTest.extraverted,
      introverted: mbtiTest && this.props.mbtiTest.introverted,
      intuitive: mbtiTest && this.props.mbtiTest.intuitive,
      observant: mbtiTest && this.props.mbtiTest.observant,
      thinking: mbtiTest && this.props.mbtiTest.thinking,
      feeling: mbtiTest && this.props.mbtiTest.feeling,
      judging: mbtiTest && this.props.mbtiTest.judging,
      prospecting: mbtiTest && this.props.mbtiTest.prospecting,
      extravertedState: mbtiTest && mbtiTest.extraverted ? "valid" : "",
      introvertedState: mbtiTest && mbtiTest.introverted ? "valid" : "",
      intuitiveState: mbtiTest && mbtiTest.intuitive ? "valid" : "",
      observantState: mbtiTest && mbtiTest.observant ? "valid" : "",
      thinkingState: mbtiTest && mbtiTest.thinking ? "valid" : "",
      feelingState: mbtiTest && mbtiTest.feeling ? "valid" : "",
      judgingState: mbtiTest && mbtiTest.judging ? "valid" : "",
      prospectingState: mbtiTest && mbtiTest.prospecting ? "valid" : "", 
    })
  }

  componentDidUpdate(prevProps) { 
    const { mbtiTest } = this.props;
    if (this.props.mbtiTest !== prevProps.mbtiTest) {
      this.setState({
        extraverted: this.props.mbtiTest.extraverted,
        introverted: this.props.mbtiTest.introverted,
        intuitive: this.props.mbtiTest.intuitive,
        observant: this.props.mbtiTest.observant,
        thinking: this.props.mbtiTest.thinking,
        feeling: this.props.mbtiTest.feeling,
        judging: this.props.mbtiTest.judging,
        prospecting: this.props.mbtiTest.prospecting,
        extravertedState: mbtiTest && mbtiTest.extraverted ? "valid" : "",
        introvertedState: mbtiTest && mbtiTest.introverted ? "valid" : "",
        intuitiveState: mbtiTest && mbtiTest.intuitive ? "valid" : "",
        observantState: mbtiTest && mbtiTest.observant ? "valid" : "",
        thinkingState: mbtiTest && mbtiTest.thinking ? "valid" : "",
        feelingState: mbtiTest && mbtiTest.feeling ? "valid" : "",
        judgingState: mbtiTest && mbtiTest.judging ? "valid" : "",
        prospectingState: mbtiTest && mbtiTest.prospecting ? "valid" : "", 
      })
    }
  }

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change = (e, stateName, type, stateNameEqualTo, pairStateName) => {
    switch (type) {
      case "length":
        if (this.verifyLength(e.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "valid" });
          this.setState({ [pairStateName + "State"]: "valid" });
        } else {
          this.setState({ [stateName + "State"]: "invalid" });
          this.setState({ [pairStateName + "State"]: "invalid" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: e.target.value, [pairStateName] : 100 - e.target.value });
  }

  isValidated = () => {
    if (
      this.state.extravertedState === "valid" &&
      this.state.introvertedState === "valid" &&
      this.state.intuitiveState === "valid" &&
      this.state.observantState === "valid" &&
      this.state.thinkingState === "valid" &&
      this.state.feelingState === "valid" &&
      this.state.judgingState === "valid" &&
      this.state.prospectingState === "valid"
    ) {
      return true;
    } else {
      if (this.state.extravertedState !== "valid") {
        this.setState({ extravertedState: "invalid" });
      }
      if (this.state.introvertedState !== "valid") {
        this.setState({ introvertedState: "invalid" });
      }
      if (this.state.intuitiveState !== "valid") {
        this.setState({ intuitiveState: "invalid" });
      }
      if (this.state.observantState !== "valid") {
        this.setState({ observantState: "invalid" });
      }
      if (this.state.thinkingState !== "valid") {
        this.setState({ thinkingState: "invalid" });
      }
      if (this.state.feelingState !== "valid") {
        this.setState({ feelingState: "invalid" });
      }
      if (this.state.judgingState !== "valid") {
        this.setState({ judgingState: "invalid" });
      }
      if (this.state.prospectingState !== "valid") {
        this.setState({ prospectingState: "invalid" });
      }
      return false;
    }
  }

  paragraphText = (paragraph) => {
    return paragraph.split('\n').map((str,index) => <h5 className="text-dark" key={index}>{str}</h5>);
  }

  renderInitials = () => {
    const { extraverted, introverted, intuitive, observant, thinking, feeling, judging, prospecting } = this.state;
    const res = {}
    let result = ''
    res[1] = extraverted > introverted ? 'E' : 'I';
    res[2] = intuitive > observant ? 'N' : 'S';
    res[3] = thinking > feeling ? 'T' : 'F';
    res[4] = judging > prospecting ? 'J' : 'P';
    const type = indicators.find((x) => x.result === result.concat(...Object.values(res)));
    if ( extraverted && judging ){
      return(
        <Col lg="10" sm="10">
          <h3 className="text-dark">
            Your result : 
            <span className="title-weight ml-1">{result.concat(...Object.values(res))}</span>
          </h3>
          <h4 className="text-info">{type.type}</h4>
          {type && this.paragraphText(type.careerTypes)}
        </Col>)
    }
    return null
  }
  
  render() {
    const { extraverted, introverted, intuitive, observant, thinking, feeling, judging, prospecting } = this.state
    return (
      <>
        <h1 className="mb-2">MBTI Personality Assessment</h1>   
        <Row className="justify-content-center mb-3">
          <Col lg="10" sm="10">
            <Row>
              <p>Click on 'Go to test' to complete the Assessment from 16Personalities and come back to report your results below. This exercise will take you about 15 mins to complete.</p> 
              <Col className="row justify-content-end" >      
                <Button color="info" outline type="button" onClick={() => window.open('https://www.16personalities.com/free-personality-test')}>
                  Go to test.
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr/>
        <Row className="align-items-center justify-content-center px-3">
          <Col sm="3" xs="6">
            <FormGroup>
              <label className="form-control-label">EXTRAVERTED</label>
              <Input
                className="form-control-sm"
                placeholder="%"
                type="number"
                value={extraverted || 0}
                onChange={e => this.change(e, "extraverted", "length", 1, "introverted")}
              />
              {this.state.extravertedState === "invalid" && <label className="form-control-label text-primary warning">Missing</label>}
            </FormGroup>
          </Col>
          <Col sm="3" xs="6">
            <FormGroup>
              <label className="form-control-label">INTROVERTED</label>
              <Input
                className="form-control-sm"
                placeholder="%"
                type="number"
                value={introverted || 0}
                onChange={e => this.change(e, "introverted", "length", 1, "extraverted")}
              />
              {this.state.introvertedState === "invalid" && <label className="form-control-label text-primary warning">Missing</label>}
            </FormGroup>
          </Col>
          <Col sm="6" xs="12">
            <Row className="mb-0">
              <Col sm="2" xs="2" className="mx-0">
                <h6 className={classnames(extraverted > introverted ? "text-primary": "" )}>{extraverted}%</h6>
              </Col>
              <Col sm="8" xs="8">
                <Progress 
                  max="100" 
                  value={extraverted > introverted ? extraverted : introverted} 
                  color="primary" 
                  className={classnames(extraverted > introverted ? "progress-md": "progress-md-inverted" )}/>
              </Col>
              <Col sm="2" xs="2" className="mx-0">
                <h6 className={classnames(extraverted < introverted ? "text-primary": "" )}>{introverted}%</h6>
              </Col>
            </Row>
            <Row className="mt-0 justify-content-between">
                <h6 className={classnames(extraverted > introverted ? "text-primary title-weight": "" )}>EXTRAVERTED</h6>
                <h6 className={classnames(extraverted < introverted ? "text-primary title-weight": "" )}>INTROVERTED</h6>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center justify-content-center px-3">
          <Col sm="3" xs="6">
            <FormGroup>
              <label className="form-control-label">INTUITIVE</label>
              <Input
                className="form-control-sm"
                placeholder="%"
                type="number"
                value={intuitive || 0}
                onChange={e => this.change(e, "intuitive", "length", 1, "observant")}
              />
              {this.state.intuitiveState === "invalid" && <label className="form-control-label text-primary warning">Missing</label>}
            </FormGroup>
          </Col>
          <Col sm="3" xs="6">
            <FormGroup>
              <label className="form-control-label">OBSERVANT</label>
              <Input
                className="form-control-sm"
                placeholder="%"
                type="number"
                value={observant || 0}
                onChange={e => this.change(e, "observant", "length", 1, "intuitive")}
              />
              {this.state.observantState === "invalid" && <label className="form-control-label text-primary warning">Missing</label>}
            </FormGroup>
          </Col>
          <Col sm="6" xs="12">
            <Row className="mb-0">
              <Col sm="2" xs="2" className="mx-0">
                <h6 className={classnames(intuitive > observant ? "text-indigo": "" )}>{intuitive}%</h6>
              </Col>
              <Col sm="8" xs="8">
                <Progress 
                  max="100" 
                  value={intuitive > observant ? intuitive : observant} 
                  color="indigo" 
                  className={intuitive > observant ? "progress-md": "progress-md-inverted" }/>
              </Col>
              <Col sm="2" xs="2" className="mx-0">
                <h6 className={classnames(intuitive < observant ? "text-indigo": "" )}>{observant}%</h6>
              </Col>
            </Row>
            <Row className="mt-0 justify-content-between">
                <h6 className={classnames(intuitive > observant ? "text-indigo title-weight": "" )}>INTUITIVE</h6>
                <h6 className={classnames(intuitive < observant ? "text-indigo title-weight": "" )}>OBSERVANT</h6>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center justify-content-center px-3">
          <Col sm="3" xs="6">
            <FormGroup>
              <label className="form-control-label">THINKING</label>
              <Input
                className="form-control-sm"
                placeholder="%"
                type="number"
                value={thinking || 0}
                onChange={e => this.change(e, "thinking", "length", 1, "feeling")}
              />
               {this.state.thinkingState === "invalid" && <label className="form-control-label text-primary warning">Missing</label>}
            </FormGroup>
          </Col>
          <Col sm="3" xs="6">
            <FormGroup>
              <label className="form-control-label">FEELING</label>
              <Input
                className="form-control-sm"
                placeholder="%"
                type="number"
                value={feeling || 0}
                onChange={e => this.change(e, "feeling", "length", 1, "thinking")}
              />
               {this.state.feelingState === "invalid" && <label className="form-control-label text-primary warning">Missing</label>}
            </FormGroup>
          </Col>
          <Col sm="6" xs="12">
            <Row className="mb-0">
              <Col sm="2" xs="2" className="mx-0">
                <h6 className={classnames(thinking > feeling ? "text-success": "" )}>{thinking}%</h6>
              </Col>
              <Col sm="8" xs="8">
                <Progress 
                  max="100" 
                  value={thinking > feeling ? thinking : feeling} 
                  color="success" 
                  className={classnames(thinking > feeling ? "progress-md": "progress-md-inverted" )}
                />
              </Col>
              <Col sm="2" xs="2" className="mx-0">
                <h6 className={classnames(thinking < feeling ? "text-success": "" )}>{feeling}%</h6>
              </Col>
            </Row>
            <Row className="mt-0 justify-content-between">
                <h6 className={classnames(thinking > feeling ? "text-success title-weight": "" )}>THINKING</h6>
                <h6 className={classnames(thinking < feeling ? "text-success title-weight": "" )}>FEELING</h6>
            </Row>
          </Col>
          
        </Row>
        <Row className="align-items-center justify-content-center px-3">
          <Col sm="3" xs="6">
            <FormGroup>
              <label className="form-control-label">JUDGING</label>
              <Input
                className="form-control-sm"
                placeholder="%"
                type="number"
                value={judging || 0}
                onChange={e => this.change(e, "judging", "length", 1, "prospecting")}
              />
            </FormGroup>
            {this.state.judgingState === "invalid" && <label className="form-control-label text-primary warning">Missing</label>}
          </Col>
          <Col sm="3" xs="6">
            <FormGroup>
              <label className="form-control-label">PROSPECTING</label>
              <Input
                className="form-control-sm"
                placeholder="%"
                type="number"
                value={prospecting || 0}
                onChange={e => this.change(e, "prospecting", "length", 1, "judging")}
              />
            </FormGroup>
            {this.state.prospectingState === "invalid" && <label className="form-control-label text-primary warning">Missing</label>}
          </Col>
          <Col sm="6" xs="12">
            <Row className="mb-0">
              <Col sm="2" xs="2" className="mx-0">
                <h6 className={classnames(judging > prospecting ? "text-warning": "" )}>{judging}%</h6>
              </Col>
              <Col sm="8" xs="8">
                <Progress 
                  max="100" 
                  value={judging > prospecting ? judging : prospecting} 
                  color="warning" 
                  className={classnames(judging > prospecting ? "progress-md": "progress-md-inverted" )}/>
              </Col>
              <Col sm="2" xs="2" className="mx-0">
                <h6 className={classnames(judging < prospecting ? "text-warning": "" )}>{prospecting}%</h6>
              </Col>
            </Row>
            <Row className="mt-0 justify-content-between">
                <h6 className={classnames(judging > prospecting ? "text-warning title-weight": "" )}>JUDGING</h6>
                <h6 className={classnames(judging < prospecting ? "text-warning title-weight": "" )}>PROSPECTING</h6>
            </Row>
          </Col>
        </Row> 
        
        <Row className="align-items-center justify-content-center my-0">
          
            {this.renderInitials()}

        </Row>    
      </>
    );
  }
}

export default Step2;