import React from "react";
import { Row, Col, Card, CardHeader, CardBody, FormGroup, Input, Button, UncontrolledTooltip } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ModuleLocked from "components/Sections/ModuleLocked";
import ModalBadge3 from "components/Modals/ModalBadge3";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { submitExercise3B } from "redux/actions/module3Actions";

class Exercise3B extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      yourStory: '',
      alert: null,
      badgeModal: false
    };
  };

  componentDidUpdate(prevProps) { 
    if (this.props.exercise3 !== prevProps.exercise3) {
      this.setState({
        yourStory: this.props.exercise3.yourStory,
        yourStoryState : this.props.exercise3.yourStory && 'valid',
      })
    }
  }

  toggleModal = () => {
    this.setState({ badgeModal: !this.state.badgeModal})
  }

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change = (e, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "length":
        if (this.verifyLength(e.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "valid" });
        } else {
          this.setState({ [stateName + "State"]: "invalid" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: e.target.value });
  }

  handleSave = () => {
    const data = {
      userID: this.props.uid,
      yourStory: this.state.yourStory
    }
    if (this.isValidated()){
      this.props.submitExercise3B(data)
      this.toggleModal()
    } else {
      this.setState({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "100px" }}
            title=""
            onConfirm={() => this.hideAlert()}
            confirmBtnBsStyle="default"
            confirmBtnText="Ok"
            btnSize=""
          >
            Ops! Please complete this exercise.
          </ReactBSAlert>
        )
      });
    }
  }

  hideAlert = () => {
    this.setState({ alert: null });
  };

  isValidated = () => {
    if (
      this.state.yourStoryState === "valid" 
    ) {
      return true;
    } else {
      if (this.state.yourStoryState !== "valid") {
        this.setState({ yourStoryState: "invalid" });
      }
      return false;
    }
  };

  render() {
    const { yourStory, yourStoryState, alert, badgeModal } = this.state;
    const { progress, moduleBlocked } = this.props;

    return (
      <>
        <Col md="8" className="wizard-pills mr-auto ml-auto"> 
          {alert}
          { progress < 70 || moduleBlocked === true
          ? <ModuleLocked message={'Please complete previous exercise.'}/> 
          : <>   
              <Card>
                <CardHeader className="text-center">
                  <h3 className="mb-2"> Module 3 - Super Powers</h3>
                  <h4>Ex - 3B</h4>
                </CardHeader>
                <CardBody>
                  <h1 className="mb-2">What Everyone Else Needs to Know About YOU!</h1>  
                  <h4 className="text-dark mb-2">
                    From your gifts, let’s pull out the pieces of your story that makes you uniquely you. Include & give examples of:
                  </h4>
                  <ul>
                    <li><h4 className="text-dark">Your goals and name what it is you are passionate about.</h4> </li>
                    <li><h4 className="text-dark">What are you most proud of? </h4></li>
                    <li><h4 className="text-dark">What adversities have you overcome and how did you overcome them? </h4></li>
                    <li><h4 className="text-dark">Describe your work philosophy.<label className="question2" id="helper"></label> </h4></li>
                    <UncontrolledTooltip placement="top" target="helper">
                        - What does work mean to you? <br/>- Are you a team player or are you a natural leader?<br/>- What is your leadership style? 
                    </UncontrolledTooltip>
                  </ul>
                  <hr/>
                  <Row className="justify-content-center">
                    <Col md="10" xs="12">
                      <FormGroup>
                        <label>Please use the box below to write 3-5 paragraphs of your story </label>
                        <Input
                          rows="12"
                          type="textarea"
                          value={yourStory}
                          onChange={e => this.change(e, "yourStory", "length", 5)}
                        />
                        {yourStoryState === "invalid" && <label className="form-control-label text-primary warning"></label>} 
                      </FormGroup>
                    </Col>
                  </Row>      
                </CardBody>
              </Card> 
            
              <hr/>

              <Row className="justify-content-center my-3">
                <Col md='6' xs='10'>
                  <Button block color="success" size="lg" type="button" onClick={this.handleSave} >
                    Save & Continue
                  </Button>
                </Col>
              </Row>

              <ModalBadge3
                isOpen={badgeModal}
                toggle={this.toggleModal}
                history={this.props.history}
                title={'Exercise 3 Completed'}
                message={'Fantastic, you earned the Superpowers and Gifts badge'}
                next={'/user/module3/ex4'}
              /> 
            </>       
          }
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    module3 : state.firestore.data.module3 || {},
    exercise3 : state.firestore.data.exercise3 || {}
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitExercise3B: (data) => dispatch(submitExercise3B(data))
  }
}

export default compose( 
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module3"}],
        storeAs: 'module3'
      },
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module3",
          subcollections: [{ collection: "exercises", doc: "exercise3"}],
        }],
        storeAs: 'exercise3'
      }
    ]
  }),
)(Exercise3B);