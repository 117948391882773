const initState = {
  options:[
    {
        position: "1.1",
        areaName: "Computer & Mathematics",
        approach: "Information Technology, Computer & Mathematical Occupations - Project or Gig-Based",
        checked: false
    },
    {
        position: "1.2",
        areaName:"Business & Finance",
        approach: "Business and Financial Operations - Project or Gig-Based",
        checked: false
    },
    {
        position: "1.3",
        areaName:"Tech Sales and Management",
        approach: "",   
        checked: false
    },
    {
        position: "2.1",
        areaName: "Computer & Mathematics",
        approach: "Information Technology, Computer & Mathematical Occupations - Apprenticeships",
        checked: false
    }, 
    {
        position: "2.2",
        areaName:"Business & Finance",
        approach: "Business and Financial Operations - Apprenticeships",
        checked: false
    },
    {
        position: "2.3",
        areaName:"Tech Sales and Management",
        checked: false
    },
    {
        position: "2.4",
        areaName:"Skill Trades",
        approach: "Skill Trades - Apprenticeships",
        checked: false
    }, 
    {
        position: "3.1",
        areaName: "Computer & Mathematics",
        approach: "Information Technology, Computer & Mathematical Occupations - Entry-Level Position",
        checked: false
    },
    {
        position: "3.2",
        areaName:"Business & Finance",
        approach: "Business and Financial Operations - Entry-Level Position",
        checked: false
    },
    {
        position: "3.3",
        areaName:"Tech Sales and Management",
        approach: "Tech Sales and Management - Entry-Level Position",
        checked: false
    },
    {
        position: "3.4",
        areaName:"Skill Trades",
        approach: "Skill Trades - Entry-Level Position",
        checked: false
    },
    {
        position: "3.5",
        areaName:"Healthcare",
        approach: "Healthcare and Technical Occupations - Entry-Level Position",
        checked: false
    }     
  ]
}
    
const module5Reducer = (state = initState, action) => {
    let optionSelected = '';
    switch(action.type){
      case 'SELECT_CAREER_APPROACH':
          optionSelected = state.options.find(element => element.position === action.payload);
          optionSelected.checked = !optionSelected.checked
          state.options = state.options.map(u => u.position !== optionSelected.position ? u : optionSelected);
          return {
            ...state,
          } 
      case 'UPDATE_STATE_PATHS':
          state.options = action.payload
          return state
      case 'SUBMIT_EXERCISE1M5_SUCCESS':
          console.log('Exercise 1 Module5 success');
          return state
      case 'SUBMIT_EXERCISE1M5_ERROR':
          console.log('Exercise 1 Module5 Error Submission');
          return state  
      default:
        return state
    }
};
  
export default module5Reducer;