import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const ModuleHeader = ({color}) => {

  return (
    <>
      <div className={classnames(
        "module-header mb-6", 
        {"bg-primary": color === "badges" },
        {"bg-warning": color === "module1" },
        {"bg-cyan": color === "module2" },
        {"bg-blue2": color === "module3" },
        {"bg-blue": color === "module4" },
        {"bg-indigo": color === "module5" })}>
        <div className="logo-module-left">
          <img alt='Pathways Logo' src={require("assets/img/brand/logo1.svg")}/>  
        </div>
      </div>
    </>
  );
}


ModuleHeader.defaultProps = {
  color: "badges"
};

ModuleHeader.propTypes = {
  color: PropTypes.string,
};

export default ModuleHeader;
