import React from 'react';
import { FormGroup, Row, Col } from "reactstrap";
import Select from "react-select";
import superpowers from "variables/superpowers";

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Q6: '',
      Q7: '',
      alert: null,
    };
  };

  componentDidMount() {
    this.setState({
      Q6: this.props.Q6,
      Q7: this.props.Q7,
    })
  }

  componentDidUpdate(prevProps) { 
    if (this.props.Q6 !== prevProps.Q6) {
      this.setState({
        Q6: this.props.Q6,
        Q7: this.props.Q7,
      })
    }
  }

  dropdownOptions = () => {
    let options = superpowers.map(i => ({ label: i.superpower, value: i.superpower }));
    return options;
  }

  selectValue = (stateName, value) => {
    this.setState({ [stateName]: value });
    this.setState({ [stateName + "State"]: "valid" });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };


  render() {
    const { Q6, Q7 } = this.state;  
    return (
        <>
          <h4 className="text-dark mb-2">
            What the people around you know. 
          </h4>
          <h4 className="text-dark">Pick 1-2 people from 3 areas of your life such as, but not limited to:</h4>
          <Row>
            <Col md="6">
              <ul>
                <li><h4>Home/family</h4></li>
                <li><h4>Work</h4></li>
                <li><h4>Friends</h4></li>
                <li><h4>Employees</h4></li>
                <li><h4>Clubs, community work</h4></li>
              </ul>
            </Col>
            <Col md="6">
              <FormGroup>
                <h4 className="text-dark">
                  Ask them what they think you are good at.
                </h4>
                <Select
                    className="react-select mt-2"
                    classNamePrefix="react-select"
                    value={Q6}
                    required
                    onChange={(value) => this.selectValue('Q6', value)}
                    options={this.dropdownOptions()}
                  /> 
                <Select
                    className="react-select mt-2"
                    classNamePrefix="react-select"
                    value={Q7}
                    required
                    onChange={(value) => this.selectValue('Q7', value)}
                    options={this.dropdownOptions()}
                  /> 
              </FormGroup>
            </Col>
          </Row>
        </>
    );
  }
}

export default Step2;