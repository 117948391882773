export const selectApproach = (approachPosition) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SELECT_CAREER_APPROACH', payload: approachPosition })
    }
}

export const updateStatePaths = () => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const uid = getState().firebase.auth.uid;
    try { 
      let paths = []
      const userPaths = getState().module5.options
      const userRef = firestore.collection('users').doc(uid);
      const moduleRef = userRef.collection('modules').doc('module5');
      const exerciseRef = moduleRef.collection('exercises').doc('exercise1')
      const exDoc = await exerciseRef.get();
      if ( exDoc.data().name ) {
        paths = exDoc.data().userPaths
      } else {
        paths = [...userPaths]
      }
      dispatch({ type: 'UPDATE_STATE_PATHS', payload: paths })
    } catch (err){
      dispatch({ type: 'UPDATE_STATE_PATHS_ERROR', err });
    }
} 
}

export const submitExercise1 = (data) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    const batch = firestore.batch();
    const userPaths = getState().module5.options
    let userRef = firestore.collection('users').doc(data.userID);
    let moduleRef = userRef.collection('modules').doc('module5');
    let exerciseRef = moduleRef.collection('exercises').doc('exercise1')

    batch.update(exerciseRef, {
      userPaths: [...userPaths],
      name: 'Matrix Approach',      
    });
    batch.commit().then(() => {
      dispatch({ type: 'SUBMIT_EXERCISE1M5_SUCCESS', data });
    }).catch(err => {
      dispatch({ type: 'SUBMIT_EXERCISE1M5_ERROR', err});
    });
  }
}