import React from "react";
import { Row, Col, Card, CardHeader,CardBody,FormGroup, Input, Button } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ModuleLocked from "../../../components/Sections/ModuleLocked";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { submitExercise1 } from "redux/actions/module2Actions";

class Exercise1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Q1 : '',
      Q2 : '',
      alert: null
    };
  };

  componentDidUpdate(prevProps) { 
    if (this.props.exercise1 !== prevProps.exercise1) {
      this.setState({
        Q1: this.props.exercise1.goal1,
        Q2: this.props.exercise1.goal2,
        Q1State : this.props.exercise1.goal1 && 'valid',
        Q2State : this.props.exercise1.goal2 && 'valid',
      })
    }
  }

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  change = (e, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "length":
        if (this.verifyLength(e.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "valid" });
        } else {
          this.setState({ [stateName + "State"]: "invalid" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: e.target.value });
  };

  handleSave = () => {
    const data = {
      userID: this.props.uid,
      goal1: this.state.Q1,
      goal2: this.state.Q2
    }
    if (this.isValidated()){
      this.props.submitExercise1(data)
      this.setState({
        alert: (
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "100px" }}
            title="Great!"
            onConfirm={() => this.props.history.push('/user/module2/ex2')}
            confirmBtnBsStyle="success"
            confirmBtnText="Continue"
            btnSize=""
          >
            Submission succesful, you can continue now.
          </ReactBSAlert>
        )
      });
    } else {
      this.setState({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "100px" }}
            title=""
            onConfirm={() => this.hideAlert()}
            confirmBtnBsStyle="default"
            confirmBtnText="Ok"
            btnSize=""
          >
            Ops! Please complete the exercise.
          </ReactBSAlert>
        )
      });
    }
  }

  hideAlert = () => {
    this.setState({ alert: null });
  };

  isValidated = () => {
    if (
      this.state.Q1State === "valid" &&
      this.state.Q2State === "valid" 
    ) {
      return true;
    } else {
      if (this.state.Q1State !== "valid") {
        this.setState({ Q1State: "invalid" });
      }
      if (this.state.Q2State !== "valid") {
        this.setState({ Q2State: "invalid" });
      }
      return false;
    }
  };

  render() {
    const { Q1, Q2, Q1State, Q2State, alert } = this.state;
    const { progress, moduleBlocked } = this.props;

    return (
      <>
        {alert}
        { progress < 0 || moduleBlocked === true
        ? <ModuleLocked message={'Please complete previous module 1.'}/> 
        : <>   
            <Card>
              <CardHeader className="text-center">
                <h3 className="mb-2"> Module 2 - Goal Setting</h3>
                <h4>Ex - 1</h4>
              </CardHeader>
              <CardBody>
              <h1 className="mb-2">Short Term Goals</h1>  
                <p className="mb-2">
                  Write at least 2 SMARTER Goals that you want to achieve in the next 3-6 months concerning your career or job search. (hint if you are in a workforce or training program, you should be able to tie these goals to that program and its successful completion.) 
                </p>
                <Row className="justify-content-center mt-3">
                  <Col md="3">
                    <label>Smarter Goal 1:</label>
                  </Col>
                  <Col md="8">
                    <FormGroup>
                      <Input
                        rows="3"
                        type="textarea"
                        value={Q1}
                        onChange={e => this.change(e, "Q1", "length", 4)}
                      />
                      {Q1State === "invalid" && <label className="form-control-label text-primary warning"> </label>} 
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col md="3">
                    <label>Smarter Goal 2:</label>
                  </Col>
                  <Col md="8">
                    <FormGroup>
                      <Input
                        rows="3"
                        type="textarea"
                        value={Q2}
                        onChange={e => this.change(e, "Q2", "length", 4)}
                      />
                      {Q2State === "invalid" && <label className="form-control-label text-primary warning"></label>} 
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card> 
          
            <hr/>

            <Row className="justify-content-center my-3">
              <Col md='6' xs='6'>
                <Button block color="success" size="lg" type="button" onClick={this.handleSave} >
                  Save
                </Button>
              </Col>
            </Row>
          </>       
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    module2 : state.firestore.data.module2 || {},
    exercise1 : state.firestore.data.exercise1 || {}
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitExercise1: (data) => dispatch(submitExercise1(data))
  }
}

export default compose( 
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module2"}],
        storeAs: 'module2'
      },
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module2",
          subcollections: [{ collection: "exercises", doc: "exercise1"}],
        }],
        storeAs: 'exercise1'
      }
    ]
  }),
)(Exercise1);