import React from "react";
import { Redirect } from 'react-router-dom';
import { Card, CardBody, Container, Row, Col, Spinner } from "reactstrap";
import { LeavesImg, StudentSeatsCard } from "components/Sections";
import ModuleHeader from "components/Headers/ModuleHeader";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const AdminHome = (props) => {
  
    const { profile, history } = props;
    if ( profile.isEmpty ) return <div className="text-center"><Spinner color="primary" size="md"/> </div> 
    if ( profile.token && profile.token.claims.student ) return <Redirect direct to="/user/home"/>
    return (
      <>
        <div className="special-heading bg-info mb-7">
          <Container fluid>
            <ModuleHeader color={'module5'}/>
          </Container>
        </div>
        <LeavesImg/>
         
        <Container className="mt-0 bg-home d-none d-lg-block" fluid>
          <Row className="justify-content-center">
            <Col className="ml-auto mr-auto my-6 text-center">
              <h1 className="text-dark title-weight mt-2">
                {profile.school}
              </h1>
              <h6 className="h2 text-white d-inline-block">
                Welcome, <span className="title-weight">{profile.displayName}</span>! 
              </h6>
            </Col>
          </Row>  
          <Row className="justify-content-center my-2">
            <Col md='8' className="text-dark">
              <h3 className="title-weight d-inline-block"> What do you want to do today?</h3>
            </Col> 
          </Row>
          <Row className="align-items-center justify-content-center my-2">
            <Col lg="4" xs="12">
              <Card className="mb-4 bg-primary">
                <CardBody onClick={() => {props.history.push('/admin/add-users')}}>
                  <h3 className="mb-2 text-center text-dark">Add User</h3>
                  <p className="mb-1 text-center text-dark">Send invites to your users and have them join Pathways by LBB. </p>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" xs="12">
              <Card className="mb-4 bg-warning">
                <CardBody onClick={() => {props.history.push('/admin/users-progress')}}>
                  <h3 className="mb-2 text-center text-dark">See Progress</h3>
                  <p className="mb-1 text-center text-dark">See your users' progress and help them discover their path.</p>
                </CardBody>
              </Card>
            </Col>
          </Row> 
        </Container>

        <Container className="mt-0 d-inline-block d-block d-sm-none" fluid>
          <Row className="align-items-center">
            <Col xs="12" className="text-dark text-center">
              <h6 className="mobile-margin-t h2">
                  Welcome, <span className="title-weight">{profile.displayName}</span>!  
              </h6>
            </Col>  
          </Row>
          <Row className="justify-content-center mt-0">
            <Col md='8' xs="6" className="text-dark text-center">
              <hr/>
            </Col> 
          </Row>
          <Row className="justify-content-center my-2">
            <Col md='8' className="text-dark">
              <h3 className="title-weight d-inline-block"> What do you want to do today?</h3>
            </Col> 
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col lg="4" xs="12">
              <Card className="mb-4 bg-primary">
                <CardBody onClick={() => {props.history.push('/admin/add-users')}}>
                  <h3 className="mb-2 text-center text-dark">Add User</h3>
                  <p className="mb-1 text-center text-dark">Send invites to your users and have them join Pathways by LBB. Help them discover their path</p>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" xs="12">
              <Card className="mb-4 bg-warning">
                <CardBody onClick={() => {props.history.push('/admin/users-progress')}}>
                  <h3 className="mb-2 text-center text-dark">See Progress</h3>
                  <p className="mb-1 text-center text-dark">See your users' progress and discover how they are doing throughout the app.</p>
                </CardBody>
              </Card>
            </Col>
          </Row> 
        </Container>
        <Container>
          <Row className="justify-content-center"> 
            <Col md="8" xs="10">
              <hr/>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <StudentSeatsCard schoolData={props.schoolData} history={history}/>
          </Row>
        </Container>
      </>
    );
}

const mapStateToProps = (state) => {
  return { 
    uid: state.firebase.auth.uid,
    profile: state.firebase.profile,
    schoolData: state.firestore.data.schoolData || {}
  }
}

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'schools', 
        doc: props.uid,
        storeAs: 'schoolData'
      }]
    }),
 )(AdminHome);
