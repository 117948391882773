import React from "react";
import PropTypes from "prop-types";

import { Button, Modal, ModalBody } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import defaultImage from "assets/img/brand/placeholder.jpg";
import defaultAvatar from "assets/img/brand/placeholder.jpg";
import Avatar from 'react-avatar-edit';
import Fire from '../../Fire';

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userAvatar: this.props.userAvatar,
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
      imagePreviewEdited: null,

      alert: null,
      modalEdit: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.userAvatar !== prevProps.userAvatar) {
      this.setState({ 
        userAvatar: this.props.userAvatar,
      })}
  }

// ----- Read file and receive image data ----- 
  handleClick = () => {
    this.refs.fileInput.click();
  }
   
  handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];  
    reader.onloadend = () => {
      this.setState({
        modalEdit: true,
        file: file,
        imagePreviewUrl: reader.result
      });
    };
    reader.readAsDataURL(file);
  }
// ----- Submit new image to database -----
  handleSubmit = async () => {
    try {
      await Fire.shared.updateAvatar(this.state.imagePreviewEdited);
      this.successAlert();
      this.setState({ file: null })}
    catch (error){
      console.log(error.message)
    }
  }

  handleRemove = () => {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
      userAvatar: this.props.userAvatar
    });
    this.refs.fileInput.value = null;
  }

  successAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "100px" }}
          title="Great!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          btnSize="sm"
        >
          Your profile pic was updated.
        </ReactBSAlert>
      ),
    });
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  }
// ---------- Crop functions -------  
  onClose = () => {
    this.setState({ modalEdit: false })
  }
  
  onCrop = (preview) => {
    this.setState({
      userAvatar: preview,
      imagePreviewEdited: preview
    })
  }

  onBeforeFileLoad = (elem) => {
    if(elem.target.files[0].size > 71680){
      alert("File is too big!");
      elem.target.value = "";
    };
  }

  toggleModal = () => {
    this.setState({
      modalEdit: !this.state.modalEdit
    });  
  }

  render() {
    return (
      <>
        {this.state.alert}
        <input type="file" onChange={this.handleImageChange} ref="fileInput" style={{cursor: 'pointer',  display: "none"}}/>
        <div className="card-profile-image"> 
            {this.state.userAvatar 
            ? <img className="rounded-circle" src={this.state.userAvatar} alt="avatar"/> 
            : <img className="rounded-circle" src={this.state.imagePreviewUrl} alt="..." />}
        </div>

        <div className="mt-6 py-2">
          {this.state.file === null 
          ? <Button className="mt-3" color="default" type="button" size='sm' onClick={() => this.handleClick()}>
              Edit
            </Button>
          : <span>
              <Button className="mt-3" color="default" type="button" size='sm' onClick={() => this.handleSubmit()}>
                Save
              </Button>   
              <Button className="mt-3" color="danger" type="button" size='sm' onClick={() => this.handleRemove()}>
                Cancel
              </Button>
            </span>}
        </div>

        <Modal isOpen={this.state.modalEdit} toggle={this.toggleModal}>  
          <ModalBody>
            <Avatar
              width={365}
              height={295}
              onCrop={this.onCrop}
              onClose={this.onClose}
              onBeforeFileLoad={this.onBeforeFileLoad}
              src={this.state.imagePreviewUrl}
            />      
          </ModalBody>
        </Modal>
      </>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
};

export default ImageUpload;