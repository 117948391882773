import Home from "views/adminViews/AdminHome";
import AddUsers from "views/adminViews/AddUsers";
import AdminSettings from "views/adminViews/AdminSettings";
import UsersProgress from "views/adminViews/UsersProgress";

const routes = [
    {
      path: "/home",
      name: "Home",
      icon: "fas fa-home text-indigo",
      component: Home,
      layout: "/admin"
    },
    {
      path: "/add-users",
      name: "Add Users",
      icon: "ni ni-hat-3 text-indigo",
      component: AddUsers,
      layout: "/admin"
    },
    {
      path: "/users-progress",
      name: "Progress",
      icon: "ni ni-sound-wave text-indigo",
      component: UsersProgress,
      layout: "/admin"
    },
    {
      path: "/settings",
      name: "Settings",
      icon: "ni ni-settings-gear-65 text-indigo",
      component: AdminSettings,
      layout: "/admin"
    },
];

export default routes;