import React from "react";
import classnames from "classnames";
import { Redirect } from 'react-router-dom';
import { Button,Card, CardHeader, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col, Spinner } from "reactstrap";
import { LinkedIn } from 'react-linkedin-login-oauth2';

import LoginHeader from "components/Headers/LoginHeader.js";
import MainNavbar from "components/Navbars/MainNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import ErrorMessage from "../../components/Alerts/ErrorMessage";

import { connect } from 'react-redux';
import { signIn, signOut } from '../../redux/actions/authActions';
import Fire from "../../Fire";
import axios from "axios";

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errorMessage: null,
      loading: false
    };
  }

  change = (e, stateName) => {
    this.setState({ [stateName]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let credentials = {
      email: this.state.email,
      password: this.state.password
    }
    this.props.signIn(credentials)
  }

  gmailLogin = () => {
    const provider = Fire.shared.provider;
    const membership = 'starter'
    const schoolID = null;
    const institution_userID = null
    Fire.shared.auth.signInWithPopup(provider)
    .then( async (result) => {
        let user = result.user;
        await Fire.shared.gmailUserDBAction(user, membership, schoolID, institution_userID)
    })
    .catch((error) => {
        console.log(error.message)
    });
  }

  handleLinkedInSignIn = (token) => {
    Fire.shared.auth.signInWithCustomToken(token)
      .then((user) => {
        this.props.history.push('/user/home')
      })
      .catch((error) => {
        if (error.message !== null) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState({ errorMessage: null });
        }
      })
  }

  handleLinkedInSuccess = async (data) => {
    try {  
      this.setState({ loading: true })    
      const { data: linkedinID } = await axios.post('https://us-central1-pathways-f7c3a.cloudfunctions.net/linkedInAPItoken?code='+ data.code +'&state=sahir123&membership=starter&schoolid=na&institutionuserid=na')
      this.handleLinkedInSignIn(linkedinID)
      this.setState({
        errorMessage: null,
        loading: false
      });
    } catch (error) {
      this.setState({
        errorMessage: error.response.data.message
      })
    }
  }

  handleLinkedInFailure = (error) => {
    console.log(error.message)
    this.setState({
      errorMessage: error.errorMessage,
    });
  }

  render() {
    const { errorMessage, loading } = this.state;
    const { authError, profile } = this.props;
    
    if ( profile.token && profile.token.claims.student ) return <Redirect direct to="/user/home"/>
    if ( profile.token && profile.token.claims.admin ) return <Redirect direct to="/admin/home"/>
    return (
        <>
          <div className="bg-indigo main-content" ref="mainContent">
            <MainNavbar />
            <LoginHeader/>
            <Container className="mt--8 pb-5">
              <Row className="justify-content-center">
                <Col lg="5" md="7">
                  <Card className="bg-secondary border-0 mb-0">
                    <CardHeader className="bg-transparent pb-5">
                      <div className="text-muted text-center mt-2 mb-3">
                        <small>Sign in with</small>
                      </div>
                      <div className="btn-wrapper text-center">
                        <LinkedIn
                          clientId="77i4jdx1x0ho09"
                          onFailure={this.handleLinkedInFailure}
                          onSuccess={this.handleLinkedInSuccess}
                          //redirectUri="https://pathways-app.netlify.app/linkedin"
                          redirectUri="https://careerpathways.app/linkedin"
                          scope="r_liteprofile r_emailaddress"
                          state="sahir123"
                          renderElement={({ onClick, disabled }) => (
                            <Button className="btn-neutral btn-icon" color="default" onClick={onClick} disabled={disabled}>
                            {loading 
                              ? <Spinner color="primary" size="sm" className="mx-4"/> 
                              : <>
                                  <span className="btn-inner--icon">
                                    <img alt="..." src={require("assets/img/icons/common/linkedin-icon.svg")}/>
                                  </span>
                                  <span className="btn-inner--text">LinkedIn</span>
                                </>
                            }
                            </Button> 
                          )}
                        /> 
                       
                        <Button className="btn-neutral btn-icon" color="default" onClick={this.gmailLogin}>
                          <span className="btn-inner--icon mr-1">
                            <img
                              alt="..."
                              src={require("assets/img/icons/common/google.svg")}
                            />
                          </span>
                          <span className="btn-inner--text">Google{''}</span>
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      {errorMessage && <ErrorMessage message={errorMessage}/>}
                      <div className="text-center text-muted mb-4">
                        <small>Or sign in with credentials</small>
                      </div>
                      <Form role="form" onSubmit={this.handleSubmit}>
                        <FormGroup
                          className={classnames("mb-3", {
                            focused: this.state.focusedEmail
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Email"
                              type="email"
                              onFocus={() => this.setState({ focusedEmail: true })}
                              onBlur={() => this.setState({ focusedEmail: false })}
                              onChange={e => this.change(e, "email")}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup
                          className={classnames({
                            focused: this.state.focusedPassword
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Password"
                              type="password"
                              autoComplete="on"
                              onFocus={() => this.setState({ focusedPassword: true })}
                              onBlur={() => this.setState({ focusedPassword: false })}
                              onChange={e => this.change(e, "password")}
                            />
                          </InputGroup>
                        </FormGroup>
                        <div className="custom-control custom-control-alternative custom-checkbox mb-2">
                          <input
                            className="custom-control-input"
                            id=" customCheckLogin"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor=" customCheckLogin"
                          >
                            <span className="text-muted">Remember me</span>
                          </label>
                        </div>
                        {authError && <ErrorMessage message={authError.message}/> } 
                        <div className="text-center">
                          <Button className="my-4" color="info" type="submit">
                              Sign in
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Row className="mt-3">
                    <Col xs="6">
                      <span className="text-light" onClick={() => {this.props.history.push('/lock-screen')}}>
                        <small>Forgot password?</small>
                      </span>
                    </Col>
                    <Col className="text-right" xs="6">
                      <span className="text-light" onClick={() => {this.props.history.push('/register')}}>
                        <small>Create new account</small>
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
          <AuthFooter/>
        </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (credentials) => dispatch(signIn(credentials)),
    signOut: () => dispatch(signOut())
  }
}

export default connect(mapStateToProps, mapDispatchToProps )(Login);
