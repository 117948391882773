import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button, Table, Spinner } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ModuleLocked from "components/Sections/ModuleLocked";
import MatrixM4ContentModal from "components/Modals/MatrixM4ContentModal";
import matrixData from "variables/matrixData";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { submitExercise1, updateStatePaths } from "redux/actions/module5Actions";

const list = [
  {
    areaName:"Business & Finance",
    shortName:"business"
  },
  {
    areaName:"Healthcare",
    shortName:"healthcare"
  },
  {
    areaName:"Computer & Mathematics",
    shortName:"computer"
  },
  {
    areaName:"Tech Sales and Management",
    shortName:"tech"
  },
  {
    areaName:"Skill Trades",
    shortName:"trade"
  },
  {
    areaName:"Entrepreneurship",
    shortName:"entrepreneurship"
  }
]

const Exercise1 = (props) => {

    const { progress, moduleBlocked, approaches, updateStatePaths } = props;

    const [ ex1m4data, setEx1m4Data ] = useState({})
    useEffect(() => { setEx1m4Data(props.exercise1m4) }, [props.exercise1m4])

    useEffect(() => {
      updateStatePaths()
    }, [updateStatePaths])

    const [infoModal, setModal] = useState(false);
    const [modalData, setModalData] = useState({})
    const [alert, setAlert] = useState(null)

    const betterSkilled = () => {
      const skilledAreas = ex1m4data.skilledAreas || [];
      let values = list.filter(value => skilledAreas.includes(value.shortName))
      return (<Fragment>{values.map(a => a.areaName).join(', ')}</Fragment>)
    }

    const approachesSelected = () => {
      let values = []
      values = props.approaches.options.filter(o => o.checked === true) || []
      return (<Fragment><ul>{values.map((a, index) => <li key={index}>{a.approach}</li>)}</ul></Fragment>)
    }

    const handleSave = () => {
      const data = {
        userID: props.uid
      }
      if (isValidated()){
        props.submitExercise1(data)
        successAlert()
      } else {
        setAlert((
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "100px" }}
            title=""
            onConfirm={() => hideAlert()}
            confirmBtnBsStyle="default"
            confirmBtnText="Ok"
            btnSize=""
          >
            Ops you must select at least 2 different career development paths.
          </ReactBSAlert>
        ))

      }
    }

    const successAlert = () => {
      setAlert(
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "100px" }}
            title=""
            onConfirm={() => props.history.push('/user/module5/final-report')}
            confirmBtnBsStyle="success"
            confirmBtnText="Continue"
            btnSize="sm"
          >
            Submission Successful
          </ReactBSAlert>  
      )
    }

    const toggleModal = (value) => {
      let obj = matrixData.find(o => o.position === value);
      let extraInfo = approaches.options.find(i => i.position === value)
      setModal(!infoModal)
      setModalData({...obj, ...extraInfo})
    }

    const hideAlert = () => {
      setAlert(null)
    }

    const isValidated = () => {
      let valuesSelected = approaches.options.filter(o => o.checked === true)
      let count = valuesSelected.length; 
      if ( count >= 2 ) {
        return true;
      } 
      return false;
    }

    if (moduleBlocked === null) {
      return  <Col md="8" className="wizard-pills mr-auto ml-auto">
                <div className="text-center"><Spinner color="primary" size="md"/> </div> 
              </Col>
    }
    
    return (
      <>
        { alert }
        { progress < 0 || moduleBlocked === true
        ?  <Col md="8" className="wizard-pills mr-auto ml-auto">
            < ModuleLocked message={'Complete previous module to unlock.'}/>
          </Col>
        : <Col md="8" className="wizard-pills mr-auto ml-auto">
            <Card>
              <CardHeader className="text-center">
                <h3 className="mb-2"> Module 5 - Begin Your Career Path</h3>
                <h4>Ex - 1</h4>
              </CardHeader>
              <CardBody>
                <h1 className="mb-2">Matrix Approach/ Entry</h1>  
                <p className="mb-2 text-dark">
                  How to get your career path started:
                </p>
                <h5 className="text-dark"> Based on Module 4, you could pursue a career in  <span className="text-info">{betterSkilled()}</span></h5>
                <Table className="align-items-center table-flush mb-3" responsive>
                    <thead className="thead-light text-center">
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Information Technology<br/> Computer & Mathematics</th>
                        <th scope="col">Business & Finance</th>
                        <th scope="col">Tech Sales &<br/>Management</th>
                        <th scope="col">Skill Trades &<br/> Construction</th>
                        <th scope="col">Healthcare & <br/> Technical Occupations</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      <tr>
                        <th scope="row">Project / Gig-Based <br/> (Entrepreneurial Path)</th>
                        <td onClick={() => toggleModal('1.1')}>
                            <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow grow-icon">
                              <i className="ni ni-active-40"/>
                            </div>
                        </td>
                        <td onClick={() => toggleModal('1.2')}>
                          <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow grow-icon">
                            <i className="ni ni-active-40" />
                          </div>
                        </td>
                        <td>
                          <div className="icon icon-shape bg-gray text-white rounded-circle shadow">
                            <i className="ni ni-fat-delete" />
                          </div>
                        </td>
                        <td>
                          <div className="icon icon-shape bg-gray text-white rounded-circle shadow">
                            <i className="ni ni-fat-delete" />
                          </div>
                        </td>
                        <td>
                          <div className="icon icon-shape bg-gray text-white rounded-circle shadow">
                            <i className="ni ni-fat-delete" />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Apprenticeship</th>
                        <td onClick={() => toggleModal('2.1')}>
                            <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow grow-icon">
                              <i className="ni ni-active-40" />
                            </div>
                        </td>
                        <td onClick={() => toggleModal('2.2')}>
                          <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow grow-icon">
                            <i className="ni ni-active-40" />
                          </div>
                        </td>
                        <td>
                          <div className="icon icon-shape bg-gray text-white rounded-circle shadow">
                            <i className="ni ni-fat-delete" />
                          </div>
                        </td>
                        <td onClick={() => toggleModal('2.4')}>
                          <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow grow-icon">
                            <i className="ni ni-active-40" />
                          </div>
                        </td>
                        <td>
                          <div className="icon icon-shape bg-gray text-white rounded-circle shadow">
                            <i className="ni ni-fat-delete" />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>Entry-level Position <br/> (FT/ PT)</th>
                        <td onClick={() => toggleModal('3.1')}>
                          <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow grow-icon">
                            <i className="ni ni-active-40" />
                          </div>
                        </td>
                        <td onClick={() => toggleModal('3.2')}>
                          <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow grow-icon">
                            <i className="ni ni-active-40" />
                          </div>
                        </td>
                        <td onClick={() => toggleModal('3.3')}>
                          <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow grow-icon">
                            <i className="ni ni-active-40" />
                          </div>
                        </td>
                        <td onClick={() => toggleModal('3.4')}>
                          <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow grow-icon">
                            <i className="ni ni-active-40" />
                          </div>
                        </td>
                        <td onClick={() => toggleModal('3.5')}>
                          <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow grow-icon">
                            <i className="ni ni-active-40" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <p className="text-primary title-weight">Paths Selected</p>
                  <span className="text-blue">{approachesSelected()}</span>

                  <Row className="align-content-center mt-4">
                    <div className="col">
                      <p className="text-dark">Now, <span className="text-primary title-weight">set up an appointment</span> with a career coach or counselor to explore your path further and to get personalized guidance.</p>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-white text-dark rounded-circle shadow grow-icon" >
                        <a href="https://calendly.com/ahlia-lbb/pathways-coach-call">
                          <i className="ni ni-calendar-grid-58 text-blue" />
                        </a>
                      </div>
                    </Col>
                  </Row> 
                  
              </CardBody>
            </Card> 
          
            <hr/>

            <Row className="justify-content-center my-4">
              <Col md='6' xs='8'>
                <Button block color="success" size="lg" type="button" onClick={handleSave}>
                  Save
                </Button>
              </Col>
            </Row>

            <hr/>

          </Col>    
        }
        <MatrixM4ContentModal
          isOpen={infoModal}
          toggle={toggleModal} 
          data={modalData}
        />     
      </>
    )
};


const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    approaches : state.module5,
    exercise1m4 : state.firestore.data.exercise1m4 || {},
    exercise1m5 : state.firestore.data.exercise1m5 || {}
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitExercise1: (data) => dispatch(submitExercise1(data)),
    updateStatePaths: (userData) => dispatch(updateStatePaths(userData))
  }
}

export default compose( 
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module4",
          subcollections: [{ collection: "exercises", doc: "exercise1"}],
        }],
        storeAs: 'exercise1m4'
      },
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module5",
          subcollections: [{ collection: "exercises", doc: "exercise1"}],
        }],
        storeAs: 'exercise1m5'
      }]
    }),
)(Exercise1);