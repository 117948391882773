/*eslint-disable*/
import React from "react";
import { NavItem, NavLink, Nav, Container, Row, Col} from "reactstrap";

class Login extends React.Component {
  render() {
    return (
      <>
        <footer className="bg-blue py-5" id="footer-main">
          <Container>
            <Row className="justify-content-lg-between">
              <Col lg="8">
                <Row >
                  <Col md="3">
                    <Nav className="nav-footer justify-content-center flex-column mb-1">
                      <h4 className="ml-2"> Get To Know Us</h4>
                      <NavItem>
                        <NavLink
                          href="https://careerpathways.app/about"
                        >
                          About Us
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="https://littleblackbuddha.com/contact/"
                          target="_blank"
                        >
                          Contact
                        </NavLink>
                      </NavItem>
                    </Nav> 
                  </Col>
                  <Col md="3">
                    <Nav className="nav-footer flex-column justify-content-center mb-2">
                      <h4 className="ml-2">Site</h4>
                      <NavItem>
                        <NavLink
                          href="https://careerpathways.app/privacy-policy"
                          target="_blank"
                        >
                          Privacy Policy
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="https://careerpathways.app/terms-conditions"
                          target="_blank"
                        >
                          Terms & Conditions
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="https://www.freepik.com/vectors/people"
                          target="_blank"
                        >
                          Image License
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                </Row>        
              </Col>
              <Col lg="4">
                <h4 className="footer-subtitle-text" style={{textAlign: 'right'}}>Stay Connected</h4>
                <Nav className="nav-footer justify-content-end justify-content-lg-end">   
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.facebook.com/lil.blackbuddha"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-square fa-2x" />
                    </NavLink>   
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://instagram.com/littleblackbuddha?igshid=3hpm0jzdlee7"   
                      target="_blank"
                    >
                      <i className="fab fa-instagram fa-2x" />
                    </NavLink>      
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.linkedin.com/company/littleblackbuddha/"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin fa-2x" />
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            <Row className="align-items-center mt-3">
              <Col xl="12">
                <div className="text-center"> 
                  <p className="footer-copyright-text"> 
                    © {new Date().getFullYear()}{" "} 
                    <span className="text-warning font-weight-400">Little Black Buddha</span> | All Rights Reserved
                  </p> 
                </div>
              </Col>      
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default Login;
