import React from "react";
import { Route, Switch } from "react-router-dom";
import DashboardNavbar from "components/Navbars/DashboardNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import SuperAdminHome from "views/superAdminViews/SuperAdminHome";
import Module1Responses from "views/superAdminViews/modules/module1data/Module1Responses";
import Module2Responses from "views/superAdminViews/modules/module2data/Module2Responses";
import Module3Responses from "views/superAdminViews/modules/module3data/Module3Responses";
import Module4Responses from "views/superAdminViews/modules/module4data/Module4Responses";
import Module5Responses from "views/superAdminViews/modules/module5data/Module5Responses";

import superAdmin_routes from "superAdmin_routes.js";

class SuperAdmin extends React.Component {

  state = {
    sidenavOpen: true
  };
  
  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      let subpath = prop.subpath || ""
      if (prop.layout === "/lbb-admin") {
        return (
          <Route
            exact 
            path={prop.layout + prop.path + subpath}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < superAdmin_routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          superAdmin_routes[i].layout + superAdmin_routes[i].path
        ) !== -1
      ) {
        return superAdmin_routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  toggleSidenav = e => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen
    });
  };

  render() {
    
    return (
      <>
        <Sidebar
          {...this.props}
          routes={superAdmin_routes}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          logo={{
            innerLink: "/lbb-admin/home",
            imgSrc: require("assets/img/brand/logo1.png"),
            imgAlt: "Pathways Logo"
          }}
        />
        <div className="main-content" ref="mainContent" onClick={this.closeSidenav}>
          <DashboardNavbar
            {...this.props}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {this.getRoutes(superAdmin_routes)}
            <Route exact path="/lbb-admin" render={props => <SuperAdminHome {...props} />}/>
            <Route exact path="/lbb-admin/module1" render={props => <Module1Responses {...props} />}/>
            <Route exact path="/lbb-admin/module2" render={props => <Module2Responses {...props} />}/>
            <Route exact path="/lbb-admin/module3" render={props => <Module3Responses {...props} />}/>
            <Route exact path="/lbb-admin/module4" render={props => <Module4Responses {...props} />}/>
            <Route exact path="/lbb-admin/module5" render={props => <Module5Responses {...props} />}/>
          </Switch>
          <AdminFooter />
        </div>
        {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </>
    );
  }
}

export default SuperAdmin

