import React from "react";
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Button, Spinner } from "reactstrap";
import ModuleLocked from "./ModuleLocked";

const IntroModule5 = (props) => {

  if (props.moduleBlocked === null) {
    return  <Col md="8" className="wizard-pills mr-auto ml-auto">
              <div className="text-center"><Spinner color="primary" size="md"/> </div> 
            </Col>
  }

  if (props.moduleBlocked === true) {
    return  <Col md="8" className="wizard-pills mr-auto ml-auto">
              < ModuleLocked message={'Complete previous module to unlock.'}/>
            </Col>
  }

  return (
    <>
      <Col md="8" className="wizard-pills mr-auto ml-auto">
        <Card>
          <CardHeader className="text-center">
            <h2 className="mb-2"> Module 5 - Begin Your Career Path</h2> 
          </CardHeader>
          <CardBody>
            <h1 className="mb-1">You got started</h1>   
            <p className="mb-3">Last module and the beginning of your career.</p> 
            <iframe className="youtube" title="intro video to module 5" src="https://www.youtube.com/embed/H9m72TnKnc4?autoplay=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> 
          </CardBody>
          <CardFooter>
            <Row className="justify-content-end">
              <Button color="default" outline type="button" onClick={() => props.history.push('/user/module5/ex1')}>
                Continue
              </Button>
            </Row> 
          </CardFooter> 
        </Card>  
      </Col> 

    </>
  );
}

export default IntroModule5;