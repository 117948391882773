import React from 'react';
import { Row, Col, Button } from "reactstrap";
import ExamplesModal from "components/Modals/Pitch2ExamplesModal";
import UserSuperpowersModal from "components/Modals/UserSuperpowersModal";

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Q3: '',
      Q4: '', 
      Q5: '',
      Q6: '',
      Q7: '',
      Q8: '',
      examplesModal: false,
      superpowersModal: false,
    }
  }

  componentDidUpdate(prevProps) { 
    if (this.props.profile !== prevProps.profile || this.props.exercise3m4 !== prevProps.exercise3m4) {
      this.setState({
        Q3: this.props.exercise3m4.Q3,
        Q4: this.props.exercise3m4.Q4,
        Q5: this.props.exercise3m4.Q5,
        Q6: this.props.exercise3m4.Q6,
        Q7: this.props.exercise3m4.Q7,
        Q8: this.props.exercise3m4.Q8,
        Q3State : this.props.exercise3m4.Q3 && 'valid',
        Q4State : this.props.exercise3m4.Q4 && 'valid',
        Q5State : this.props.exercise3m4.Q5 && 'valid',
        Q6State : this.props.exercise3m4.Q6 && 'valid',
        Q7State : this.props.exercise3m4.Q7 && 'valid',
        Q8State : this.props.exercise3m4.Q8 && 'valid',
        blank1: this.props.aspirationalJob && this.props.aspirationalJob.label
      })
    }
  }

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  toggleModal = () => {
    this.setState({ examplesModal: !this.state.examplesModal})
  }

  toggleModal2 = () => {
    this.setState({ superpowersModal: !this.state.superpowersModal})
  }

  change = (e, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "length":
        if (this.verifyLength(e.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "valid" });
        } else {
          this.setState({ [stateName + "State"]: "invalid" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: e.target.value });
  }

  isValidated = () => {
    if ( 
      this.state.Q3State === "valid" &&
      this.state.Q4State === "valid" &&
      this.state.Q5State === "valid" &&
      this.state.Q6State === "valid" &&
      this.state.Q7State === "valid" &&
      this.state.Q8State === "valid"
    ) {
      return true;
    } else {
      if (this.state.Q3State !== "valid") {
        this.setState({ Q3State: "invalid" });
      }
      if (this.state.Q4State !== "valid") {
        this.setState({ Q4State: "invalid" });
      }
      if (this.state.Q5State !== "valid") {
        this.setState({ Q5State: "invalid" });
      }
      if (this.state.Q6State !== "valid") {
        this.setState({ Q6State: "invalid" });
      }
      if (this.state.Q7State !== "valid") {
        this.setState({ Q7State: "invalid" });
      }
      if (this.state.Q8State !== "valid") {
        this.setState({ Q8State: "invalid" });
      }
      return false;
    }
  }

  render() {
    const { Q3, Q4, Q5, Q6, Q7, Q8, blank1, Q3State, Q4State, Q5State, Q6State, Q7State, Q8State, examplesModal, superpowersModal } = this.state;
    const { profile, wizardData } = this.props;
    return (
        <>
          <Row className="justify-content-center mb-3">
            <Col md="10" xs="12">
              <p className="text-dark">My name is <span className="title-weight">{ profile && profile.displayName }</span>, 
                  I am looking for work or to transition to {blank1}. 
                  I have a background in{' '}
                  <span className="text-dark">{wizardData['2'] && wizardData['2'].Q1}</span> 
                  {' '}and I want to leverage my skills in{' '}
                  <span className="text-dark">{wizardData['2'] && wizardData['2'].Q2}</span>.
              </p>          
            </Col>
          </Row> 

          <h2>Pitch Part 2 - Story about what you do or have done</h2> 
          <p className="title-weight">“PAR” (Problem—Action—Result) formula + Superpowers</p>
          <Row className="justify-content-center">
            <Col md="10" xs="11">
              <Row>
                <p>This is where you insert the <span className="title-weight">one thing you want your audience to remember</span>. Another way to accomplish this short story telling is “PAR” (Problem—Action—Result) formula: identify a problem, name the action you took and talk about the result. 
                    Name a superpower you used in coming up with your result. This is your opportunity to highlight one of your superpowers and how you used it in real life.
                </p>
                <p>Additionally, name 2 additional superpowers that are most relevant to conversation you want to have or your top superpowers that you identified in Module 3.</p>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-end align-items-center my-2">
            <Col md="2">
              <Button outline color="info" size="sm" onClick={() => this.toggleModal()}>Examples</Button>
            </Col>
          </Row> 

          <h3 className="text-info my-2">Fill in the blanks</h3>  

          <Row className="justify-content-center">
            <Col md="10" xs="12">
              <p> As a{' '}
                <input
                  className="special-input"
                  placeholder=" enter former role"
                  type="text"
                  value={Q3 || ""}
                  onChange={e => this.change(e, "Q3", "length", 3)}
                /> {Q3State === "invalid" && <label className="warning"></label>}
                {' '}we had trouble with
                <input
                  className="special-input"
                  placeholder="enter a problem you resolved"
                  type="text"
                  value={Q4 || ""}
                  onChange={e => this.change(e, "Q4", "length", 3)}
                />{Q4State === "invalid" && <label className="warning"></label>},
                {' '}I used my{' '} 
                <input
                  className="special-input-2"
                  placeholder="mention 1st superpower"
                  type="text"
                  value={Q5 || ""}
                  onChange={e => this.change(e, "Q5", "length", 3)}
                />{Q5State === "invalid" && <label className="warning"></label>}{' '}<label className="information" onClick={() => this.toggleModal2()}></label> (superpower) to{' '}
                <input
                  className="special-input-2"
                  placeholder="how you use your superpower"
                  type="text"
                  value={Q6 || ""}
                  onChange={e => this.change(e, "Q6", "length", 3)}
                />{Q6State === "invalid" && <label className="warning"></label>}{' '}and that is how I solved the problem.
                Additionally, I considered myself good at{' '}
                <input
                  className="special-input"
                  placeholder="mention superpower"
                  type="text"
                  value={Q7 || ""}
                  onChange={e => this.change(e, "Q7", "length", 3)}
                />{Q7State === "invalid" && <label className="warning"></label>}{' '}and{' '}
                <input
                  className="special-input"
                  placeholder="mention superpower"
                  type="text"
                  value={Q8 || ""}
                  onChange={e => this.change(e, "Q8", "length", 3)}
                />{Q8State === "invalid" && <label className="warning"></label>}.
              </p>
            </Col>
          </Row>

          <ExamplesModal
            isOpen={examplesModal}
            toggle={this.toggleModal} 
          />
          <UserSuperpowersModal
            isOpen={superpowersModal}
            toggle={this.toggleModal2} 
          />  
        </>
    )
  }
}

export default Step3;
