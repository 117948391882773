import React from "react";
import { Container, Row, Col } from "reactstrap";

class AuthHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-indigo py-7 py-lg-8 pt-lg-9">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col className="px-5" lg="6" md="8" xl="5">
                    <h1 className="text-white">Welcome Back!</h1>
                    <p className="text-lead text-white">Take the journey.<br/> Discover Your Career Path & Transition </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default AuthHeader;
