import React, { useRef, useEffect, useState } from 'react';
import ReactToPrint from "react-to-print";

import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button, ButtonGroup, Card, CardHeader, Row, Col, Progress, Media, UncontrolledTooltip } from "reactstrap";

import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';


const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: false,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        users.
      </label>
    </div>
  )
})
const { SearchBar } = Search;

const SuperAdminUserProgressTable = (props) => {

  const componentRef = useRef();
  const [data, setData] = useState([]);
  useEffect(() => { setData(props.allUsers)}, [props.allUsers])

  const final_array = data && data.map((i) => {
    let data = props.schoolsData.find(school => school.id === i.schoolID);
    return { ...data, ...i };
  });

  const displayData = final_array.map((i, index) => ({ 
    email: i.email, 
    name: i.displayName, 
    avatar: {
      photoURL: i.photoURL, 
      firstnameInit: i.firstname && i.firstname.charAt(0).toUpperCase(), 
      lastnameInit: i.lastname && i.lastname.charAt(0).toUpperCase()
    },
    progress: i.op, 
    currentLevel: i.currentLevel,
    institution: i.school || 'N/A',
    joined: moment(i.joined.toDate()).format('MMM YYYY') || ''
  }));

  const avatarField = (key) => {
    return  <Media className="align-items-center">
              <span className="avatar avatar-sm rounded-circle bg-cyan mr-2">
                {key.photoURL 
                ? <img
                    alt="avatar"
                    src={key.photoURL}
                  /> 
                : <h5 style={{ marginTop:'5px', color:'#fff' }}>
                    {key.firstnameInit + key.lastnameInit}
                  </h5>
                }
              </span>
            </Media>
  }
  
  const progressColor = (value) => {
    if (value >= 70) {
      return "success"
    } else if ( value >= 25 && value < 70 ){
      return "warning"
    } else {
      return "danger"
    }
  }

  const progressField = (key) => {
    const color = progressColor(key)
    return  <div className="d-flex align-items-center">
              <span className="completion mr-2">{key} %</span>
              <div>
                <Progress max="100" value={key} color={color} />
              </div>
            </div>
  }

  return (
      <Card>
        <CardHeader>
          <h3 className="mb-0">Users</h3>
          <p className="text-sm mb-0">
            Total Pathways Explorers: {displayData && displayData.length}
          </p>
        </CardHeader>
        <ToolkitProvider
          data={displayData || []}
          keyField="email"
          columns={[
            {
              dataField: "avatar",
              text: "",
              sort: false,
              formatter: avatarField,
            },
            {
              dataField: "name",
              text: "Name",
              sort: true,
            },
            {
              dataField: "email",
              text: "Email",
              sort: false
            },
            {
              dataField: "currentLevel",
              text: "Current Level",
              sort: false,
            },
            {
              dataField: "institution",
              text: "Institution",
              sort: false,
            },
            {
              dataField: "progress",
              text: "Overall Progress",
              sort: true,
              formatter: progressField,
            },
            {
              dataField: "joined",
              text: "Joined",
              sort: false,
            },
          ]}
          search
        >
          {props => (
            <div className="py-4 table-responsive">
              <div
                id="datatable-basic_filter"
                className="dataTables_filter px-4 pb-1"
              >
                <label>
                  Search:
                  <SearchBar
                    className="form-control-sm"
                    placeholder=""
                    {...props.searchProps}
                  />
                </label>
              </div>
              <BootstrapTable
                ref={componentRef}
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                id="react-bs-table"
              />
            </div>
          )}
        </ToolkitProvider>
        <Col>
          <Row className="justify-content-end"> 
            <ButtonGroup>
              <ReactToPrint
                trigger={() => (
                  <Button
                    color="default"
                    size="sm"
                    className="buttons-copy buttons-html5 mx-2 my-2"
                    id="print-tooltip"
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </ButtonGroup>
            <UncontrolledTooltip placement="top" target="print-tooltip">
              Print or save to PDF.
            </UncontrolledTooltip>    
          </Row>
        </Col>     
      </Card>
    )
}

const mapStateToProps = (state) => {
  return {
      profile: state.firebase.profile,
      allUsers: state.firestore.ordered.allUsers || [],
  }
}
        
export default compose( 
connect(mapStateToProps, null),
firestoreConnect((props)=> {
  if (props.profile.type !== 'superAdmin') return []
  return [
    {
      collection:'users', 
      where:[['type','==','student']],
      storeAs: 'allUsers'
    }]
  }),
)(SuperAdminUserProgressTable);