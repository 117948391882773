import React from "react";
import ReactWizard from "react-bootstrap-wizard";
import { Spinner } from "reactstrap";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Module1BadgeModal from "components/Modals/Module1BadgeModal";
import UpgradeMembershipModal from 'components/Modals/UpgradeMembershipModal';
import ModuleLocked from "components/Sections/ModuleLocked";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { submitExercise3 } from "redux/actions/module1Actions";
  
const steps = [
  { stepName: "1", component: Step1 },
  { stepName: "2", component: Step2 },
];

class Exercise3 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      steps: steps,
      badgeModal: false,
    }
  }

  componentDidMount(){
    this.setState({
      steps: [
        { stepName: "1", component: Step1 },
        { stepName: "2", component: Step2, stepProps: this.props.exercise3 },
      ]
    })
  }

  componentDidUpdate(prevProps) { 
    if (this.props.exercise3 !== prevProps.exercise3 || this.props.profile !== prevProps.profile) {
      this.setState({
        steps: [
          { stepName: "1", component: Step1 },
          { stepName: "2", component: Step2, stepProps: this.props.exercise3 },
        ]
      })
    }
  }

  finishButtonClick = (allStates) => {
    const res = {}
    let result = ''
    const mbtiTest = {
      extraverted: parseInt(allStates["2"].extraverted),
      introverted: parseInt(allStates["2"].introverted),
      intuitive: parseInt(allStates["2"].intuitive),
      observant: parseInt(allStates["2"].observant),
      thinking: parseInt(allStates["2"].thinking),
      feeling: parseInt(allStates["2"].feeling),
      judging: parseInt(allStates["2"].judging),
      prospecting: parseInt(allStates["2"].prospecting)
    }
    res[1] = mbtiTest.extraverted > mbtiTest.introverted ? 'E' : 'I';
    res[2] = mbtiTest.intuitive > mbtiTest.observant ? 'N' : 'S';
    res[3] = mbtiTest.thinking > mbtiTest.feeling ? 'T' : 'F';
    res[4] = mbtiTest.judging > mbtiTest.prospecting ? 'J' : 'P';
    
    const data = {
      userID: this.props.uid,
      mbtiTest: mbtiTest,
      personality : result.concat(...Object.values(res))
    }
    this.props.submitExercise3(data)
    this.toggleModal()
  }

  toggleModal = () => {
    this.setState({ badgeModal: !this.state.badgeModal })
  }

  render() {  
    const { steps, badgeModal } = this.state;
    const { profile, history } = this.props;
    const isIndividualUser = profile.institution_userID === null || profile.institution_userID === 'na' ? true : false;
    const isStarter = profile.token && profile.token.claims.stripeRole !== 'premium' ? true : false

    if (this.props.profile.isEmpty) return <div className="text-center"><Spinner color="primary" size="md"/></div>
    return (
        <>
          { this.props.progress < 70
          ? <ModuleLocked message={'Please complete previous exercise.'}/> 
          : <>
              <ReactWizard
                steps={steps}
                navSteps
                validate
                progressbar
                title="Module 1 - Right Now"
                description="Ex - 3"
                headerTextCenter
                finishButtonClasses="btn-default"
                finishButtonText="Finish"
                nextButtonClasses="btn-sm-default"
                previousButtonClasses="btn-danger"
                finishButtonClick={this.finishButtonClick}
              />
              <Module1BadgeModal
                isOpen={badgeModal}
                toggle={this.toggleModal}
                history={this.props.history}
                title={'Module 1 Achieved'}
                message={'You completed this module, Module 2 is now unlocked'}
                next={'/user/module2'}
              /> 
            </> 
            }
          <UpgradeMembershipModal
            isOpen={isIndividualUser && isStarter}
            history={history}
            message={'Seems like you must upgrade your account before continuing. Please go to settings and subscribe.'}
            redirectTo={'/user/settings'}
          />
        </>
    )
  }

}  

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    profile: state.firebase.profile,
    module1 : state.firestore.data.module1 || {},
    exercise3 : state.firestore.data.exercise3 || {}
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitExercise3: (data) => dispatch(submitExercise3(data))
  }
}

export default compose( 
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module1"}],
        storeAs: 'module1'
      },
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module1",
          subcollections: [{ collection: "exercises", doc: "exercise3"}],
        }],
        storeAs: 'exercise3'
      }
    ]
  }),
)(Exercise3);