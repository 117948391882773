import React, { useState, useEffect, Fragment } from "react";
import { Row, Col } from "reactstrap";
import ModuleLocked from "components/Sections/ModuleLocked";
import skillsDefinitions from "variables/skillsDefinitions";

import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';

const definitions = skillsDefinitions;
const filterResults = (userTopSkills) => {
  let data = definitions.filter( i => userTopSkills && userTopSkills.includes( i.skill ) );
  return data;
}

const M3Ex4Summary = (props) => {

    const [ results, setResults ] = useState([]);
    const { progress, moduleBlocked } = props;

    useEffect(() => { 
      setResults(filterResults(props.exercise4m3.topSkills)) 
    }, [props.exercise4m3])

    const userSkills = results && results.map((i, index) => {
      return (
        <Fragment key={index}>
          <tr className="d-flex list-skills-table">
            <td className="col-4 col-md-4">{i.skill}</td>
            <td className="col-8 col-md-8">{i.definition}</td>
          </tr>
        </Fragment>
        )
    });

    return (
      <>
      { progress < 75 || moduleBlocked === true
      ? <ModuleLocked message={'Exercise Locked'}/> 
      : <>
          <Row className="justify-content-center">
            <Col md='10' xs="12">
              <h4 className="title-weight">Transferable Skills You Rated Highest</h4>
              <div className="table-responsive">
                <table className="summary-skills-table">
                  <thead>
                    <tr className="d-flex list-head-skills-table">
                      <th className="col-4 col-md-4">Skill</th>
                      <th className="col-8 col-md-8">Description</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {userSkills}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </>
      }
      </> 
    )
}


const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    exercise4m3 : state.firestore.data.exercise4m3 || {}
  }
};

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props) => {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module3",
          subcollections: [{ collection: "exercises", doc: "exercise4"}],
        }],
        storeAs: 'exercise4m3'
      }]
    }),
)(M3Ex4Summary);