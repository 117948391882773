import React, { useRef } from 'react';
import ReactToPrint from "react-to-print";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button, ButtonGroup, Card, Row, Col, UncontrolledTooltip } from "reactstrap";

import { connect } from "react-redux";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: false,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        users.
      </label>
    </div>
  )
})
const { SearchBar } = Search;

const Ex2Table = (props) => {

  const componentRef = useRef();

  const displayData = props.allResponses?.ex2?.map((i, index) => ({ 
    key: index,
    uuid: i.uuid,
    q1: i.Q1,
    q2a: i.Q2A?.value,
    q2b: i.Q2B,
    q3: i.Q3,
    q4: i.Q4,
    q5: i.Q5,
    q6: i.Q6,
    q7: i.Q7,
    q8: i.Q8,
    q9: i.Q9,
  }));
  
  return (
      <Card>
        <ToolkitProvider
          data={displayData || []}
          keyField="key"
          columns={[
            {
              dataField: "uuid",
              text: "User id",
              sort: false,
            },
            {
              dataField: "q1",
              text: "What do you do in your spare time",
              sort: false,
              style: {inlineSize: "1000px", whiteSpace: "pre-wrap",overflowWrap: "break-word"}
            },
            {
              dataField: "q2a",
              text: "Question 2 - A ",
              sort: false,
              style: { whiteSpace: "pre-wrap",overflowWrap: "break-word"}
            },
            {
              dataField: "q2b",
              text: "Question 2 - B",
              sort: false,
              style: { minWidth: "300px", whiteSpace: "pre-wrap",overflowWrap: "break-word"}
            },
            {
              dataField: "q3",
              text: "Do you have hobbies and interests outside your current job?",
              sort: false,
              style: { maxWidth: "350px", whiteSpace: "pre-wrap",overflowWrap: "break-word"}
            },
            {
              dataField: "q4",
              text: "What positive qualities do you possess?",
              sort: false,
              style: {inlineSize: "400px", whiteSpace: "pre-wrap",overflowWrap: "break-word"}
            },
            {
              dataField: "q5",
              text: "What life experiences set you apart?",
              sort: false,
              style: {inlineSize: "400px", whiteSpace: "pre-wrap",overflowWrap: "break-word"}
            },
            {
              dataField: "q6",
              text: "How is your personal story unique?",
              sort: false,
              style: {inlineSize: "400px", whiteSpace: "pre-wrap",overflowWrap: "break-word"}
            },
            {
              dataField: "q7",
              text: "What have you always been good at?",
              sort: false,
              style: {inlineSize: "400px", whiteSpace: "pre-wrap",overflowWrap: "break-word"}
            },
            {
              dataField: "q8",
              text: "What made you stand out as a child?",
              sort: false,
              style: {inlineSize: "400px", whiteSpace: "pre-wrap",overflowWrap: "break-word"}
            },
            {
              dataField: "q9",
              text: "What makes you feel strong?",
              sort: false,
              style: {inlineSize: "400px", whiteSpace: "pre-wrap",overflowWrap: "break-word"}
            },
            
          ]}
          search
        >
          {props => (
            <div className="py-4 table-responsive">
              <div
                id="datatable-basic_filter"
                className="dataTables_filter px-4 pb-1"
              >
                <label>
                  Search:
                  <SearchBar
                    className="form-control-sm"
                    placeholder=""
                    {...props.searchProps}
                  />
                </label>
              </div>
              <BootstrapTable
                ref={componentRef}
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                id="react-bs-table"
              />
            </div>
          )}
        </ToolkitProvider>
        <Col>
          <Row className="justify-content-end"> 
            <ButtonGroup>
              <ReactToPrint
                trigger={() => (
                  <Button
                    color="default"
                    size="sm"
                    className="buttons-copy buttons-html5 mx-2 my-2"
                    id="print-tooltip"
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </ButtonGroup>
            <UncontrolledTooltip placement="top" target="print-tooltip">
              Print or save to PDF.
            </UncontrolledTooltip>    
          </Row>
        </Col>     
      </Card>
    )
}

const mapStateToProps = (state) => {
  return {
    allResponses: state.superAdminReducer.allResponses
  };
};
        
export default connect(mapStateToProps, null)(Ex2Table);