import React from "react";
import { Row, Col } from "reactstrap";
import Pagination from "react-js-pagination";

const arrayOrganicer = (a) =>{
  let output = [];
  for(let i = 0; i < a.length; i++) {
    for(let k = i + 1; k < a.length; k++) {
      let pair = { "values": [{ "value": a[i] }, { "value": a[k] }] }
      output.push(pair);
    }
  }
  output.forEach((i,index) => {
    i.item = `a${index+1}`
  })
  return output
}

class Step4 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      currentPage: 1,
      validationState: "",
    };
  };

  componentDidMount(){
    this.setState({
      list: this.props.userValues && arrayOrganicer(this.props.userValues),
      ...this.props.comparisons
    })
  }
 
  componentDidUpdate(prevProps) {
    if (this.props.userValues !== prevProps.userValues) {    
      this.setState({
        list: arrayOrganicer(this.props.userValues),
        ...this.props.comparisons
      })
    }
  }

  paginate = (pageNumber) => {
    this.setState({ currentPage: pageNumber })
  }

  isValidated = () => {
    const answers = [...Array(45)].map((_,i) => 'a'+(i+1));
    const hasAllAnswers = answers.every(item => this.state.hasOwnProperty(item));
    if (hasAllAnswers) {
      this.setState({validationState: "valid"})
      return true;
    } 
    this.setState({validationState: "invalid"})
    return false;
  }

  render() {
    const { list, currentPage } = this.state;
    
    const currentComparison = list && list.slice(currentPage - 1, currentPage);
    const valuesList = currentComparison && currentComparison.map((i, index) => {
      return (
          <Row className="justify-content-center" key={index}>  
              <Col md='4'>
                <label className="labl">
                  <input 
                    type="radio" 
                    id={`a${index+1}option1`} 
                    checked={this.state[i.item] === i.values[0].value}
                    onChange={() => {this.setState({ [i.item]: i.values[0].value, currentPage: currentPage+ 1 })} }/>
                  <div>{i.values[0].value}</div>
                </label>         
              </Col>
              <Col md='4'>
                <label className="labl">
                  <input 
                    type="radio" 
                    id={`a${index+1}option2`} 
                    checked={this.state[i.item] === i.values[1].value}
                    onChange={() => {this.setState({ [i.item]: i.values[1].value, currentPage: currentPage+ 1 })} }/>
                  <div> {i.values[1].value}</div>
                </label>            
              </Col>   
            </Row>
      )
    })
    return (
      <>
        <h2 >Your Core Values</h2>
        <p>Compare all the values selected against each other. Select the one you consider more important.</p>
        <p>Continue to compare all <span className="title-weight"> 45 pairs</span>.</p>

        {valuesList}
        <Row className="justify-content-center mt-4">
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            hideFirstLastPages
            //hideNavigation
            pageRangeDisplayed={1}
            activePage={currentPage}
            itemsCountPerPage={1}
            totalItemsCount={(list && list.length) || 0}
            onChange={this.paginate}
          />
        </Row> 
        {this.state.validationState === "invalid" && <label className="form-control-label text-primary warning">Ops! You must complete the 45 comparisons</label>}   
      </>
    );
  }
}

export default Step4;

