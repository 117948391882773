import React from "react";
import { Col } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ModuleLocked from "components/Sections/ModuleLocked";
import ModalBadge from 'components/Modals/Module1BadgeModal';
import ReactWizard from "react-bootstrap-wizard";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { submitExercise3 } from "redux/actions/module4Actions";

const steps = [
  { stepName: "1", component: Step1 },
  { stepName: "2", component: Step2 },
  { stepName: "3", component: Step3 },
  { stepName: "4", component: Step4 },
  { stepName: "5", component: Step5 },
];

class Exercise3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: steps,
      alert: null,
      badgeModal: false
    };
  };

  componentDidMount() {
    const exerciseProps = {
      profile:this.props.profile,
      exercise3m4: this.props.exercise3m4, 
      aspirationalJob: this.props.exercise1m1.Q17
    }
    this.setState({
      steps: [
        { stepName: "1", component: Step1, stepProps: exerciseProps },
        { stepName: "2", component: Step2, stepProps: exerciseProps },
        { stepName: "3", component: Step3, stepProps: exerciseProps },
        { stepName: "4", component: Step4, stepProps: exerciseProps },
        { stepName: "5", component: Step5, stepProps: exerciseProps }
      ]
    })
  }

  componentDidUpdate(prevProps) { 
    if (this.props.exercise3m4 !== prevProps.exercise3m4 || this.props.profile !== prevProps.profile) {
      const exerciseProps = {
        profile: this.props.profile,
        exercise3m4: this.props.exercise3m4, 
        aspirationalJob: this.props.exercise1m1.Q17
      }
      this.setState({
        steps: [
          { stepName: "1", component: Step1, stepProps: exerciseProps },
          { stepName: "2", component: Step2, stepProps: exerciseProps },
          { stepName: "3", component: Step3, stepProps: exerciseProps },
          { stepName: "4", component: Step4, stepProps: exerciseProps },
          { stepName: "5", component: Step5, stepProps: exerciseProps }
        ]
      })
    }
  }

  toggleModal = () => {
    this.setState({ 
      badgeModal: !this.state.badgeModal,
      alert: null
    })
  }

  getPitchOutput = (allStates) => {
    let name = this.props.profile.displayName;
    let aspirationalJob = this.props.exercise1m1.Q17.value;
    const userData = {
      Q1: allStates["2"].Q1,
      Q2: allStates["2"].Q2,
      Q3: allStates["3"].Q3,
      Q4: allStates["3"].Q4,
      Q5: allStates["3"].Q5,
      Q6: allStates["3"].Q6,
      Q7: allStates["3"].Q7,
      Q8: allStates["3"].Q8,
      Q9: allStates["4"].Q9,
    }
    const pitch = `My name is ${name}, I am looking for work or to transition to ${aspirationalJob}. I have a background in ${userData.Q1} and I want to leverage my skills in ${userData.Q2}.\n 
      As a ${userData.Q3} we had trouble with ${userData.Q4}, I used my ${userData.Q5} to ${userData.Q6} and that is how I solved the problem. Additionally, I considered myself good at ${userData.Q7} and ${userData.Q8}.\n
      ${userData.Q9.value}`

    return pitch
  }

  finishButtonClick = (allStates) => {
    const data = {
      userID: this.props.uid,
      Q1: allStates["2"].Q1,
      Q2: allStates["2"].Q2,
      Q3: allStates["3"].Q3,
      Q4: allStates["3"].Q4,
      Q5: allStates["3"].Q5,
      Q6: allStates["3"].Q6,
      Q7: allStates["3"].Q7,
      Q8: allStates["3"].Q8,
      Q9: allStates["4"].Q9,
      pitchOutput : this.getPitchOutput(allStates)
    }
    this.props.submitExercise3(data)
    this.setState({
        alert: (
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "100px" }}
            title="Great!"
            onConfirm={() => this.toggleModal()}
            confirmBtnBsStyle="success"
            confirmBtnText="Continue"
            btnSize=""
          >
            Submission successful, you can continue now. 
          </ReactBSAlert>
        )
    });
  }

  render() {
    const { alert, steps, badgeModal } = this.state;
    const { progress, moduleBlocked } = this.props;
    return (
      <>
       <Col md="8" className="wizard-pills mr-auto ml-auto"> 
        {alert}
          { progress < 66 || moduleBlocked === true
          ? <Col md="8" className="mr-auto ml-auto"><ModuleLocked message={'Please complete previous exercise.'}/> </Col>
          : 
            <>   
              <ReactWizard
                steps={steps}
                navSteps
                validate
                progressbar
                title="Module 4 - All About You"
                description="Ex - 3"
                headerTextCenter
                finishButtonClasses="btn-default"
                nextButtonClasses="btn-sm-default"
                previousButtonClasses="btn-danger"
                finishButtonClick={this.finishButtonClick}
              /> 
              <ModalBadge
                isOpen={badgeModal}
                toggle={this.toggleModal}
                history={this.props.history}
                title={'Module 4 Completed'}
                message={'You earned another red badge.\n Almost there.'}
                next={'/user/module5'}
              />      
            </>            
          }
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    profile: state.firebase.profile,
    module4 : state.firestore.data.module4 || {},
    exercise3m4 : state.firestore.data.exercise3m4 || {},
    exercise1m1 : state.firestore.data.exercise1m1 || {}
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitExercise3: (data) => dispatch(submitExercise3(data))
  }
}

export default compose( 
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module4"}],
        storeAs: 'module4'
      },
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module1",
          subcollections: [{ collection: "exercises", doc: "exercise1"}],
        }],
        storeAs: 'exercise1m1'
      },
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module4",
          subcollections: [{ collection: "exercises", doc: "exercise3"}],
        }],
        storeAs: 'exercise3m4'
      }
    ]
  }),
)(Exercise3);
