import React, { useState, useEffect } from "react";
import { Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ModuleLocked from "components/Sections/ModuleLocked";

import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';

const M4Ex3Summary = (props) => {
    const [ data, setData ] = useState({})
    const [ copied, setCopied ] = useState(false);
    const [ contentCopied, setContentCopied ] = useState('')
    const { progress, moduleBlocked } = props;

    useEffect(() => { 
      setData(props.exercise3m4);
      setContentCopied(props.exercise3m4.pitchOutput) 
    }, [props.exercise3m4])

    const onCopy = () => {
      setCopied(true)
    }

    const userPitch = data.pitchOutput && data.pitchOutput.split('\n').map((i, index) => {
      return(
        <h5 className="text-dark text-start" key={index}>{i}</h5>)
    })

    return (
      <>
      { progress < 66 || moduleBlocked === true
      ? <ModuleLocked message={'Exercise Locked'}/> 
      : <div className="bg-white p-2">
          <Row className="justify-content-center">
            <Col md='8' xs='10' className='mt-2'> 
              <Row className="justify-content-between align-items-center">
                <h4 className="title-weight">Your Pitch</h4>  
                <CopyToClipboard onCopy={onCopy} text={contentCopied}> 
                  <Button size="sm" id="tooltipEx3m4" type="button">{copied ? 'Copied' : 'Copy'}</Button>
                </CopyToClipboard>
              </Row>
            </Col>
          </Row>

          <UncontrolledTooltip delay={0} trigger="hover focus" target="tooltipEx3m4">
            Copy to clipboard
          </UncontrolledTooltip>

          <Row className="justify-content-center">
            <Col md='8' xs='12' className='mt-2'> 
              {userPitch}
            </Col>
          </Row>
        </div>
      }
      </>
    )
}


const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    exercise3m4 : state.firestore.data.exercise3m4 || {}
  }
};

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module4",
          subcollections: [{ collection: "exercises", doc: "exercise3"}],
        }],
        storeAs: 'exercise3m4'
      }]
    }),
)(M4Ex3Summary);