import React from "react";
import { Route, Switch } from "react-router-dom";
import { Col, Row, Container} from "reactstrap";

import { ModuleProgressBar, IntroModule1, Module1Map } from "components/Sections";
import ModuleHeader from "../../../components/Headers/ModuleHeader";
import Exercise1 from "./exercise1/Exercise1";
import Exercise2 from "./exercise2/Exercise2";
import Exercise2B from "./exercise2/Exercise2B";
import Exercise2C from "./exercise2/Exercise2C";
import Exercise3 from "./exercise3/Exercise3";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

class Module1 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      badgeModal: false
    }
  }

  componentDidUpdate(prevProps){
    if (this.props.module1 !== prevProps.module1) {
      this.setState({ 
        progress: this.props.module1.progress,
      })
    }
  }

  render() {
    const { progress } = this.state;
    const { history, module1, module2 } = this.props;
    return (
      <>
       <ModuleHeader color={'module1'}/>
       <Container className="bg-mobile-1 mt-7" fluid>
          <Row>
            <Col md="4" className="text-center d-none d-lg-block">
              <ModuleProgressBar value={this.props.module1.progress || 0}/>
              <Module1Map history={history} progress={module1.progress} nextModuleBlocked={module2.blocked}/> 
            </Col>

            { this.props.match.path === "/user/module1" && <IntroModule1 history={history}/> }
            <Col md="8" className="wizard-pills mr-auto ml-auto">
              <Switch>
                <Route exact path="/user/module1/ex1" render={props => <Exercise1 {...props} history={history}/>}/>
                <Route exact path="/user/module1/ex2" render={props => <Exercise2 {...props} history={history} progress={progress}/>}/>
                <Route exact path="/user/module1/ex2B" render={props => <Exercise2B {...props} history={history} progress={progress}/>}/>
                <Route exact path="/user/module1/ex2C" render={props => <Exercise2C {...props} history={history} progress={progress}/>}/> 
                <Route exact path="/user/module1/ex3" render={props => <Exercise3 {...props} history={history} progress={progress}/>}/>  
              </Switch>        
            </Col>
          </Row>                                        
        </Container>
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    module1 : state.firestore.data.module1 || {},
    module2 : state.firestore.data.module2 || {}
  }
};

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module1"}],
        storeAs: 'module1'
      },
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module2"}],
        storeAs: 'module2'
      }]
    }),
)(Module1);
