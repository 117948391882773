import React from "react";
import { FormGroup, Row, Col } from "reactstrap";
import Select from "react-select";

class Step4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Q15: '',
      //Q16: '',
      //Q17: '',
      race: "",
      gender: "",
      disability: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.race !== prevProps.race || this.props.gender !== prevProps.gender) {
      this.setState({
        race: { label: this.props.race, value: this.props.race },
        gender: { label: this.props.gender, value: this.props.gender },
        disability: { label: this.props.disability, value: this.props.disability }
      });
    }
  }

  selectValue = (stateName, value) => {
    this.setState({ [stateName]: value });
    this.setState({ [stateName + "State"]: "valid" });
  };

  render() {
    const { race, gender, disability } = this.state;

    return (
      <>
        <Row>
          <Col md="12">
            <label className="form-control-label">
              * EEOC Form, completion of the questions below is voluntary and
              will not affect your results.
            </label>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label">
                What is your race?
              </label>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                value={race}
                required
                onChange={(value) => this.selectValue("race", value)}
                options={[
                  { label: "White", value: "White" },
                  {
                    label: "Black or African American",
                    value: "Black or African American",
                  },
                  { label: "Hispanic or Latino", value: "Hispanic or Latino" },
                  {
                    label: "American Indian or Alaska Native",
                    value: "American Indian or Alaska Native",
                  },
                  { label: "Asian", value: "Asian" },
                  {
                    label: "Native Hawaiian or Other Pacific Islander",
                    value: "Native Hawaiian or Other Pacific Islander",
                  },
                  { label: "No Specified", value: "No Specified" },
                ]}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label">
                What is your gender?
              </label>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                value={gender}
                required
                onChange={(value) => this.selectValue("gender", value)}
                options={[
                  { label: "male", value: "male" },
                  { label: "female", value: "female" },
                  { label: "nonbinary", value: "nonbinary"}
                ]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label">
                Do you have a disability?
              </label>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                value={disability}
                required
                onChange={(value) => this.selectValue("disability", value)}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]}
              />
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default Step4;
