import React, {useState, useEffect} from "react";
import { Row, Col, ListGroup, ListGroupItem, } from "reactstrap";
import ModuleLocked from "components/Sections/ModuleLocked"

import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';


const M3Ex1Summary = (props) => {
    const [ answers, setAnswers ] = useState({})

    useEffect(() => { setAnswers(props.exercise1m3) }, [props.exercise1m3])
    const { progress, moduleBlocked } = props;

    const renderObject = (answerObj, name) => {
      return Object.entries(answerObj).map(([key, value], i) => {
        if (key === name) {
          return <Col key={i} md="8" xs="6">{value.value}</Col>
        }
        return null
      })
    }

    return (
      <>
      { progress < 0 || moduleBlocked === true
      ? <ModuleLocked message={'Exercise Locked'}/> 
      : <>
          <Row className="justify-content-center">
            <Col md='10' xs='12' className='mt-2'>
              <h4 className="title-weight">Your Super Powers</h4>
              <ListGroup className="thead-light">
                <ListGroupItem className="list-head">
                  <Row>
                    <Col md="4" xs="6">Five Clues</Col>
                    <Col md="8" xs="6">Response</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="list-results">
                  <Row>
                    <Col md="4" xs="6"><p>1. Yearning </p></Col>
                    <Col md="8" xs="6">{answers.Q1 && answers.Q1.value}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="4" xs="6"><p>2. Rapid Learning</p></Col>
                    <Col md="8" xs="6">{answers.Q2 && answers.Q2.value}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="4" xs="6"><p>3. Flow</p></Col>
                    <Col md="8" xs="6">{answers.Q3 && answers.Q3.value}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="4" xs="6"><p>4. Glimpses of Excellence</p></Col>
                    <Col md="8" xs="6">{answers.Q4 && answers.Q4.value}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="4" xs="6"><p>5. Satisfaction</p></Col>
                    <Col md="8" xs="6">{answers.Q5 && answers.Q5.value}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="list-head">
                  <Row>
                    <Col md="6">What the people around you know</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="4" xs="6"><p>Person 1</p></Col>
                    {renderObject(answers, 'Q6')}
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="4" xs="6"><p>Person 2</p></Col>
                    {renderObject(answers, 'Q7')}
                  </Row>
                </ListGroupItem>
              </ListGroup>        
            </Col>
          </Row>
        </>
      }
      </>
    )
}


const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    exercise1m3 : state.firestore.data.exercise1m3 || {}
  }
};

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module3",
          subcollections: [{ collection: "exercises", doc: "exercise1"}],
        }],
        storeAs: 'exercise1m3'
      }]
    }),
)(M3Ex1Summary);