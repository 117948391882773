import React, { useRef, useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Spinner, UncontrolledTooltip  } from "reactstrap";
import ReactToPrint from 'react-to-print';
import Confetti from 'react-confetti';
//import { PDFViewer } from '@react-pdf/renderer';
import ModuleLocked from "components/Sections/ModuleLocked";
import { M1Ex1Summary, M1Ex2Summary, M1Ex3Summary, M2Ex1Summary, M2Ex2Summary, M2Ex3Summary, M3Ex1Summary, M3Ex2Summary, M3Ex3Summary, M3Ex4Summary, M4Ex1Summary, M4Ex2Summary, M4Ex3Summary, M5Ex1Summary } from "components/Tables";

import { connect } from 'react-redux';

const getWidth = () => {
  const width = window.screen.width
  if (width >= 1800) {
    return 1080
  } else if ( width <= 1280 ){
    return 360
  } else {
    return 780
  }
}

const getHeight = () => {
  const height = window.screen.width >= 1280 ? 620 : 400
  return height
}

const Final = (props) => {
    const [recycleConfeti, setRecycleConfeti] = useState(true)
    const { progress, moduleBlocked, profile } = props;
    const componentRef = useRef();

    useEffect(() => {
      const timer = setTimeout(() => setRecycleConfeti(false), 6000);
      return () => clearTimeout(timer);
    }, []);

    const CardContainer = () => (
                          <Col md="10" xs="12" className="mr-auto ml-auto">
                            <Card className="bg-gradient-blue">
                              <CardBody>
                                <Row>
                                  <div className="col">
                                    <CardTitle className="text-uppercase text-muted mb-0 text-white">
                                      FINAL REPORT
                                    </CardTitle>
                                    <span className="text-light mb-0">
                                      It was a great pleasure discovering a new path together.
                                    </span>
                                  </div>
                                  <Col className="col-auto">
                                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow grow-icon" id="tooltipM5">
                                      <i className="ni ni-collection" />
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                              <UncontrolledTooltip placement="top" target="tooltipM5">
                                Save PDF Report
                              </UncontrolledTooltip>
                            </Card>
                          </Col>
    )

    if (profile.isEmpty) return <div className="mr-auto ml-auto"><Spinner color="primary" size="md"/></div> 
    if (moduleBlocked === null) {
      return  <Col md="8" className="mr-auto ml-auto">
                <div className="text-center"><Spinner color="primary" size="md"/> </div> 
              </Col>
    }

    return (
      <>
        <Col md="8" className="mr-auto ml-auto"> 
          { progress < 100 || moduleBlocked === true
          ? <ModuleLocked message={'Please complete previous exercise to unlock this page'}/> 
          : <>   
              <Confetti
                width={getWidth()}
                height={getHeight()}
                recycle={recycleConfeti}
              />  
                <h2 className="title-weight text-center text-blue my-2 mb-5">
                  Hey {profile.displayName}
                </h2> 
                <div className="text-center my-1">
                  <img alt={'Final Badge'} src={require("assets/img/icons/Badge4.svg")}/>
                </div>     
                <h1 className="title-weight text-center text-cyan my-4">
                  CONGRATS !! YOU MADE IT.
                </h1>
                <hr/>
                <h6 className="text-danger text-center d-inline-block d-block d-sm-none mb-2">
                    * Please download your report from Pathways desktop *  
                </h6>
                <ReactToPrint
                  trigger={() => CardContainer()}
                  content={() => componentRef.current}/>

                <div style={{overflow: 'hidden', height: 0}}>     
                  <div ref={componentRef} className="px-4 print-area">        
                    <Row className="my-4 justify-content-center">
                      <img alt='Pathways Logo' src={require("assets/img/brand/logo1.svg")}/>  
                    </Row>
                    <h2 className="text-center text-cyan"> FINAL REPORT </h2>
                    <h4>{profile.displayName}</h4>
                      <div className="p-1 justify-content-center">
                        <M1Ex1Summary/>              
                      </div>
                    <hr/>
                      <div className="mt-2 py-1">
                        <M1Ex2Summary/> 
                      </div>
                    <hr/>
                      <div className="pagebreak"/>
                      <div className="mt-2 py-1">
                        <M1Ex3Summary/>
                      </div>
                    <hr/>
                      <div className="pagebreak"/>
                      <M2Ex1Summary/>
                    <hr/>
                      <M2Ex2Summary/>
                    <hr/>
                      <M2Ex3Summary/>
                    <hr/>
                      <div className="pagebreak"/>
                      <M3Ex1Summary/>
                    <hr/>
                      <div className="pagebreak"/>
                      <M3Ex2Summary/>
                    <hr/>
                      <div className="pagebreak"/>
                      <M3Ex3Summary/>
                    <hr/>
                      <div className="pagebreak"/>
                      <M3Ex4Summary/>
                    <hr/>
                      <div className="pagebreak"/>
                      <M4Ex1Summary/> 
                    <hr/>
                      <div className="pagebreak"/>
                      <M4Ex2Summary/>   
                    <hr/>           
                      <M4Ex3Summary/>  
                    <hr/>
                      <M5Ex1Summary/> 
                    <hr/>        
                  </div>
                </div>
            </>                    
          }
        </Col>
      </>
    );
}

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile
  }
};

export default connect(mapStateToProps, null)(Final);
