import React from "react";
import { Row, Col, Card, CardHeader, CardBody, FormGroup, Input, Button, Spinner } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import ModuleLocked from "components/Sections/ModuleLocked";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { submitExercise3A } from "redux/actions/module3Actions";

class Exercise3A extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Q1 : '',
      Q2 : '',
      Q3 : '',
      Q4 : '',
      Q5 : '',
      Q6 : '',
      alert: null
    };
  };

  componentDidUpdate(prevProps) { 
    if (this.props.exercise3m3 !== prevProps.exercise3m3) {
      this.setState({
        Q1: this.props.exercise3m3['1'],
        Q2: this.props.exercise3m3['2'],
        Q3: this.props.exercise3m3['3'],
        Q4: this.props.exercise3m3.why1,
        Q5: this.props.exercise3m3.why2,
        Q6: this.props.exercise3m3.why3,
        Q1State : this.props.exercise3m3['1'] && 'valid',
        Q2State : this.props.exercise3m3['2'] && 'valid',
        Q3State : this.props.exercise3m3['3'] && 'valid',
        Q4State : this.props.exercise3m3.why1 && 'valid',
        Q5State : this.props.exercise3m3.why2 && 'valid',
        Q6State : this.props.exercise3m3.why3 && 'valid',
      })
    }
  }

  selectOptions = () => {
    const a1 = this.props.exercise1m3.Q1;
    const a2 = this.props.exercise1m3.Q2;
    const a3 = this.props.exercise1m3.Q3;
    const a4 = this.props.exercise1m3.Q4;
    const a5 = this.props.exercise1m3.Q5;
    let options = [a1 ,a2, a3, a4, a5]
    return options;
  }

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change = (e, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "length":
        if (this.verifyLength(e.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "valid" });
        } else {
          this.setState({ [stateName + "State"]: "invalid" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: e.target.value });
  }

  selectValue = (stateName, value) => {
    this.setState({ [stateName]: value });
    this.setState({ [stateName + "State"]: "valid" });
  };

  handleSave = () => {
    const data = {
      userID: this.props.uid,
      '1': this.state.Q1,
      '2': this.state.Q2,
      '3': this.state.Q3,
      why1: this.state.Q4,
      why2: this.state.Q5,
      why3: this.state.Q6,
      superpowersTop3: [this.state.Q1.value, this.state.Q2.value, this.state.Q3.value]
    }
    if (this.isValidated()){
      this.props.submitExercise3A(data)
      this.props.history.push('/user/module3/ex3b');
    } else {
      this.setState({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "100px" }}
            title=""
            onConfirm={() => this.hideAlert()}
            confirmBtnBsStyle="default"
            confirmBtnText="Ok"
            btnSize=""
          >
            Ops! Please complete this exercise.
          </ReactBSAlert>
        )
      });
    }
  }

  hideAlert = () => {
    this.setState({ alert: null });
  };

  isValidated = () => {
    if (
      this.state.Q1State === "valid" &&
      this.state.Q2State === "valid" &&
      this.state.Q3State === "valid" &&
      this.state.Q4State === "valid" &&
      this.state.Q5State === "valid" &&
      this.state.Q6State === "valid" 
    ) {
      return true;
    } else {
      if (this.state.Q1State !== "valid") {
        this.setState({ Q1State: "invalid" });
      }
      if (this.state.Q2State !== "valid") {
        this.setState({ Q2State: "invalid" });
      }
      if (this.state.Q3State !== "valid") {
        this.setState({ Q3State: "invalid" });
      }
      if (this.state.Q4State !== "valid") {
        this.setState({ Q4State: "invalid" });
      }
      if (this.state.Q5State !== "valid") {
        this.setState({ Q5State: "invalid" });
      }
      if (this.state.Q6State !== "valid") {
        this.setState({ Q6State: "invalid" });
      }
      return false;
    }
  };

  render() {
    const { Q1, Q2, Q3, Q4, Q5, Q6, Q1State, Q2State, Q3State, Q4State, Q5State, Q6State, alert } = this.state;
    const { profile, progress, moduleBlocked } = this.props;

    if (profile.isEmpty || moduleBlocked === null) return <div className="mr-auto ml-auto"><Spinner color="primary" size="md"/> </div> 
    return (
      <>
        <Col md="8" className="wizard-pills mr-auto ml-auto"> 
          {alert}
          { progress < 66 || moduleBlocked === true
          ? <ModuleLocked message={'Please complete previous exercise.'}/> 
          : <>   
              <Card>
                <CardHeader className="text-center">
                  <h3 className="mb-2"> Module 3 - Super Powers</h3>
                  <h4>Ex - 3A</h4>
                </CardHeader>
                <CardBody>
                  <h1 className="mb-2">What Everyone Else Needs to Know About YOU!</h1>  
                  <h4 className="mb-2">
                    A. From the 5 superpowers you selected in exercise 1, list your top 3 Superpowers in ranking system:
                  </h4>

                  <Row className="justify-content-center mt-3">
                    <Col md="1" xs="1">
                      <p>1</p>
                    </Col>
                    <Col md="10" xs="10">
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        value={Q1}
                        required
                        onChange={(value) => this.selectValue('Q1', value)}
                        options={this.selectOptions()}
                      /> 
                      {Q1State === "invalid" && <label className="form-control-label text-primary warning"></label>} 
                    </Col>                    
                  </Row>
                  <Row className="justify-content-center mt-3">
                    <Col md="1" xs="1">
                      <p>2</p>
                    </Col>
                    <Col md="10" xs="10">
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        value={Q2}
                        required
                        onChange={(value) => this.selectValue('Q2', value)}
                        options={this.selectOptions()}
                      /> 
                      {Q2State === "invalid" && <label className="form-control-label text-primary warning"></label>} 
                    </Col>                    
                  </Row>
                  <Row className="justify-content-center mt-3">
                    <Col md="1" xs="1">
                      <p>3</p>
                    </Col>
                    <Col md="10" xs="10">
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        value={Q3}
                        required
                        onChange={(value) => this.selectValue('Q3', value)}
                        options={this.selectOptions()}
                      /> 
                      {Q3State === "invalid" && <label className="form-control-label text-primary warning"></label>} 
                    </Col>                    
                  </Row>
                  <hr/>
      
                  <h4 className="mb-2">
                    B. For your top 3 Superpowers, why did you choose them? What makes you so good at them?
                  </h4>
                  <Row className="justify-content-center">
                    <Col md="1" xs="1">
                      <p>1</p>
                    </Col>
                    <Col md="10" xs="10">
                      <FormGroup>
                        <Input
                          rows="2"
                          type="textarea"
                          value={Q4}
                          onChange={e => this.change(e, "Q4", "length", 4)}
                        />
                        {Q4State === "invalid" && <label className="form-control-label text-primary warning"></label>} 
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col md="1" xs="1">
                      <p>2</p>
                    </Col>
                    <Col md="10" xs="10">
                      <FormGroup>
                        <Input
                          rows="2"
                          type="textarea"
                          value={Q5}
                          onChange={e => this.change(e, "Q5", "length", 4)}
                        />
                        {Q5State === "invalid" && <label className="form-control-label text-primary warning"></label>} 
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col md="1" xs="1">
                      <p>3</p>
                    </Col>
                    <Col md="10" xs="10">
                      <FormGroup>
                        <Input
                          rows="2"
                          type="textarea"
                          value={Q6}
                          onChange={e => this.change(e, "Q6", "length", 4)}
                        />
                        {Q6State === "invalid" && <label className="form-control-label text-primary warning"></label>} 
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card> 
            
              <hr/>

              <Row className="justify-content-center my-3">
                <Col md='6' xs='10'>
                  <Button block color="success" size="lg" type="button" onClick={this.handleSave} >
                    Save & Continue
                  </Button>
                </Col>
              </Row>
            </>       
          }
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    profile: state.firebase.profile,
    module3 : state.firestore.data.module3 || {},
    exercise1m3 : state.firestore.data.exercise1m3 || {},
    exercise3m3 : state.firestore.data.exercise3m3 || {}
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitExercise3A: (data) => dispatch(submitExercise3A(data))
  }
}

export default compose( 
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module3"}],
        storeAs: 'module3'
      },
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module3",
          subcollections: [{ collection: "exercises", doc: "exercise1"}],
        }],
        storeAs: 'exercise1m3'
      },
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module3",
          subcollections: [{ collection: "exercises", doc: "exercise3"}],
        }],
        storeAs: 'exercise3m3'
      }
    ]
  }),
)(Exercise3A);