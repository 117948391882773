const values = [
    {
        label: "Accountability",
        value: "Accountability"
    },
    {
        label: "Achievement",
        value: "Achievement"
    },
    {
        label: "Adventure",
        value: "Adventure"
    },
    {
        label: "Authenticity",
        value: "Authenticity"
    },
    {
        label: "Authority",
        value: "Authority"
    },
    {
        label: "Autonomy",
        value: "Autonomy"
    },
    {
        label: "Balance",
        value: "Balance"
    },
    {
        label: "Beauty",
        value: "Beauty"
    },
    {
        label: "Belonging",
        value: "Belonging"
    },
    {
        label: "Boldness",
        value: "Boldness"
    },
    {
        label: "Calmness",
        value: "Calmness"
    },
    {
        label: "Challenge",
        value: "Challenge"
    },
    {
        label: "Citizenship",
        value: "Citizenship"
    },
    {
        label: "Clear-mindedness",
        value: "Clear-mindedness"
    },
    {
        label: "Community",
        value: "Community"
    },
    {
        label: "Compassion",
        value: "Compassion"
    },
    {
        label: "Competency",
        value: "Competency"
    },
    {
        label: "Competitiveness",
        value: "Competitiveness"
    },
    {
        label: "Contribution",
        value: "Contribution"
    },
    {
        label: "Cooperation",
        value: "Cooperation"
    },
    {
        label: "Correctness",
        value: "Correctness"
    },
    {
        label: "Creativity",
        value:"Creativity"
    },
    {
        label: "Curiosity",
        value: "Curiosity"
    },
    {
        label: "Determination",
        value: "Determination"
    },
    {
        label: "Diligence",
        value: "Diligence"
    },
    {
        label: "Diversity",
        value: "Diversity"
    },
    {
        label: "Efficiency",
        value: "Efficiency"
    },
    {
        label: "Equity",
        value: "Equity"
    },
    {
        label: "Excellence",
        value: "Excellence"
    },
    {
        label: "Exploration",
        value: "Exploration"
    },
    {
        label: "Fairness",
        value: "Fairness"
    },
    {
        label: "Faith",
        value: "Faith"
    },
    {
        label: "Fame",
        value: "Fame"
    },
    {
        label: "Fitness",
        value: "Fitness"
    },
    {
        label: "Freedom",
        value: "Freedom"
    },
    {
        label: "Friendships",
        value: "Friendships"
    },
    {
        label: "Fun",
        value: "Fun"
    },
    {
        label: "Growth",
        value: "Growth"
    },
    {
        label: "Happiness",
        value: "Happiness"
    },
    {
        label: "Hard Work",
        value: "Hard Work"
    },
    {
        label: "Honesty",
        value: "Honesty"
    },
    {
        label: "Humility",
        value: "Humility"
    },
    {
        label: "Humor",
        value: "Humor"
    },
    {
        label: "Independence",
        value: "Independence"
    },
    {
        label: "Influence",
        value: "Influence"
    },
    {
        label: "Inner Harmony",
        value: "Inner Harmony"
    },
    {
        label: "Inquiry",
        value: "Inquiry"
    },
    {
        label: "Intelligence",
        value: "Intelligence"
    },
    {
        label: "Intuition",
        value: "Intuition"
    },
    {
        label: "Joy",
        value: "Joy"
    },
    {
        label: "Justice",
        value: "Justice"
    },
    {
        label: "Kindness",
        value: "Kindness"
    },
    {
        label: "Knowledge",
        value: "Knowledge"
    },
    {
        label: "Leadership",
        value: "Leadership"
    },
    {
        label: "Learning",
        value: "Learning"
    },
    {
        label: "Love",
        value: "Love"
    },
    {
        label: "Loyalty",
        value: "Loyalty"
    },
    {
        label: "Mastery",
        value: "Mastery"
    },
    {
        label: "Meaningful Work",
        value: "Meaningful Work"
    },
    {
        label: "Openness",
        value: "Openness"
    },
    {
        label: "Optimism",
        value: "Optimism"
    },
    {
        label: "Order",
        value: "Order"
    },
    {
        label: "Patriotism",
        value: "Patriotism"
    },
    {
        label: "Peace",
        value: "Peace"
    },
    {
        label: "Perfection",
        value: "Perfection"
    },
    {
        label: "Pleasure",
        value: "Pleasure"
    },
    {
        label: "Poise",
        value: "Poise"
    },
    {
        label: "Popularity",
        value: "Popularity"
    },
    {
        label: "Practicality",
        value: "Practicality"
    },
    {
        label: "Recognition",
        value: "Recognition"
    },
    {
        label: "Reliability",
        value: "Reliability"
    },
    {
        label: "Religion",
        value: "Religion"
    },
    {
        label: "Reputation",
        value: "Reputation"
    },
    {
        label: "Respect",
        value: "Respect"
    },
    {
        label: "Responsibility",
        value: "Responsibility"
    },
    {
        label: "Security",
        value: "Security"
    },
    {
        label: "Self-Respect",
        value: "Self-Respect"
    },
    {
        label: "Selflessness",
        value: "Selflessness"
    },
    {
        label: "Service",
        value: "Service"
    },
    {
        label: "Simplicity",
        value: "Simplicity"
    },
    {
        label: "Spirituality",
        value: "Spirituality"
    },
    {
        label: "Spontaneity",
        value: "Spontaneity"
    },
    {
        label: "Stability",
        value: "Stability"
    },
    {
        label: "Status",
        value: "Status"
    },
    {
        label: "Strength",
        value: "Strength"
    },
    {
        label: "Success",
        value: "Success"
    },
    {
        label: "Teamwork",
        value: "Teamwork"
    },
    {
        label: "Thoroughness",
        value: "Thoroughness"
    },
    {
        label: "Thoughtfulness",
        value: "Thoughtfulness"
    },
    {
        label: "Traditionalism",
        value: "Traditionalism"
    },
    {
        label: "Trustworthiness",
        value: "Trustworthiness"
    },
    {
        label: "Truth-seeking",
        value: "Truth-seeking"
    },
    {
        label: "Wealth",
        value: "Wealth"
    },
    {
        label: "Wisdom",
        value: "Wisdom"
    },
]

export default values;