import React from "react";

import { Row, Col } from "reactstrap";
import values from "../../../../variables/values";

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values : values.map(obj=> ({ ...obj, isChecked: false })),
      validationState: "",
      list: []
    };
  };

  componentDidMount() {
    let userValues = this.props.userValues && this.props.userValues.map((str, index) => ({ label: str, value: str, isChecked: true}));
    let tempValues = values.map(obj=> ({ ...obj, isChecked: false }));
    let map = new Map(userValues && userValues.map(o => [o.label, o]));
    tempValues.forEach(o => map.has(o.label) && (o.isChecked = map.get(o.label).isChecked));
    let valuesSelected = tempValues.filter(d => d.isChecked === true);
    let list = valuesSelected.map(a => a.value);

    this.setState({
      values: tempValues,
      list: list
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.userValues !== prevProps.userValues) {
      let userValues = this.props.userValues.map((str, index) => ({ label: str, value: str, isChecked: true}));
      let tempValues = values.map(obj=> ({ ...obj, isChecked: false }));
      let map = new Map(userValues.map(o => [o.label, o]));
      tempValues.forEach(o => map.has(o.label) && (o.isChecked = map.get(o.label).isChecked));
      let valuesSelected = tempValues.filter(d => d.isChecked === true);
      let list = valuesSelected.map(a => a.value);
  
      this.setState({
        values: tempValues,
        list: list
      })
    }
  }

  chunk = (array, size)=> {
    const chunked_arr = [];
    let index = 0;
    while (index < array.length) {
      chunked_arr.push(array.slice(index, size + index));
      index += size;
    }
    return chunked_arr;
  }

  handleCheck = (event) => {
    let values = this.state.values;
    values.forEach(i => {
       if (i.value === event.target.value)
          i.isChecked = event.target.checked
    })
    let valuesSelected = values.filter(d => d.isChecked === true);
    let list = valuesSelected.map(a => a.value);

    this.setState({ 
      values: values,
      list: list
    })
  }

  isValidated = () => {
    const values = this.state.values;
    let valuesSelected = values.filter(d => d.isChecked === true);
    let count = valuesSelected.length;
    
    if (count === 10) {
      this.setState({validationState: "has-success"})
      return true;
    } 
    this.setState({validationState: "has-danger"})
    return false;
  }

  render() {
    const { values, validationState } = this.state;

    return (
      <>
        <h2>Your Core Values</h2>
        <p>Choose ten values from the list below. The definitions for the values are intentionally left out so that you can interpret and define them for yourself (in no particular order).</p>
         
          <Row className="justify-content-center mr-auto ml-auto">
            {this.chunk(values,32)[0].map((i, index) => 
            <Col sm="4" className="mr-auto ml-auto" key={index}>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input"
                  id={i.label}
                  type="checkbox"
                  checked={i.isChecked}
                  value={i.label}
                  onChange={this.handleCheck}
                />
                <label className="custom-control-label" htmlFor={i.label}>
                  {i.label}
                </label>
              </div>
            </Col>)}
            {this.chunk(values,32)[1].map((i, index) => 
            <Col sm="4" className="mr-auto ml-auto" key={index}>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input"
                  id={i.label}
                  type="checkbox"
                  checked={i.isChecked}
                  value={i.label}
                  onChange={this.handleCheck}
                />
                <label className="custom-control-label" htmlFor={i.label}>
                  {i.label}
                </label>
              </div>
            </Col>)}
            {this.chunk(values,32)[2].map((i, index) => 
            <Col sm="4" className="mr-auto ml-auto" key={index}>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input"
                  id={i.label}
                  type="checkbox"
                  checked={i.isChecked}
                  value={i.label}
                  onChange={this.handleCheck}
                />
                <label className="custom-control-label" htmlFor={i.label}>
                  {i.label}
                </label>
              </div>
            </Col>)}
          </Row>
          {validationState === "has-danger" && <label className="form-control-label text-primary warning">Ops, you must select 10 values exactly.</label>}  
                
      </>
    );
  }
}

export default Step2;
