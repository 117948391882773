import React from 'react';
import classnames from "classnames";
import { Card, CardBody, Col } from "reactstrap";
import { connect } from 'react-redux';

const MembershipCard = (props) => {

    const { profile } = props;
    console.log(profile.token && profile.token.claims.stripeRole)
    
    if (profile.institution_userID === null || profile.institution_userID === 'na') {
      return  <Col md="3">
                  <Card className="bg-gradient-default">
                    <CardBody>
                      <h4 className="title-weight text-white">Current Plan:</h4>
                        {profile.token && profile.token.claims.stripeRole === "premium" 
                          ? 
                          <h4 className="text-success">Premium</h4>
                          :
                          <h4 className="text-warning">Starter</h4>}           
                    </CardBody>
                  </Card>
                </Col> 
    }

    return(
        <Col md="3">
          <Card className="bg-gradient-default">
            <CardBody>
              <h4 className="title-weight text-white">Current Plan</h4>
              <h4 className={classnames(
                {"text-warning": profile.membership === "starter" },
                {"text-success": profile.membership === "premium" })}>
                {profile.membership}
              </h4>
            </CardBody>
          </Card>
        </Col>
    )
}

const mapStateToProps = (state, props) => {
  return {
      profile: state.firebase.profile,
  }
}

export default connect(mapStateToProps, null)(MembershipCard);