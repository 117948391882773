import React from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col, Spinner } from "reactstrap";
import { AdminUserProgressTable } from "components/Tables";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const ManageUsers = (props) => {
  
    const { profile } = props;
    if ( profile.isEmpty ) return <div className="text-center"><Spinner color="primary" size="md"/> </div> 
    if ( profile.token && profile.token.claims.student ) return <Redirect direct to="/user/home"/>

    return (
          <Container className="mt-2" fluid>
            <Row className="justify-content-center">
              <Col xl="12" className="text-center">
                <h1 className="text-dark title-weight my-2">Your Users Progress</h1>
              </Col>
            </Row>

            <Row className="justify-content-center mt-4">
              <Col md="10">
                <AdminUserProgressTable/>
              </Col>
            </Row>
          </Container>
      )
}


const mapStateToProps = (state) => {
  return {
      uid: state.firebase.auth.uid,
      profile: state.firebase.profile,
      schoolData: state.firestore.data.schoolData || {}
  }
}

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'schools', 
        doc: props.uid,
        storeAs: 'schoolData'
      }]
    }),
 )(ManageUsers);