import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Collapse, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Navbar, NavItem, NavLink, Nav, Container } from "reactstrap";
import Avatar from '../Avatar/Avatar';
import { connect } from 'react-redux';
import { signOut } from '../../redux/actions/authActions';

class DashboardNavbar extends React.Component {
  
  accountSettingsSelector = () => {
    const type = this.props.profile.type
    switch (type) {
      case "student":
        return  <DropdownItem onClick={() => {this.props.history.push('/user/settings')}}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
      case "admin":
        return <DropdownItem onClick={() => {this.props.history.push('/admin/settings')}}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
      case "superAdmin":
        return <DropdownItem onClick={() => {this.props.history.push('/lbb-admin/settings')}}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
      default:
        return [];
    }
  }

  myProfileSelector = () => {
    const type = this.props.profile.type
    switch (type) {
      case "student":
        return  <DropdownItem onClick={() => {this.props.history.push('/user/user-profile')}} >
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
      case "admin":
        return null
      case "superAdmin":
        return null
      default:
        return [];
    }
  }

  render() {
    const { profile } = this.props
    if (profile.isEmpty) return <div className="text-center"><p>Wait...</p></div>
    return (
      <>
        <Navbar className= "navbar-top navbar-expand border-bottom navbar-light bg-secondary">   
          <Container fluid>
            <Collapse navbar isOpen={true}>   
              <Nav className="justify-content-start align-items-center ml-md-auto" navbar>
                <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      "pr-3 sidenav-toggler",
                      { active: this.props.sidenavOpen },
                      { "sidenav-toggler-dark": "light" === "dark" }
                    )}
                    onClick={this.props.toggleSidenav}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                    </div>
                  </div>
                </NavItem>              
              </Nav>
         
              <Nav className="ml-auto ml-md-0 align-items-center" navbar>
                { profile.token && profile.token.claims.student &&
                  <NavItem className="mr--3">
                    <NavLink onClick={()=> {this.props.history.push('/user/badges')}}>
                      <span>
                        <img alt="Badge Icon" style={{width:'18px'}} src={require("assets/img/icons/common/Badges.png")}/>
                      </span>
                    </NavLink>
                  </NavItem> }
                  
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <Media className="align-items-center">
                      <span className="avatar avatar-sm rounded-circle">
                         <Avatar firstname={profile.firstname || ''} lastname={profile.lastname || ''} photoURL={profile.photoURL} width={38} fontSize={18}></Avatar>
                      </span>
                      <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                          {profile.displayName}
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                    
                    { this.myProfileSelector() }

                    { this.accountSettingsSelector() }
                    
                    <DropdownItem
                      href="https://littleblackbuddha.com/contact/"
                    >
                      <i className="ni ni-support-16" />
                      <span>Support</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={() => this.props.signOut()}
                    >
                      <i className="fas fa-sign-out-alt"></i>
                      <span>Logout</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}
DashboardNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark"
};
DashboardNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"])
};

const mapStateToProps = (state) => {
  return { 
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNavbar);