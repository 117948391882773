export const profileUpdate = (user) =>{
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        try { 
            let db = firestore.collection("users").doc(user.uid);
            let displayName = user.firstname+' '+user.lastname;
            await firebase.updateAuth({displayName: displayName}, true)
            db.update({
                displayName: displayName,
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email,
                school: user.school
            }); 
            dispatch({ type: 'NAME_UPDATE_SUCCESS' });   
		} 
		catch (err) {
            dispatch({ type: 'NAME_UPDATE_ERROR', err });
        }
    }
};

export const passwordUpdate = (currentPassword, newPassword) => {
    return  async (dispatch, getState, {getFirebase}) => { 
      const firebase = getFirebase();
      try { 
        let user = firebase.auth().currentUser;
        let cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword); 
        await user.reauthenticateWithCredential(cred)
        await user.updatePassword(newPassword)
        dispatch({ type: 'UPDATE_PASSWORD_SUCCESS' });
      } catch (err) {
        dispatch({ type: 'UPDATE_PASSWORD_ERROR', err});
      }
    }
};

export const updateMembership = (uid) => {
  return  async (dispatch, getState, {getFirestore}) => { 
    const firestore = getFirestore();
    try { 
      let db = firestore.collection("users").doc(uid); 
      await db.update({
        membership: 'premium'
      })
      dispatch({ type: 'UPDATE_SINGLE_USER_MEMBERSHIP_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'UPDATE_SINGLE_USER_MEMBERSHIP_ERROR', err});
    }
  }
};

export const sendIndividualCoupon = (data) => {
  return  async (dispatch, getState, {getFirebase}) => { 
    const firebase = getFirebase();
    try { 
      const sendIndCouponCode = firebase.functions().httpsCallable('validateIndividualCoupon');
      const response = await sendIndCouponCode({ userID: data.userID, couponCode: data.couponCode })
      dispatch({ type: 'SUBMIT_IND_COUPON_SUCCESS', payload: response });
    } catch (err) {
      dispatch({ type: 'SUBMIT_IND_COUPON_ERROR', err});
    }
  }
};