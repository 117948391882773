import React from 'react';
import { Row, Col } from "reactstrap";
import Select from "react-select";

class Step4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Q9: '',
    }
  }

  componentDidUpdate(prevProps) { 
    if (this.props.profile !== prevProps.profile || this.props.exercise3m4 !== prevProps.exercise3m4) {
      this.setState({
        Q9: this.props.exercise3m4.Q9,
        Q9State: this.props.exercise3m4.Q9 && 'valid',
        blank1: this.props.aspirationalJob && this.props.aspirationalJob.label
      })
    }
  }

  selectValue = (stateName, value) => {
    this.setState({ [stateName]: value });
    this.setState({ [stateName + "State"]: "valid" });
  }

  isValidated = () => {
    if ( 
      this.state.Q9State === "valid" 
    ) {
      return true;
    } else {
      if (this.state.Q9State !== "valid") {
        this.setState({ Q9State: "invalid" });
      }
      return false;
    }
  }

  render() {
    const { Q9, blank1, Q9State } = this.state;
    const { profile, wizardData } = this.props;

    return (
        <>
          <Row className="justify-content-center">
            <Col md="10" xs="12">
              <p className="text-dark">My name is <span className="title-weight">{ profile && profile.displayName }</span>, 
                  I am looking for work or to transition to {blank1}. 
                  I have a background in{' '}
                  <span className="text-dark">{wizardData['2'] && wizardData['2'].Q1}</span> 
                  {' '}and I want to leverage my skills in{' '}
                  <span className="text-dark">{wizardData['2'] && wizardData['2'].Q2}</span>.
              </p> 
              <p className="text-dark">As a{' '}<span className="text-dark">{wizardData['3'] && wizardData['3'].Q3}</span>
                  {' '}we had trouble with <span >{wizardData['3'] && wizardData['3'].Q4}</span>, 
                  I used my {wizardData['3'] && wizardData['3'].Q5} to {wizardData['3'] && wizardData['3'].Q6} and that is how I solved the problem. 
                  Additionally, I considered myself good at {wizardData['3'] && wizardData['3'].Q7} and {wizardData['3'] && wizardData['3'].Q8}.
              </p>                 
            </Col>
          </Row> 

          <h2>Pitch Part 3 - Close with a “CTA”</h2> 
          <p className="title-weight">Close with a “CTA” (Call—To—Action) or an Ask: </p>
          <Row className="justify-content-center">
            <Col md="10" xs="11">
              <Row>
                <p>Clearly state what you would like to happen as a result of the conversation or speaking with this person.</p>
              </Row>
            </Col>
          </Row>
          
          <h3 className="text-info">Select one CTA</h3>
          <Row className="justify-content-center mt-2">
            <Col md="10" xs="12">
              <Select
                className="react-select"
                classNamePrefix="react-select"
                value={Q9}
                required
                onChange={(value) => this.selectValue('Q9', value)}
                options={[
                  { value:'I would appreciate if you can share some of your insights on how to approach this career path. What is the best way to contact you and stay connected?', label:'I would appreciate if you can share some of your insights on how to approach this career path. What is the best way to contact you and stay connected?'},
                  { value:'I would love to learn more about what it is like to work at your company. What is the best way to contact you and stay connected?', label:'I would love to learn more about what it is like to work at your company. What is the best way to contact you and stay connected?'},
                  { value:'What is the best way to get on your calendar so that we can talk further?', label: 'What is the best way to get on your calendar so that we can talk further?'}
                ]}
              /> 
              {Q9State === "invalid" && <label className="form-control-label text-primary warning">Please select an option.</label>}
            </Col>
          </Row>
        </>
    )
  }
}

export default Step4;