const initState = {
  tempCouponCode: null,
  couponError: null,
  couponSuccess : null,
  total: 249900,
  optionSelected: 'Option1',
  seatsNumber: 10,
  plans:{
      Option1: {
          name:'10 Seats',
          seats: 10,
          price: 249900
      },
      Option2: {
          name:'25 Seats',
          seats: 25,
          price: 624900
      },
      Option3: {
          name:'50 Seats',
          seats: 50,
          price: 1249800
      },
      Option4: {
          name:'100 Seats',
          seats: 100,
          price: 2499600
      },
    }
  }
    
const adminReducer = (state = initState, action) => {
    let planSelected = '';
    let discountedPlans = {};
    switch(action.type){
      case 'CREATE_STUDENT_SUCCESS':
        console.log('Student created successfully' );
        return state;
      case 'CREATE_STUDENT_ERROR':
        console.log('student creation error', action.err);
        return state; 
      case 'DELETE_UNREGISTERED_USER_SUCCESS':
        console.log('User unregistered deleted successfully' );
        return state; 
      case 'DELETE_UNREGISTERED_USER_ERROR':
        console.log('User unregistered deleted ERROR', action.err  );
        return state; 
      case 'SELECT_NUMBER_SEATS':
        planSelected = { ...state.plans[action.payload] }
        return {
          ...state,
          total: state.plans[action.payload].price,
          optionSelected: action.payload,
          seatsNumber: state.plans[action.payload].seats,
          plans:{
              ...state.plans,
              [action.payload]: planSelected
          }
      }
      case 'GET_PLAN_SELECTED':  
        return { ...state };
      case 'UPDATE_SEATS_NUMBER_SUCCESS':
        console.log('New seats added successfully' );
        return state;
      case 'UPDATE_SEATS_NUMBER_ERROR':
        console.log('error:', action.err);
        return state;
      case 'RESEND_INVITE_SUCCESS':
        console.log('Email sent succesfully');
        return state;
      case 'RESEND_INVITE_ERROR':
        console.log('error:', action.err);
        return state;
      case 'SUBMIT_COUPON_SUCCESS':
        //console.log(action.payload.data);
        if (action.payload.data.isDiscount){
          discountedPlans = action.payload.data.discountedPlans
          return {
            ...state,
            tempCouponCode: action.payload.data.name,
            couponError: null,
            couponSuccess : 'This is a valid coupon. Prices were updated.',
            total: discountedPlans['Option1'].price,
            optionSelected: 'Option1',
            seatsNumber: 10,
            plans: discountedPlans
          };
        }
        return {
          ...state,
          couponError: null,
          couponSuccess : `This is a valid coupon. you've got free seats`,
        }
      case 'SUBMIT_COUPON_ERROR':
        return {
          ...state,
          tempCouponCode: null,
          couponError: action.err,
          couponSuccess: null
        };
      case 'REDEEM_COUPON_SUCCESS':
        console.log('Coupon reedemed');
        return state;
      case 'REDEEM_COUPON_ERROR':
        console.log('error:', action.err);
        return state;         
      default:
        return state
    }
};
    
export default adminReducer;