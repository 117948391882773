import React from 'react';

const LeavesImg = () => {
    return (
        <>
          <div className="img-container-abs-left d-block d-sm-none">
            <img alt='...' src={require("assets/img/theme/Leave1.svg")}/>  
          </div>
          <div className="img-container-abs-right d-block d-sm-none">
            <img alt='...' src={require("assets/img/theme/Leave2.svg")}/>  
          </div>
          <div className="img-abs-leaf d-none d-lg-block">
            <img alt='...' src={require("assets/img/theme/Leave3.svg")}/>  
          </div>
        </>
    )
}

export default LeavesImg;