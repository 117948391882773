import React, { useState } from "react";
import { Carousel, CarouselItem, CarouselIndicators, CarouselCaption } from "reactstrap";
import Slider1 from '../../components/Sliders/Slider1';
import Slider2 from '../../components/Sliders/Slider2';
import Slider3 from '../../components/Sliders/Slider3';
import SplashNavbar from "components/Navbars/SplashNavbar.js";

const items = [
  { 
    id: 1,
    component: <Slider1/>,
    caption: 'NEXT'
  },
  { 
    id: 2,
    component: <Slider2/>,
    caption: 'NEXT'
  },
  {
    id: 3,
    component: <Slider3/>,
    caption: '..'
  }
];

const Splash = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  /*const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }*/

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        className="justify-content-md-center"
        tag="div"
        key={item.id}
        onExiting={() => setAnimating(false)}
        onExited={() => setAnimating(false)}
      > 
        {item.component}
        <CarouselCaption className="text-dark mb-3" captionText={item.caption}/>
      </CarouselItem>
    );
  });
    return (
      <> 
        <div className="my__carousel_main lbb-corousel bg-white bg-image main-content">
          <SplashNavbar/>
          <Carousel
            activeIndex={activeIndex}
            next={() => {}}
            previous={() => {}}
            autoPlay={false}
            controls={false}
          >
            <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
            {slides}
          </Carousel>
        </div> 
      </>
    );
  }


export default Splash;
