import React, {useState, useEffect, Fragment} from "react";
import { Row, Col, ListGroup, ListGroupItem, } from "reactstrap";
import ModuleLocked from "components/Sections/ModuleLocked"

import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';

const list = [
  {
    areaName:"Business & Finance",
    shortName:"business"
  },
  {
    areaName:"Healthcare",
    shortName:"healthcare"
  },
  {
    areaName:"Computer & Mathematics",
    shortName:"computer"
  },
  {
    areaName:"Tech Sales and Management",
    shortName:"tech"
  },
  {
    areaName:"Skill Trades",
    shortName:"trade"
  },
  {
    areaName:"Entrepreneurship",
    shortName:"entrepreneurship"
  }
]

const M4Ex1Summary = (props) => {
    const [ data, setData ] = useState({})
    const { progress, moduleBlocked } = props;

    useEffect(() => { setData(props.exercise1m4) }, [props.exercise1m4])

    const renderObject = (area) => {
      return (
        <ul>{data[area] && data[area].map((i, index) => 
          <li key={index}><p className="list-results text-dark">{i.label}</p></li>)}
        </ul>
      ) 
    }

    const betterSkilled = () => {
      const skilledAreas = data.skilledAreas || [];
      let values = list.filter(value => skilledAreas.includes(value.shortName))
      return (<Fragment>{values.map(a => a.areaName).join(', ')}</Fragment>)
    }

    return (
      <>
      { progress < 0 || moduleBlocked === true
      ? <ModuleLocked message={'Exercise Locked'}/> 
      : <>
          <Row className="justify-content-center">
            <Col md='10' xs='12' className='mt-2'>
            <h4 className="title-weight"> Occupational Tracks</h4>
            <h5 className="text-dark"> You can leverage your skills better on <span className="text-info">{betterSkilled()}</span></h5>
              <ListGroup className="thead-light">
                <ListGroupItem className="list-head">
                  <Row>
                    <Col md="4" xs="6">Occupational Areas</Col>
                    <Col md="8" xs="6">Your Transferable Skills</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="list-results">
                  <Row>
                    <Col md="4" xs="4"><p>Business & Finance</p></Col>
                    <Col md="8" xs="8">{renderObject('business')}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="4" xs="4"><p>Healthcare</p></Col>
                    <Col md="8" xs="8">{renderObject('healthcare')}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="list-results">
                  <Row>
                    <Col md="4" xs="4"><p>Computer & Mathematics</p></Col>
                    <Col md="8" xs="8">{renderObject('computer')}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="list-results">
                  <Row>
                    <Col md="4" xs="4"><p>Tech Sales and Management</p></Col>
                    <Col md="8" xs="8">{renderObject('tech')}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="list-results">
                  <Row>
                    <Col md="4" xs="4"><p>Skill Trades</p></Col>
                    <Col md="8" xs="8">{renderObject('trade')}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="list-results">
                  <Row>
                    <Col md="4" xs="4"><p>Entrepreneurship</p></Col>
                    <Col md="8" xs="8">{renderObject('entrepreneurship')}</Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>        
            </Col>
          </Row>
        </>
      }
      </>
    )
}


const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    exercise1m4 : state.firestore.data.exercise1m4 || {}
  }
};

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module4",
          subcollections: [{ collection: "exercises", doc: "exercise1"}],
        }],
        storeAs: 'exercise1m4'
      }]
    }),
)(M4Ex1Summary);