import React, { useRef, useEffect, useState } from 'react';
import ReactToPrint from "react-to-print";

import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button, ButtonGroup, Card, CardHeader, Row, Col, Media, UncontrolledTooltip, Spinner } from "reactstrap";

import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: false,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
          </select>
        }{" "}
        users.
      </label>
    </div>
  )
})
const { SearchBar } = Search;


const SuperAdminsTable = (props) => {

  const componentRef = useRef();
  const [isFetching, setIsFetching] = useState(true);
  const [data, setData] = useState([]);
  
  useEffect(() => { 
    setData(props.superAdmins);
    setIsFetching(false)
  }, [props.superAdmins])

  const displayData = data && data.map((i, index) => ({
      id: i.id, 
      avatar: {
        photoURL: i.photoURL, 
        firstnameInit: i.firstname && i.firstname.charAt(0).toUpperCase(), 
        lastnameInit: i.lastname && i.lastname.charAt(0).toUpperCase()
      },
      firstname: i.firstname,
      lastname: i.lastname,
      email: i.email, 
      joined: i.joined ? moment(i.joined.toDate()).format('MMM DD YYYY') : '',
    }
  ));

  const avatarField = (key) => {
    return  <Media className="align-items-center">
              <span className="avatar avatar-sm rounded-circle bg-cyan">
                {key.photoURL 
                ? <img
                    alt="avatar"
                    src={key.photoURL}
                  /> 
                : <h5 style={{ marginTop:'5px', color:'#fff' }}>
                    {key.firstnameInit + key.lastnameInit}
                  </h5>
                }
              </span>
            </Media>
  }

  if (isFetching) return  <div className='text-center'><Spinner color="secondary" > ...Loading </Spinner></div>

  return (
    <>
      <Card>
        <CardHeader>
          <h3 className="mb-0">Super Admins</h3>
        </CardHeader>
        <ToolkitProvider
          data={displayData || []}
          keyField="id"
          columns={[
            {
              dataField: "avatar",
              text: "",
              sort: false,
              formatter: avatarField,
            },
            {
              dataField: "firstname",
              text: "Name",
              sort: true
            },
            {
              dataField: "lastname",
              text: "Lastname",
              sort: true
            },
            {
              dataField: "email",
              text: "Email",
              sort: true
            },
            {
              dataField: "joined",
              text: "Joined",
              sort: false,
            }
          ]}
          search
        >
          {props => (
            <div className="py-4 table-responsive">
              <div
                id="datatable-basic_filter"
                className="dataTables_filter px-4 pb-1"
              >
                <label>
                  Search:
                  <SearchBar
                    className="form-control-sm"
                    placeholder=""
                    {...props.searchProps}
                  />
                </label>
              </div>
              <BootstrapTable
                ref={componentRef}
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                id="react-bs-table"
              />
            </div>
          )}
        </ToolkitProvider>
        <Col>
          <Row className="justify-content-end"> 
            <ButtonGroup>
              <ReactToPrint
                trigger={() => (
                  <Button
                    color="default"
                    size="sm"
                    className="buttons-copy buttons-html5 mx-2 my-2"
                    id="print-tooltip"
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </ButtonGroup>
            <UncontrolledTooltip placement="top" target="print-tooltip">
              Print or save to PDF.
            </UncontrolledTooltip>    
          </Row>
        </Col>     
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
    superAdmins: state.firestore.ordered.superAdmins || [],
  }
}
        
export default compose( 
connect(mapStateToProps, null),
firestoreConnect((props)=> {
  if (props.profile.type !== 'superAdmin') return []
  return [
    {
      collection:'users', 
      where:[['type','==','superAdmin']],
      storeAs: 'superAdmins'
    }]
  }),
)(SuperAdminsTable);