import React from "react";
import { Row, Col } from "reactstrap";
import Collapse2 from "components/Alerts/Collapse2";

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  
  render() {
    
    return (
      <>
        <h1 className="mb-2">Introduction</h1>   
        <Row className="justify-content-center">
          <Col lg="10">
            <Row>
              <p><span className="title-weight">MBTI Assessment</span> (Myers-Briggs Type Indicator) This exercise will help us discover your personality type.</p> 
              <p>Your personality type depends on four crucial factors:</p>
                <ul>
                    <li> <p>Favorite world & how you get energized- <span className="title-weight">Extrovert (E) or Introvert (I)</span></p></li>
                    <li> <p>How you take in and process information - <span className="title-weight">Sensing (S) or Intuition (N)</span></p></li>
                    <li> <p>How you make decisions - <span className="title-weight">Thinking (T) or Feeling (F)</span> </p></li>
                    <li> <p>Life structure - <span className="title-weight">Judging (J) or Perceiving (P)</span></p></li>
                </ul>
              <p>None of these traits are necessarily set in stone, in some cases you may flow back and forth between the two factors depending on the situation.</p>
              <p>Now, we will redirect you to a third-party test provided by 16Personalities.com. We will help you interpretate your results as we go along with Pathways.</p>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Collapse2/>
        </Row>
        <Row className="justify-content-center">
          <Col md="10">
            <Row>
            <img 
              src="https://static.neris-assets.com/images/logo.svg" 
              alt="16Personalities" 
              className="personalities-logo"/>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default Step1;