import React from 'react';
import { Row, Col } from "reactstrap";

class Step5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidUpdate(prevProps) { 
    if (this.props.profile !== prevProps.profile || this.props.exercise3m4 !== prevProps.exercise3m4) {
      this.setState({
        blank1: this.props.aspirationalJob && this.props.aspirationalJob.label
      })
    }
  }

  getWordsCounted = () => {
    const { profile, wizardData } = this.props;
    let name = profile && profile.displayName;
    let aspirationalJob = this.state.blank1;
    const userData = {
      Q1: wizardData['2'] && wizardData['2'].Q1,
      Q2: wizardData['2'] && wizardData['2'].Q2,
      Q3: wizardData['3'] && wizardData['3'].Q3,
      Q4: wizardData['3'] && wizardData['3'].Q4,
      Q5: wizardData['3'] && wizardData['3'].Q5,
      Q6: wizardData['3'] && wizardData['3'].Q6,
      Q7: wizardData['3'] && wizardData['3'].Q7,
      Q8: wizardData['3'] && wizardData['3'].Q8,
      Q9: wizardData['4'] && wizardData['4'].Q9,
    }
    const pitch = `My name is ${name}, I am looking for work or to transition to ${aspirationalJob}. I have a background in ${userData.Q1} and I want to leverage my skills in ${userData.Q2}.\n 
      As a ${userData.Q3} we had trouble with ${userData.Q4}, I used my ${userData.Q5} to ${userData.Q6} and that is how I solved the problem. Additionally, I considered myself good at ${userData.Q7} and ${userData.Q8}.\n
      ${userData.Q9 && userData.Q9.value}`;

    return pitch.split(" ").length;
  }

  isValidated = () => {
    const count = this.getWordsCounted()
    if ( count <= 140 ) {
      this.setState({ wordCountState: "valid" })
      return true;
    } else {
      if (this.state.wordCountState !== "valid") {
        this.setState({ wordCountState: "invalid" });
      }
      return false;
    }
  }

  render() {
    const { blank1, wordCountState } = this.state;
    const { profile, wizardData } = this.props;

    return (
        <>
          <h2>Pitch Part 4 - Check Your Pitch</h2> 
          <p>This is the first draft of your elevator pitch, you can edit it and practice it until it is uniquely yours. This pitch can be adapted to change.</p>
          <p>Remember your pitch shouldn't be over 140 words</p>
            <Row className="justify-content-end mt-3 mr-3">   
              <h5 className="text-info">Current word count : {this.getWordsCounted()}</h5>  
            </Row> 

            <Row className="justify-content-center">
              <Col md="10" xs="12">
                <p className="text-dark">My name is <span className="title-weight">{ profile && profile.displayName }</span>, 
                    I am looking for work or to transition to {blank1}. 
                    I have a background in{' '}
                    <span className="text-dark">{wizardData['2'] && wizardData['2'].Q1}</span> 
                    {' '}and I want to leverage my skills in{' '}
                    <span className="text-dark">{wizardData['2'] && wizardData['2'].Q2}</span>.
                </p> 
                <p className="text-dark">As a{' '}<span className="text-dark">{wizardData['3'] && wizardData['3'].Q3}</span>
                    {' '}we had trouble with <span >{wizardData['3'] && wizardData['3'].Q4}</span>, 
                    I used my {wizardData['3'] && wizardData['3'].Q5} to {wizardData['3'] && wizardData['3'].Q6} and that is how I solved the problem. 
                    Additionally, I considered myself good at {wizardData['3'] && wizardData['3'].Q7} and {wizardData['3'] && wizardData['3'].Q8}.
                </p> 
                <p className="text-dark">{wizardData['4'] && wizardData['4'].Q9.value}</p>  
                {wordCountState === "invalid" && <label className="form-control-label text-primary warning">Pitch shouldn't be over 140 words. You can go back and make a few changes.</label>}              
              </Col>    
            </Row> 
        </>
    )
  }
}

export default Step5;