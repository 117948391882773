import React, { useState, useEffect } from 'react';
import { Button, Card, CardHeader, CardBody, Form, Input, Row, Col, Spinner, Alert } from "reactstrap";

import { connect } from 'react-redux';
import { sendCoupon } from 'redux/actions/adminActions';

const CouponForm = (props) => {
  const [isProcessing, setProcessingTo] = useState(false);
  const [coupon, setCoupon ] = useState('')
  const [couponError, setCouponError] = useState(null)
  const [couponSuccess, setCouponSuccess] = useState(null)
  const [visible, setVisible] = useState(true);

  useEffect(() => { 
    setCouponError(props.couponError);
  }, [props.couponError])
  useEffect(() => { 
    setCouponSuccess(props.couponSuccess);
  }, [props.couponSuccess])

  const onDismiss = () => {
    setVisible(false);
    setCouponError(null)
    setCouponSuccess(null)
  }

  const handleFormSubmit = async (ev) => {
    ev.preventDefault();
    setProcessingTo(true);
    setVisible(true);
    try {
      const data = {
        schoolID: props.profile.schoolID,
        couponCode : coupon
      }
      await props.sendCoupon(data)
      setProcessingTo(false);
    } catch (err) {
      console.log(err)
    }
  }
  
    return (
        <>
          <Card>
            <CardHeader>
              <h4 className="title-weight">Have a coupon?</h4>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col lg="6">              
                    <Input 
                      placeholder="Enter Coupon" 
                      type="text" 
                      name="coupon" 
                      value={coupon} 
                      onChange={(e) => setCoupon(e.target.value)}/>              
                  </Col>
                  <Col lg="6">
                    {!isProcessing 
                    ? <Button className="btn-round" color="success" disabled={isProcessing} onClick={handleFormSubmit}>Submit</Button>
                    : <span className="text-center"><Spinner color="primary" size="md"/> </span>  }
                  </Col>
                </Row>
              </Form>   

              {couponError && 
              <Row className="mt-2">
                <Col md="12">            
                  <Alert color="danger" isOpen={visible} toggle={onDismiss}>
                    <strong>Ops!</strong> {couponError.message}
                  </Alert>
                </Col>
              </Row>}
              {couponSuccess && 
              <Row className="mt-2">
                <Col md="12">            
                  <Alert color="success" isOpen={visible} toggle={onDismiss}>
                    <strong>Great!</strong> {couponSuccess}
                  </Alert>
                </Col>
              </Row>}
            </CardBody>
          </Card>     
        </>
    )
}


const mapStateToProps = (state) => {
  return {
      profile: state.firebase.profile,
      couponError : state.admin.couponError,
      couponSuccess : state.admin.couponSuccess
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendCoupon : (data) => dispatch(sendCoupon(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponForm);
