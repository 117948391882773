import React from 'react';
import classnames from "classnames";
import { FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Col } from "reactstrap";

class FirstStep extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        institutionName: '',
        institutionNameState: null,
        firstname: '',
        firstnameState: null,
        lastname: '',
        lastnameState: null
      };
    }
  
    verifyLength = (value, length) => {
      if (value.length >= length) {
        return true;
      }
      return false;
    };

    change = (e, stateName, type, stateNameEqualTo) => {
      switch (type) {
        case "length":
          if (this.verifyLength(e.target.value, stateNameEqualTo)) {
            this.setState({ [stateName + "State"]: "valid" });
          } else {
            this.setState({ [stateName + "State"]: "invalid" });
          }
          break;
        default:
          break;
        }
        this.setState({ [stateName]: e.target.value });
    };

    isValidated = () => {
      if (
        this.state.institutionNameState === "valid" &&
        this.state.firstnameState === "valid" &&
        this.state.lastnameState === "valid"
      ) {
        return true;
      } else {
        if (this.state.institutionNameState !== "valid") {
          this.setState({ institutionNameState: "invalid" });
        }
        if (this.state.firstnameState !== "valid") {
          this.setState({ firstnameState: "invalid" });
        }
        if (this.state.lastnameState !== "valid") {
          this.setState({ lastnameState: "invalid" });
        }
        return false;
      }
    };

    render() {
      return (
        <>
         <div className="mb-3 text-center"><h4 className="text-primary">Let's get started with some basic information.</h4></div>
          <Col className="wizard-pills mr-auto ml-auto">
            <Form className="needs-validation" noValidate role="form">
              <FormGroup className={classnames({ focused: this.state.focusedInstitutionName })}>
                <InputGroup className="input-group-merge input-group-alternative mb-2">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Institution Name"
                    type="text"
                    onFocus={() => this.setState({ focusedInstitutionName: true })}
                    onBlur={() => this.setState({ focusedInstitutionName: false })}
                    valid={ this.state.institutionNameState === "valid"}
                    invalid={ this.state.institutionNameState === "invalid"}
                    onChange={e => this.change(e, "institutionName", "length", 2)}
                  />
                  <div className="invalid-feedback text-center">
                      This field is required.
                  </div>
                </InputGroup>
              </FormGroup>
              <FormGroup className={classnames({ focused: this.state.focusedName })}>
                <InputGroup className="input-group-merge input-group-alternative mb-2">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-badge" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Admin First Name"
                    type="text"
                    onFocus={() => this.setState({ focusedName: true })}
                    onBlur={() => this.setState({ focusedName: false })}
                    valid={ this.state.firstnameState === "valid"}
                    invalid={ this.state.firstnameState === "invalid"}
                    onChange={e => this.change(e, "firstname", "length", 2)}
                  />
                  <div className="invalid-feedback text-center">
                      This field is required.
                  </div>
                </InputGroup>
              </FormGroup>
              <FormGroup className={classnames({focused: this.state.focusedlastname })}>
                <InputGroup className="input-group-merge input-group-alternative mb-2">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-badge" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Admin Last Name"
                    type="text"
                    onFocus={() => this.setState({ focusedlastname: true })}
                    onBlur={() => this.setState({ focusedlastname: false })}
                    valid={ this.state.lastnameState === "valid"}
                    invalid={ this.state.lastnameState === "invalid"}
                    onChange={e => this.change(e, "lastname", "length", 2)}
                  />
                  <div className="invalid-feedback text-center">
                      This field is required.
                  </div>
                </InputGroup>
              </FormGroup>
            </Form>
          </Col>
        </>);
    }
  }

export default FirstStep;