import React from 'react';
import { Input, FormGroup, Row, Col } from "reactstrap";
import Select from "react-select";

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Q1: '',
      Q2A: '',
      Q2B: '',
      Q3: '',
      Q4: ''    
    }
  }

  componentDidMount() {
    this.setState({
      Q1: this.props.Q1,
      Q2A: this.props.Q2A,
      Q2B: this.props.Q2B,
      Q3: this.props.Q3,
      Q4: this.props.Q4,
      Q1State : this.props.Q1 && 'valid',
      Q2AState : this.props.Q2A && 'valid',
      Q3State : this.props.Q3 && 'valid',
      Q4State : this.props.Q4 && 'valid',
    })
  }

  componentDidUpdate(prevProps) { 
    if (this.props.Q1 !== prevProps.Q1) {
      this.setState({
        Q1: this.props.Q1,
        Q2A: this.props.Q2A,
        Q2B: this.props.Q2B,
        Q3: this.props.Q3,
        Q4: this.props.Q4,
        Q1State : this.props.Q1 && 'valid',
        Q2AState : this.props.Q2A && 'valid',
        Q3State : this.props.Q3 && 'valid',
        Q4State : this.props.Q4 && 'valid',
      })
    }
  }

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  selectValue = (stateName, value) => {
    this.setState({ [stateName]: value });
    this.setState({ [stateName + "State"]: "valid" });
  };

  change = (e, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "length":
        if (this.verifyLength(e.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "valid" });
        } else {
          this.setState({ [stateName + "State"]: "invalid" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: e.target.value });
  };

  isValidated = () => {
    if (
      this.state.Q1State === "valid" &&
      this.state.Q2AState === "valid" &&
      this.state.Q3State === "valid" &&
      this.state.Q4State === "valid"
    ) {
      return true;
    } else {
      if (this.state.Q1State !== "valid") {
        this.setState({ Q1State: "invalid" });
      }
      if (this.state.Q2AState !== "valid") {
        this.setState({ Q2AState: "invalid" });
      }
      if (this.state.Q3State !== "valid") {
        this.setState({ Q3State: "invalid" });
      }
      if (this.state.Q4State !== "valid") {
        this.setState({ Q4State: "invalid" });
      }
      return false;
    }
  };

  render(){
    const { Q1, Q2A, Q2B, Q3, Q4, Q1State, Q2AState, Q3State, Q4State } = this.state;
    return (
        <>
          <h1 className="mb-2">Your Gifts -</h1>  
          <p>Please answer the questions below</p>
          <Row>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label">
                  1. What do you do in your spare time (when and if you have spare time)?
                </label>
                <Input
                  rows="3"
                  type="textarea"
                  value={Q1}
                  onChange={e => this.change(e, "Q1", "length", 4)}
                />
                {Q1State === "invalid" && <label className="form-control-label text-primary warning">This field is required.</label>}   
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label">
                  2. Is what you do in your spare time reflected in your values and strengths? Yes or No? Explain.
                </label>
                <Select
                  className="react-select mb-1"
                  classNamePrefix="react-select"
                  value={Q2A}
                  required
                  onChange={(value) => this.selectValue('Q2A', value)}
                  options={[
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" }
                  ]}
                />
                <Input
                  rows="3"
                  placeholder="Explain"
                  type="textarea"
                  value={Q2B}
                  onChange={e => this.change(e, "Q2B", "length", 4)}
                />
                {Q2AState === "invalid" && <label className="form-control-label text-primary warning">This field is required.</label>}   
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label">
                  3. Do you have hobbies and interests outside your current job or business?
                </label>
                <Input
                  type="textarea"
                  rows="2"
                  value={Q3}
                  onChange={e => this.change(e, "Q3", "length", 4)}
                />
                {Q3State === "invalid" && <label className="form-control-label text-primary warning">This field is required.</label>}   
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label">
                  4. What positive qualities do you possess that you couldn’t deny if you try?
                </label>
                <Input
                  type="textarea"
                  rows="2"
                  value={Q4}
                  onChange={e => this.change(e, "Q4", "length", 4)}
                />
                {Q4State === "invalid" && <label className="form-control-label text-primary warning">This field is required.</label>}   
              </FormGroup>
            </Col>
          </Row>  
        </>
    )
  }
}

export default Step1;
