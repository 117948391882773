import React from 'react';
import { Card, CardBody, Progress, Button, Row, Col } from "reactstrap";

const StudentSeatsCard = (props) => {

    const { schoolData, history } = props;
    return (
          <Col md="4" xs="12">
            <Card className="bg-gradient-default">
              <CardBody>
                <div className="mb-2">
                  <sup className="text-white">Total Users: </sup>{" "}
                  <span className="h2 text-white">{schoolData.students && schoolData.students.length}</span>
                  <div className="text-light mt-2 text-sm"> Empty seats</div>
                  <div>
                    <span className="text-success font-weight-600">
                      {schoolData.students && schoolData.totalSeats - schoolData.students.length}
                    </span>
                  </div>
                </div>
                <Button
                  block
                  className="btn-neutral"
                  color="default"
                  size="sm"
                  onClick={() => {history.push('/admin/settings/#billing')}}
                >
                  Add Users Seats
                </Button>
              </CardBody>
              <CardBody>
                <Row>
                  <div className="col">
                    <small className="text-light">Invites: {schoolData.students && Math.round(schoolData.students.length/schoolData.totalSeats * 100, 2)} %</small>
                    <Progress
                      className="progress-xs my-2"
                      max="100"
                      value={schoolData.students && schoolData.students.length/schoolData.totalSeats * 100}
                      color="success"
                    />
                  </div>             
                </Row>
              </CardBody>
            </Card>
          </Col>
    )
}

export default StudentSeatsCard;
