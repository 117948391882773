import React from "react";
import { Col, Row, Card, CardHeader, CardBody, CardTitle, CardText, Collapse, Button } from "reactstrap";

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedCollapses: []
    };
  }

  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: []
      });
    } else {
      this.setState({
        openedCollapses: [collapse]
      });
    }
  };

  render() {
    return (
      <>
        <Col md="8" className="mr-auto ml-auto">
          <Card>
            <CardHeader className="text-center">
              <h2 className="mb-2"> Module 1 - Right Now</h2> 
            </CardHeader>
            <CardBody>
              <p className="mb-2">
                <span className="title-weight mr-1">Welcome to Pathways by LBB!</span> 
                 You will begin your career journey with us here in Module 1. In Module 1, we will get a little familiar with some of the initial building blocks of what makes you, YOU! After we get the demographics out of the way, we will get into what you value and get to know a little bit about your personality type
              </p> 
            </CardBody>
          </Card>  

          <div className="accordion">
            <Card className="card-plain">
              <CardHeader
                role="tab"
                onClick={() => this.collapsesToggle("collapseOne")}
                aria-expanded={this.state.openedCollapses.includes("collapseOne")}
              >
                <CardTitle className="mb-0" tag="h3">
                  1. Let us get to know you better.
                </CardTitle>
              </CardHeader>
              <Collapse role="tabpanel" isOpen={this.state.openedCollapses.includes("collapseOne")}>
                <CardBody>
                  <CardText className="text-dark">
                    This exercise will allow us to discover where you are right now, your relationship with your current job, career and expectations.
                  </CardText>
                  <Row className="justify-content-end mr-1">
                    <Button color="default" outline type="button" onClick={() => this.props.history.push('/user/module1/ex1')}>
                      Continue
                    </Button>
                  </Row>    
                </CardBody>
              </Collapse>
            </Card>
            <Card className="card-plain">
              <CardHeader
                role="tab"
                onClick={() => this.collapsesToggle("collapseTwo")}
                aria-expanded={this.state.openedCollapses.includes("collapseTwo")}
              >
                <CardTitle className="mb-0" tag="h3">
                  2. Discover your values
                </CardTitle>
              </CardHeader>
              <Collapse role="tabpanel" isOpen={this.state.openedCollapses.includes("collapseTwo")}>
                <CardBody>
                  <CardText className="text-dark">
                    This exercise will guide you in identifying your personal values for yourself. Knowing your personal values gives you another tool which you can turn to when you are making big and small decisions in your life.
                  </CardText>
                  <Row className="justify-content-end mr-1">
                    <Button color="default" outline type="button" onClick={() => this.props.history.push('/user/module1/ex2')}>
                      Continue
                    </Button>
                  </Row>
                </CardBody>
              </Collapse>
            </Card>
            <Card className="card-plain">
              <CardHeader
                role="tab"
                onClick={() => this.collapsesToggle("collapseThree")}
                aria-expanded={this.state.openedCollapses.includes("collapseThree")}
              >
                <CardTitle className="mb-0" tag="h3">
                  3. MBTI Assessment
                </CardTitle>
              </CardHeader>
              <Collapse role="tabpanel" isOpen={this.state.openedCollapses.includes("collapseThree")}>
                <CardBody>
                  <CardText className="text-dark">
                    The next step in laying out your career path for you is to identify what your personality type is. We will tell you what your type of personality is and what you may lean towards because of your personality type. And, yes, There are certain careers and jobs that align with certain personality types. We are not here to tell you which career or job you should choose for yourself, that is for you to figure out and decide for yourself based on the information you uncover as you work through Pathways
                  </CardText>
                  <Row className="justify-content-end mr-1">
                    <Button color="default" outline type="button" onClick={() => this.props.history.push('/user/module1/ex3')}>
                      Continue
                    </Button>
                  </Row>
                </CardBody>
              </Collapse>
            </Card>
          </div>
        </Col>
      </>
    );
  }
}

export default Panels;