import firebase from 'firebase/app';
import "firebase/analytics";
import 'firebase/firestore';
import 'firebase/auth';
import "firebase/storage";
import "firebase/functions";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: "app.careerpathways.app",
    //authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

class Fire {

    // ----- Initialize Firebase -----
    constructor() {
        if (!firebase.apps.length){
            firebase.initializeApp(firebaseConfig);
            firebase.analytics();
            firebase.firestore();
            firebase.storage();
            firebase.functions();
        };
    }

    createAdminUser = async (user) => {
      const displayName = user.firstname + ' ' + user.lastname;
      try { 
          const batch = this.firestore.batch();
          await firebase.auth().createUserWithEmailAndPassword(user.email, user.password);
          await firebase.auth().currentUser.updateProfile({displayName: displayName});
          let userdb = this.firestore.collection("users").doc(this.uid);
          let schooldb = this.firestore.collection("schools").doc(this.uid);  
          const addAdminRole = await firebase.functions().httpsCallable('addAdminRole');  
          await addAdminRole({ email: user.email }).then(result => {console.log(result)});
          
          await batch.set( userdb,{
              displayName: displayName,
              firstname: user.firstname,
              lastname: user.lastname,
              email: user.email,
              joined: new Date(),
              photoURL: null,
              school: user.school,
              schoolID: this.uid,
              type: "admin",
              membership: "starter"
          })
          await batch.set( schooldb,{
              school: user.school,
              schoolID: this.uid,
              adminEmail: user.email,
              adminName: displayName,
              address: user.address,
              city: user.city,
              state: user.state,
              zip: user.zip,
              joined: new Date(),
              numStudents: 0,
              totalSeats: 0,
              students: []
          })
          batch.commit();
      } catch (error) {
        console.log(error)
        throw(error)
      }
  };

    createUser = async (user) => { 
      try { 
          let arr = user.displayName.split(/\s+/);
          let firstname = arr[0];
          let lastname = arr[1];
          await firebase.auth().createUserWithEmailAndPassword(user.email, user.password);
          await firebase.auth().currentUser.updateProfile({displayName: user.displayName});
          let userdb = this.firestore.collection("users").doc(this.uid);
          const addStudentRole = await firebase.functions().httpsCallable('addStudentRole');  
          await addStudentRole({ email: user.email }).then(result => {console.log(result)});

          await userdb.set({
              displayName: user.displayName,
              firstname: firstname,
              lastname: lastname,
              email: user.email,
              joined: new Date(),
              photoURL: null,
              uid: this.uid,
              type: "student",
              membership: user.membership,
              schoolID: user.schoolID,
              institution_userID : user.institution_userID || null,
          })
      } catch (error) {
          throw(error)
      }
    };

    uploadPhotoAsync = async (uri,filename) => {
        return new Promise(async (res, rej) => {
            const response = await fetch(uri);
            const file = await response.blob();

            let upload = firebase.storage()
                .ref(filename)
                .put(file);

            upload.on(
                "state_changed",
                snapshot => {},
                err => {
                    rej(err);
                },
                async () => {
                    const url = await upload.snapshot.ref.getDownloadURL();
                    console.log(url)
                    res(url);
                }
            );
        });
    };

    updateAvatar = async (photoUri) => {
        const path = `avatars/${this.email}/${Date.now()}`;
        const remoteUri = await this.uploadPhotoAsync(photoUri, path);
        try { 
            let db = this.firestore.collection("users").doc(this.uid);
            db.update({ photoURL: remoteUri });
        } 
		    catch (error) {
            alert("Error: ", error);
        }
    };

    gmailUserDBAction = async (user, membership, schoolID, institution_userID) => {
      const userRef = this.firestore.collection("users").doc(this.uid);
      const addStudentRole = await firebase.functions().httpsCallable('addStudentRole');  
    
      try { 
        const userDoc = await userRef.get();
        const databaseTask = [];
        if (userDoc.exists) {
          const p = await userRef.update({
            displayName: user.displayName,
            email: user.email,
            lastLogin: new Date(),
            photoURL: user.photoURL,
          });
          databaseTask.push(p);
        } else {
          await addStudentRole({ email: user.email }).then(result => {console.log(result)});
          const p = await userRef.set({
            joined: new Date(),
            displayName: user.displayName,
            email: user.email,
            lastLogin: new Date(),
            photoURL: user.photoURL,
            uid: user.uid,
            type: "student",
            membership: membership,
            schoolID: schoolID,
            institution_userID : institution_userID,
          });
          databaseTask.push(p);
        }
        return Promise.all(databaseTask);
      } catch (error) {
        throw (error.message)
      }
    };

    updateIntitutionUserCollection = async (user) => {
      const schoolRef = this.firestore.collection('schools').doc(user.schoolID);
      const userRef = schoolRef.collection('institution_users').doc(user.institution_userID);
      try {
        await userRef.update({
          registered: true,
          joined: new Date()
        })
      } catch (error) {
        throw(error)
      }
    }

    get app(){
        return firebase.app()
    }

    get firestore() {
        return firebase.firestore();
    }

    get functions() {
        return firebase.functions();   
    }

    get auth(){
        return firebase.auth()
    }

    get uid() {
        return (firebase.auth().currentUser || {}).uid;
    }

    get email(){
        return (firebase.auth().currentUser || {}).email;
    }

    get provider(){
        return new firebase.auth.GoogleAuthProvider();
    }

    get EmailAuthProvider(){
        return firebase.auth.EmailAuthProvider
    }

    get fbConfig(){
        return firebaseConfig;
    }

}

Fire.shared = new Fire();
export default Fire;