import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button, Spinner, Alert } from "reactstrap";
import { SuperAdminsTable } from "components/Tables";
import ReactBSAlert from "react-bootstrap-sweetalert";

import { connect } from 'react-redux';
import { createSuperAdmin, getAllResponses } from 'redux/actions/superAdminActions';

const AddSuperAdmin = (props) => {
    const [isProcessing, setProcessingTo] = useState(false)
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail ] = useState('')
    const [emailError, setEmailError] = useState(null)
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState(null)

    const [alertError, setAlertError] = useState(null)
    const [alertSuccess, setAlertSuccess] = useState(null)
    const [visible, setVisible] = useState(true);

    const [alert, setAlert] = useState(null)

    useEffect(() => { 
      setAlertError(props.createSuperAdminError);
    }, [props.createSuperAdminError])
    useEffect(() => { 
      setAlertSuccess(props.createSuperAdminSuccess);
    }, [props.createSuperAdminSuccess])
  
    const onDismiss = () => {
      setVisible(false);
      setAlertError(null)
      setAlertSuccess(null)
    }


    const verifyEmail = (value) => {
      var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRex.test(value)) {
        return true;
      }
      setEmailError('Must be a valid email')
      return false;
    }

    const verifyPassword = (value, length) => {
      if (value.length >= length) {
        return true;
      }
      setPasswordError('Must contain 6 characters.')
      return false;
    }

    const hideAlert = () => { 
      setAlert(null) 
    }

    const verifyFields = () => {
      if ( 
        firstname !== '' &&
        lastname !== '' &&
        verifyEmail(email) &&
        verifyPassword(password, 6)
      ) {
        return true;
      } else {
        return false;
      }
    }

    const handleSubmit = async () => {
      setProcessingTo(true);
      setVisible(true);
      const data = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        password: password
      }

      try {      
        if (verifyFields()){
          await props.createSuperAdmin(data)
          setProcessingTo(false);
          setPassword('')
          setEmail('')
        } else {
          setAlert(
            <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "100px" }}
              title=""
              onConfirm={() => hideAlert()}
              confirmBtnBsStyle="default"
              confirmBtnText="Try again."
              btnSize=""
            >
              Ops! Something is not right.
            </ReactBSAlert>
          )
          setProcessingTo(false);
        } 
      } catch (error) {
        console.log(error.message)
        return
      }
    }

    const { profile } = props;
    if ( profile.isEmpty ) return <div className="text-center"><Spinner color="primary" size="md"/></div> 
    if ( profile.token && profile.token.claims.student ) return <Redirect direct to="/user/home"/>
    if ( profile.token && profile.token.claims.admin ) return <Redirect direct to="/admin/home"/>

    return (
          <Container className="mt-2 mb-6" fluid>
            { alert }
            <Row className="justify-content-center my-4">
              <Col xl="12" className="text-center">
                <h1 className="text-dark title-weight my-2">Add Pathways Admin</h1>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md="6" >
                <Card>
                  <CardHeader>
                    <h4 className="mb-0 text-info">Please fill out the form below</h4>
                  </CardHeader>
                  <CardBody>
                  
                  { alertError && 
                    <Row className="mt-2">
                      <Col md="12">            
                        <Alert color="danger" isOpen={visible} toggle={onDismiss}>
                          <strong>Ops!</strong> {alertError.message}
                        </Alert>
                      </Col>
                    </Row>}
                  { alertSuccess && 
                    <Row className="mt-2">
                      <Col md="12">            
                        <Alert color="success" isOpen={visible} toggle={onDismiss}>
                          <strong>Great!</strong> {alertSuccess}
                        </Alert>
                      </Col>
                    </Row>}

                    <Form>
                      <FormGroup className="row justify-content-center">
                        <Label className="form-control-label" htmlFor="firstname" md="4">
                          First Name
                        </Label>
                        <Col md="8">
                          <Input
                            id="firstname"
                            type="text"
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                          /> 
                        </Col>
                      </FormGroup>
                      <FormGroup className="row justify-content-center">
                        <Label className="form-control-label" htmlFor="lastname" md="4">
                          Lastname
                        </Label>
                        <Col md="8">
                          <Input
                            id="lastname"
                            type="text"
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row justify-content-center">
                        <Label className="form-control-label" htmlFor="example-email-input" md="4">
                          Email
                        </Label>
                        <Col md="8">
                          <Input
                            id="example-email-input"
                            type="email"
                            autoComplete="username"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value); 
                              setEmailError(null)
                            }}
                          />
                          {emailError && <label className="h5 warning text-primary">{emailError}</label>}
                        </Col>
                      </FormGroup>
                      <FormGroup className="row justify-content-center">
                        <Label className="form-control-label" htmlFor="password" md="4">
                          Password
                        </Label>
                        <Col md="8">
                          <Input
                            id="password"
                            type="password"
                            autoComplete="new-password"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                              setPasswordError(null)
                            }}
                          />
                          <h6 className="text-cyan">Use at least 6 characters, must contain at least one capital letter.</h6>
                          {passwordError && <label className="h5 warning text-primary">{passwordError}</label>}
                        </Col>
                      </FormGroup>
                      <hr/>
                      <Col md="6" xs="12" className="ml-auto">
                        <Row className="justify-content-end">
                          <Button 
                            className="btn-round" 
                            color="default" 
                            disabled={isProcessing}
                            onClick={handleSubmit}>
                            {isProcessing ? "Processing..." : "Submit"}
                          </Button>
                        </Row>
                      </Col>
                    </Form>
                  </CardBody>
                </Card>
              </Col>    
            </Row>
            <Row className="justify-content-center">
              <Col md="10"> 
                <SuperAdminsTable/>
              </Col>
            </Row>
          </Container>
      )
}

const mapStateToProps = (state) => {
  return {
      uid: state.firebase.auth.uid,
      profile: state.firebase.profile,
      createSuperAdminError: state.superAdminReducer.createSuperAdminError,
      createSuperAdminSuccess: state.superAdminReducer.createSuperAdminSuccess
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createSuperAdmin: (data) => dispatch(createSuperAdmin(data)),
    getAllResponses: (data) => dispatch(getAllResponses(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSuperAdmin);
