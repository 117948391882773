import React from "react";
import { Container, Row, Col } from "reactstrap";

const LoginAdminHeader = (props) => {
    return (
        <div className="header bg-indigo py-7 py-lg-8 pt-lg-9">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col className="px-2" lg="6" md="8" xl="5">
                    <h2 className="text-white">Welcome Back!</h2>
                    <p className="text-white">Please enter your admin credentials.<br/>Or create an account 
                      <span
                        style = {{cursor: 'pointer'}}
                        className="text-warning font-weight-400 ml-1" 
                        onClick={() => {props.history.push('/register-institutions')}}
                      > 
                        here.
                      </span>
                    </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
    )
}

export default LoginAdminHeader;
