import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Redirect } from 'react-router-dom';

import ReactWizard from "react-bootstrap-wizard";
import AuthFooter from "components/Footers/AuthFooter.js";
import AuthHeader from "components/Headers/AuthHeader";
import MainNavbar from "components/Navbars/MainNavbar";
import { InstitutionRegisterStep1, InstitutionRegisterStep2, InstitutionRegisterStep3 } from "components/Wizards";

import ErrorMessage from "../../components/Alerts/ErrorMessage";
import { connect } from 'react-redux';
import Fire from '../../Fire';
  
const steps = [
  { stepName: "1", component: InstitutionRegisterStep1 },
  { stepName: "2", component: InstitutionRegisterStep2 },
  { stepName: "3", component: InstitutionRegisterStep3 },
];

class RegisterInstitutions extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null
    };
  }

  finishButtonClick = (allStates) => {
    const data = {
      school: allStates["1"].institutionName,
      firstname: allStates["1"].firstname,
      lastname: allStates["1"].lastname,
      address: allStates["2"].address,
      city: allStates["2"].city,
      state: allStates["2"].state.value,
      zip: allStates["2"].zip,
      email: allStates["3"].email,
      password: allStates["3"].password
    }
    this.handleCredentialsSignUp(data)
  }

  handleCredentialsSignUp = async (data) => {
    try {  
      await Fire.shared.createAdminUser(data);
    } catch (error) {
      this.setState({ errorMessage: error.message });
    }
  }

  
  render() {
    const { errorMessage } = this.state;
    const { profile } = this.props;
    return (
      <>
       { profile.token && profile.token.claims.admin ? <Redirect direct to="/admin/home"/> : 
       <>
        <div className="bg-indigo main-content" ref="mainContent">
          <MainNavbar />
          <AuthHeader
            title="Sign Up Today!"
            lead="Create Admin Account, help your students find their pathway."
          />
          <Container className="mt--8 pb-5">
            <Row>
              <Col md="8" xs="12" className="wizard-pills mr-auto ml-auto">
                <ReactWizard
                  steps={steps}
                  navSteps
                  validate
                  progressbar
                  title="Pathways by LBB"
                  headerTextCenter
                  finishButtonClasses="btn-default"
                  nextButtonClasses="btn-info"
                  previousButtonClasses="btn-danger"
                  finishButtonClick={this.finishButtonClick}
                />
                {errorMessage && <ErrorMessage message={errorMessage}/>}     
              </Col>
            </Row>
          </Container>
        </div>
        <AuthFooter/>  
        </>
      }
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile || {}
  }
}

export default connect(mapStateToProps)(RegisterInstitutions);
