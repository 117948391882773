import React from "react";
import { Input, FormGroup, Row, Col } from "reactstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Q2: "", // Current job
      Q7: "", // DOB
      Q18: "", // Phone
      Q19: "", // Can contact
      Q20: "", // Dream job
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.Q2 !== prevProps.Q2 || this.props.Q20 !== prevProps.Q20 || this.props !== prevProps) {
      this.verifyRequiredFields();
      this.setState({
        Q2: this.props.Q2,
        Q7: this.props.Q7,
        Q18: this.props.Q18,
        Q19: { label: this.props.Q19, value: this.props.Q19 },
        Q20: this.props.Q20,
      });
    }
  }

  verifyRequiredFields = () => {
    if (this.state.Q7 !== null && this.state.Q7 !== "" && this.props.Q7 !== undefined) {
      this.setState({ Q7State: "valid" });
    }
    if (this.state.Q18 !== null && this.state.Q18 !== "" && this.props.Q18 !== undefined) {
      this.setState({ Q18State: "valid" });
    }
    if (this.state.Q19 !== null && this.state.Q19 !== "" && this.props.Q19 !== undefined) {
      this.setState({ Q19State: "valid" });
    }
    if (this.state.Q20 !== null && this.state.Q20 !== "" && this.props.Q19 !== undefined) {
      this.setState({ Q20State: "valid" });
    }
  };

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  selectValue = (stateName, value) => {
    this.setState({ [stateName]: value });
    this.setState({ [stateName + "State"]: "valid" });
  };

  change = (e, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "length":
        if (this.verifyLength(e.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "valid" });
        } else {
          this.setState({ [stateName + "State"]: "invalid" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: e.target.value });
  };

  isValidated = () => {
    if (
      this.state.Q7State === "valid" &&
      this.state.Q18State === "valid" &&
      this.state.Q19State === "valid" &&
      this.state.Q20State === "valid"
    ) {
      return true;
    } else {
      if (this.state.Q7State !== "valid") {
        this.setState({ Q7State: "invalid" });
      }
      if (this.state.Q18State !== "valid") {
        this.setState({ Q18State: "invalid" });
      }
      if (this.state.Q19State !== "valid") {
        this.setState({ Q19State: "invalid" });
      }
      if (this.state.Q20State !== "valid") {
        this.setState({ Q20State: "invalid" });
      }
      return false;
    }
  };

  render() {
    const { Q2, Q7, Q18, Q19, Q20, Q7State, Q18State, Q19State, Q20State } = this.state;

    return (
      <>
        <h1> Let's get started!</h1>

        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label">
                1. Current job or position:
              </label>
              <Input
                type="text"
                value={Q2 || ""}
                onChange={(e) => this.change(e, "Q2")}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label">
                2. Dream job or position *
              </label>
              <Input
                type="text"
                value={Q20 || ""}
                onChange={(e) => this.change(e, "Q20", "length", 3)}
              />
              {Q20State === "invalid" && (
                <label className="form-control-label text-primary warning">
                  This field is required.
                </label>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="4">
            <FormGroup>
              <label className="form-control-label">3. Date of Birth *</label>
              <ReactDatetime
                inputProps={{ className: "form-control" }}
                timeFormat={false}
                value={Q7}
                onChange={(value) => this.selectValue("Q7", value)}
              />
              {Q7State === "invalid" && (
                <label className="form-control-label text-primary warning">
                  Enter your DOB.
                </label>
              )}
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="form-control-label">4. Phone # * <small> (e.g. 5852008009)</small></label>
              <Input
                type="number"
                value={Q18 || ""}
                onChange={(e) => this.change(e, "Q18", "length", 3)}
              />
              {Q18State === "invalid" && (
                <label className="form-control-label text-primary warning">
                  Enter your phone number in only numbers format.
                </label>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <FormGroup>
              <label className="form-control-label">
                5. Can we contact you for future job placement? *
              </label>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                value={Q19}
                required
                onChange={(value) => this.selectValue("Q19", value)}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]}
              />
              {Q19State === "invalid" && (
                <label className="form-control-label text-primary warning">
                  This field is required.
                </label>
              )}
            </FormGroup>
          </Col>{" "}
        </Row>
      </>
    );
  }
}

export default Step2;
