import React, {useState, useEffect} from "react";
import { Row, Col, ListGroup, ListGroupItem, } from "reactstrap";
import ModuleLocked from "components/Sections/ModuleLocked"

import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';


const M2Ex1Summary = (props) => {
    const [ goals, setGoals ] = useState({})

    useEffect(() => { setGoals(props.exercise1m2) }, [props.exercise1m2])
    const { progress, moduleBlocked } = props;
    return (
      <>
      { progress < 0 || moduleBlocked === true
      ? <ModuleLocked message={'Exercise Locked'}/> 
      : <>
          <Row className="justify-content-center">
            <Col md='8' xs='12' className='mt-2'>
            <h4 className="title-weight"> Your Short Term Goals</h4>
              <ListGroup className="thead-light">
                <ListGroupItem className="list-head">
                  <Row>
                    <Col md="4" xs="6">Short Term Goals</Col>
                    <Col md="8" xs="6">Response</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="list-results">
                  <Row>
                    <Col md="4" xs="5"><p>Smarter Goal 1 </p></Col>
                    <Col md="8" xs="7">{goals.goal1}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="list-results">
                    <Col md="4" xs="5"><p>Smarter Goal 2</p></Col>
                    <Col md="8" xs="7">{goals.goal2}</Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>        
            </Col>
          </Row>
        </>
      }
      </>
    )
}


const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    exercise1m2 : state.firestore.data.exercise1m2 || {}
  }
};

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ 
          collection: "modules", 
          doc: "module2",
          subcollections: [{ collection: "exercises", doc: "exercise1"}],
        }],
        storeAs: 'exercise1m2'
      }]
    }),
)(M2Ex1Summary);