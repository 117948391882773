import React, { Component } from 'react';
import { Button, Modal, Form, FormGroup, Input, UncontrolledAlert } from "reactstrap";
import ErrorMessage from '../Alerts/ErrorMessage';
import { connect } from 'react-redux';
import { passwordUpdate } from '../../redux/actions/profileActions';

class EditPasswordModal extends Component {

  constructor(props) {
      super(props);
      this.state = {
        currentPassword: '',
        newPassword:'',
        confirmNewPassword:'',

        newPasswordState: '',
        confirmNewPasswordState: '',
        passwordModal: false,
        profileError: null,
        successMessage: null,
        errorMessage: null
      }
  }

  componentDidUpdate(prevProps){
    if (this.props.profileError !== prevProps.profileError || this.props.successMessage !== prevProps.successMessage) {
      this.setState({ 
        profileError: this.props.profileError,
        successMessage: this.props.successMessage
      })
    }
  };

  handleChange = (e) => {
    const itemName = e.target.name;
    const itemValue = e.target.value;
    this.setState({ [itemName]: itemValue, errorMessage: null, profileError: null, successMessage: null });
  };

  compare = (string1, string2) => {
    if (string1 === string2) {
      return true;
    }
    return false;
  };

  changeNewPassword = (event, stateName, type, stateNameEqualTo) => {
    switch (type) {
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "State"]: "valid" });
          this.setState({ [stateNameEqualTo + "State"]: "valid" });
        } else {
          this.setState({ [stateName + "State"]: "invalid" });
          this.setState({ [stateNameEqualTo + "State"]: "invalid" });
        }
        break;
      default:
        break;
      }
      this.setState({ [stateName]: event.target.value, errorMessage: null, profileError: null, successMessage: null });
  };
      
  onChangePasswordPress = () => {
    const { currentPassword, confirmNewPassword, newPasswordState } = this.state;
    if ( newPasswordState !== 'invalid') {
      this.props.passwordUpdate(currentPassword, confirmNewPassword)
        .then(() => {
            /*const passwordChangeNotificationEmail = Fire.shared.functions.httpsCallable('passwordChangeNotificationEmail');
            passwordChangeNotificationEmail({ email: this.props.email, name: this.props.displayName })
              .then(result => {
                console.log(result)
              });*/
            this.setState({ 
              currentPassword: '', 
              newPassword:'', 
              confirmNewPassword: '', 
              newPasswordState: '',
              confirmNewPasswordState: '',
            })
        })
        .catch((error) => { 
          this.setState({ errorMessage : error.message })  
        });
    }
  };  

  closeModal = () => {
    this.setState({successMessage: null, profileError: null})
    this.props.toggle()
  };

  render() {
    const { profileError, successMessage, errorMessage  } = this.state;
      return (
           <Modal className="modal-dialog-centered" size="sm" isOpen={this.props.isOpen} toggle={this.closeModal}>
           <div className="modal-header">
             <h6 className="modal-title" id="modal-title-default">
               Edit Password
             </h6>
             <button
               aria-label="Close"
               className="close"
               data-dismiss="modal"
               type="button"
               onClick={this.closeModal}
             >
               <span aria-hidden={true}>×</span>
             </button>
           </div>
           <div className="modal-body">
            <Form action="#" method="#">
              { errorMessage && <ErrorMessage message={errorMessage}/> }
              { profileError && <ErrorMessage message={profileError.message}/> } 
              { successMessage && 
                <UncontrolledAlert color="success" fade={false}>
                  <span><b>Nice! - </b>
                      Your password has been updated successfully.
                  </span>
                </UncontrolledAlert>}    
              <h5>Current Password</h5>
                <FormGroup>
                  <Input 
                    placeholder="Please enter your current password"
                    type="password"
                    name="currentPassword"
                    autoComplete="off"
                    value={this.state.currentPassword}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              <h5>New Password</h5>
                <FormGroup className={this.state.newPasswordState}>
                  <Input 
                    type="password"
                    autoComplete="off"
                    onFocus={() => this.setState({ focusedPassword: true })}
                    onBlur={() => this.setState({ focusedPassword: false })}
                    valid={ this.state.newPasswordState === "valid"}
                    invalid={ this.state.newPasswordState === "invalid"}
                    onChange={(e) => this.changeNewPassword(e, "newPassword", "equalTo", "confirmNewPassword")}
                  />     
                </FormGroup> 
              <h5>Confirm New Password</h5>
                <FormGroup className={this.state.confirmNewPasswordState}>            
                  <Input
                    placeholder="Confirm Password"
                    type="password"
                    autoComplete="off"
                    onFocus={() => this.setState({ focusedConfirmPassword: true })}
                    onBlur={() => this.setState({ focusedConfirmPassword: false })}
                    valid={ this.state.confirmNewPasswordState === "valid"}
                    invalid={ this.state.confirmNewPasswordState === "invalid"}
                    onChange={e => this.changeNewPassword(e, "confirmNewPassword", "equalTo", "newPassword")}
                  />
                  { this.state.confirmNewPasswordState === "invalid" && 
                    <p className="invalid-feedback text-center">
                      Password doesn't match.
                    </p> }
                </FormGroup>   
            </Form>   
           </div>
           <div className="modal-footer">
             <Button color="success" type="button" size="md" onClick={this.onChangePasswordPress}>
               Save New Password
             </Button>
             <Button
               className="ml-auto"
               color="link"
               data-dismiss="modal"
               type="button"
               onClick={this.closeModal}
             >
               Close
             </Button>
           </div>
         </Modal>
      );
    }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profileError: state.userProfile.profileError,
    successMessage: state.userProfile.successMessage
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    passwordUpdate: (currentPassword, newPassword) => dispatch(passwordUpdate(currentPassword, newPassword))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPasswordModal);