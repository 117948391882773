/*eslint-disable*/
import React from "react";
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

class DashboardFooter extends React.Component {
  render() {
    return (
      <>
        <Container fluid>
          <footer className="footer pt-0">
            <Row className="align-items-center justify-content-lg-between">
              <Col lg="6">
                <div className="copyright text-center text-lg-left text-muted">
                  © {new Date().getFullYear()}{" "} Little Black Buddha
                </div>
              </Col>
              <Col lg="6">
                <Nav className="nav-footer justify-content-center justify-content-lg-end">
                  <NavItem>
                    <NavLink
                      href="https://careerpathways.app/about"
                    >
                      About Us
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://careerpathways.app/terms-conditions"
                      target="_blank"
                    >
                      Terms
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://careerpathways.app/privacy-policy"
                      target="_blank"
                    >
                      Privacy
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://littleblackbuddha.com/contact/"
                      target="_blank"
                    >
                      Contact
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </footer>
        </Container>
      </>
    );
  }
}

export default DashboardFooter;
