import React, {useState, useEffect} from 'react';
import { Card, CardHeader, CardBody, Collapse, Button, Row } from "reactstrap";
import { M4Ex1Summary, M4Ex2Summary, M4Ex3Summary } from "components/Tables";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const CollapseM4 = (props) => {

    const [ openedCollapses, setOpenedCollapses] = useState([])
    const [ module4, setModule4] = useState({});

    useEffect(() => { 
      setModule4(props.module4);
    }, [props.module4])
    
    const collapsesToggle = (collapse) => {
      if (openedCollapses.includes(collapse)) {
        setOpenedCollapses([]);
      } else {
        setOpenedCollapses([collapse]);
      }
    };

    return (
      <>
        <div className="accordion">
          <Card className="card-plain">
            <CardHeader
              role="tab"
              onClick={() => collapsesToggle("collapseOne")}
              aria-expanded={openedCollapses.includes( "collapseOne" )}
            >
              <h5 className="mb-0">Exercise 1</h5>
            </CardHeader>
            <Collapse role="tabpanel" isOpen={openedCollapses.includes("collapseOne")}>
              <CardBody>
                <M4Ex1Summary history={props.history} progress={module4.progress} moduleBlocked={module4.blocked}/>
                  <Row className="justify-content-end mr-1 my-2">
                    <Button color="primary" size="sm" onClick={() => props.history.push('/user/module4/ex1')}>
                      Continue
                    </Button>
                  </Row>
              </CardBody>
            </Collapse>
          </Card>

          <Card className="card-plain">
            <CardHeader
              role="tab"
              onClick={() => collapsesToggle("collapseTwo")}
              aria-expanded={openedCollapses.includes( "collapseTwo" )}
            >
              <h5 className="mb-0">Exercise 2</h5>
            </CardHeader>
            <Collapse role="tabpanel" isOpen={openedCollapses.includes("collapseTwo")}>
              <CardBody>
                <M4Ex2Summary history={props.history} progress={module4.progress} moduleBlocked={module4.blocked}/>
                  <Row className="justify-content-end mr-1 my-2">
                    <Button color="primary" size="sm" onClick={() => props.history.push('/user/module4/ex2')}>
                      Continue
                    </Button>
                  </Row>
              </CardBody>
            </Collapse>
          </Card>
          
          <Card className="card-plain">
            <CardHeader
              role="tab"
              onClick={() => collapsesToggle("collapseThree")}
              aria-expanded={ openedCollapses.includes("collapseThree") }
            >
              <h5 className="mb-0">Exercise 3</h5>
            </CardHeader>
            <Collapse role="tabpanel"isOpen={openedCollapses.includes("collapseThree")}>
              <CardBody>
                <M4Ex3Summary history={props.history} progress={module4.progress} moduleBlocked={module4.blocked}/>
                  <Row className="justify-content-end mr-1 my-2">
                    <Button color="primary" size="sm" onClick={() => props.history.push('/user/module4/ex3')}>
                      Continue
                    </Button>
                  </Row>
              </CardBody>
            </Collapse>
          </Card>
        </div>
      </>
    )
}


const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    module4 : state.firestore.data.module4 || {},
  }
};

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module4"}],
        storeAs: 'module4'
      }]
    }),
)(CollapseM4);
