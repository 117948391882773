import React from 'react';
import { Button, Modal } from "reactstrap";

const SuperpowersModal = (props) => {

    return ( 
        <Modal className="modal-dialog-centered" isOpen={props.isOpen} toggle={props.toggle}>
          <div className="modal-header">
            <h6 className="modal-title">
              Examples
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => props.toggle()}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <div className="modal-body">        
            <ul>
              <li>
                <h4 className="text-dark">Hello, my name is Lauren, I have spent my professional career creating storybook immersive experiences for young children. I am looking to get started writing screen plays.</h4>
              </li>
              <li>
                <h4 className="text-dark">Hi, I’m the Batman of strategic financial planning and over time, I have helped over 50 clients save millions as a result. I am looking to expand my reach beyond Gotham City.</h4>
              </li>
              <li>
                <h4 className="text-dark">My name is Sheerah, I’m the Beyonce of client acquisition for coaches. I am looking to build a new website for my business.</h4>
              </li>
            </ul>
          </div>

          <div className="modal-footer">
            <Button color="primary" size="sm" type="button" onClick={() => props.toggle()}>
              Ok, Got it
            </Button>
          </div>
        </Modal> 
    )
}
export default SuperpowersModal;