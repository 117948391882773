import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 5px 0`,
  display: "inline-flex",
  width: "280px",
  // change background colour if dragging
  background: isDragging ? "#3a6a88 " : "white",
  color: isDragging ? "white" : "",
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#ea9b82" : "#efcb87",
  //padding: grid,
  //margin: "10px 0"
});

export default class ItemsToDrag extends React.Component {
  render() {
    return (
      <Droppable droppableId={this.props.type} type={`droppableSubItem-${this.props.type}`}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {this.props.subItems.map((item, index) => (
              <Draggable key={item.value} draggableId={item.value} index={index}>
                {(provided, snapshot) => (
                  <div style={{ display: "flex" }} className="dnd-text">
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <span className="title-weight mr-2">{this.props.position}. </span>{item.value}
                    </div>
                    {provided.placeholder}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}
