import React from 'react';

const ErrorMessage = (props) => {
    return (       
      <div className="col-12 alert alert-danger px-3 text-center">
          <strong>Ops!</strong> {props.message}
      </div>  
    )
};

export default ErrorMessage;
