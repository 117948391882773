import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Collapse, Button, Row } from "reactstrap";
import { M5Ex1Summary } from "components/Tables";

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const CollapseM5 = (props) => {

    const [ openedCollapses, setOpenedCollapses] = useState([])
    const [ module5, setModule5] = useState({});

    useEffect(() => { 
      setModule5(props.module5);
    }, [props.module5])
    
    const collapsesToggle = (collapse) => {
      if (openedCollapses.includes(collapse)) {
        setOpenedCollapses([]);
      } else {
        setOpenedCollapses([collapse]);
      }
    };

    return (
      <>
        <div className="accordion">
          <Card className="card-plain">
            <CardHeader
              role="tab"
              onClick={() => collapsesToggle("collapseOne")}
              aria-expanded={openedCollapses.includes( "collapseOne" )}
            >
              <h5 className="mb-0">Exercise 1</h5>
            </CardHeader>
            <Collapse role="tabpanel" isOpen={openedCollapses.includes("collapseOne")}>
              <CardBody>
                <M5Ex1Summary history={props.history} progress={module5.progress} moduleBlocked={module5.blocked}/>
                  <Row className="justify-content-end mr-1 my-2">
                    <Button color="primary" size="sm" onClick={() => props.history.push('/user/module5/ex1')}>
                      Continue
                    </Button>
                  </Row>
              </CardBody>
            </Collapse>
          </Card>
          
        </div>
      </>
    )
}


const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    module5 : state.firestore.data.module5 || {},
  }
};

export default compose( 
  connect(mapStateToProps, null),
  firestoreConnect((props)=> {
    if (!props.uid) return []
    return [
      {
        collection:'users', 
        doc: props.uid,
        subcollections: [{ collection: "modules", doc: "module5"}],
        storeAs: 'module5'
      }]
    }),
)(CollapseM5);